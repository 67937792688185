import { Component, Inject, Input, Sanitizer } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from "@angular/common";
import { Transition } from "@uirouter/angular";
import { UntypedFormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Zedder } from '../zeddb.types';
import { OrdersService } from '../orders.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

export interface Param {
  id: string;
  name: string;
  cost: number;
  contents: string;
  tshirt: boolean;
  general: boolean;
  generalprize: string;
  monthly: boolean;
  monthlyprize: string;
  gold: boolean;
  goldprize: string;
  daily: boolean;
  dailyprize: string;
  pack: boolean;
  special: boolean;
  nameoncard: string;
  favshow: string;
  image: string;
  weight: any;
  type: string;
  email: string;
  phone: string;
  monthlyPrize: string;
  aprilAtonementPrize: string;
  tshirtsize: string;
  postpack: boolean;
  specialname: string;
  members: string[]
  price: number[],
  netWeight: number;
  itemPrice: number;
}

export interface Items {
  id: string;
  name: string;
  cost: number;
  contents: string;
  tshirt: boolean;
  general: boolean;
  generalprize: string;
  monthly: boolean;
  monthlyprize: string;
  gold: boolean;
  goldprize: string;
  daily: boolean;
  dailyprize: string;
  pack: boolean;
  special: boolean;
  nameoncard: string;
  favshow: string;
  image: string;
  weight: any;
  type: string;
  email: string;
  phone: string;
  monthlyPrize: string;
  aprilAtonementPrize: string;
  tshirtsize: string;
  postpack: boolean;
  specialname: string;
  members: string[]
  price: number[],
  param?: Param;
  netWeight: number;
  itemPrice: number;
}
export interface Order {
  order_id: number;
  zedder_id: number;
  total: string;
  subtotal: string;
  total_tax: string;
  total_weight: string;
  shipping_weight: string;
  shipping_price: string;
  sub_discount: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  items_json: Items[];
  invoice_no: string;
  station_pickup: boolean;
  processed_old: any;
  processed: string | null;
  notes: string | null;
  transaction: any;
  zedder: Zedder;
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent {
  // @Input() order: any = { zedder: {}, order_id: 0, subscriptions: [] };

  constructor(
    public OrdersService: OrdersService,
    // @Inject('$rootScope') private $rootScope: any,
    private http: HttpClient,
    public location: Location,
    // protected trans: Transition,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {

    this.route.data.subscribe(({ order }) => {
      this.order = order;

      this.id = this.route.snapshot.paramMap.get('id');

      console.log(this.order)
      this.notes.setValue(this.order.notes);
      this.order.hasMerch = this.order.items_json && this.order.items_json.some(i => i.type === 'merch')
  
    })

  }

  order: any = { zedder: {}, order_id: 0, subscriptions: [] };

  formEdited = false;
  submitLocked = false;
  // params = this.trans.params();
  id = this.route.snapshot.paramMap.get('id');

  notes = new UntypedFormControl(null);

  showPrompt = (ev, order) => {

  }
  
  sendNotes = () => {
    this.http.post(environment.apiSrc + '/order/' + this.id  , { notes: this.notes.value }).subscribe( data => {});
  }

  deleteOrder = (orderId) => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Order',
        message: 'Delete order #'+ orderId +'?',
        confirm: 'Delete',
        abort: 'Cancel',
      }});
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result === 'confirm') {
        console.log('I will delete');
        this.http.delete(environment.apiSrc + '/order/'+ orderId).subscribe( data => {
          this.location.go('/orders');
        });
      }
      else {
        // console.log('I will not');
      }
    });
  }

  process = (orderId) =>  {
		this.http.get('https://support.4zzz.org.au/api/reception-process-order/'+ orderId ).subscribe( data => {
			this.OrdersService.get(orderId).subscribe( res => {
				this.order = res;
				//$scope.gs.selected = _.pluck(data.genres, 'id');
			});
		})
	}


	fetchPrintableSubcards(orderId) {
		this.http.get<any>('https://support.4zzz.org.au/api/printable-subcards/' + orderId, { responseType: 'blob' as 'json' }).subscribe( data => {
			// console.log(response)
			var file = new File([data], 'subcards.pdf', { type: 'application/pdf' });
			var fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, '_blank');
			// this.content = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
			// $window.open(fileUrl, '_blank');
		})
	}


  fetchInvoice = (orderId) => {
    this.http.get('https://support.4zzz.org.au/api/order-invoice/'+ orderId, { responseType: 'arraybuffer' }).subscribe( data => {
			// console.log(response)
			var file = new File([data], 'invoice.pdf',  { type: 'application/pdf' });
			var fileUrl = URL.createObjectURL(file);
			window.open(fileUrl, '_blank');
		})
  }
 
  fetchPackingSlip = (orderId) => {
    this.http.get('https://support.4zzz.org.au/api/packing-slip/'+ orderId, { responseType: 'arraybuffer' }).subscribe( data => {
    // console.log(response)
      var file = new File([data], 'packing-slip.pdf',  { type: 'application/pdf' });
      var fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, '_blank');
    })
  }

}
