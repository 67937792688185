import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-orders-admin-tools',
  templateUrl: './orders-admin-tools.component.html',
  styleUrls: ['./orders-admin-tools.component.scss']
})
export class OrdersAdminToolsComponent {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    // @Inject('$rootScope') private $rootScope,
  ) {



  }

  ordersList = false;
  orders = []
  page = 1;
  subcardOffset = 0;
  subcardPage = Array.from(Array(12), (i, n) => n );

  labelOffset = 0;
  labelPage = Array.from(Array(14), (i, n) => n );

  content: any;

	cardIndex = -1;
	subcodes: any[] = [];

  setLabelOffset(key) {
		this.labelOffset = key;
	}

  setSubcardOffset(key) {
		this.subcardOffset = key;
	}


	fetchPrintableSubcards() {

		this.subcodes = [];
		this.cardIndex = -1;
		this.http.get<any>('https://support.4zzz.org.au/api/subcard-swatches').subscribe( data => { 
			this.subcodes = data.map(sub => {
				let type = ''
				switch(sub.subtypecode) {
					case('Band'):
					case('Business'):
					case('Concession'):
					case('Full'):
					case('Solo Artist'):
					case('Under 18'):
						type = 'standard';
						break;
					case('Passionate'):
						type = 'passionate';
						break;
					case('Super Sub'):
						type = 'super';
						break;
				}
				return { subtype: sub.subtypecode, card: type } 
		 }) 
		});

		this.http.get<any>('https://support.4zzz.org.au/api/batch-printable-subcards' + (this.subcardOffset > 0 ? '/'+ this.subcardOffset : ''), { responseType: 'blob' as 'json' }).subscribe( data => {
			// console.log(response)
			var file = new File([data], 'subcards.pdf', { type: 'application/pdf' });
			var fileUrl = URL.createObjectURL(file);
			this.content = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
			// $window.open(fileUrl, '_blank');
		})
	}

  fetchBulkSubCards() {
		
		this.http.get<any>('https://support.4zzz.org.au/api/batch-subcards' + (this.subcardOffset > 0 ? '/'+ this.subcardOffset : ''), { responseType: 'blob' as 'json' }).subscribe( data => {
			// console.log(response)
			var file = new File([data], 'subcards.pdf', { type: 'application/pdf' });
			var fileUrl = URL.createObjectURL(file);
			this.content = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
			// $window.open(fileUrl, '_blank');
		})
	}

  fetchBulkEnvelopeLabels() {
		this.http.get<any>('https://support.4zzz.org.au/api/batch-envelope-labels' + (this.labelOffset > 0 ? '/'+ this.labelOffset : ''), { responseType: 'blob' as 'json' }).subscribe(  data => {
			// console.log(response)
			var file = new File([data], 'envelopes.pdf', { type: 'application/pdf' });
			var fileUrl = URL.createObjectURL(file);
			this.content = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
			// $window.open(fileUrl, '_blank');
		})
	}
	
  fetchBulkPackingSlips() {
		this.http.get<any>('https://support.4zzz.org.au/api/batch-packing-slips', { responseType: 'blob' as 'json' }).subscribe( data  => {
			// console.log(response)
			var file = new File([data], 'packing-slips.pdf', { type: 'application/pdf' });
			var fileUrl = URL.createObjectURL(file);
			this.content = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
			// $window.open(fileUrl, '_blank');
		})
	}

  moveCheckedToProcessing() {
		this.http.get<any>('https://support.4zzz.org.au/api/checked-to-processing', { responseType: 'blob' as 'json' }).subscribe( data => {
			// console.log(response)
			var file = new File([data], 'orders-checked.csv', { type: 'text/csv' });
			var fileUrl = URL.createObjectURL(file);
			// $scope.content = $sce.trustAsResourceUrl(fileUrl);
			window.open(fileUrl, '_blank');
			
			// $window.open(fileUrl, '_blank');
		})
	}

  fetchPackageCsv() {
		this.http.get<any>('https://support.4zzz.org.au/api/batch-package-csv' + (this.labelOffset > 0 ? '/'+ this.labelOffset : ''), { responseType: 'blob' as 'json' }).subscribe( data => {
			// console.log(response)
			var file = new File([data], 'packages.csv', { type: 'text/csv' });
			var fileUrl = URL.createObjectURL(file);
			// $scope.content = $sce.trustAsResourceUrl(fileUrl);
			window.open(fileUrl, '_blank');
		})
	}
}
