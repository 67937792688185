<div #scrollElement>
  <div class="year" *ngFor="let year of dates">
    <div class="year-title">{{year[0].year}}</div>
    <div class="months">
      <div class="month-wrapper" (click)="selectDate(month.date)" *ngFor="let month of year">
        <button 
          [class.current]="month.month === currentMonth && month.year === currentYear"
          [class.selected]="month.month === selectedMonth && month.year === selectedYear" 
          class="month">
          {{month.monthCode}}
        </button>
      </div>
    </div>
  </div>
</div>
