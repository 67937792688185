<mat-toolbar>
	Merge Zedders
</mat-toolbar>
<mat-toolbar>
	<mat-form-field class="full-width">
		<mat-label>Zedder ID</mat-label>
		<mat-chip-grid (change)="updateZedder($event)" #chipGrid aria-label="Enter zedders" [formControl]="zedderControl">
			<mat-chip-row *ngFor="let zedder of zedderIds"
										(removed)="remove(zedder)"
										[editable]="true"
										(edited)="edit(zedder, $event)"
										[aria-description]="'press enter to edit ' + zedder.id">
				{{zedder.id}}
				<button matChipRemove [attr.aria-label]="'remove ' + zedder.id">
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input placeholder="Enter zedder IDs to merge"
						 [matChipInputFor]="chipGrid"
						 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						 [matChipInputAddOnBlur]="addOnBlur"
						 (matChipInputTokenEnd)="add($event)"/>
		</mat-chip-grid>
	</mat-form-field>
	
</mat-toolbar>
<section style="background: #212121;" class="mat-typography">
	<div style="display: flex; gap: 8px; padding: 8px;">
		<div style="flex: 1;" [style.width]="'calc(' + (100 / zedders.length )+ 'vw - 180px)'" *ngFor="let zedder of zedders; index as $index">
			<mat-card [formGroup]="zeddersForm.controls[$index]">
				<mat-card-header>
					<mat-card-title>
							<div style="display: flex; justify-content: space-between;">

								<div>
									<ul class="inline">
										<li>
											<strong>Zedder ID #:</strong> {{zedder.id}}
										</li>
										<li>
											<strong>Created:</strong> {{zedder.created_at | date:'medium'}}
										</li>
										<li>
											<strong>Last Updated:</strong> {{zedder.updated_at | date:'medium'}}
										</li>
									</ul>
								</div>

								<div>
									<mat-icon (click)="makeDefault('id', zedder.id)" color="warn">{{ defaults.id === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
								</div>
							</div>
					</mat-card-title>
				</mat-card-header>

				<mat-card-content>

					<mat-form-field class="full-width">
						<mat-label>First Name</mat-label>
						<input matInput formControlName="firstname">
						<mat-icon matSuffix (click)="makeDefault('firstname', zedder.id)">{{ defaults.firstname === null ? 'check_box_outline_blank' : defaults.firstname === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Last Name</mat-label>
						<input matInput formControlName="lastname" >
						<mat-icon matSuffix (click)="makeDefault('lastname', zedder.id)">{{ defaults.lastname === null ? 'check_box_outline_blank' : defaults.lastname === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<div style="display: flex; gap: 8px;">



						<mat-form-field class="full-width">
							<mat-label>Last Expiry</mat-label>
							<input matInput formControlName="expiry" [matDatepicker]="picker">
							<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>

						<mat-form-field class="full-width">
							<mat-label>Sub #</mat-label>
							<input matInput formControlName="subnumber" >
							<mat-icon matSuffix (click)="makeDefault('sub', zedder.id)">{{ defaults.sub === null ? 'check_box_outline_blank' : defaults.sub === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
						</mat-form-field>

					</div>

					<mat-form-field class="full-width">
						<mat-label>Last Subscription Type</mat-label>
						<input matInput formControlName="subtypecode" >
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Email</mat-label>
						<input matInput formControlName="email" >
						<mat-icon matSuffix (click)="makeDefault('email', zedder.id)">{{ defaults.email === null ? 'check_box_outline_blank' : defaults.email === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Phone</mat-label>
						<input matInput formControlName="phone" >
						<mat-icon matSuffix (click)="makeDefault('phone', zedder.id)">{{ defaults.phone === null ? 'check_box_outline_blank' : defaults.phone === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Address</mat-label>
						<input matInput  formControlName="address">
						<mat-icon matSuffix (click)="makeDefault('address', zedder.id)">{{ defaults.address === null ? 'check_box_outline_blank' : defaults.address === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>


					<div style="display: flex; gap: 8px;">
						<mat-form-field class="full-width">
							<mat-label>Suburb</mat-label>
							<input matInput  formControlName="locality">
						</mat-form-field>

						<mat-form-field class="full-width">
							<mat-label>State</mat-label>
							<input matInput  formControlName="region">
						</mat-form-field>

						<mat-form-field class="full-width">
							<mat-label>Country</mat-label>
							<mat-select  formControlName="country">
								<mat-option *ngFor="let country of countries" value="{{country.alpha}}">
									{{country.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="full-width">
							<mat-label>Postal Code</mat-label>
							<input matInput name="postalCode"  formControlName="postcode" placeholder="1234"
						 >

						</mat-form-field>
					</div>


					<div>
						<mat-checkbox  formControlName="zedletter" aria-label="Checkbox 1">
							Receive Zedletter
						</mat-checkbox>
						<mat-icon style="float:right; margin: 8px 12px;" (click)="makeDefault('zedletter', zedder.id)">{{ defaults.zedletter === null ? 'check_box_outline_blank' : defaults.zedletter === zedder.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</div>

					<div>{{zedder.password ? 'NEW PASSWORD' : zedder.zcpassword ? 'OLD PASSWORD' : 'NO PASSWORD' }}</div>

				</mat-card-content>
			</mat-card>
		</div>

		<div style="flex: 1; display: flex; flex-direction: column; gap: 8px;">
			<mat-card *ngIf="subscribers.length" class="info-card" style="background-color: hsl(224, 47%, 40%);">
				<mat-card-header style="background-color: hsl(224, 47%, 32%);">
					<mat-card-title>Subscriptions</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<mat-list>
						<a mat-list-item *ngFor="let sub of subscribers;" [href]="'/subscribers/' + sub.subid">
							<span matListItemTitle>{{ sub.subnumber }} {{sub.subfirstname}} {{sub.sublastname}} <em>{{sub.nameoncard }}</em></span>
							<span matListItemLine>{{ sub.subscription.subtypecode }} <em>{{sub.petname}}{{sub.subbandname}}</em></span>
							<span matListItemLine><strong>Expiry</strong> {{ sub.expirydate | date: 'dd/MM/yyyy'}} <em>(Created {{ sub.created_at | date: 'dd/MM/yyyy'}})</em></span>
						</a>
					</mat-list>
				</mat-card-content>
			</mat-card>
	
			<mat-card *ngIf="related.length" class="info-card">
				<mat-card-header style="background-color: hsl(224, 47%, 32%);">
					<mat-card-title>Related</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<mat-list>
						<a *ngFor="let sub of related" [href]="'/subscribers/' + sub.subid" mat-list-item>
							<span matListItemTitle>{{ sub.subnumber }} {{sub.subfirstname}} {{sub.sublastname}} <em>{{sub.nameoncard }}</em></span>
							<span matListItemLine>{{ sub.subscription.subtypecode }} <em>{{sub.petname}}{{sub.subbandname}}</em></span>
							<span matListItemLine><strong>Expiry</strong> {{ sub.expirydate | date: 'dd/MM/yyyy'}} <em>(Created {{ sub.created_at | date: 'dd/MM/yyyy'}})</em></span>
						</a>
					</mat-list>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</section>

<button mat-fab [hidden]="!default" class="floating-fab" (click)="mergeZedders()" aria-label="Merge Zedder" matTooltip="Merge Zedder">
	<mat-icon>
		merge_type
	</mat-icon>
</button>
