import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor( 
    private AuthenticationService: AuthenticationService,
    private location: Location,
    private router: Router
  ) {
   
  }

  error: boolean = false;

  login() {
    if(this.credentials.valid) {
      this.AuthenticationService.login(this.credentials.value).subscribe( response => {
        console.log('HELLO HELLO HELLO HELLO')
      	this.router.navigateByUrl('/');
    }, () => { 
      this.error = true;
      setTimeout(() => { 
        this.error = false; 
      }, 3000);
      console.log('is this an error');})
    }
  }

  credentials = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl()
  })
}
