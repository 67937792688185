<mat-card class="mat-typography" style="width: 480px">
	<mat-card-header class="search-params">
		<mat-card-title>

			<div style="display: flex; margin-top: 0.5em; margin-bottom: 0.5em; justify-content: center; align-items: center;">
				<div style="margin-left: 1em; flex: 1">
					<h5 style="font-size: 20px; font-weight: 700">Metadata</h5>
					<h6 style="font-size: 14px">{{fileMeta && fileMeta.file}} | {{fileMeta && fileMeta.length | hms }}</h6>
				</div>
				<div style="width: 72px; justify-content: center; align-items: center;">
					<div  style="width: 64px; height: 64px; position: relative;">
						<mat-spinner *ngIf="saving" diameter="64" style="position: absolute; top: 0; left: 0" />
						<button [disabled]="saving" class="tool-icon"  matTooltip="Save tags" mat-icon-button aria-label="Save tags" (click)="saveTags()" style="position: absolute; top: 8px; left: 8px">
							<mat-icon>playlist_add_check</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</mat-card-title>
	</mat-card-header>

	<mat-card-content>
		 <div>
			 <div [formGroup]="metadata" style="display: flex; flex-direction: column;">


				<mat-form-field>
					<mat-label>Episode Number</mat-label>
					<input matInput placeholder="Episode Number" formControlName="tracknum">
				</mat-form-field> 

				<mat-form-field class="full-width">
          <mat-label>Album</mat-label>
          <input matInput placeholder="Album" formControlName="album">
        </mat-form-field> 

				<mat-form-field class="full-width">
          <mat-label>Artist</mat-label>
          <input matInput placeholder="Artist" formControlName="artist">
        </mat-form-field> 

				<!-- <mat-form-field class="full-width">
          <mat-label>Album Artist</mat-label>
          <input matInput placeholder="Album Artist" formControlName="albumartist">
        </mat-form-field>  -->

					<mat-form-field>
						<mat-label>Date</mat-label>
						<input matInput placeholder="Date" formControlName="date">
					</mat-form-field> 

	
					<mat-form-field class="full-width">
						<mat-label>Title</mat-label>
						<textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="Title" formControlName="title"></textarea>
					</mat-form-field> 

			</div>
		</div>
		
	</mat-card-content>
</mat-card>