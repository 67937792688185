import { Component, inject, Inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Subtypes, Show, Zedder, Countries, ProgramsShort } from '../zeddb.types';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CountriesService } from '../countries.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-donated-subscriptions',
  templateUrl: './donated-subscriptions.component.html',
  styleUrls: ['./donated-subscriptions.component.scss']
})
export class DonatedSubscriptionsComponent {

  constructor(
    public CountriesService: CountriesService,
    // @Inject('$rootScope') private $rootScope: any,
    private http: HttpClient,

  ) {
    CountriesService.query().subscribe(response => { 
      this.countries = response; 
    });

    this.donatedSubscriptionForm.valueChanges.subscribe( () => {
      console.log('CHANGED!', this.donatedSubscriptionForm)

     if ( this.donatedSubscriptionForm.status === 'VALID' ) {
      this.formValid = true;
     }
     if ( this.donatedSubscriptionForm.status === 'INVALID' ) {
      this.formValid = false;
     }
      this.formEdited = true;
    });
  }

  subscription: any = {};

  searchResults : any[] = [];

  populate = ( zedder ) => {
    console.log(zedder);
    this.donatedSubscriptionForm.patchValue({
      id: zedder.id,
      firstname: zedder.firstname, 
      lastname: zedder.lastname, 
      nameoncard: zedder.nameoncard, 
      email: zedder.email, 
      phone: zedder.phone, 
    }, { emitEvent: true } );
  }

  checkZedder = (type, $event) => {
    console.log(type, $event);
    let searchString;

    if (type === 'name')
      searchString = this.donatedSubscriptionForm.get('firstname')?.value + ' ' + this.donatedSubscriptionForm.get('lastname')?.value;

    if (type === 'email')
      searchString = this.donatedSubscriptionForm.get('email')?.value;

    if (type === 'phone')
      searchString = this.donatedSubscriptionForm.get('phone')?.value.replaceAll(/\s/g,'');

    if (searchString){
      this.http.post<any>(environment.apiSrc + '/zedders', { searchString: searchString }).subscribe(data => {
        this.searchResults = data;
      })
    }
  }


  countries: Countries[] = [];
  addDonatedSubscription = () => {
    this.http.post<any>(environment.apiSrc + '/process-donated-subscription', this.donatedSubscriptionForm.value ).subscribe(data => {
      this.subscription = data;
    });
   }
      
  donatedSubscriptionForm = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    type: new UntypedFormControl(null, Validators.required),
    firstname: new UntypedFormControl(null, Validators.required), 
    lastname: new UntypedFormControl(null, Validators.required), 
    nameoncard: new UntypedFormControl(null), 
    email: new UntypedFormControl(null, Validators.required), 
    phone: new UntypedFormControl(null, Validators.required), 
    delivery_address: new UntypedFormGroup({
      address: new UntypedFormControl(null),
      locality: new UntypedFormControl(null),
      region: new UntypedFormControl(null),
      postcode: new UntypedFormControl(null),
      country: new UntypedFormControl(null)
    }),
  });

  formEdited = false;
  formValid = false;
}
