import { Component, Inject, Input } from '@angular/core';
import { Favourites, Info } from '../zeddb.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cover-player',
  templateUrl: './cover-player.component.html',
  styleUrls: ['./cover-player.component.scss']
})
export class CoverPlayerComponent {
  @Input() favourite!: (arg0: any, arg1: any) => void;
  @Input() info!: Info;
  @Input() release!: (arg0: any) => void;
  @Input() releases!: any[];
  @Input() queue!: (arg0: any, arg1: any, arg2: any, arg3: any) => void;
  @Input() more!: (arg0: any) => void;
  @Input() play!: (...args : any[]) => void;
  @Input() selection!: any[];
  @Input() clear = () => {};
  @Input() showYear: boolean = true;

  constructor() {}

  musicDBapiSrc = environment.musicDBapiSrc;
  
  addToPlayList = () => {};

  selectedItem: any = null;

  fav: boolean = false; 

  albumListing = (item, $event) => {
    this.selectedItem = item;
  }
}
