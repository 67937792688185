import * as moment from 'moment';

export const secondsToHMS = function (totalSec) {
	var hours = Math.floor( totalSec / 3600 ) % 24;
	var minutes = Math.floor( totalSec / 60 ) % 60;
	var seconds = Math.round(totalSec % 60);

	return (hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : '' ) + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds  < 10 ? "0" + seconds : seconds)
}

export const convertDate = function(oldate) {
	var d = new Date(oldate);
	//gross js date formatting
	var curr_date =  ('0' + d.getDate()).slice(-2);
		var curr_month = ('0' + (d.getMonth()+1)).slice(-2);
		var curr_year = d.getFullYear();
		var entd = curr_year + '-' + curr_month + '-' + curr_date;
	return entd;
}

export const compare = function(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export const capitalize = function(str, lower = false){
	return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase() );
}

export const slugify = text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

export const buildLocaleProvider = function (formatString) {
	return {
		formatDate: function(date) {
			if (date) return moment(date).format(formatString);
			else return null;
		},
		parseDate: function(dateString) {
			if (dateString) {
				var m = moment(dateString, formatString, true);
				return m.isValid() ? m.toDate() : new Date(NaN);
			} else return null;
		},
		isDateComplete: function(dateString) {
			return true;
		}
	};
}


export const playlistToM3U = (tracks: any[]) => {
	var data = "#EXTM3U\r\n";
	for (var i = 0; i < tracks.length; i++) {
		const track = tracks[i];
		var link = track.file;

		if (track.artist)
			data += "#EXTINF:" + track.length + ","  + track.artist + " - " + track.title + "\r\n";
		else
			data += "#EXTINF:" + track.length + ","  + track.title + "\r\n";

		data += link + "\r\n";
	}
	return data;
}

export const formatBytes = (bytes: number, decimals: number = 2) => {
	if (bytes === 0) {
		return '0 Bytes';
	}
	const k = 1024;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const fileSubstitution = (string: string) => {
	return string.replace(/\\/g,'⧵').replace(/\//g,'∕').replace(/\"/g, '”').replace(/:/g, '꞉').replace(/>/g, '〉').replace(/</g, '〈').replace(/\*/g, '﹡').replace(/\.\.\./g, '…').replace(/\?/g, '？').replace(/\|/g, '｜').replace(/( |\.)+$/, '');
} 

export const copyToClipboard = (text: string) => {
	var link;
	if (navigator.platform.search('Win') !== -1) {
		console.log('win');
		link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
	}

	if (navigator.platform.search('Mac') !== -1) {
		console.log('mac');
		link = text.replace('/mnt/fela', '/Volumes/audio')
	}

	if (navigator.platform.search('Linux') !== -1) {
		console.log('lin');
		console.log(text);
		link = text;
	}

	console.log(navigator.platform);
	window.prompt("Copy to clipboard: Ctrl+C, Enter", link);
}


export const escapeRegExp = (string : string) => {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const tagFromFile = (pattern, filename) => {

  let escaped = escapeRegExp(pattern)
  let percents = RegExp('(%\\w*%)', 'g')
  let percentsstr = RegExp('(%(?!track|year)\\w*%)', 'g')
  let regex = escaped.replace(percentsstr, '(.+)')
  let percentsnum = RegExp('(%(?=track|year)\\w*%)', 'g')
  regex = regex.replace(percentsnum, '(\\d+)')
  let reg = RegExp(regex, 'g')
  let tags = Array.from(escaped.matchAll(percents), m => m[0])
  let extracted = reg.exec(filename)
  tags = tags.map( i => i.replace(/%/g, '') )

  // console.log ( tags )
  // console.log( extracted )

  let tagged = {}
  for ( var i=0; i<tags.length; i++) {
    tagged[ tags[i] ] = (extracted && extracted[i + 1]) ? extracted[i + 1] : ''
  }

  return tagged
}