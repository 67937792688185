<div>
<mat-toolbar>
  Sponsorship
  <div style="flex: 1;"></div>
  <button (click)="showDialog()" matTooltip="Sponsorship Comms" matTooltipPosition="below" mat-icon-button>
    <mat-icon>sentiment_very_satisfied</mat-icon>
  </button>
</mat-toolbar>
<mat-tab-group [selectedIndex]="tabIndex" [formGroup]="giveaway">
  <mat-tab *ngFor="let day of fmGrid | keyvalue: originalOrder" [label]="day.key">
    <div *ngFor="let hour of day.value | keyvalue" style="display: flex; padding-bottom: 16px;">
      <div style="padding: 0 1em"><h2>{{hour.key}}</h2></div>
      <div style="flex: 1;">
        <h2>{{hour.value.program.name}}</h2>
        <div *ngIf="hour.value.liveReads">
          <div (click)="hour.value.showLiveReads = !hour.value.showLiveReads" style="background-color: #dadde8; color: black">
            <div>
              <div style="flex: 1; display: flex; align-items: center; padding: 8px; gap: 8px;">
                <mat-icon style="width: 24px; margin: 12px;" aria-label="live reads">article</mat-icon>
                <div style="font-size: 20px; font-weight: 700;">Live Reads</div>
              </div>
              <div *ngIf="hour.value.showLiveReads" style="padding: 8px;">
                <div *ngFor="let liveRead of hour.value.liveReads" style="display: flex; align-items: center; padding: 16px 0;" (click)="liveRead.read = !liveRead.read; $event.preventDefault(); $event.stopPropagation();">
                  <mat-checkbox style="padding: 4px" [(ngModel)]="liveRead.read" [ngModelOptions]="{ standalone: true }"></mat-checkbox>
                  <div [style.textDecoration]="liveRead.read ? 'line-through' : ''" [style.color]="liveRead.read ? 'grey': ''" style="font-size: 125%; line-height: 160%; width: 100%; padding-left: 8px; padding-right: 24px;">
                    <div style="text-decoration: inherit; font-size: 75%; color: #aaa; float: right">{{liveRead.commence_date}} - {{liveRead.complete_date}}</div>
                    <h2 style="text-decoration: inherit;">{{liveRead.title}}</h2>
                    <p [style.textDecoration]="liveRead.read ? 'line-through' : ''" [style.color]="liveRead.read ? 'grey' : ''"  [innerHtml]="liveRead.script"></p>
                    <!-- <div>{{liveRead.script}}</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="hour.value.hasGiveaway" formGroupName="{{day.key}}">
          <div formArrayName="{{hour.key}}"  style="background-color: hsl(0, 42%, 62.7%); color: black;">
          <div>
            <div (click)="hour.value.showGiveaways = !hour.value.showGiveaways" style="flex: 1; display: flex; align-items: center; padding: 8px; gap: 8px;">
              <mat-icon style="width: 24px; margin: 12px" aria-label="giveaway">
              star
              </mat-icon> 
              <div style="font-size: 20px; font-weight: 700;">Giveaways</div>
            </div>
              <div *ngIf="hour.value.showGiveaways" style="background-color: hsl(0, 42%, 62.7%)">
                <div style="display: flex; align-items: center; padding: 24px 8px; gap: 8px; margin: 0 56px;" *ngFor="let giveaway of hour.value.promo | filter:  {campaign_type: 3}">
                  
                  <div ng-click="console.log(giveaway)" class="md-list-item-text">
                    <h3>{{giveaway.title}}</h3>
                    <p style="text-decoration: inherit;" [innerHTML]="giveaway.script"></p>
                    <p style="font-style: oblique; color: white;" [innerHTML]="giveaway.instructions"></p>
                    <p style="font-style: oblique; text-decoration: underline; color: white;">First search for the subscriber, clicking to select from the list or results. Then submit the prizewinner with the smiley face button.</p>

                    <div *ngIf="giveaway.winners.length > 0" >
                      <div *ngFor="let x of giveaway.winners; index as $index" style="display: flex; gap: 8px;">
                        <mat-form-field class="full-width">
                          <mat-label>Search by name, subnumber or phone number</mat-label>
                          <input type="text"
                            placeholder="Search by name, subnumber or phone number"
                            aria-label="Search by name, subnumber or phone number"
                            matInput
                            [formControlName]="$index"
                            [matAutocomplete]="auto">
                          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions[giveaway.winnerIndex + $index] | async" [value]="option">
                              {{option.firstname}} {{option.lastname}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                        
                        <!-- <mat-autocomplete 
                          flex
                          md-search-text="x.search"
                          md-items="item in subscriberSearch(x.search)"
                          md-item-text="item.firstname + ' ' + item.lastname"
                          md-selected-item-change="x.zedder = item"
                          md-floating-label="Search by name, subnumber or phone number"
                        >
                          <md-item-template>
                            <span md-highlight-text="x.search">{{item.firstname}} {{item.lastname}}</span>
                          </md-item-template>
                        </mat-autocomplete> -->
                        <button mat-fab type="submit" style="background-color: hsl(250.3, 100%, 70.4%);" [disabled]="x.success || x.submitted" (click)="submitWinner( {day: day.key, hour: hour.key, winner: $index}, giveaway, true, x)" aria-label="Submit winner" matTooltip="Submit the winner" matTooltipPosition="above">
                          <mat-icon aria-label="update" class="material-icons step">
                            {{ x.success ? 'thumb_up' : 'sentiment_very_satisfied' }}	
                          </mat-icon>
                        </button>
                        <!-- <button class="md-fab md-button md-warn" type="submit" ng-click="submitWinner(x, giveaway, false)" aria-label="Submit winner">
                          <md-tooltip md-direction="top">No winner</md-tooltip>
                          <md-icon aria-label="update" class="material-icons step">
                            sentiment_dissatisfied
                          </md-icon>
                        </button> -->
                      </div>
                    </div>

                    <div style="font-style: oblique;">{{giveaway.winnerspershow}} potential winners per show</div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="hour.value.toggle" >
          <div [style.textDecoration]="play.checked && 'line-through'" *ngFor="let play in hour.value.plays">{{play.time}} {{play.filename}} {{play.playback_duration }} {{play.studio}}</div>
        </div>

        <div [style.backgroundColor]="campaignColours[spot.campaign_cat]" *ngFor="let spot of hour.value.promo | filter: {campaign_type: 1}" href="#" style="margin-bottom: 2px; display: flex; align-items: center; padding: 8px; gap: 8px;">
          <button (click)="playTemp(spot.media_location)" mat-icon-button [matTooltip]="'Preview' + spot.media_location" matTooltipPosition="below">
            <mat-icon aria-label="play">
              {{mediaExists(spot.media_location) ? 'play_circle' : 'play_disabled'}}
            </mat-icon>
          </button>
          <div>
            <div>{{spot.title}}</div>
            <div><em [style.text-decoration]="mediaExists(spot.media_location) ? '' : 'line-through'">{{spot.media_location}}</em> {{spot.played ? '✅': ''}}</div>
            <div>{{spot.media_length | hms}}</div>
            <!-- <p>{{spot.commence_date}} - {{spot.complete_date}}</p> -->
          </div>
        </div>

        <div *ngIf="hour.value.promo && hour.value.promo.length >0 " >
          <em>{{totalLength(hour.value.promo) | hms}}</em>
        </div>
        
      </div>

      <div style="min-width: 80px; text-align: right">
        <button  *ngIf="onAir" (click)="attachSpots(hour.value)" matTooltip="Send Spots to Mairlist" matTooltipPosition="below" style="color: #fa8817" mat-icon-button>
          <mat-icon svgIcon="mairlist"></mat-icon>
        </button>


        <button *ngIf="hour.value.past" (click)="hour.value.toggle ? hour.value.toggle = false : hour.value.toggle = true" matTooltip="Check spots played" matTooltipPosition="below" aria-label="check-spots-played" mat-icon-button>
          <mat-icon>
            playlist_add_check
          </mat-icon>
        </button>

        <button (click)="downloadSpots(hour.value)" aria-label="download-spots" matTooltip="Download Spots" matTooltipPosition="below" mat-icon-button>
          <mat-icon >
            download
          </mat-icon>
        </button>

      </div>
    </div>
  </mat-tab>
</mat-tab-group>
</div>