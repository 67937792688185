import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  file: any;
  rename: string;
}

@Component({
  selector: 'app-send-to-bruce-dialog',
  templateUrl: './send-to-bruce-dialog.component.html',
  styleUrls: ['./send-to-bruce-dialog.component.scss']
})
export class SendToBruceDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,) {

  }
}
