<mat-toolbar>
	<h3 style="flex: 1">Show Playlists</h3>
	
	<mat-form-field subscriptSizing="dynamic">
		<mat-label>Fill-in show</mat-label>
    <input type="text"
			#input
			placeholder="Search Programs"
			aria-label="Programs"
			matInput
			[formControl]="programControl"
			(input)="filter()"
			(focus)="filter()"
			[matAutocomplete]="auto">

		<mat-autocomplete requireSelection autoActiveFirstOption [displayWith]="displayName" #auto="matAutocomplete">
			<mat-option *ngFor="let program of filteredPrograms" [value]="program">
				{{program.name}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field> 
</mat-toolbar>

<section class="mat-typography">
	<mat-card *ngIf="fillInProgram" class="info-card">
		<mat-card-header>
			<mat-card-title>
				{{fillInProgram.name}} Playlist
			</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<app-program-playlists [program]="fillInProgram"></app-program-playlists>
		</mat-card-content>
	</mat-card>

	<mat-card *ngFor="let program of user.details.announcer"  class="info-card">
		<mat-card-header>
			<mat-card-title>
				{{program.name}} Playlist
			</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<app-program-playlists [program]="program"></app-program-playlists>
		</mat-card-content>
	</mat-card>

</section>