import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, Inject, ViewChild, inject} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {filter, map, startWith, switchMap} from 'rxjs/operators';
import {MatIconModule} from '@angular/material/icon';
import {NgFor, AsyncPipe} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { HttpClient } from '@angular/common/http';
import { groupBy, sortBy, uniq } from 'lodash';
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers'
import * as moment from 'moment';
import { ChartConfiguration, ChartData, ChartType, Chart } from 'chart.js';
import { VennDiagramController, ArcSlice, extractSets, EulerDiagramController } from 'chartjs-chart-venn';
import { forEach } from 'angular';
import { MatPaginator } from '@angular/material/paginator';
import { SubService } from '../sub.service';
import { SubtypesService } from '../subtypes.service';
import { PrizesService } from '../prizes.service';
import { environment } from 'src/environments/environment';
// import { extractSets } from 'chartjs-chart-venn';

const mix = (...args) => {
  // 

  let vectors = args.map( i => {
    let [hue, saturation, lightness] = i;

    let x = Math.cos(hue / 180 * Math.PI) * saturation * 1.75;
    let y = Math.sin(hue / 180 * Math.PI) * saturation * 1.75;
    let z = lightness * 1.1;
    return {x: x, y: y, z: z};
  })

  let avgX = vectors.map( i => i.x).reduce((a, b) => (a + b)) / vectors.length;
  let avgY = vectors.map( i => i.y).reduce((a, b) => (a + b)) / vectors.length;
  let avgZ = vectors.map( i => i.z).reduce((a, b) => (a + b)) / vectors.length;

  let h = Math.round(Math.round(Math.atan2(avgY, avgX) * 180 / Math.PI) * 10)/10;
  let s = Math.round(Math.sqrt(avgX * avgX + avgY * avgY) * 100) / 100;
  let l = Math.round(avgZ * 100) / 100;
  return 'hsl('+h+', '+s * 100 +'%, '+l * 100+'%)';
}

const hsl = i => {
  let [h, s, l] = i;
  return 'hsl('+h+', '+s * 100 +'%, '+l * 100+'%)';
}

@Component({
  selector: 'app-subscribers-report',
  templateUrl: './subscribers-report.component.html',
  styleUrls: ['./subscribers-report.component.scss']
})
export class SubscribersReportComponent {
  @ViewChild('subscriptionInput') subscriptionInput?: ElementRef<HTMLInputElement>;
  @ViewChild('emailArea') emailArea?: ElementRef<HTMLInputElement>;
  @ViewChild('tableContainer') tableContainer!: ElementRef;
  @ViewChild('subsPaginator', {static: false} ) paginator: MatPaginator = <MatPaginator>{};
  constructor(
    // @Inject('$rootScope') private $rootScope: any,
    private SubService: SubService,
    private SubtypesService: SubtypesService,
    private PrizesService: PrizesService,
    
    private http: HttpClient,
  ) {

    PrizesService.query().subscribe( data => { this.prizes = data });

    SubtypesService.query().subscribe(data => { this.allSubscriptions = data });

    this.subFilter.valueChanges.subscribe(
      value => { console.log(value) }
    )

    this.activeSubs();
    Chart.register(VennDiagramController, EulerDiagramController, ArcSlice);

  }

  ngOnInit() {

    this.filteredSubscriptions = this.subscriptionCtrl.valueChanges.pipe(
      startWith(''),
      map((value:any) => typeof value === 'string' ? value : value.subtypecode),
      map(name => name ? this._filter(name) : this.subscriptions.slice())
    );

    this.suburbFilteredOptions = this.subFilter.controls['suburb'].valueChanges.pipe(
      filter((value) => value.length > 1),
      switchMap( value => value ? this.searchSuburbs(value) : [])
    );


    // this.genColours();
    
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe( data =>{ 
      // this.fetchOrders() 
      this.sortData(this.lastSort);
    } )
  }

  announcer = inject(LiveAnnouncer);

  tally: any;
  tallyTotal: number | undefined;
  growthData: any;

  venn: boolean = false;

  fetchingSearch = false;
  fetchingFiveYear = false;
  fetchingLapsed = false;
  fetchingActiveSubList = false;

  prizeControl = new UntypedFormControl();
  monthlyPrizeControl = new UntypedFormControl();
  selectedDate: any;

  subscribers: any[] = [];
  resultsLength?: number;
  sortedData: any[] = [];
  emails: string = '';
  displayedColumns: string[] = ['id', 'name', 'altname', 'email', 'phone', 'subtype', 'subnumber', 'expiry', 'suburb'];
  displayedFilters: string[] = ['id-filter', 'name-filter', 'altname-filter', 'email-filter', 'phone-filter', 'subtype-filter', 'subnumber-filter', 'expiry-filter', 'suburb-filter'];
  allColumns: any[] = [
    { 'column': 'id', 'visible': true},
    { 'column': 'name', 'visible': true},
    { 'column': 'altname', 'visible': true},
    { 'column': 'email', 'visible': true},
    { 'column': 'phone', 'visible': true}, 
    { 'column': 'subtype', 'visible': true},
    { 'column': 'subnumber', 'visible': true},
    { 'column': 'expiry', 'visible': true},
    { 'column': 'suburb', 'visible': true},
  ];
  lastSort: Sort = { active: 'expiry', direction: 'desc' };
  searchResultsSelection: any = {}

  prizes: any[] = [];
  prizeWinner: any;
  monthlyPrizeWinner: any;

  separatorKeysCodes: number[] = [COMMA];
  subscriptionCtrl = new FormControl();
  subFilter = new UntypedFormGroup({
    subtype: new UntypedFormArray([]),
    suburb: new UntypedFormControl(),
    postcode: new UntypedFormControl(),
    expiredStart: new UntypedFormControl(),
    expiredEnd: new UntypedFormControl(),
    paidStart: new UntypedFormControl(),
    paidEnd: new UntypedFormControl(),
  }) 
  filteredSubscriptions!: Observable<any[]>;
  subscriptions: any[] = [];
  allSubscriptions: any[] = [];


  lapsed = new UntypedFormGroup({
    startDate: new UntypedFormControl(),
    endDate: new UntypedFormControl(),
    lapsed: new UntypedFormControl('90 days'),
  }) 

  suburbControl = new UntypedFormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  suburbFilteredOptions!: Observable<any>;

  public pieChartType: ChartType = 'pie';

  data: any;

  incremental?: ChartConfiguration['data'];

  vennOptions: ChartConfiguration['options'] = {

    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
    },
  };


  changeColumns = (column, $event) => {
    console.log(column, $event);
    this.allColumns[column].visible = $event.checked;
    this.updateColumns();
  }

  updateColumns = () => {
    this.displayedColumns = this.allColumns.filter(i => i.visible).map(i => i.column)
    this.displayedFilters = this.allColumns.filter(i => i.visible).map(i => i.column + '-filter')
  }

  copyEmails() {
    const textarea = this.emailArea?.nativeElement;
    if(textarea) {
      navigator.clipboard.writeText(textarea.value).then(
        () => {
          console.log('clipboard successfully set');
        },
        () => {
          console.error('clipboard write failed');
        }
      );
    }
  }

  getSubsByPrize() {
    const radiothonprizeid = this.prizeControl.value;

    this.http.get<any>(environment.apiSrc + '/subscribers/reports/pledge/' + radiothonprizeid).subscribe( data => {

      this.subscribers = data.map(i => i.subscriber).filter(s => !!s);
      this.resultsLength = data.length;
      this.sortData(this.lastSort);

        // var subs = _.map(response.data, 'subscriber');

        // var truesubs = _.filter(subs, function(s) {
        //     if (s !== null) {
        //         return s;
        //     }
        // });
        // $scope.subs = truesubs;

        //$scope.emails = $scope.emailList(response.data);

    });
  }




  drawPrize() {
    const radiothonprizeid = this.prizeControl.value;

    this.http.get<any>(environment.apiSrc + '/subscribers/reports/pledge/' + radiothonprizeid).subscribe( data => {

      this.subscribers = data.map(i => i.subscriber).filter(s => !!s);
      this.resultsLength = data.length;
      this.sortData(this.lastSort);

      let random = Math.floor(Math.random() * data.length);

      this.prizeWinner = this.subscribers[random];

    });
  }

  monthPickerFocus;

  isMonthPickerOpen = false;
  closeOverlay(event) { 
    event.stopPropagation();
    this.isMonthPickerOpen = false;
  }

  toggleHotbinOverlay = () => {
    this.monthPickerFocus = document.querySelector(':focus')
    console.log(this.monthPickerFocus);
    this.isMonthPickerOpen = !this.isMonthPickerOpen;
  }

  dateChanged = ($ev) => {
    this.monthPickerFocus && this.monthPickerFocus.focus()
    this.monthlyPrizeControl.patchValue(moment($ev).format('YYYY-MMMM'));
    console.log($ev);
    this.isMonthPickerOpen = false;
  }

  checkMonthlyPrizeContestants() {
    const month = moment(this.monthlyPrizeControl.value, 'YYYY-MMMM').format('YYYY-MM');
    this.http.get<any>(environment.apiSrc + '/subscribers/monthlyprize/' +  month).subscribe( data => {
      this.subscribers = data;
      this.resultsLength = data.length;
      this.sortData(this.lastSort);
    })
  }

  drawMonthlyPrize() {
    const month = moment(this.monthlyPrizeControl.value, 'YYYY-MMMM').format('YYYY-MM');
    this.http.get<any>(environment.apiSrc + '/subscribers/draw/monthlyprize/' + month).subscribe( data => {
      this.monthlyPrizeWinner = data;
    })
  }


  genColours() {
    let components = this.data.labels.map( i => i.split(' ∩ '));
    let sets = components.filter ( i => i.length === 1).length;
    let i = 0;
    let baseColours: any[] = [];
    let colours: any[] = [];

    components.forEach( n => { 
      if (n.length === 1) {
        let hue = (360 / sets)  *  i;
        let saturation = 0.75;
        let lightness = 0.75;
        baseColours.push([hue, saturation, lightness]);
        colours.push(hsl([hue, saturation, lightness]));

        i++;
      }
      else {
        colours.push(mix(...n.map( a => baseColours[this.data.labels.findIndex(b => b === a)])));
      } 
    })

    this.data.datasets[0].backgroundColor = colours;
  }

  fiveYearSubGrowth() {
    this.fetchingFiveYear = true;
    this.http.get<any>(environment.apiSrc + '/subs/monthcount').subscribe( data => {
      this.fetchingFiveYear = false;

      let datearray = data;
      // datearray.all = datearray.all.map(i => { i.x = new Date(i.expiryStart); return i })
      // datearray.full = datearray.full.map( i => { i.x = new Date(i.expiryStart); return i })
      // datearray.concession = datearray.concession.map( i => { i.x = new Date(i.expiryStart); return i })
      // datearray.passionate = datearray.passionate.map( i => { i.x = new Date(i.expiryStart); return i })
      // this.growthData = { timed: datearray.all, full: datearray.full, concession: datearray.concession, passionate: datearray.passionate };

      this.incremental = {
        labels: datearray.all.map(i => i.expiryStart),
        datasets: [
          {
            data: datearray.all.map(i => i.value),
            label: 'All',
            pointStyle: false, 
            pointRadius: 0,
            borderWidth: 2,
            borderColor: 'black',
            backgroundColor: 'black',
          },
          {
            data: datearray.full.map(i => i.value),
            label: 'Full',
            pointStyle: false, 
            pointRadius: 0,
            borderWidth: 2,
            borderColor: 'blue',
            backgroundColor: 'blue',
          },
          {
            data: datearray.concession.map(i => i.value),
            label: 'Concession',
            pointStyle: false, 
            pointRadius: 0,
            borderWidth: 2,
            borderColor: 'orange',
            backgroundColor: 'orange',
          },
          {
            data: datearray.passionate.map(i => i.value),
            label: 'Passionate',
            pointStyle: false, 
            pointRadius: 0,
            borderWidth: 2,
            borderColor: 'red',
            backgroundColor: 'red',
          }
        ]
      }

    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our subscription
    if (value) {
      this.subFilter.controls['subtype'].value.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.subscriptionCtrl.setValue(null);
  }

  remove(subscription: string): void {
    const index = this.subFilter.get('subtype')!.value.indexOf(subscription);

    if (index >= 0) {
      this.subFilter.get('subtype')!.value.splice(index, 1);

      this.announcer.announce(`Removed ${subscription}`);
    }
  }

  selectSuburb(event) {
    this.subFilter.controls['postcode'].setValue(event.option.value.postcode);
  }

  displayFn(option): string {
    return option && option.suburb ? option.suburb : '';
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let subtype = event.option.value;
    this.subFilter.get('subtype')!.value.push(subtype);
    if(this.subscriptionInput)
      this.subscriptionInput.nativeElement.value = '';
    this.subscriptionCtrl.setValue('');
  }


  activeSubsList(){
    this.fetchingActiveSubList = true;
    this.http.get<any>(environment.apiSrc + '/subscriber/activelist').subscribe(
      data => {
        this.fetchingActiveSubList = false;
        this.subscribers = data;
        this.resultsLength = data.length;
        this.sortData(this.lastSort);
        this.emails = this.emailList(data);
      }
    )
  }

  expiresToday() {
    let today = moment().format('YYYY-MM-DD');
    this.http.get<any>(environment.apiSrc + '/subscribers/expired/' + today).subscribe(
      data => {
        this.subscribers = data;
        this.resultsLength = data.length;
        this.sortData(this.lastSort);
        this.emails = this.emailList(data);
      }
    )
  }

  expiresTwoWeeks() {
    this.http.get<any>(environment.apiSrc + '/subscribers/expiring').subscribe(
      data => {
        this.subscribers = data;
        this.resultsLength = data.length;
        this.sortData(this.lastSort);
        this.emails = this.emailList(data);
      }
    )
  }

  getEmailList() {
    this.http.get<any>(environment.apiSrc + '/subscriber/emaillist').subscribe( data => {
        this.emails = this.emailList(data);
    });
  }

  paidToday() {
    this.http.get<any>(environment.apiSrc + '/subscribers/paid').subscribe(
      data => {
        this.subscribers = data;
        this.resultsLength = data.length;
        this.sortData(this.lastSort);
        this.emails = this.emailList(data);
      }
    )
  }

  paidVsExpired() {
    this.http.get<any>(environment.apiSrc + '/renewed-subs-list').subscribe( data => {
      this.data = extractSets(
        [
          { label: 'Paid Subs 90days', values: data['paid'].map(i => i.subnumber) },
          { label: 'Expired Subs 90days', values: data['expired'].map(i => i.subnumber) },
        ],
        {
          label: 'Paid vs Expired',
        }
      );
      this.genColours();
    })

  }
  lapsedSearch() {
    const { startDate, endDate, lapsed } = this.lapsed.value;
    this.fetchingLapsed = true;
    this.http.get<any>(environment.apiSrc + '/subs/getlapsedsubs/lapsed/' + startDate.format('YYYY-MM-DD') + '/'  + endDate.format('YYYY-MM-DD') + '/' + lapsed).subscribe( data => {
      this.fetchingLapsed = false;

      this.subscribers = data;
      this.sortData(this.lastSort);
      let indexed = groupBy(data, 'status');
      let activeNew = indexed['ACTIVE NEW'].filter( i => {
        if (moment(i.paymentdate).isBetween(startDate, endDate, null, '[]')) { return i }
      })  


      this.data = extractSets(
        [
          { label: 'Active Renewed', values: indexed['ACTIVE RENEWED'].map(i => i.subnumber) },
          { label: 'Inactive Renewed', values: indexed['INACTIVE RENEWED'].map(i => i.subnumber) },
          { label: 'Inactive Expired', values: indexed['INACTIVE EXPIRED'].map(i => i.subnumber) },
        ],
        {
          label: 'Extract',
        }
      );

      this.genColours()
      // [
      //   {sets: ['Active'], size: activeNew.length + indexed['ACTIVE RENEWED'].length },
      //   {sets: ['Expired'], size: indexed['INACTIVE EXPIRED'].length + indexed['ACTIVE RENEWED'].length },
      //   {sets: ['Active', 'Expired'], size: indexed['ACTIVE RENEWED'].length }
      // ]

      // this.lapsedTotals = [
      //   { name: 'Expired', color: '#f8dcbe', size: indexed['INACTIVE EXPIRED'].length },
      //   { name: 'Renewed', color: '#cfc3b1;', size: indexed['ACTIVE RENEWED'].length },
      //   { name: 'Active', color: '#c5d9e8', size: activeNew.length }
      // ]
      // this.grandTotal = activeNew.length + indexed['ACTIVE RENEWED'].length + indexed['INACTIVE EXPIRED'].length
    });
  }

  search() {
    this.fetchingSearch = true;
    let searchTerms = this.subFilter.value;

    this.http.post<any>(environment.apiSrc + '/subscriber/newreport', searchTerms).subscribe( data => {
        this.fetchingSearch = false;
        this.subscribers = data;
        this.resultsLength = data.length;

        this.sortData(this.lastSort);

        this.emails = this.emailList(data);
    });

  }

  private searchSuburbs = (value) => {
    const filterValue = value.toLowerCase();
    return this.http.get<any>(environment.apiSrc + '/suburbsuggest/' + filterValue).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data.slice(0, 30);
      })
    )
  }


  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.allSubscriptions.filter(subscription => subscription.subtypecode.toLowerCase().includes(filterValue));
  }

  resetExpiredRange() {
    this.subFilter.patchValue({
      expiredStart: null,
      expiredEnd: null,
    });
  }

  resetPaidRange() {
    this.subFilter.patchValue({
      paidStart: null,
      paidEnd: null,
    });
  }

  emailList = (data) => {
    var text = '';

    var emails = sortBy(data, 'subemail')
    emails = uniq(emails, 'subemail')

    emails.forEach( d => {

        if (d.subemail != null && d.subemail != '') {
            text = text + d.subemail.trim() + '\n';
        }
    });

    text = text.substring(0, text.length - 2);
    return text;
}

  activeSubs = () => {
    this.http.get<any>(environment.apiSrc + '/subscriber/types').subscribe( data => {
        this.tally = data.sort( (a, b) => a.subtype.localeCompare(b.subtype) );
        var subtt = 0
        var totals = data.map( i => i.subtypecount );
        this.tallyTotal = totals.reduce( (memo, num)  => {
            return memo + parseInt(num);
        }, 0);
    });
  };

  sortData(sort: Sort) {
    this.lastSort = sort;

    const data = this.subscribers.slice();
    // const data = this.subscribers.slice();

    if (!sort.active || sort.direction === '') {
      let sortedData = data;
      this.sortedData = sortedData.slice( (this.paginator.pageIndex * 40), (this.paginator.pageIndex * 40) + 40 )
      return;
    }

    let sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.subid, b.subid, isAsc);
        case 'name':
          return compare(a.sublastname + a.subfirstname, b.sublastname + b.subfirstname, isAsc);
        case 'email':
          return compare(a.subemail, b.subemail, isAsc);
        case 'subtype':
          return compare(a.subscription.subtypecode, b.subscription.subtypecode, isAsc)
        case 'subnumber':
          return compare(a.subsubnumber, b.subsubnumber, isAsc);
        case 'expiry':
          return compare(a.expirydate, b.expirydate, isAsc);
        default:
          return 0;
      }
    });

    this.sortedData = sortedData.slice( (this.paginator.pageIndex * 40), (this.paginator.pageIndex * 40) + 40 )

  }
  
  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
  };

  exportCSV = () => {
		console.log(this.exportCols);
    // let {start, end} = this.range.value;

		const flatList = this.subscribers.map( order => 
      this.exportCols.map(i => { 
        let keys = i.field.replace(/]/g,"").replace(/\[/g,".").split(".");
        let value = keys.reduce((obj,i) => obj && obj[i], order)
        return {key: i.displayName ? i.displayName : i.field,  value: value === null ? '' : value };
      }) 
    );
    

		console.log(flatList);
		            
    let headerNames = flatList[0].map( i => i .key );
		const csv = [
			headerNames.join(','), // header row first
			...flatList.map( order => order.map( i => i.value && JSON.stringify(i.value).replace(/\\"/g, '""')).join(',') )
		].join('\r\n')

		console.log(csv)

   
    let startText = moment().format('YYMMDD');

		let title = 'Subscriber-report'; 
		const blob = new File([csv], title + '-' + startText + '.csv', { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);
	};

  // exportCols = [
  //   {
  //       field: 'order_id',
  //       displayName: 'Order ID',
  //   }, {
  //       field: 'invoice_no',
  //       displayName: 'Invoice #'
  //   }, {
  //       field: 'zedder.firstname',
  //       displayName: 'Firstname'
  //   },{
  //       field: 'zedder.lastname',
  //       displayName: 'Lastname'
  //   }, {
  //       field: 'zedder.email',
  //       displayName: 'Email',
  //   }, {
  //       field: 'zedder.phone',
  //       displayName: 'Phone',
  //   }, {
  //       field: 'name',
  //       displayName: 'Item'
  //   }, {
  //       field: 'type',
  //       displayName: 'Item Type'
  //   }, {
  //       field: 'itemPrice',
  //       displayName: 'Item Price'
  //   }, {
  //       field: 'total',
  //       displayName: 'Order Total'
  //   }, {
  //       field: 'shipping_price',
  //       displayName: 'Order Shipping'
  //   }, {
  //       field: 'transaction[0].service',
  //       displayName: 'Transaction Type'
  //   },{
  //       field: 'completed[0].transaction_id',
  //       displayName: 'Transaction ID',
  //       visible: false
  //   },{
  //       field: 'completed[0].payer_id',
  //       displayName: 'Payer ID',
  //       visible: false
  //   },{
  //       field: 'completed[0].payment_id',
  //       displayName: 'Payment ID',
  //       visible: true
  //   },{
  //       field: 'completed[0].gross',
  //       displayName: 'Gross ammount',
  //       visible: false
  //   },{
  //       field: 'completed[0].fee',
  //       displayName: 'PayPal Fee',
  //       visible: false
  //   },{
  //       field: 'completed[0].net',
  //       displayName: 'Net Ammount',
  //       visible: false
  //   },{
  //       field: 'completed[0].capture_status',
  //       displayName: 'Transaction Status'
  //   },{
  //       field: 'completed[0].pledge_paid',
  //       displayName: 'Pledge Payment'
  //   },{
  //       field: 'items_json.length',
  //       displayName: 'Total Items'
  //   },{
  //       field: 'created_at',
  //       displayName: 'Created'
  //   },{
  //       field: 'updated_at',
  //       displayName: 'Updated',
  //       sort: { priority: 0, direction: 'desc' },
  //   },{
  //       field: 'zedder.delivery_address.address',
  //       displayName: 'Address',
  //   },{
  //       field: 'zedder.delivery_address.locality',
  //       displayName: 'Suburb',
  //   },{
  //       field: 'zedder.delivery_address.region',
  //       displayName: 'State',
  //   },{
  //       field: 'zedder.delivery_address.postcode',
  //       displayName: 'Postcode',
  //   }
  // ];

  exportCols = [{
    field: 'subnumber',
    displayName: 'Sub No',
}, {
    field: 'sublastname',
    displayName: 'Last Name'
}, {
    field: 'subfirstname',
    displayName: 'First Name'
}, {
    field: 'subemail',
    displayName: 'Email'
}, {
    field: 'submobile',
    displayName: 'Phone'
}, {
    field: 'posted',
    displayName: 'Processed',
}, {
    field: 'subscription.subtypecode',
    displayName: 'Sub Type'
},

{
    field: 'program.programname',
    displayName: 'Program'
},
{
    field: 'nameoncard',
    visible: false
},
{
    field: 'subaddress1',
    visible: false
}, {
    field: 'subaddress2',
    visible: false
}, {
    field: 'delivery_address.address',
    visible: false
}, {
    field: 'delivery_address.locality',
    visible: false
}, {
    field: 'delivery_address.postcode',
    visible: false
}, {
    field: 'delivery_address.region',
    visible: false
}, {
    field: 'delivery_address.country',
    visible: false
}, {
    field: 'expirydate',
    visible: false
}, {
    field: 'paymentdate',
    visible: false
}, {
    field: 'receiptnumber',
    visible: false
}, {
    field: 'suburl',
    visible: false
}, {
    field: 'subhomephone',
    visible: false
}, {
    field: 'gender',
    visible: false
}, {
    field: 'subcomment',
    visible: false
}, {
    field: 'submusicanname',
    visible: false
}, {
    field: 'subbandname',
    visible: false
}, {
    field: 'subbusinessname',
    visible: false
}, {
    field: 'subcommunitygroup',
    visible: false
}, {
    field: 'subartistname',
    visible: false
}, {
    field: 'fl_announcer',
    visible: false
}, {
    field: 'fl_volunteer',
    visible: false
}, {
    field: 'petname',
    visible: false
}, {
    field: 'donation',
    visible: false
}, {
    field: 'subskill',
    visible: false
}, {
    field: 'subskilldesc',
    visible: false
}, {
    field: 'createddate',
    visible: false
}, {
    field: 'created_at',
    visible: false
}

]
}
