<mat-card class="mat-typography">
	<mat-card-header class="search-params">
		<mat-card-title>

			<div style="display: flex; margin-top: 0.5em; margin-bottom: 0.5em; justify-content: center; align-items: center;">
				<div>
					<img style="width: 100px; height:auto" (click)="editArt()" [src]="albumart ? albumart : '/assets/record.svg'">
				</div>
				<div style="margin-left: 1em; flex: 1">
					<h5 style="font-size: 20px; font-weight: 700"><a matTooltip="Populate fields from tags" (click)="populate()">{{artist}}</a></h5>
					<h6 style="font-size: 17px">{{album}}</h6>
				</div>
				<div style="width: 72px; justify-content: center; align-items: center;">
					<div  style="width: 64px; height: 64px; position: relative;">
						<mat-spinner *ngIf="saving" diameter="64" style="position: absolute; top: 0; left: 0" />
						<button [disabled]="saving" class="tool-icon"  matTooltip="Save tags" mat-icon-button aria-label="Save tags" (click)="saveTags()" style="position: absolute; top: 8px; left: 8px">
							<mat-icon>playlist_add_check</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</mat-card-title>
	</mat-card-header>

	<mat-card-content>
		 <div>
			 <div [formGroup]="myGroup" style="display: flex; flex-direction: column;">

				<mat-form-field class="full-width">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Title" formControlName="title">
        </mat-form-field> 

				<mat-form-field class="full-width">
          <mat-label>Album</mat-label>
          <input matInput placeholder="Album" formControlName="album">
        </mat-form-field> 

				<mat-form-field class="full-width">
          <mat-label>Artist</mat-label>
          <input matInput placeholder="Artist" formControlName="artist">
        </mat-form-field> 

				<mat-form-field class="full-width">
          <mat-label>Album Artist</mat-label>
          <input matInput placeholder="Album Artist" formControlName="albumartist">
        </mat-form-field> 

        <div style="display: flex; gap: 8px;">
					<mat-form-field>
						<mat-label>Year</mat-label>
						<input matInput placeholder="Year" formControlName="year">
					</mat-form-field> 

					<mat-form-field>
						<mat-label>Track Number</mat-label>
						<input matInput placeholder="Track Number" formControlName="tracknum">
					</mat-form-field> 

					<mat-form-field>
						<mat-label>Track Total</mat-label>
						<input matInput placeholder="Track Total" formControlName="totaltracks">
					</mat-form-field> 

					<mat-form-field>
						<mat-label>Disc Number</mat-label>
						<input matInput placeholder="Disc Number" formControlName="discnum">
					</mat-form-field> 

					<mat-form-field>
						<mat-label>Disc Total</mat-label>
						<input matInput placeholder="Disc Total" formControlName="disctotal">
					</mat-form-field> 
	
				</div>

			</div>
		</div>
		<div style="display: flex; justify-content: center; align-items: center;">
			<button class="tool-icon"  matTooltip="Previous tag" mat-icon-button aria-label="Previous Tag" (click)="prevItem($event)" [disabled]="index < 0" >
				<mat-icon>navigate_before</mat-icon>
			</button>
			<div style="flex: 1;">
				{{ (index === -1) ? 'Tag all files' : tracks[index].name }}
			</div>
			<button class="tool-icon"  matTooltip="Next" mat-icon-button aria-label="Next Tag" (click)="nextItem($event)" [disabled]="index === tracks.length - 1" >
				<mat-icon>navigate_next</mat-icon>
			</button>
		</div>
	</mat-card-content>
</mat-card>