<mat-toolbar>
  <span>Contacts</span>
  <span style="flex: 1"></span>
  <button routerLink="/contacts/search" mat-button class="caps" aria-label="Search">Search</button>
  <button routerLink="/contacts/new" mat-button class="caps" aria-label="Add Contact">Add Contact</button>
</mat-toolbar>

<mat-toolbar class="quick-search" style="position: sticky; top: 0; z-index: 150">
  <mat-form-field class="full-width">
    <input matInput placeholder="Quick Search" (keydown.enter)="quickSearch($event)" [value]="searchString">
  </mat-form-field>
</mat-toolbar>

<section [hidden]="searchResults.length === 0" class="search-results mat-elevation-z8">
  <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element">{{element.contact_no}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"><a [routerLink]="'/contacts/' + element.contact_no">{{element.org_nm}}</a></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element">{{element.email}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> Phone </th>
      <td mat-cell *matCellDef="let element">{{element.work_ph}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>    
</section>

<mat-toolbar style="position: sticky; top: 0; z-index: 150;">
  <mat-toolbar-row>
    <h2>{{contact.org_nm}}</h2>
  </mat-toolbar-row>
  <mat-toolbar-row style="height: 24px;" ng-show="zedder.id">
    <ul class="inline" style="width: 100%;">
      <li>
        <strong>ID #:</strong> {{contact.contact_no}}
      </li>
      <li>
        <strong>Created:</strong> {{contact.created_at | date:'medium'}}
      </li>
      <li>
        <strong>Last Updated:</strong> {{contact.updated_at | date:'medium'}}
      </li>
    </ul>
  </mat-toolbar-row>
</mat-toolbar>

<div class="info-container mat-typography" [formGroup]="contactForm">
  <div style="flex: 1">
    
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Contact Information</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-form-field class="full-width">
          <mat-label>Organisation Name</mat-label>
          <input matInput placeholder="Organisation Name" formControlName="org_nm">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Mobile Phone</mat-label>
          <a [disabled]="!contact.work_ph" [href]="'tel:' + contact.work_ph" mat-icon-button matSuffix>
            <mat-icon>phone</mat-icon>
          </a>
          <input matInput placeholder="Mobile Phone" formControlName="work_ph">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <a [disabled]="!contact.email" [href]="'mailto:' + contact.email" mat-icon-button matSuffix>
            <mat-icon>email</mat-icon>
          </a>
          <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>

				<mat-form-field class="full-width">
          <mat-label>Notes</mat-label>
          <textarea cdkTextareaAutosize matInput placeholder="Notes" formControlName="notes" ></textarea>
        </mat-form-field>
      </mat-card-content>
		</mat-card>

		<mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Details</mat-card-title>
      </mat-card-header>

      <mat-card-content>

				<mat-form-field class="full-width">
          <mat-label>Categories</mat-label>
          <mat-chip-grid #categoryChip aria-label="Cateogories">
            <mat-chip-row *ngFor="let category of contactForm.get('contactcategories')!.value"
              (removed)="removeCategory(category)"
              [editable]="false"
              (edited)="editCategory(category, $event)"
              [aria-description]="'press enter to edit ' + category.category">
              {{category.category}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + category.category">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Tags / Themes..."
            #categoryInput
            [formControl]="categoryControl"
            [matAutocomplete]="categoryComplete"
            [matChipInputFor]="categoryChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #categoryComplete="matAutocomplete" 
            (optionSelected)="selectedCategory($event)">
            <mat-option *ngFor="let category of categoryOptions | async" [value]="category">
              {{category.category}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Website</mat-label>
					<a [disabled]="!contact.website" [href]="contact.website" mat-icon-button matSuffix>
						<mat-icon>link</mat-icon>
					</a>
					<input matInput placeholder="Website URL" formControlName="website">
				</mat-form-field>


				<mat-form-field class="full-width">
          <mat-label>ABN / ACN</mat-label>
					<input matInput placeholder="ABN / ACN" formControlName="org_abn">
        </mat-form-field>

      </mat-card-content>
		</mat-card>



    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Address</mat-card-title>
      </mat-card-header>
      
      <mat-card-content>
        <div formGroupName="address">
          <mat-form-field class="full-width">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Address" formControlName="address" value="">
          </mat-form-field>

          <div style="display: flex; gap: 8px;">
            <mat-form-field style="flex-grow: 1;">
              <mat-label>Suburb</mat-label>
              <input matInput placeholder="Suburb" formControlName="locality" value="">
            </mat-form-field>

            <mat-form-field style="flex-grow: 1;">
              <mat-label>State</mat-label>
              <input matInput placeholder="State" formControlName="region" value="">
            </mat-form-field>

            <mat-form-field style="flex-grow: 1;">
              <mat-label>Country</mat-label>
              <mat-select matNativeControl formControlName="country">
                <mat-option *ngFor="let country of countries" [value]="country.alpha">
                  {{country.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 160px;">
              <mat-label>Post Code</mat-label>
              <input matInput placeholder="Postal Code" formControlName="postcode" value="">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
		</mat-card>



  </div>

  <div style="flex: 1">

    <mat-card *ngIf="id === 'search'" class="search-card" >
      <mat-card-header>
        <mat-card-title>Search Paramaters</mat-card-title>
      </mat-card-header>
      <mat-card-content [formGroup]="searchForm">

        <mat-form-field class="full-width">
          <mat-label>Individual Contact</mat-label>
          <input matInput placeholder="Individual Contact" formControlName="contactname">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Date (Created)</mat-label>
          <input matInput [matDatepicker]="createdDateStartPicker" formControlName="createdDateStart">
          <mat-datepicker-toggle matIconSuffix [for]="createdDateStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #createdDateStartPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date (Created)</mat-label>
          <input matInput [matDatepicker]="createdDateEndPicker" formControlName="createdDateEnd">
          <mat-datepicker-toggle matIconSuffix [for]="createdDateEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #createdDateEndPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Date (Release Year)</mat-label>
          <input matInput [matDatepicker]="releaseDateStartPicker" formControlName="releaseYearStart">
          <mat-datepicker-toggle matIconSuffix [for]="releaseDateStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #releaseDateStartPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date (Release Year)</mat-label>
          <input matInput [matDatepicker]="releaseDateEndPicker" formControlName="releaseYearEnd">
          <mat-datepicker-toggle matIconSuffix [for]="releaseDateEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #releaseDateEndPicker></mat-datepicker>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title style="display:flex">Individual Contacts<div style="flex: 1"></div>
					<mat-icon matTooltip="Add Individual Contact Card" style="margin-top: 4px;" (click)="addMember()">add_circle</mat-icon>
				</mat-card-title>
      </mat-card-header>

      <mat-card-content>
				<div formArrayName="individuals">
					<div *ngFor="let member of refForm.controls; index as memberGroup" [formGroupName]="memberGroup">
						<mat-card class="contact-card">
							<mat-card-header>
								<mat-card-title-group>
									<mat-card-title>{{member.value['name']}}</mat-card-title>
									<mat-card-subtitle>{{member.value['position_title']}}</mat-card-subtitle>
									<button mat-icon-button (click)="member['visible'] = !member['visible']">
										<mat-icon>account_circle</mat-icon>
									</button>
								</mat-card-title-group>
							</mat-card-header>
							<mat-card-content>
								<div>{{member.value['email']}}</div>
								<div>{{member.value['mobile_phone']}}</div>
								<div>{{member.value['work_phone']}}</div>

								<div *ngIf="member['visible']" style="padding-top: 32px;">
									<mat-form-field class="full-width">
										<mat-label>Name</mat-label>
										<input matInput placeholder="Name" formControlName="name">
									</mat-form-field>
	
									<mat-form-field class="full-width">
										<mat-label>Position</mat-label>
										<input matInput placeholder="Position Title" formControlName="position_title">
									</mat-form-field>
	
									<mat-form-field class="full-width">
										<mat-label>Email</mat-label>
										<input matInput placeholder="Email" formControlName="email">
									</mat-form-field>
	
									<mat-form-field class="full-width">
										<mat-label>Mobile Phone</mat-label>
										<input matInput placeholder="Mobile Phone" formControlName="mobile_phone">
									</mat-form-field>
	
									<mat-form-field class="full-width">
										<mat-label>Work Phone</mat-label>
										<input matInput placeholder="Work Phone" formControlName="work_phone">
									</mat-form-field>
	
									<mat-form-field class="full-width">
										<mat-label>Notes</mat-label>
										<textarea cdkTextareaAutosize matInput placeholder="Notes" formControlName="notes"></textarea>
									</mat-form-field>
	
									<button matTooltip="Remove Member" (click)="removeMember(memberGroup)" mat-icon-button><mat-icon>remove_circle</mat-icon></button>
								</div>
							</mat-card-content>
						</mat-card>
					</div>
				</div>  
			</mat-card-content>

		</mat-card>

		<mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Sponsorship</mat-card-title>
      </mat-card-header>
      
      <mat-card-content>
				<mat-list role="list">
					<mat-list-item *ngFor="let promo of contact.promos" role="listitem">
						<a [href]="'/sponsorship/' + promo.id">{{promo.title}}</a>
					</mat-list-item>
				</mat-list>
      </mat-card-content>
		</mat-card>


    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Relation</mat-card-title>
      </mat-card-header>

      <mat-card-content>

				<mat-form-field class="full-width">
					<mat-label>Contact Log</mat-label>
					<textarea cdkTextareaAutosize matInput placeholder="Contact Log" formControlName="log"></textarea>
				</mat-form-field>

      </mat-card-content>
		</mat-card>

  </div>
</div>

<div class="floating-fab" [hidden]="!formEdited">
  <div  style="width: 64px; height: 64px; position: relative;">
    <mat-spinner *ngIf="submitLocked" diameter="72" style="position: absolute; top: 0; left: 0" />
    <button [disabled]="submitLocked" (click)="searchResultsSelection.contact_no ? goto(searchResultsSelection.contact_no) : id === 'search' ? searchContacts() : saveContact()" mat-fab color="primary" aria-label="Save Contact" style="position: absolute; top: 8px; left: 8px">
      <mat-icon>{{searchResultsSelection.contact_no ? 'arrow_forward' : id === 'search' ? 'search' : id === 'new' ? 'add' : 'edit' }}</mat-icon>
    </button>
  </div>
</div>