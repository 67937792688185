import { Component, Inject } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { StudioDelegationComponent } from '../studio-delegation/studio-delegation.component';
import { SocketService } from '../socket.service';
import { AuthenticationService } from '../authentication.service';
import { StudioService } from '../studio.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-on-air-studio',
  templateUrl: './on-air-studio.component.html',
  styleUrls: ['./on-air-studio.component.scss']
})
export class OnAirStudioComponent {

  constructor(
    private socket: SocketService,
    // @Inject('$rootScope') private $rootScope,
    public dialog: MatDialog,
    private AuthenticationService: AuthenticationService,
    public StudioService: StudioService
  ) {

    this.socket.onmessage( (ev) => { 

			let message = JSON.parse(ev.data);
			console.log(message);
			if(message.status === 'connected') {
				socket.send({ studioAccess: { status: String(this.AuthenticationService.getUser()?.details.id).padStart(5, '0') } });
				// socket.send(JSON.stringify({ studioAccess: { studioStatus: 99 } }));
	
			}
      if(message.delegationStatus) {
        console.log('got delegationStatus')
        this.delegation = message.delegationStatus;

				this.StudioService.setDelegationStatus(message.delegationStatus);

        if(this.delPanelRef) {
          this.delPanelRef.config.scope.ctrl.delegation = message.delegationStatus;
        }
      }
			if(message.studioAccess === 'granted') {
				if(message.studio) { 
          this.StudioService.setStudio(message.studio)
					// this.$rootScope.studio = message.studio;
					this.studio = message.studio;
				}
				console.log('awesome')
				this.onAir = true;
				this.StudioService.setOnAir(true);
				console.log(this.onAir)
				this.StudioService.sendMessage({ onAir: true, studio: this.studio });
        
        socket.send({ 
          delegationRequest: { channel: 'fm', source: 'studio1', studio: this.studio }
        });
				
				setTimeout(() => { 
					socket.send({ studioAccess: { status: String(this.AuthenticationService.getUser().details.id).padStart(5, '0') } }) 
				}, 305000);
			}
			if(message.studioAccess === 'denied' ||  message.studioAccess === 'expired') {
				this.onAir = false;
				this.StudioService.setOnAir(false);

				this.StudioService.sendMessage({ onAir: false, studio: this.studio });

				// this.$rootScope.$broadcast('onAir', { onAir: false, studio: this.studio });
			}
		});

		this.socket.connect();

  }
  studio = 1;
  onAir: string | boolean = false;
  delegation: any;

  delegationPanel: boolean = false;
  delPanelRef: any;

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 36,
      originX: 'start',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'top',
    },
  ];
  
  changeStudios = (event) => {
    event.stopPropagation();
    switch (this.studio) {
      case 1:
        this.studio = 2;
        break;
      case 2:
        this.studio = 3;
        break;
      case 3:
        this.studio = 4;
        break;
      default:
        this.studio = 1;
        break;
    }
    if(this.onAir)
      this.toggleOnAir('reset');
  }

  toggleDelegation(event) {
    event.stopPropagation();
    this.delegationPanel = !this.delegationPanel;

    // this.dialog.open(StudioDelegationComponent, {
    //   // height: '80vh',
    //   // width: 'calc(80vh * 3/4)',
    //   data: {
  
    //   },
    // });
  }

  toggleOnAir = (reset: string | boolean = false) =>  { 
    if(reset) {
      this.onAir = false;
    }
    else {
      this.onAir = this.onAir ? false : 'requested';
      if(!this.onAir) {
        this.StudioService.setOnAir(false)
      }
    }
    console.log(this.onAir);
    this.socket.send({ 
      studioAccess: { 
        studio: this.studio, 
        state: this.onAir, 
        id: String(this.AuthenticationService.getUser().details.id).padStart(5, '0'), 
        name: this.AuthenticationService.getUser().details.firstname + ' ' + this.AuthenticationService.getUser().details.lastname 
      } 
    });
  }
}
