import { X } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import 'chartjs-adapter-moment';
import { Location } from "@angular/common";
import { thresholdSturges } from 'd3';
import * as moment from 'moment';
import { ReleasesBaseComponent } from '../releases-archive/releases.base.component';
import { UIRouter } from "@uirouter/core";
import { SessionService } from '../session.service';
import { PlaylistService } from '../playlist.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ReleasesBaseComponent {
  constructor(
    router: Router,
    location: Location,
    http: HttpClient,
		// @Inject('$rootScope') $rootScope: any,
		PlaylistService: PlaylistService,
		SessionService: SessionService,
    private AuthenticationService: AuthenticationService
  ) {

    super(router, location, http, PlaylistService, SessionService)

    this.http.get<any>(environment.apiSrc + '/AOTW' ).subscribe( data => {
      this.aotw = data;
    });

    

    this.http.get<any>(environment.apiSrc +'/seven-day-orders').subscribe( data => {
      let sevenDayOrders = data.map((i, n) => {
        let c = i.created_at.split(' ');
        let time = c[1].split('+')[0].split(':');
        let colors = ['#7b66d2', '#a699e8', '#dc5fbd', '#ffc0da', '#5f5a41', '#b4b19b', '#995688', '#d898ba', '#ab6ad5', '#d098ee', '#8b7c6e', '#dbd4c5'];
        let color = hex2rgba( colors[n % 12], 0.3);
        let seconds = (time[0] * 60 * 60) + (time[1] * 60) + (time[2])
        return { x: moment(c[0]), y: moment(c[1].split('+')[0], 'HH:mm:ss' ), r: i.total / 25, color: color, order: i.order_id };
      });

      let colours = data.map((i, n) => {
        // let colors = ['#7b66d2', '#a699e8', '#dc5fbd', '#ffc0da', '#5f5a41', '#b4b19b', '#995688', '#d898ba', '#ab6ad5', '#d098ee', '#8b7c6e', '#dbd4c5'];
        // let color = hex2rgba( colors[n % 12], 0.3);
        // let color 'h'
        let colour = 'hsla('+ Math.floor((Math.random() * 120) + ((n / data.length) * 360) ) +  ', 75%, 75%, 75%)';

        return colour;
      } )

      this.sevenDayOrders = data;

      this.bubbleChartData = {
        labels: [],
        datasets: [
          {
            data: sevenDayOrders,
            label: '7 Days of Orders',
            backgroundColor: colours,
            borderColor: 'blue',
            borderWidth: 0,
            hoverBorderWidth: 0,
          },
        ],
      };
      

    });
  
  
  }

  aotw: any;
  
  sevenDayOrders : any[] = [];

  bubbleChartOptions: ChartConfiguration['options'] = {
		scales: {
			x: 
      {
        type: 'time',
        time: {
          unit: 'day',
        }
      },
      y: 
      {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            'minute': 'HH:mm'
          }
        }
      },
		},
    plugins: {
      tooltip: {
        callbacks: {
          label: (context : any) => { 
            // let date = moment(context.parsed.x).format('ddd DD/MM');
            let date = moment(context.parsed.x).format('ddd');
            let time = moment(context.parsed.y).format('hh:mm A');
            let total = context.raw.r  * 25;
            console.log(context);
            return  date + ' ' + time + ', $' + total;
          }
        }
      }
    }
	};

  public bubbleChartData?: ChartData<'bubble'>;
  colors: any[] = [];

  test = (element) => {
		if (this.AuthenticationService.requireAccess('reception') || this.AuthenticationService.requireAccess('admin')  ) {
			if (element.active && element.active[0]) {
				let order = this.sevenDayOrders[element.active[0].index];
				console.log(order);
				this.router.navigateByUrl('/orders/' + order.order_id );
				// this.$rootScope.$apply();
			}
		}
	};
}
