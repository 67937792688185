import { Component, ElementRef, HostListener, ViewChild, ViewChildren } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { PlaylistService } from './playlist.service';
import { MatSidenav } from '@angular/material/sidenav';
import { CdkDropList, DragDrop } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AnnouncerPlaylistDialogComponent } from './announcer-playlist-dialog/announcer-playlist-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(
    private AuthenticationService: AuthenticationService,
    private PlaylistService: PlaylistService,
    private dragDrop: DragDrop,
    private http: HttpClient,
    public dialog: MatDialog
  ){}

  @ViewChild('navItems', { read: ElementRef }) private navItems!: ElementRef;
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChildren(CdkDropList) droplist!: any;

  // overflow;
  // leftVisible;
  // rightVisible;

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.overflow = this.navItems.nativeElement.clientWidth - this.navItems.nativeElement.scrollWidth;
  //   this.checkVisible();
  // }

  // checkVisible() {
  //   if(this.overflow < 0 && this.navItems.nativeElement.scrollLeft > 0) {
  //     this.leftVisible = true;
  //   } else {
  //     this.leftVisible = false;
  //   }
  //   if(this.overflow < 0 && this.navItems.nativeElement.offsetWidth + this.navItems.nativeElement.scrollLeft < this.navItems.nativeElement.scrollWidth) {
  //     this.rightVisible = true;
  //   }
  //   else {
  //     this.rightVisible = false;
  //   }
  // }



  // shiftLeft() {
  //   this.navItems.nativeElement.scrollTo({
  //     top: 0,
  //     left: this.navItems.nativeElement.scrollLeft + 200,
  //     behavior: "smooth",
  //   })
  //   this.checkVisible()

  // }


  // shiftRight() {
  //   this.navItems.nativeElement.scrollTo({
  //     top: 0,
  //     left: this.navItems.nativeElement.scrollLeft - 200,
  //     behavior: "smooth",
  //   })
  //   this.checkVisible()

  // }


  ngOnInit() {
    this.AuthenticationService.startBeacon()

    this.PlaylistService.onPlaylistMessage().subscribe( data => {
      if ( data ){
        this.showPlaylist = true;
        this.sidenav.open();
        setTimeout(() => {
          // console.log(this.dragDrop)
          this.droplist.forEach(list => list._dropListRef._cacheParentPositions())
          // this.droplist._dropListRef._cacheParentPositions();
        }, 200)
      }
      else {
        this.showPlaylist = false;
        this.sidenav.close();
      }
      // this.panelOpenState = true;
    })

    const zedderId = this.AuthenticationService.getUser().details?.id;

    if(zedderId)
      this.http.get<any[]>( environment.musicDBapiSrc + '/playlists-by-user/' + zedderId ).subscribe( data => {
        this.playlists = data.filter(i => !i.archive);
      })
  }

  ngAfterViewInit() {
    // this.overflow = this.navItems.nativeElement.clientWidth - this.navItems.nativeElement.scrollWidth;
    // setTimeout(() => {
    //   this.checkVisible();
    // });
  }


  predicate(drag: any, drop: any){
    console.log(drag, drop)
    return true;
  }


  playlistDrop(event) {
    console.log(event);
    const playlistId = event.container.data.id;
    const trackId = event.item.data.title_id;
    console.log({ 'playlistId': event.container.data.id, 'trackId': event.item.data.title_id});
    if (playlistId && trackId) {
      this.http.post<any[]>( environment.musicDBapiSrc + '/add-to-playlist', { 'playlistId': playlistId, 'trackId': trackId } ).subscribe( data => {
        console.log(data)
        const zedderId = this.AuthenticationService.getUser().details?.id;
        if(zedderId)
          this.http.get<any[]>( environment.musicDBapiSrc + '/playlists-by-user/' + zedderId ).subscribe( data => {
            this.playlists = data.filter(i => !i.archive);;
          })
        // this.playlists = data;
      })
    }
    else if (playlistId) { 
      let data = event.item.data
      console.log(data)
      if (data.track && data.track.playback_duration)
        console.log('TEMP!')

      this.http.post<any[]>( environment.musicDBapiSrc + '/add-to-playlist', { 'playlistId': playlistId, 'file': data.track.filename, 'details': {length: Number(data.track.playback_duration), artist: data.track.artist, title: data.track.title } } ).subscribe( data => {
        console.log(data)
        const zedderId = this.AuthenticationService.getUser().details?.id;
        if(zedderId)
          this.http.get<any[]>( environment.musicDBapiSrc + '/playlists-by-user/' + zedderId ).subscribe( data => {
            this.playlists = data.filter(i => !i.archive);;
          })
        // this.playlists = data;
      })
      // this.http.post<any[]>( environment.musicDBapiSrc + '/add-to-playlist', { 'playlistId': playlistId, 'trackId': trackId } ).subscribe( data => {
      //   console.log(data)
      //   const zedderId = this.AuthenticationService.getUser().details?.id;
      //   if(zedderId)
      //     this.http.get<any[]>( environment.musicDBapiSrc + '/playlists-by-user/' + zedderId ).subscribe( data => {
      //       this.playlists = data.filter(i => !i.archive);;
      //     })
      //   // this.playlists = data;
      // })
    }
    else {
      const dialogRef = this.dialog.open(AnnouncerPlaylistDialogComponent, {
        data: {
          tracks: [ trackId ]
        },
      })
  
      dialogRef.afterClosed().subscribe(result => {
        if(result !== 'abort') {
          console.log(result);
          const zedderId = this.AuthenticationService.getUser().details?.id;
          if(zedderId)
            this.http.get<any[]>( environment.musicDBapiSrc + '/playlists-by-user/' + zedderId ).subscribe( data => {
              this.playlists = data;
            })
        }
      });
    }

    setTimeout(() => {
      this.PlaylistService.sendPlaylistMessage(false);
    }, 500); 
  }
  musicDBapiSrc = environment.musicDBapiSrc;

  playlists: any[] = [];

  playlist: any[] = []
  showPlaylist = false;

  public panelOpenState: boolean = false;

  public requireAccess = (acl) => this.AuthenticationService.requireAccess(acl);

  public isLoggedIn = () => !!this.AuthenticationService.getUser();

  public getUser = () => this.AuthenticationService.getUser();

  copyToken(): void {
    navigator.clipboard ? navigator.clipboard.writeText(this.AuthenticationService.getUser().token).catch(() => {
    }) : console.log({ userToken: this.AuthenticationService.getUser().token });
  }

  togglePlayerVisibility() {
    this.PlaylistService.sendMessage({ action: 'toggleVisibility' })
  }

  // sidebarVisible = false;
}
