import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, inject, Inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Sort } from '@angular/material/sort';
import { NodeWithI18n } from '@angular/compiler';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { subscriptionLogsToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { Transition } from "@uirouter/angular";
import { Subtypes, Show, Zedder, Countries, ProgramsShort } from '../zeddb.types';
import { compare } from '../helpers'
import { CountriesService } from '../countries.service';
import { SubtypesService } from '../subtypes.service';
import { ZedderService } from '../zedder.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-zedders',
  templateUrl: './zedders.component.html',
  styleUrls: ['./zedders.component.scss']
})
export class ZeddersComponent implements OnInit {
  @ViewChild('showInput')
  showInput!: ElementRef<HTMLInputElement>;
  @ViewChild('backupShowInput')
  backupShowInput!: ElementRef<HTMLInputElement>;

 constructor(
    public SubtypesService: SubtypesService, 
    public ZedderService: ZedderService,
    public CountriesService: CountriesService,
    // @Inject('$scope') private $scope: any,
    // @Inject('$rootScope') private $rootScope: any,
    private http: HttpClient,
    public location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.lockObservable.subscribe( value => {
      console.log('lock: ', value)
      this.formDisabled = value;
      if (value) {
        this.zedderForm.disable()
      }
      else {
        this.zedderForm.enable()
      }
    })
  }

  ngOnInit() {


    this.route.params.subscribe( (data:any) => {
      console.log(data);
      this.id = data.id;

      if (this.id === 'search') {
        // let searchString = this.params['#'];
        let searchString = this.route.snapshot.fragment;
        if (searchString && searchString !== '') {
          this.searchString = searchString;
          this.http.post<any>(environment.apiSrc + '/zedders', { searchString: searchString }).subscribe(data => {
            this.searchResults = data;
            this.sortData(this.lastSort);
          })
        }
        this.zedderForm.reset();
      }
      if (this.id === 'search' || this.id === 'add') {
        this.lockObservable.next(false);
      }
      else {
        this.searchResultsSelection = {};
        this.fetchZedder(this.id)
        this.lockObservable.next(false);

      }
  
    })
 

    this.filteredOptions = this.announcerControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.programNames.slice())
    );

    this.CountriesService.query().subscribe(response => { 
      this.countries = response; 
    });
    // this.InterestsService = InterestsService;
    this.SubtypesService.query().subscribe(response => { 
      this.subCategories = response; 
    });
    
    
    this.zedderForm.statusChanges.subscribe( () => {
      console.log('status changed');
    });

    this.zedderForm.valueChanges.subscribe( () => {
      console.log('CHANGED!', this.zedderForm)
      this.zedder = Object.assign(this.zedder, this.zedderForm.value);
      this.formEdited = true;
    });
   
    this.fetchPrograms();

  }

  id: string | null = null;

  announcerControl = new UntypedFormControl('');
  filteredOptions!: Observable<ProgramsShort[]>;
  countries: Countries[] = [];

  subCategories: Subtypes[] = [];

  // params = this.trans.params();
  lastSort: Sort = { active: 'id', direction: '' };
  sortedData: any[] = [];
  zedderForm = new UntypedFormGroup({
    firstname: new UntypedFormControl(null), 
    lastname: new UntypedFormControl(null), 
    pronouns: new UntypedFormControl(null), 
    subnumber: new UntypedFormControl(null),
    subtype: new UntypedFormControl(1),
    expiry: new UntypedFormControl(new Date()),
    gender: new UntypedFormControl(null),
    dob: new UntypedFormControl(null),
    email: new UntypedFormControl(null), 
    phone: new UntypedFormControl(null), 
    zedletter: new UntypedFormControl(false),
    acknowledgement: new UntypedFormControl(false),
    delivery_address: new UntypedFormGroup({
      address: new UntypedFormControl(null),
      locality: new UntypedFormControl(null),
      region: new UntypedFormControl(null),
      postcode: new UntypedFormControl(null),
      country: new UntypedFormControl(null)
    }),
    subcomment: new UntypedFormControl(null),
    volunteer_status: new UntypedFormControl(false)
  });

  today = new Date();

  zedder: Zedder = {id: 0, created_at: '', updated_at: '', expiry: new Date('2024-04-01'), firstname: 'Firstname', lastname: 'Lastname', subscribers: [], related: [], volunteer_status: false, orders: [], volunteer: []};

  lockObservable = new Subject<boolean>();

  formEdited = false;
  formDisabled = false;
  submitLocked = false;


  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  shows: ProgramsShort[] = [];
  backupShows: ProgramsShort[] = [];

  genders = ['Not Listed', 'Man', 'Woman', 'Other', 'Non-Binary']
  displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'subnumber', 'expiry'];
  
  announcer = inject(LiveAnnouncer);

  message: string = '';
  searchString = '';
  searchResults: any = [];
  searchResultsSelection: any = {}
  programNames: ProgramsShort[] = [];

  searchZedders() {

  }
  fetchPrograms = () => {
    this.http.get<any>(environment.musicDBapiSrc + '/program-names').subscribe(data => {
      this.programNames = data;
    })
  }  
  // activeSub = (sub) => new Date(sub) > this.today;

  activeSub = (sub) => { 
    let expiry = new Date(sub).valueOf();
    let now = this.today.valueOf();
    return expiry > now;
  };


  quickSearch = (event) => {
    let searchString = event.target.value;
    this.searchString = searchString;
    this.location.replaceState('/zedders/search#' + searchString);
    this.http.post<any>(environment.apiSrc + '/zedders', { searchString: searchString }).subscribe(data => {
      this.searchResults = data;
      this.sortData(this.lastSort);
    })
  }

  saveZedder = () => {
    this.zedder.announcer = this.shows;
    this.zedder.backup_announcer = this.backupShows;
    console.log(this.zedderForm.value);
    console.log(this.zedder);
    console.log(this.shows, this.backupShows);

    this.ZedderService.update(this.zedder).subscribe((response) => {
			this.message = 'Zedder ' + this.zedder.id + ' Updated!';
			// this.fetchZedder(this.zedder.id);
      console.log(response);
		}, (err) => {
			alert('Couldnt update!');
		});
  }

  fetchZedder = (id) => {
    this.ZedderService.get(id).subscribe((res) => {
      this.zedder = res;
      this.shows = res.announcer.map(i => ({id: i.id, name: i.name, slug: i.slug}) )
      this.backupShows = res.backup_announcer.map(i => ({id: i.id, name: i.name, slug: i.slug}))
      this.zedderForm.patchValue({
        firstname: res.firstname, 
        lastname: res.lastname, 
        gender: res.gender ? Number(res.gender) : null,
        pronouns: res.pronouns, 
        dob: res.dob,
        subtype: res.subtypeid, 
        subnumber: res.subnumber, 
        expiry: new Date(res.expiry), 
        email: res.email, 
        phone: res.phone, 
        zedletter: res.zedletter, 
        acknowledgement: res.acknowledgement, 
        delivery_address: {
          address: res.delivery_address && res.delivery_address.address, 
          locality: res.delivery_address && res.delivery_address.locality, 
          region: res.delivery_address && res.delivery_address.region, 
          country: res.delivery_address && res.delivery_address.country, 
          postcode: res.delivery_address && res.delivery_address.postcode, 
        },
        subcomment: res.subcomment,
        volunteer_status: res.volunteer_status
      }, { emitEvent: false } );
    });
  }

  goto = (id) => {
    this.lockObservable.next(false);
    this.searchResultsSelection = {};
    this.router.navigateByUrl('/zedders/' + id);
  }

  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    this.fetchZedder(row.id);
    this.lockObservable.next(true);
  };

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.searchResults.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'name':
          return compare(a.firstname + a.lastname, b.firstname + b.lastname, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'subnumber':
          return compare(a.subnumber, b.subnumber, isAsc);
        case 'expiry':
          return compare(a.expiry, b.expiry, isAsc);
        default:
          return 0;
      }
    });
  }

  sortByDate(array: string, prop: string) {
    return this.zedder[array].sort((a, b) => new Date(a[prop]).getTime() < new Date(b[prop]).getTime() ? 1 : new Date(a[prop]).getTime() === new Date(b[prop]).getTime() ? 0 : -1 );
  }

  sortSubByDate(prop: string) {
    return this.zedder.subscribers.sort((a, b) => new Date(a[prop]).getTime() < new Date(b[prop]).getTime() ? 1 : new Date(a[prop]).getTime() === new Date(b[prop]).getTime() ? 0 : -1 );
  }

  sortRelatedByDate(prop: string) {
    return this.zedder.related.sort((a, b) => new Date(a[prop]).getTime() < new Date(b[prop]).getTime() ? 1 : new Date(a[prop]).getTime() === new Date(b[prop]).getTime() ? 0 : -1 );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add from input
    if (value) {
      this.shows.push({id: 0, name: value, slug: ''});
    }
    // Clear the input value
    event.chipInput!.clear();
    this.announcerControl.setValue('');
    this.zedderForm.updateValueAndValidity();
  }

  remove(show: ProgramsShort): void {
    const index = this.shows.indexOf(show);

    if (index >= 0) {
      this.shows.splice(index, 1);

      this.announcer.announce(`Removed ${show}`);
    }
    this.zedderForm.updateValueAndValidity();
  }

  removeBackup(show: ProgramsShort): void {
    const index = this.backupShows.indexOf(show);

    if (index >= 0) {
      this.backupShows.splice(index, 1);

      this.announcer.announce(`Removed ${show}`);
    }
    this.zedderForm.updateValueAndValidity();
  }
  edit(show: ProgramsShort, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(show);
      return;
    }

    // Edit existing fruit
    const index = this.shows.indexOf(show);
    if (index >= 0) {
      this.shows[index].name = value;
    }
    this.zedderForm.updateValueAndValidity();
  }

  selected(event: any): void {
    let program = event.option.value;
    this.shows.push({ id: program.id, name: program.name, slug: program.slug});
    this.showInput.nativeElement.value = '';
    this.announcerControl.setValue('');
    this.zedderForm.updateValueAndValidity();
  }

  backupSelected(event: any): void {
    let program = event.option.value;
    this.backupShows.push({  id: program.id, name: program.name, slug: program.slug });
    this.backupShowInput.nativeElement.value = '';
    this.announcerControl.setValue('');
    this.zedderForm.updateValueAndValidity();
  }

  private _filter(value: string): ProgramsShort[] {
    const filterValue = value.toLowerCase();

    return this.programNames.filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
