import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, SecurityContext, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import zedletterTemplate from "src/app/ajs/controllers/zedletter-template";
import tnfkaTemplate from "src/app/ajs/controllers/tnfka-template";
import { DomSanitizer } from '@angular/platform-browser';
// import { grapesjs } from 'grapesjs';
declare var grapesjs: any;
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SaveDialogComponent } from '../save-dialog/save-dialog.component';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../pending-changes.guard';

@Component({
  selector: 'app-zedletter',
  templateUrl: './zedletter.component.html',
  styleUrls: ['./zedletter.component.scss']
})
export class ZedletterComponent implements ComponentCanDeactivate {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) {

    this.newTemplate = this.sanitizer.bypassSecurityTrustHtml( zedletterTemplate);
    this.template = this.sanitizer.bypassSecurityTrustHtml( zedletterTemplate);


  }

@ViewChild('gjs')
grapesjs!: ElementRef<HTMLInputElement>;


  ngAfterViewInit() {

    // this.grapesjs.nativeElement.innerHTML = this.template;
    this.gjs = grapesjs.init({
      container: '#gjs',
      fromElement: true,
      height: '800px',
      width: 'auto',
      storageManager: false,
      panels: { defaults: [] },
      assetManager: {
        upload: 'https://data.4zzz.org.au/zeddb/zedletter/assets',
        uploadName: 'files'
      },
      plugins: ['grapesjs-preset-newsletter', 'grapesjs-plugin-ckeditor'],
      pluginsOpts: {
        'grapesjs-preset-newsletter': {
          // options
        },
        'grapesjs-plugin-ckeditor': {
          position: 'center',
          options: {
            startupFocus: true,
            allowedContent: true, // Disable auto-formatting, class removing, etc.
            removePlugins: 'magicline',
            extraPlugins: ['justify', 'colorbutton', 'font', 'basicstyles'],
            toolbar: [
              ['Styles','Format','Font','FontSize', 'TextColor', 'BGColor'],
              '/',
              ['Bold','Italic','Underline','Strike','-','Undo','Redo','-','Cut','Copy','Paste','Outdent','Indent','-','Print'],
              ['NumberedList','BulletedList','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
              ['Image','Table','-','Link','Unlink']
           ] ,
          }
        }
      }
    });

    this.setupGrapes()
   

  }

  gjs: any;
  templates: any[] = [];

  editor: boolean = false;

  isOpen: boolean = false;

  zedletterEmails: any[] = [];

  zedletterControl = new UntypedFormGroup( {
    from: new UntypedFormControl('hello@4zzz.org.au', [Validators.required]),
    to: new UntypedFormControl(null, [Validators.required]),
    subject: new UntypedFormControl(null, [Validators.required]),
    body: new UntypedFormControl(null, [Validators.required] )
  })

  newZL: boolean = false;
  
  newTemplate;
  template;

  sending: boolean = false;

  ngOnInit() {
    this.getTemplates()
  }


  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm alert before navigating away

    return false;
  }
  
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
        $event.returnValue = "This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)";
    }
  }
  
  generateZedletterEmails() {
    this.http.post<any[]>(environment.apiSrc + '/zedletter-list', {}).subscribe( data => {
			this.zedletterEmails = data;
			this.zedletterControl.patchValue({to: data.map(i => i.email).join('\n') }) 
		})
  }

  activeSubsList() {
    this.http.get<any[]>(environment.apiSrc + '/active-sub-email-list').subscribe( data => {
      this.zedletterEmails = data;
      this.zedletterControl.patchValue({to: data.map(i => i.subemail).join('\n') });
    });
  }

  dummy() {
		this.zedletterEmails = [ 'hello@4zzz.org.au' ];
		this.zedletterControl.patchValue({to: 'hello@4zzz.org.au' });
	}

  openTemplate(template) {
    console.log(template)
    this.http.get<any>('https://zedletter.4zzz.org.au/templates/' + template.filename, { responseType: 'text' as 'json' } ).subscribe(  data => {
			this.gjs.setComponents(data);
			this.newTemplate = this.sanitizer.bypassSecurityTrustHtml(data);
		});
  }

  selectTemplate(template){
    switch (template) {
			case 'zedletter':
				this.zedletterControl.patchValue({ body: zedletterTemplate, subject: 'Zedletter' }) 
        this.gjs.setComponents(zedletterTemplate);
        this.newTemplate = this.sanitizer.bypassSecurityTrustHtml(zedletterTemplate);
				break;
			case 'tnfka':
				this.zedletterControl.patchValue({ body: tnfkaTemplate, subject: 'The Newsletter Formerly Known As 💤' })
        this.gjs.setComponents(tnfkaTemplate);
        this.newTemplate = this.sanitizer.bypassSecurityTrustHtml(tnfkaTemplate);
				break;
			default:
		}

    this.newZL = false;
  }

  closeEditor() {
    this.newTemplate = this.sanitizer.bypassSecurityTrustHtml( this.gjs.runCommand('gjs-get-inlined-html') );
    this.zedletterControl.patchValue({ body: this.newTemplate.changingThisBreaksApplicationSecurity });
    this.editor = false;
  }

  getTemplates() {
    this.http.get<any>(environment.apiSrc + '/zedletter/templates').subscribe( data => {
      this.templates = data.sort( (a, b) => b.mtime - a.mtime );
    });
  }


  saveEmail() {
    const saveDialogRef = this.dialog.open(SaveDialogComponent, {
      data:{ 
        filename: ''
      }
    });
    saveDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result !== 'abort') {
        console.log(result);
        this.http.post<any>(environment.apiSrc + '/zedletter/save', {
          'filename': result,
          'html': this.newTemplate.changingThisBreaksApplicationSecurity
        }).subscribe(data => {
          console.log(data)
          this.getTemplates();
        })
      }
      else {
        console.log('Cancelled Archiving');
      }
    });
  }

  confirmSend(emailList) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data:{ 
        title: 'Are you sure you want to send?',
        message: 'Email will be sent to ' + emailList.split('\n').length +  ' recipients.',
        confirm: 'Send!',
        abort: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result === 'confirm') {
        console.log('I will archive');
        this.send()
      }
      else {
        console.log('Cancelled Archiving');
      }
    });
  };


  send() {
    console.log(this.zedletterControl.value)
    this.sending = true;

    let tmp = document.createElement('DIV')
		tmp.innerHTML = this.zedletterControl.value.body;
		let plaintext = tmp.innerText
		plaintext = plaintext.replace(/^ +/gm, '');

		this.http.post<any>(environment.apiSrc + '/zedletter/mailer', {
			'emailTo': this.zedletterControl.value.to,
			'emailFrom': this.zedletterControl.value.from,
			'subject': this.zedletterControl.value.subject,
			'html': this.zedletterControl.value.body,
			'text': plaintext
		}).subscribe(data => {
      this.sending = false;
			console.log(data);
		})
  }

  setupGrapes() {
    let bm = this.gjs.BlockManager;
    let majorHeading =
    `
    <h2 style="font-family: Verdana, Geneva, sans-serif;">A major heading</h2>
    `;
    bm.add('major-heading', {
      label: 'Major Heading',
      category: 'Zedletter',
      content: majorHeading,
      attributes: {class:'fa fa-th-list'}
    });


    let borderCell = `
    <table style="width: 100%; min-height: 100px;" cellspacing="0">
      <tbody>
      <tr>
        <td style="text-align: left; border-bottom: 7px solid #000; border-top: 7px solid #000; background: #fff; font-family: Verdana, Geneva, sans-serif;" align="left">

        </td>
      </tr>
      </tbody>
    </table>
    `;
    bm.add('border-cell', {
      label: 'Cell border',
      category: 'Zedletter',
      content: borderCell,
      attributes: {class:'fa fa-th-list'}
    });


    let slimBanner = `
    <table style="width: 100%; height: 149px;" cellspacing="0">
      <tbody>
        <tr>
          <td>
            <p style="text-align: center;">
              <a title="generic" href="http://www.4zzzfm.org.au/">
                <img style="font-style: normal;" src="http://placehold.it/630x112/78c5d6/fff/" alt="BANNER" width="630" height="112" />
              </a>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    `
    bm.add('slim-banner', {
      label: 'Slim Banner',
      category: 'Zedletter',
      content: slimBanner,
      attributes: {class:'fa fa-th-list'}
    });


    let campaignBanner =
    `
    <table style="width: 100%; min-height: 149px;" cellspacing="0">
      <tbody>
        <tr style="background-color: #ffffff;">
          <td style="padding: 8px 0 8px 8px; text-align: center">
            <a href="https://link"><img src="http://placehold.it/630x230/78c5d6/fff/" alt="placeholder" width="630" height="230" /></a>
          </td>
        </tr>
        <tr style="background-color: #ffffff;">
          <td style="vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;">
            <h3>A heading</h3>
            <p>
              Some demo text, with a <a href="https://link">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right? <br /><br />And another section with some <strong>bold text</strong>.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    `;

    bm.add('campaign-banner', {
      label: 'Campaign Banner',
      category: 'Zedletter',
      content: campaignBanner,
      attributes: {class:'fa fa-th-list'}
    });

    let eventLeftLarge =
      `
      <table style="width: 100%; min-height: 149px;" cellspacing="0">
        <tbody>
          <tr style="background-color: #ffffff;">
            <td style="padding: 8px 0 8px 8px;">
              <a href="https://link"><img src="http://placehold.it/280x280/78c5d6/fff/" alt="placeholder" width="280" height="280" /></a>
            </td>
            <td style="vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;">
              <h3>A heading</h3>
              <p>
                Some demo text, with a <a href="https://link">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right? <br /><br />And another section with some <strong>bold text</strong>.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      `;
    bm.add('event-left-large', {
      label: 'Event - Left Large',
      category: 'Zedletter',
      content: eventLeftLarge,
      attributes: {class:'fa fa-th-list'}
    });

    let eventLeft =
      `
      <table style="width: 100%; min-height: 149px;" cellspacing="0">
        <tbody>
          <tr style="background-color: #ffffff;">
            <td style="padding: 8px 0 8px 8px;">
              <a href="https://link"><img src="http://placehold.it/140x140/78c5d6/fff/" alt="placeholder" width="140" height="140" /></a>
            </td>
            <td style="vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;">
              <h3>A heading</h3>
              <p>
                Some demo text, with a <a href="https://link">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right? <br /><br />And another section with some <strong>bold text</strong>.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      `;
    bm.add('event-left', {
      label: 'Event - Left',
      category: 'Zedletter',
      content: eventLeft,
      attributes: {class:'fa fa-th-list'}
    });

    let eventRight =
      `
      <table style="width: 100%; min-height: 160px;" cellspacing="0">
        <tbody>
          <tr style="background-color: #ffffff;">
            <td style="vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px;">
              <h3>A heading</h3>
              <p>
                Some demo text, with a <a href="https://link">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right? <br /><br />And another section with some <strong>bold text</strong>.
              </p>
            </td>
            <td style="padding: 8px 8px 8px 0;">
              <a href="https://link"><img src="http://placehold.it/140x140/78c5d6/fff/" alt="placeholder" width="140" height="140" /></a>
            </td>
          </tr>
        </tbody>
      </table>
      `;
    bm.add('event-right', {
      label: 'Event - Right',
      category: 'Zedletter',
      content: eventRight,
      attributes: {class:'fa fa-th-list fa-flip-horizontal'}
    });

    let imageLink =
      `<a href="https://link"><img src="http://placehold.it/140x140/78c5d6/fff/" alt="placeholder" width="140" height="140" /></a>`;
    bm.add('image-link', {
      label: 'Image Link',
      category: 'Zedletter',
      content: imageLink,
      attributes: {class:'fa fa-picture-o'}
    });

  }
}
