<div data-simplebar data-simplebar-auto-hide="false" style="overflow: auto;">
		
		<div style="display: flex; padding: 16px;">
			<div class="release item" *ngFor="let item of releases">
				<div (click)="$event.stopPropagation(); albumListing(item, $event)" [style.background-image]="'url(' + musicDBapiSrc + '/coverArt/150/' + item.library_no  + ')'" class="cover" [class.aotw]="item.aotw" [class.has-embargo]="item.embargo">
					<div class="embargo" *ngIf="item.embargo">
						<div>
							<div style="display: inline; font-size: 14px; line-height: 19px; vertical-align: top;">EMBARGO</div>
							<div style="display:inline-block">
								<div style="font-size: 7px; line-height: 8px;">UNTIL</div>
								<div style="font-size: 7px; line-height: 8px;">{{item.embargo_until | date:"dd/MM"}}</div>
							</div>
						</div>
					</div>
					<div class="bg">
						<mat-icon class="favourite" (click)="favourite(item, $event); item.fav = !item.fav" >{{ item.fav ? 'star' : 'star_border' }}</mat-icon>
						<mat-icon class="play" (click)="play(item.artist_nm, item.title, item.library_no, $event); albumListing(item, $event)">play_circle_filled</mat-icon>
            <mat-icon  mat-icon-button (click)="$event.stopPropagation(); $event.preventDefault()" [matMenuTriggerFor]="coverMenu" class="more" aria-label="more">more_horiz</mat-icon>
						<mat-menu #coverMenu="matMenu">
              <button mat-menu-item (click)="queue(item.artist_nm, item.title, item.library_no, $event)"> 
                  Add to queue
              </button>
              <button mat-menu-item disabled="disabled" (click)="addToPlayList()">
                  Add to playlist
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="release(item.library_no)">
                  Goto release
              </button>
						</mat-menu>
					</div>
				</div>
				<b>{{item.artist_nm}}</b><br />
				 {{item.title}}
				 <ng-container *ngIf="showYear"><br /><span style="font-weight: 100;">{{item.release_year}}</span></ng-container>
			</div>
		</div>

</div>

<div *ngIf="selectedItem">
	<!-- <album-info clear="$ctrl.clear" info="selectedItem" favourite="$ctrl.favourite" release="$ctrl.release" queue="$ctrl.queue" more="more" play="$ctrl.play"></album-info> -->
	<app-album-info [info]="selectedItem" [favourite]="favourite" [release]="release" [queue]="queue" [more]="more" [play]="play"></app-album-info>
</div>
