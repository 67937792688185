import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { InterestsService } from '../interests.service';

export interface Interests {
  interest_no: number;
  interest_nm: string;
}
 
@Component({
  selector: 'sub-categories',
  templateUrl: './manage-subcategories.component.html',
  styleUrls: ['./manage-subcategories.component.scss']
})

export class ManageSubCategoriesComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    interest_no: new UntypedFormControl({value: 0, disabled: true}),
    interest_nm: new UntypedFormControl('')
  });
  lastSort: Sort = { active: "interest_nm", direction: "asc" };
  editInterests: Interests = {interest_nm: '', interest_no: 0};
  subCategories: Interests[] = [];
  sortedData: Interests[];
  displayedColumns: string[] = ['interest_no', 'interest_nm', 'edit'];

  constructor(public InterestsService: InterestsService) {
    this.sortedData = this.subCategories.slice();
    // this.InterestsService = InterestsService;
    InterestsService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'interest_no':
          return compare(a.interest_no, b.interest_no, isAsc);
        case 'interest_nm':
          return compare(a.interest_nm, b.interest_nm, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      interest_no: el.interest_no,
      interest_nm: el.interest_nm
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      interest_no: 0,
      interest_nm: ''
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().interest_no === 0) { //new
      let subcategory: any = {};
      subcategory.interest_nm = this.interestForm.value.interest_nm;
      this.InterestsService.save(subcategory).subscribe(res  => {
        this.InterestsService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      let subcategory = this.InterestsService.get(this.interestForm.getRawValue().interest_no).subscribe(
        subcategory => {
          subcategory.interest_nm =  this.interestForm.value.interest_nm;
          this.InterestsService.update(subcategory).subscribe(response => { 
            let interests : Interests[] = [ {
              interest_no: response.interest_no,
              interest_nm: response.interest_nm
            }]
      
            const existing = this.subCategories.reduce((a,t)=> (a[t.interest_no] = t, a), {}),
            changed = interests.reduce((a,t)=> (a[t.interest_no] = t, a), {})
      
            this.subCategories = Object.values({...existing, ...changed})
            // this.sortedData = this.subCategories.slice(); 
            this.sortData(this.lastSort);
          });
        }
       );
     
    }
   
  }
  
}
