import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  listeners: any[] = []

  get(key: string) {
    // console.log(localStorage);
    let item = localStorage.getItem(key);
    return item ? item : ''; 
  }
  
  set(key, val) {
    let set = localStorage.setItem(key, val);
    this.listeners.map( cb => cb())
    return set;
  }

  unset(key) {
    let unset = localStorage.removeItem(key);
    this.listeners.map( cb => cb())
    return unset;
  }

  broadcast() {
    this.listeners.map( cb => cb())
  }

  onchanges(callback) {
    if(this.listeners.indexOf(callback) === -1)
      this.listeners.push(callback)
  }
}