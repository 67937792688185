<div style="display: flex;
align-items: center;
justify-content: center; height: calc(100vh - 64px); background-color: #2b2b2b;" class="mat-typography">
  <form [formGroup]="credentials" (ngSubmit)="login()">
  <mat-card [class.error]="error">
    <img style="padding: 48px; background-color: black" src="/assets/4ZZZ-logo-purple-white.svg" alt="4ZZZ Logo">
    <mat-card-header>
      <mat-card-title>
        Login
      </mat-card-title>
    </mat-card-header>
      <mat-card-content >
        <mat-form-field class="full-width">
          <mat-icon matPrefix>face</mat-icon>
          <input matInput type="text" formControlName="username"  autocomplete="username">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-icon matPrefix>lock</mat-icon>
          <input matInput type="password" formControlName="password"  autocomplete="password">
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions align="end">
        <button type="submit" color="accent" mat-raised-button>Submit</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>