<div [formGroup]="programForm">
	<h2>{{program.name}}</h2>

	<label style="margin-left: 16px; font-size: 12px; color: #ffffffa0">Banner Image</label>
	<label for="bannerDropRef" style="margin-top: 20px;" class="square" appFileDnd [accept]="'image/*'" (fileDropped)="onBannerDropped($event)">
		<div style="width: 100%; height: 300px; background-size: cover;" [style.backgroundImage]="'url( ' + bannerImage  + ')'" class="square-content">
			<span *ngIf="!bannerImage">Upload Cover</span>
			<div style="position: relative; left: 32px; top: 32px; width: 156px;">
				<label style="margin-left: 16px; font-size: 12px; color: #ffffffa0" *ngIf="!coverImage">Cover Image</label>
				<label for="coverDropRef" style="margin-top: 20px; width: 160px; height: 160px; background-position: 50% 50%;" class="square" appFileDnd [accept]="'image/*'" (fileDropped)="onCoverDropped($event)">
					<div style="width: 156px; height: 156px; background-size: cover" [style.backgroundImage]="'url( ' + coverImage  + ')'" class="square-content">
						<span *ngIf="!coverImage">Upload Cover</span>
					</div>
				</label>
			</div>
			
		</div>
	</label>
	<input style="display: none;" type="file" #coverDropRef [accept]="'image/*'" id="bannerDropRef" (change)="bannerBrowseHandler($event.target)" />
	<input style="display: none;" type="file" #coverDropRef [accept]="'image/*'" id="coverDropRef" (change)="coverBrowseHandler($event.target)" />


	<mat-form-field class="full-width">
		<mat-label>Program Name</mat-label>
		<input type="text" formControlName="name" placeholder="Name" aria-label="name" matInput />
	</mat-form-field>

	<mat-form-field class="full-width">
		<mat-label>Program Hosts</mat-label>
		<input type="text" formControlName="broadcasters" placeholder="Hosts" aria-label="hosts" matInput />
	</mat-form-field>

	<div>
		<label style="margin-left: 16px; font-size: 12px; color: #ffffffa0">Program Description</label>
		<editor [init]="tinymceOptions" type="text" formControlName="description" placeholder="Description" aria-label="description"></editor>
	</div>

	<mat-form-field class="full-width">
		<mat-label>Twitter Handle</mat-label>
		<input type="text" formControlName="twitterHandle" placeholder="Handle" aria-label="twitter handle" matInput />
	</mat-form-field>

	<mat-form-field class="full-width">
		<mat-label>Facebook Page ID</mat-label>
		<input type="text" formControlName="facebookPage" placeholder="URL" aria-label="facebook page" matInput />
	</mat-form-field>
</div>

<div *ngIf="episode" [formGroup]="episodeForm" >

	<div style="display: flex; gap: 8px;">

		<div style="width: 340px; min-width: 220px;">
			<mat-calendar [(selected)]="selectedDate" (selectedChange)="onDateSelected($event)" [dateClass]="dateClass">

			</mat-calendar>
		</div>

		<div style="flex: 1">
			<div style="display: flex; align-items: center; margin: 16px 0;">
				<h3 style="flex: 1; margin-top: 14px;">Episode - {{episode.start | date}}</h3>
				<button (click)="episode.id ? editEpisode() : addEpisode()" *ngIf="episodeUpdated" color="primary" mat-fab [matTooltip]="episode.id ? 'Update Episode' : 'Add Episode'">
					<mat-icon>
						{{ episode.id ? 'published_with_changes' : 'add'}}
					</mat-icon>
				</button>
			</div>

			<div style="display: flex; gap: 8px;">
				<mat-form-field class="full-width">
					<mat-label>Epsisode Date</mat-label>
					<input matInput [matDatepicker]="dp" formControlName="start" required>
					<mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp></mat-datepicker>
				</mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Start Time</mat-label>
					<input type="time" step="1" formControlName="startTime" matInput required />
				</mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>End Time</mat-label>
					<input type="time" step="1" formControlName="endTime" matInput required />
				</mat-form-field>
			</div>

			<mat-form-field class="full-width">
				<mat-label>Episode Title</mat-label>
				<input type="text" formControlName="title" placeholder="Name" aria-label="name" matInput />
			</mat-form-field>

			<label style="margin-left: 16px; font-size: 12px; color: #ffffffa0">Episode Image</label>
			<label for="episodeDropRef" style="margin-top: 20px;" class="square" appFileDnd [accept]="'image/*'" (fileDropped)="onEpisodeImageDropped($event)">
				<div style="width: 100%; height: 300px; background-size: cover; display: flex;
				align-items: center;
				justify-content: center;
				border: 2px dashed #aaa; margin-bottom: 16px;" [style.backgroundImage]="'url( ' + episodeImage  + ')'" class="square-content">
					<span *ngIf="!episodeImage">Uploade Episode Image</span>
				</div>
			</label>
			<input style="display: none;" type="file" #episodeDropRef [accept]="'image/*'" id="episodeDropRef" (change)="episodeImageHandler($event.target)" />

			<div>
				<label style="margin-left: 16px; font-size: 12px; color: #ffffffa0">Episode Description</label>
				<editor [init]="tinymceOptions" type="text" formControlName="description" placeholder="Description" aria-label="description"></editor>
			</div>

		</div>


	</div>

	<div formArrayName="playlist" *ngIf="refForm.controls.length">
		<div style="display: flex; align-items: center; margin: 16px 0;">
			<h3 style="flex: 1; margin: 0">Playlist - {{episode.start | date}}</h3> 
			<div style="background-color: #373737; border-radius: 30px; height: auto; display: flex; align-items: center; gap: 8px;">
				<button mat-mini-fab color="accent"  (click)="reconnectStream()" matTooltip="Connect live stream">
					<mat-icon>
						celebration
					</mat-icon>
				</button>
				<button mat-mini-fab color="accent" matTooltip="mAirlist Fetch Episode"  (click)="fetchLogger()"> 
					<mat-icon style="color: #fa8817" svgIcon="mairlist">
					</mat-icon>
				</button>
				<mat-slide-toggle matTooltip="Live from stream" aria-label="Live from stream" [checked]="stream" (change)="toggleStream($event)">
					Live
				</mat-slide-toggle>
				<!-- <md-switch style="margin-right: 16px" ng-model="live" aria-label="Live mAirlist" ng-change="toggleLive()">
					Live
				</md-switch> -->
				<a class="studio" (click)="changeStudios()">{{studio}}</a>	
			</div>
		</div>
		<div class="playlist" cdkDropList (cdkDropListDropped)="drop($event)">
			<div class="playlist-item" [class]="refForm.controls[playlistGroup].disabled ? 'disabled' : 'edit'" cdkDrag *ngFor="let item of refForm.controls; index as playlistGroup" [formGroupName]="playlistGroup" style="display: flex; gap: 8px; align-items: center;background-color: #424242;
			padding: 3px 0;">

				<button cdkDragHandle mat-icon-button class="small-button">
					<mat-icon>drag_indicator</mat-icon>
				</button>

				<mat-form-field subscriptSizing="dynamic" style="width: 150px">
					<!-- <mat-label>Start Time</mat-label> -->
					<input type="time" formControlName="startTime" aria-label="start time" matInput />
				</mat-form-field>

				<div style="display: flex; align-items: center;">
					<button [disabled]="refForm.controls[playlistGroup].disabled" (click)="toggleQuotas(refForm.controls[playlistGroup], 'isAustralian')" mat-icon-button class="small-button">
						<mat-icon [svgIcon]="refForm.controls[playlistGroup].value['contentDescriptors']['isAustralian'] ? 'australian' : 'australian_outlined'"></mat-icon>
					</button>
					<button [disabled]="refForm.controls[playlistGroup].disabled" (click)="toggleQuotas(refForm.controls[playlistGroup], 'isLocal')" mat-icon-button class="small-button">
						<mat-icon [svgIcon]="refForm.controls[playlistGroup].value['contentDescriptors']['isLocal'] ? 'local' : 'local_outlined'"></mat-icon>
					</button>
					<button [disabled]="refForm.controls[playlistGroup].disabled" (click)="toggleQuotas(refForm.controls[playlistGroup], 'isFemale')" mat-icon-button class="small-button">
						<mat-icon [svgIcon]="refForm.controls[playlistGroup].value['contentDescriptors']['isFemale'] ? 'female' : 'female_outlined'"></mat-icon>
					</button>
					<button [disabled]="refForm.controls[playlistGroup].disabled" (click)="toggleQuotas(refForm.controls[playlistGroup], 'isGenderNonConforming')" mat-icon-button class="small-button">
						<mat-icon [svgIcon]="refForm.controls[playlistGroup].value['contentDescriptors']['isGenderNonConforming'] ? 'gender_non_conforming' : 'gender_non_conforming_outlined'"></mat-icon>
					</button>
					<button [disabled]="refForm.controls[playlistGroup].disabled" (click)="toggleQuotas(refForm.controls[playlistGroup], 'isNew')" mat-icon-button class="small-button">
						<mat-icon [svgIcon]="refForm.controls[playlistGroup].value['contentDescriptors']['isNew'] ? 'new' : 'new_outlined'"></mat-icon>
					</button>
					<button [disabled]="refForm.controls[playlistGroup].disabled" (click)="toggleQuotas(refForm.controls[playlistGroup], 'isIndigenous')" mat-icon-button class="small-button">
						<mat-icon [svgIcon]="refForm.controls[playlistGroup].value['contentDescriptors']['isIndigenous'] ? 'indigenous' : 'indigenous_outlined'"></mat-icon>
					</button>
				</div>

				<mat-form-field subscriptSizing="dynamic" style="flex: 1">
					<!-- <mat-label>Artist</mat-label> -->
					<input type="text" required formControlName="artist" placeholder="Artist Name" aria-label="artist" matInput />
				</mat-form-field>

				<mat-form-field subscriptSizing="dynamic" style="flex: 1">
					<!-- <mat-label>Title</mat-label> -->
					<input type="text" required formControlName="title" placeholder="Track Title" aria-label="title" matInput />
				</mat-form-field>

				<button mat-icon-button class="edit small-button" [disabled]="!(refForm.controls[playlistGroup].status === 'VALID' || refForm.controls[playlistGroup].status === 'DISABLED' )" (click)="refForm.controls[playlistGroup].value['id'] ? editTrack($event, refForm.controls[playlistGroup]) : addTrack($event, refForm.controls[playlistGroup])">
					<mat-icon>{{refForm.controls[playlistGroup].value['id'] ? 'edit' : 'add' }}</mat-icon>
				</button>

				<button [disabled]="refForm.controls[playlistGroup].disabled" mat-icon-button class="delete small-button" (click)="deleteTrack(refForm.controls[playlistGroup].value, playlistGroup)">
					<mat-icon>delete</mat-icon>
				</button>

			</div>
		</div>
	</div>
</div>