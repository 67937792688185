<ng-container >
  <div cdkDrag (cdkDragStarted)="onCdkDragStarted($event)" (cdkDragMoved)="dragMoved($event)" (cdkDragEntered)="onCdkDragEntered($event)" (cdkDragDropped)="onCdkDragDropped($event)" [cdkDragData]="{ title_id: track.title_id, track: track }"  class="track-details">
    <ng-template #itemTpl>

      <div class="play-number">
        <button class="play-icon" mat-icon-button aria-label="Play track" (click)="play(track, $event)" >
          <mat-icon>play_arrow</mat-icon>
        </button>
        <span style="margin: 0 4px;" class="track-number">{{track.discnum ? track.discnum + '–' : '' }}{{track.tracknum}}</span>
      </div>

      <div class="artist">
        <span>{{ ( track.artist && track.artist.name ) ? track.artist.name : track.artist}}</span>
      </div>
      <div class="title">
        {{track.title}}
      </div>
      <div class="year">
        {{track.year}}
      </div>
      <div class="duration">
        {{track.duration}}
      </div>

      <div class="more" style="min-width: 32px; text-align: center;">
        <button class="play-icon" mat-icon-button aria-label="Show details" (click)="showTools(track)" >
          <mat-icon>{{!track.showDetails ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
        </button>
      </div>
    </ng-template>  

    <div *cdkDragPlaceholder class="track-details" style="display: flex; width: 100%">
      <div *ngIf="draggingOutsideSourceList; else itemTpl" class="customPlaceHolder">
        Add to playlist
      </div>
    </div>

    <ng-container *ngTemplateOutlet="itemTpl"></ng-container>

    <div *cdkDragPreview class="playlist-track">
      <span style="padding: 2px 16px; background: white;
      color: black;
      border-radius: 4px; margin: 4px;">{{ ( track.artist && track.artist.name ) ? track.artist.name : track.artist}} - {{track.title}}</span>
    </div>

	</div>

	<div class="track-tools" [hidden]="!track.showDetails">
		<div class="track-info">
			<label>#</label>{{track.title_id}}
		</div>
		<div class="track-info" style="flex-grow: 1;">
			<label>File</label><a target="_blank" [href]="'file:///' + track.fullpath">{{track.filename}}</a>
		</div>

		<button *ngIf="onAir" class="tool-icon"  style="color: #fa8817;" matTooltip="Add to mAirList playlist" mat-icon-button aria-label="Add to mAirList playlist" (click)="appendTrack(track)">
			<mat-icon svgIcon="mairlist"></mat-icon>
		</button>

		<button class="tool-icon" [style.color]="track.language_warning ? 'rgb(190, 123, 58)' : 'white'" matTooltip="Language Warning" mat-icon-button aria-label="Language Warning" (click)="languageWarning(track)" >
			<mat-icon [svgIcon]="track.language_warning ? 'emoticon-poop' : 'emoticon-poop-outline'"></mat-icon>
		</button>

		<button class="tool-icon" [style.color]="track.content_warning ? 'rgb(255, 231, 101)' : 'white'" matTooltip="Content Warning" mat-icon-button aria-label="Content Warning" (click)="contentWarning(track)" >
			<mat-icon>{{ track.content_warning ? 'report_problem' : 'change_history'  }}</mat-icon>
		</button>

		<button class="tool-icon"  matTooltip="Copy track location" mat-icon-button aria-label="Copy track location" (click)="copyToClipboard(track.fullpath)" >
			<mat-icon>assignment</mat-icon>
		</button>

		<button class="tool-icon"  matTooltip="Favourite" mat-icon-button aria-label="Favourite track" (click)="favourite(track, $event)" >
			<mat-icon>{{ track.favourite ? 'star' : 'star_outline'  }}</mat-icon>
		</button>

	</div>

</ng-container>