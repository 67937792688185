import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  grid: any;
}

@Component({
  selector: 'app-grid-save-dialog',
  templateUrl: './grid-save-dialog.component.html',
  styleUrls: ['./grid-save-dialog.component.scss']
})
export class GridSaveDialogComponent {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    console.log(data)
    this.gridForm.patchValue( {
      name: data.grid.name,
      start_date: data.grid.start_date,
      end_date: data.grid.end_date,
      draft: data.grid.draft,
      station: data.grid.station,
    }, { emitEvent: false })


    this.gridForm.controls['name'].valueChanges.subscribe(
      value => {
        console.log(value);
        this.renamed = true;
      }
    )
  }

  renamed = false; 

  stations = [ 
    {id: 'fm', station: '4ZZZ'},
    {id: 'zd', station: 'Zed Digital'}
  ];

  gridForm = new UntypedFormGroup({
    name: new UntypedFormControl(),
    start_date: new UntypedFormControl(),
    end_date: new UntypedFormControl(),
    draft: new UntypedFormControl(),
    station: new UntypedFormControl(),
  })


å
}
