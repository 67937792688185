<mat-toolbar>
  <span>Generate Donated Subscription</span>
  <span style="flex: 1"></span>
</mat-toolbar>


<div class="info-container mat-typography">
  <div style="flex: 1">
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Application</mat-card-title>
      </mat-card-header>
      <mat-card-content [formGroup]="donatedSubscriptionForm">

        <section class="example-section">
          <mat-radio-group formControlName="type">
            <mat-radio-button value="pay-it-forward">Pay It Forward</mat-radio-button>
            <mat-radio-button value="pay-the-rent">Pay the Rent</mat-radio-button>
          </mat-radio-group>
        </section>

        <div style="padding: 1em 0;" *ngIf="donatedSubscriptionForm.controls['id'].value">Using previous Zedder ID: <em>{{donatedSubscriptionForm.controls['id'].value}}</em></div>

        <mat-form-field class="full-width">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="firstname">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Last Name</mat-label>
          <input (blur)="checkZedder('name', $event)" matInput placeholder="Last Name" formControlName="lastname">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Name on Card</mat-label>
          <input matInput placeholder="Last Name" formControlName="nameoncard">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input (blur)="checkZedder('email', $event)" matInput placeholder="Email" formControlName="email">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Phone</mat-label>
          <input (blur)="checkZedder('phone', $event)" matInput placeholder="Phone" formControlName="phone">
        </mat-form-field>

        <div formGroupName="delivery_address">
          <mat-form-field class="full-width">
            <mat-label>Address</mat-label>
            <input matInput placeholder="Address" formControlName="address">
          </mat-form-field>

          <div style="display: flex; gap: 8px;">
            <mat-form-field style="flex-grow: 1;">
              <mat-label>Suburb</mat-label>
              <input matInput placeholder="Suburb" formControlName="locality">
            </mat-form-field>

            <mat-form-field style="flex-grow: 1;">
              <mat-label>State</mat-label>
              <input matInput placeholder="State" formControlName="region">
            </mat-form-field>


            <mat-form-field style="flex-grow: 1;">
              <mat-label>Country</mat-label>
              <mat-select matNativeControl formControlName="country">
                <mat-option *ngFor="let country of countries" [value]="country.alpha">
                  {{country.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 160px;">
              <mat-label>Post Code</mat-label>
              <input matInput placeholder="Postal Code" formControlName="postcode">
            </mat-form-field>
          </div>
        </div>


      </mat-card-content>
    </mat-card>
  </div>

  <div style="flex: 1;">
    <mat-card class="info-card" *ngIf="searchResults.length > 0">
      <mat-card-header style="background-color: hsl(224, 47%, 32%);">
        <mat-card-title>Found Previous Zedders</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <a *ngFor="let zedder of searchResults" (click)="populate(zedder)" mat-list-item>
            <span matListItemTitle>{{zedder.id}} {{zedder.firstname}} {{zedder.lastname}} <em>{{zedder.nameoncard }}</em></span>
            <span matListItemLine>{{zedder.email}}</span>
            <span matListItemLine><em>{{zedder.phone}}</em></span>
          </a>
        </mat-list>
      </mat-card-content>
    </mat-card>

    <mat-card class="info-card" *ngIf="subscription.subnumber">
      <mat-card-header style="background-color: hsl(224, 47%, 32%);">
        <mat-card-title>Generated Sub</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <a [href]="'/subscribers/' + subscription.subid">
            <span matListItemTitle>{{subscription.subnumber}} {{subscription.subfirstname}} {{subscription.sublastname}} <em>{{subscription.nameoncard }}</em></span>
            <span matListItemLine>{{subscription.subemail}}</span>
            <span matListItemLine><em>{{subscription.submobile}}</em></span>
          </a>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="floating-fab" [hidden]="!(formEdited && formValid)">
  <button (click)="addDonatedSubscription()" mat-fab color="primary" aria-label="Example icon button with a delete icon">
    <mat-icon>add</mat-icon>
  </button>
</div>