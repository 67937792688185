import { Component, inject, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UIRouter } from "@uirouter/core";
import { Location } from "@angular/common";
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Transition } from "@uirouter/angular";
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Observable } from 'rxjs';
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatChipEditedEvent } from '@angular/material/chips';
import { MatTable } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ReleasesBaseComponent } from '../releases-archive/releases.base.component';

import { merge, cloneDeep } from "lodash";

import { formatBytes, fileSubstitution, secondsToHMS, copyToClipboard, tagFromFile } from '../helpers';

import * as moment from 'moment';
import { Moment } from 'moment';
import { FelaBrowserComponent } from '../fela-browser/fela-browser.component';
import { error } from 'console';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { SessionService } from '../session.service';
import { PlaylistService } from '../playlist.service';
import { GenresService } from '../genres.service';
import { GenresNewService } from '../genres-new.service';
import { ThemesService } from '../themes.service';
import { ReleaseService } from '../release.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

export interface Track {
  tracknum: number;
  title: string;
  duration: number;
  artist: string;
  year: number;
}

export interface LocationExists {
  dir: boolean;
  link: boolean; 
  exists?: boolean;
}
export interface LibraryLocations {
  hotbin: LocationExists;
  library: LocationExists;
  bna: LocationExists;
  aotw: LocationExists;
  first_nations: LocationExists;
}

@Component({
  selector: 'app-releases-details',
  templateUrl: './releases-details.component.html',
  styleUrls: ['./releases-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReleasesDetailsComponent extends ReleasesBaseComponent {
  @ViewChild('aliasInput')
  aliasInput!: ElementRef<HTMLInputElement>;
  
  @ViewChild('relatedInput')
  relatedInput!: ElementRef<HTMLInputElement>;

  @ViewChild('genreInput')
  genreInput!: ElementRef<HTMLInputElement>;

  @ViewChild('tagInput')
  tagInput!: ElementRef<HTMLInputElement>;

  @ViewChild( 'uploadTable' ) uploadTable!: MatTable<any>;


  // @Input() override release: any = { artist: { id: 0 } };
  constructor(
    // router: UIRouter,
    router: Router,
    location: Location,
    http: HttpClient,
		// @Inject('$rootScope') $rootScope: any,
		PlaylistService: PlaylistService,
		SessionService: SessionService,
    private AuthenticationService: AuthenticationService,
    private ReleaseService: ReleaseService,
    private GenresService: GenresService,
    private GenresNewService: GenresNewService,
    private ThemesService: ThemesService,
    // protected trans: Transition,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    super(router, location, http, PlaylistService, SessionService);
   
    this.releaseForm.valueChanges.subscribe( () => {
      console.log('CHANGED!', this.releaseForm)
      this.changeDiversity();
      this.release = merge(this.release, this.releaseForm.value);
      // this.release = Object.assign(this.release, this.releaseForm.value);
      // this.release = structuredClone(this.release, this.releaseForm.value);
      this.formEdited = true;

      // this.region = this.releaseForm.controls['region'].value;
      this.release.artist_nm = this.releaseForm.get('artist.name')?.value;
      this.release.artist_hometown = this.releaseForm.get('artist.hometown')?.value;
      this.release.album_origin = this.releaseForm.get('artist.country')?.value;
      this.felaFlags = this.selectedDiversity.value;
      // this.genRegion();
      // this.genFelaFlags();

      if(this.release.title && this.release.artist_nm && this.region.value)
        this.suggestedLocations = this.getLocations();

      if(this.release.fela && this.suggestedLocations.library !== this.release.fela) {
        this.misfiled = true;
      } else {
        this.misfiled = false;
      }
      
    });
  }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');

    this.route.data.subscribe(({ release }) => {

      this.release = release;
      console.log('changes?')

      this.id = this.route.snapshot.paramMap.get('id');

      this.folder = '/';

      this.felaFlags = [];
      this.suggestedLocations = {};
       
      if(!this.release.artist) {
        this.release.artist = { aliases: [], related: [] };
      }

      if(!this.release.genres) {
        this.release.genres = []
      }
      if(!this.release.tags) {
        this.release.tags = []
      }
      this.releaseForm.patchValue({
        artist: {
          name: this.release.artist.name, 
          hometown: this.release.artist.hometown ? this.release.artist.hometown : this.release.artist_hometown, 
          country: this.release.artist.country ? this.release.artist.country : this.release.album_origin, 
          email: this.release.artist.email, 
          website: this.release.artist.website, 
          pronouns: this.release.artist.pronouns, 
          bio: this.release.artist.bio,
          aliases: this.release.artist.aliases,
          related: this.release.artist.related
        },
        title: this.release.title,
        release_date: this.release.release_date,
        release_year: this.release.release_year,
        album_label: this.release.album_label,
        genres: this.release.genres,
        cont_genre: this.release.cont_genre,
        support_choice: this.release.support_choice,
        tags: this.release.tags,
        interview_dt: this.release.interview_dt,
        release_reviewed: this.release.release_reviewed,
        review_dt: this.release.review_dt,
        review: this.release.review,
        bna: this.release.bna,
        hotbin: this.release.hotbin,
        aotw: this.release.aotw,
        aotw_week: this.release.aotw_week,
        embargo: this.release.embargo,
        embargo_until: this.release.embargo_until,
        release_type: this.release.release_type,
        format_submitted: this.release.format_submitted,
        modify_user: this.release.modify_user,
        comments: this.release.comments,
        bio: this.release.bio,
        fela: this.release.fela,
        release_location: this.release.release_location,
        format_stored: this.release.format_stored,
        media_cond: this.release.media_cond
      }, { emitEvent: false })

      // this.artistAliases = this.release.artist.aliases.map(i => ({id: i.id, name: i.name}) )
      // this.relatedArtists = this.release.artist.related.map(i => ({id: i.id, name: i.name}) )
      // this.releaseGenres = this.release.genres.map(i => ({genre: i.genre}) )
      // this.releaseTags = this.release.tags.map( i => ({theme_desc: i.theme_desc}))
      
      if( this.release.library_no ) {
        this.getTracks(this.release.artist_nm, this.release.title, this.release.library_no);
      
        this.albumArt(this.release.artist_nm, this.release.title, this.release.release_year, this.release.library_no );
      }

      this.genFelaFlags();
      this.genRegion();
  

    })

    this.GenresService.query().subscribe( data => { this.genres = data });
    this.GenresNewService.query().subscribe( data => { this.genresNew = data });
    this.ThemesService.query().subscribe( data => { this.themes = data }); 

    console.log(this.AuthenticationService.requireAccess('user'))

    // this.genFelaFlags();
    // this.genRegion();

    this.hotbinDate = moment(this.release.hotbin, 'YYYY-MMMM');
    console.log(this.hotbinDate)

    this.aliasOptions = this.aliasControl.valueChanges.pipe( 
      filter((value) => value?.length > 3),
      switchMap( value => value ? this.searchArtists(value) : [])
    )
    this.aliasOptions.subscribe( {
      next: (result: any) => {
        console.log(result);
      }
    })

    this.relatedOptions = this.relatedControl.valueChanges.pipe( 
      filter((value) => value?.length > 3),
      switchMap( value => value ? this.searchArtists(value) : [])
    )
    this.relatedOptions.subscribe( {
      next: (result: any) => {
        console.log(result);
      }
    })

    this.selectedDiversity.valueChanges.subscribe(
      {
        next: (result: any) => {
          console.log(result);
          this.changeDiversity()
          if(this.release.title && this.release.artist_nm && this.region.value)
            this.suggestedLocations = this.getLocations();
          this.releaseForm.updateValueAndValidity();
        }
      }
    )

    this.region.valueChanges.subscribe(
      {
        next: (result: any) => {
          console.log(result);
          this.changeRegion();
          if(this.release.title && this.release.artist_nm && this.region.value)
            this.suggestedLocations = this.getLocations();
          this.releaseForm.updateValueAndValidity();
        }
      }
    )

    this.labelOptions = this.releaseForm.controls['album_label'].valueChanges.pipe( 
      filter((value) => value?.length > 1),
      switchMap( value => value ? this.searchLabels(value) : [])
    )
    
    this.labelOptions.subscribe( {
      next: (result: any) => {
        console.log(result);
      }
    })

    this.hometownOptions = this.releaseForm.get('artist.hometown')!.valueChanges.pipe( 
      filter((value) => value?.length > 1),
      switchMap( value => value ? this.searchHometown(value) : [])
    )

    this.countryOptions = this.releaseForm.get('artist.country')!.valueChanges.pipe( 
      filter((value) => value?.length > 1),
      switchMap( value => value ? this.searchCountry(value) : [])
    )

    this.artistOptions = this.releaseForm.get('artist.name')!.valueChanges.pipe( 
      filter((value) => value?.length > 1),
      switchMap( value => value ? this.searchArtists(value) : [])
    )
    this.artistOptions.subscribe( {
      next: (result: any) => {
        console.log(result);
      }
    })


    this.genreOptions = this.genreControl.valueChanges.pipe( 
      filter((value) => value?.length > 2),
      switchMap( value => value ? this.searchNewGenres(value) : [])
    )

    // this.genreOptions = this.genreControl.valueChanges.pipe( 
    //   startWith(''),
    //   map(value => typeof value === 'string' ? value : value.name),
    //   map(name => name ? this.searchGenres(name) : this.genresNew.slice())
    // )

    this.tagOptions = this.tagControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchTags(name) : this.themes.slice())
    )

    this.folder = this.release.fela;

    if(this.release.title && this.release.artist_nm && this.region)
      this.suggestedLocations = this.getLocations();
    else
      this.suggestedLocations = {};

    if(this.release.fela && this.suggestedLocations.library !== this.release.fela) {
      this.misfiled = true;
    } else {
      this.misfiled = false;
    }

    
    this.formDisabled = (!this.AuthenticationService.requireAccess('music') && this.id !== 'search');
   


  }

  ngAfterViewInit() {
    if (this.formDisabled) {
      this.aliasControl.disable({emitEvent: false});
      this.relatedControl.disable({emitEvent: false});
      this.genreControl.disable({emitEvent: false});
      this.tagControl.disable({emitEvent: false});
      this.releaseForm.disable({emitEvent: false}); 
      this.region.disable({emitEvent: false});
      this.selectedDiversity.disable({emitEvent: false});
    }
  }
  // override release: any = { artist: { id: 0 } };

  override release: any = { artist: { id: 0 } };;

  musicDBapiSrc = environment.musicDBapiSrc;

  submitType = 'Add';
  // selectedDiversity: any[] = [];
	regions = ['Local', 'Australian', 'New Zealand', 'Overseas']
  diversity = [
    { category: 'cont_female', name: 'Female', tag: 'F' },
    { category: 'genderdiverse', name: 'Gender Diverse', tag: 'GD'},
    { category: 'nonbinary', name: 'Non-Binary', tag: 'NB' },
    { category: 'genderfluid', name: 'Gender-Fluid', tag: 'GF' },
    { category: 'first_nations', name: 'First Nations', tag: 'I' },
  ];

  formats = ['CD', 'DIGITAL', 'VINYL'];
	types = ['SINGLE', 'EP', 'LP', 'COMPILATION', 'SOUNDTRACK', 'MIXTAPE/BEAT-TAPE', 'DOUBLE LP', 'DOUBLE A-SIDE', 'SPLIT SINGLE', 'SPLIT EP', 'LABEL PROMO COMPILATION', '4ZZZ PRODUCED'];

  isFelaOpen = false;
  isTagUploadOpen = false;
  isTagEditOpen = false;
  isHotbinOpen = false;

  // params = this.trans.params();
  // id = this.params['id'];
  id: string | null = null;

  submitLocked = false;
  encodingLocked = false;
  formDisabled: boolean = false;
  formEdited: boolean = false;

  artistAliases: any[] = []
  relatedArtists: any[] = []
  releaseGenres: any[] = []
  releaseTags: any[] = []

  selectedDate; 

  user = this.AuthenticationService.getUsername();

  requireAccess = (acl) => this.AuthenticationService.requireAccess(acl);

  filePattern = '';
  announcer = inject(LiveAnnouncer);

	promos = ['Promo Campaign', 'Staff Pick', 'Other'];

  searchForm = new UntypedFormGroup({
    createdDateStart: new UntypedFormControl(null), 
    createdDateEnd: new UntypedFormControl(null), 
    releaseYearStart: new UntypedFormControl(null), 
    releaseYearEnd: new UntypedFormControl(null),
  });
  
  releaseForm = new UntypedFormGroup({
    artist:  new UntypedFormGroup({
      name: new UntypedFormControl(null), 
      hometown: new UntypedFormControl(null), 
      country: new UntypedFormControl(null), 
      aliases: new UntypedFormControl([]),
      related: new UntypedFormControl([]),
      email: new UntypedFormControl(null),
      website: new UntypedFormControl(null), 
      pronouns: new UntypedFormControl(null), 
      bio: new UntypedFormControl(null),
    }),
    artist_nm: new UntypedFormControl(null), 
    title: new UntypedFormControl(null), 
    release_date: new UntypedFormControl(null),
    release_year: new UntypedFormControl(null),
    album_label: new UntypedFormControl(null),
    cont_genre: new UntypedFormControl(null),
    cont_subgenre: new UntypedFormControl(null),
    genres: new UntypedFormControl([]),
    tags: new UntypedFormControl([]),
    bna: new UntypedFormControl(false),
    aotw: new UntypedFormControl(null),
    aotw_week: new UntypedFormControl(null),
    embargo: new UntypedFormControl(null),
    embargo_until: new UntypedFormControl(null),
    release_type: new UntypedFormControl(null),
    release_notes: new UntypedFormControl(null),
    comments: new UntypedFormControl(null),
    support_choice: new UntypedFormControl(null),
    interview_dt: new UntypedFormControl(null),
    release_reviewed: new UntypedFormControl(null),
    review_dt: new UntypedFormControl(null),
    review: new UntypedFormControl(null),
    hotbin:  new UntypedFormControl(null),
    format_submitted: new UntypedFormControl(null),
    modify_user: new UntypedFormControl(null),
    release_numgiven: new UntypedFormControl(null),
    format_stored: new UntypedFormControl(null),
    media_cond: new UntypedFormControl(null),
    release_location: new UntypedFormControl(null),
    bio: new UntypedFormControl(null),
    fela: new UntypedFormControl(null)
  });

  aliasOptions!: Observable<any>;
  relatedOptions!: Observable<any>;
  artistOptions!: Observable<any>;
  labelOptions!: Observable<any>;
  hometownOptions!: Observable<any>;
  countryOptions!: Observable<any>;
  genreOptions!: Observable<any>;
  tagOptions!: Observable<any>;

  region = new UntypedFormControl(null);
  selectedDiversity = new UntypedFormControl(null);
  aliasControl = new UntypedFormControl('');
  relatedControl = new UntypedFormControl('');
  genreControl = new UntypedFormControl('');
  tagControl = new UntypedFormControl('');

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  genres: any[] = [];
  genresNew: any[] = [];
  themes: any[] = [];

  files: any[] = [];
	felaFlags: any[] = [];

  baseData: Track[] = [ { 'tracknum': 1, 'title': 'test', 'duration': 90, 'artist': 'test', 'year': 2020}]
  trackColumns: string[] = ['tracknum', 'title', 'duration',  'artist', 'year', 'play', 'expand'];
  expandedElement!: Track | null; 
  onAir = false;

  appendTrack = (arg1) => {};
  languageWarning = (track: any) => {
    track.language_warning = !track.language_warning;
		this.http.get(environment.musicDBapiSrc + '/language-warning/' + track.title_id + '/' + track.language_warning).subscribe(data => {
      console.log(data);
    });
    console.log(track);
  }

  contentWarning = (track: any) => {
		track.content_warning = !track.content_warning;
		this.http.get(environment.musicDBapiSrc + '/content-warning/' + track.title_id + '/' + track.content_warning).subscribe(data => {
      console.log(data);
    });;
    console.log(track);
  }


  copyToClipboard = copyToClipboard;

  folder = '/';
  suggestedLocations: any;
  misfiled = false;
  correctMisfiled = false;
  // region: string = '';
  hotbinDate!: Moment | null;
  releaseCheck: any[] = [];

  uploaded: any[] = [];
  zips: any[] = [];
  selectedFolder = '';
  trackHasLibrary: boolean | undefined = false;

  libLocations: { locations: LibraryLocations, notes: any[] } = { locations: {
    library : { dir: false, link: false},
    hotbin : { dir: false, link: false},
    aotw : { dir: false, link: false},
    bna : { dir: false, link: false},
    first_nations : { dir: false, link: false},
  },  notes: [] }

  hotbinFocus;

  overlayPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center'
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top'
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom'
    }
  ];


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 3000 });
  }

  toggleHotbinOverlay = () => {
    this.hotbinFocus = document.querySelector(':focus')
    console.log(this.hotbinFocus);
    this.isHotbinOpen = !this.isHotbinOpen;
  }

  checkLocations = () => {
		let locations = this.getLocations()
		// this.libLocations = {}
		this.http.post<any>(environment.musicDBapiSrc + '/check-locations', { locations: locations }).subscribe( data => {
			console.log(data)
			this.libLocations = data;
		})
	}

  formatBitrate = (bitrate) => Math.round(bitrate / 1000);

  genRegion = () => {
    if(this.release.cont_ausnz === null && this.release.cont_local === null){
      this.region.patchValue('Overseas');
    }
    else if(this.release.cont_ausnz === 'NZ' && this.release.cont_local === null){
      this.region.patchValue('New Zealand');
    }
    else if(this.release.cont_ausnz === 'A' && this.release.cont_local === null){
      this.region.patchValue('Australian');
    }
    else if(this.release.cont_ausnz === 'A' && this.release.cont_local === 'L'){
      this.region.patchValue('Local');
    }
    else {
      this.region.patchValue('');
    }
  }

  changeRegion = () => {
    if(this.region.value === 'Overseas'){
      this.release.cont_ausnz = null;
      this.release.cont_local = null;
    }
    if(this.region.value === 'New Zealand'){
      this.release.cont_ausnz = 'NZ';
      this.release.cont_local = null;
    }
    if(this.region.value === 'Australian'){
      this.release.cont_ausnz = 'A';
      this.release.cont_local = null;
    }
    if(this.region.value === 'Local'){
      this.release.cont_ausnz = 'A';
      this.release.cont_local = 'L';
    }
  }

  changeDiversity = () => {
		this.release.cont_female = '';
		this.release.nonbinary = false;
		this.release.genderdiverse = false;
		this.release.genderfluid = false;
		this.release.first_nations = false;
		this.selectedDiversity.value.forEach( i => {
			if (i === 'F'){
				this.release.cont_female = 'F'
			}
			if (i === 'NB'){
				this.release.nonbinary = true
			}
			if (i === 'GD'){
				this.release.genderdiverse = true
			}
			if (i === 'GF'){
				this.release.genderfluid = true
			}
			if (i === 'I'){
				this.release.first_nations = true
			}
		})
    this.felaFlags = this.selectedDiversity.value;
	}

  genFelaFlags = () => {
    if(this.release.cont_female === 'F') {
      this.felaFlags.push('F');
    }
    if(this.release.nonbinary === true) {
      this.felaFlags.push('NB');
    }
    if(this.release.genderdiverse === true) {
      this.felaFlags.push('GD');
    }
    if(this.release.genderfluid === true) {
      this.felaFlags.push('GF');
    }
    if(this.release.first_nations === true) {
      this.felaFlags.push('I');
    }
    this.selectedDiversity.patchValue([...this.felaFlags])
  }
  

  closeOverlay(event) { 
    console.log(event)
    event.stopPropagation();

    this.isFelaOpen = false;
  }

  closeHotbinOverlay(event) { 
    console.log(event)
    event.stopPropagation();

    this.isHotbinOpen = false;
  }

  closeTagEditOverlay(event) { 
    console.log(event)
    event.stopPropagation();

    this.isTagEditOpen = false;
  }

  closeTagUploadOverlay(event) { 
    console.log(event)
    event.stopPropagation();

    this.isTagUploadOpen = false;
  }

  dateChanged = ($ev) => {
    this.hotbinFocus && this.hotbinFocus.focus()
    this.hotbinDate = moment($ev);
    this.releaseForm.patchValue({ hotbin: moment($ev).format('YYYY-MMMM') });
    console.log($ev);
    this.isHotbinOpen = false;

  }

	searchReleases = () => { 
    // remove null / undefined / empty arrays
    const reducer = (obj) => Object.entries(obj).reduce((a,[k,v]) => (v === false || v == null || v === '' || ( v instanceof Array && v.length === 0 ) ? a : (a[k]= v instanceof Object && !(v instanceof Array) ? reducer(v) : v ,  a)), {})
    
    //convert moment to sql date format
    let searchFrom; Object.entries( this.searchForm.value ).forEach( ([k,v]) => searchFrom = { ...searchFrom, [k]: v instanceof Object && v['_isAMomentObject'] ? (v as any).format('YYYY-MM-DD') : v } );

    let releaseForm = { ...this.release, tags: this.release.tags.map(a => a.theme_id),  genres: this.release.genres.map(a => a.id) }
    let searchTerms = reducer( { ...releaseForm, ...searchFrom })

    //flatten nested object 
    const crushObj = (obj) => Object.keys(obj).reduce((acc, cur) => typeof obj[cur] === 'object' && !( obj[cur] instanceof Array) ? { ...acc, ...crushObj(obj[cur]) } : { ...acc, [cur]: obj[cur] } , {})

    searchTerms = crushObj(searchTerms);
 
    Object.entries( searchTerms ).forEach( ([k,v]) => { if (v instanceof Array) { searchTerms[k+'[]'] = v; delete searchTerms[k] } })


    this.http.get<any>(environment.apiSrc + '/releases', { params: searchTerms }).subscribe( data => { 
      this.searchResult = data;
      this.sortData(this.lastSort);
    })
  }
  

  deleteTrack = (dBTrack) => {
    return this.http.get<any>(environment.musicDBapiSrc + '/delete-track/' + dBTrack.title_id).subscribe( data => {
      console.log(data)
      this.getTracks(this.release.artist_nm, this.release.title, this.release.library_no);
    })
  }
  
	saveRelease = () => { 
    if (this.release.release_date && this.release.release_date.format ) 
      this.release.release_date = this.release.release_date.format('Y-MM-DD')
    if (this.release.embargo_until && this.release.embargo_until.format)
      this.release.embargo_until = this.release.embargo_until.format('Y-MM-DD')
    if (this.release.aotw_week && this.release.aotw_week.format)
      this.release.aotw_week = this.release.aotw_week.format('Y-MM-DD')

    console.log(this.release)
    console.log(this.releaseForm.value)
  
    if (this.release.library_no) {
      console.log('UPDATE');
      this.submitLocked = true;

      if (this.release.embargo || this.releaseForm.get('embargo')?.touched) {
				const params = new HttpParams().append('embargo', this.release.embargo).append('embargo_until', this.release.embargo_until).append('locations', JSON.stringify(this.getLocations()));
              this.http.get(environment.musicDBapiSrc + '/embargo', {params}).subscribe( data => {});
			}
			if (this.uploaded.length > 0) {
				let uploaded = this.uploaded;
				uploaded.forEach( i => { 
					if(i.info && i.info.albumart) {
						 delete i.info.albumart;
					}
				});
        this.http.post(environment.musicDBapiSrc + '/addTracks', {
          uploaded: this.uploaded,
          locations: this.getLocations(),
          library_no: this.release.library_no
        }).subscribe( data => {
          console.log(data);
          this.getTracks(this.release.artist_nm, this.release.title, this.release.library_no);
          this.albumArt(this.release.artist_nm, this.release.title, this.release.release_year, this.release.library_no);
          this.uploaded = [];
          if (this.release.embargo) {
            const params = new HttpParams().append('embargo', this.release.embargo).append('embargo_until', this.release.embargo_until).append('locations', JSON.stringify(this.getLocations()));
            this.http.get(environment.musicDBapiSrc + '/embargo', {params}).subscribe( data => {
              this.openSnackBar('Release Embargoed', '🤐');
            });
          }
          this.openSnackBar('Tracks Added', '🎉');
        })
      }
      
      this.ReleaseService.update(this.release).subscribe(() => {
        this.submitLocked = false;
        this.openSnackBar('Record Updated', '🤘');
        // this.message = 'Record Updated!';
        if (this.uploaded.length === 0) this.getTracks(this.release.artist_nm, this.release.title, this.release.library_no);
      },
      () => {
        this.submitLocked = false;
        this.openSnackBar('Couldnt update!', 'Oh no!');
        // this.message = "Couldn't update!";
      });
    }

    else {
      console.log('SAVE');
      this.submitLocked = true;

      this.ReleaseService.save( this.release).subscribe( (release) => {
        this.release.library_no = release.library_no;
        this.submitLocked = false;
        if (this.uploaded.length > 0) {
					this.http.post(environment.musicDBapiSrc + '/addTracks', {
						uploaded: this.uploaded,
						locations: this.getLocations(),
						library_no: this.release.library_no
					}).subscribe( data => {
            console.log(data);
            this.getTracks(this.release.artist_nm, this.release.title, this.release.library_no);
            this.albumArt(this.release.artist_nm, this.release.title, this.release.release_year, this.release.library_no);
            this.uploaded = [];
            this.openSnackBar('Tracks Added', '🎉');
            if (this.release.embargo) {
              const params = new HttpParams().append('embargo', this.release.embargo).append('embargo_until', this.release.embargo_until).append('locations', JSON.stringify(this.getLocations()));
              this.http.get(environment.musicDBapiSrc + '/embargo', {params}).subscribe( data => {
                this.router.navigateByUrl('/releases/' + this.release.library_no);
                this.openSnackBar('Release Embargoed', '🤐');
              });
            }
            else {
              this.router.navigateByUrl('/releases/' + this.release.library_no);
            }
          })

        }
        else {
          this.router.navigateByUrl('/releases/' + this.release.library_no);
        }

				// this.message = 'Record Added.'
        this.openSnackBar('Record Added', 'YAY');
				// alert('Record added');

      }, 
      () => {
				// this.message = "Couldn't Add"
				// alert('Couldnt add!');
        this.openSnackBar('Couldnt add!', 'Oh no!');

        this.submitLocked = false;
      });
    }
  }
	
  searchGenres = (query) => {
    var results = query ? this.genresNew.filter((genre) => { return genre.genre.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  searchTags = (query) => {
    var results = query ? this.themes.filter((tag) => { return tag.theme_desc.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  searchArtists = (artist) => {
		return this.http.get<any>(environment.apiSrc + '/artist-suggest/' + artist).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
	}

  searchNewGenres = (genre) => {
    return this.http.get<any>(environment.apiSrc + '/newgenresuggest/' + genre).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
  }
  
  searchHometown = (hometown) => {
    return this.http.get<any>(environment.apiSrc + '/hometownsuggest/' + hometown).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
  }

  searchCountry = (country) => {
    return this.http.get<any>(environment.apiSrc + '/countrysuggest/' + country).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
  }

  searchLabels = (label) => {
		return this.http.post<any>(environment.apiSrc + '/labelsuggest', { val: label } ).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data.slice(0, 30);
      })
    )
	}

  checkReleaseQuery = (artist, title) => {
		this.http.get<any>(environment.apiSrc + '/relcheck/' + artist + '/' + title).subscribe( data => {
			this.releaseCheck = data;
		});
	};

  checkRelease = (event) => {
    if(this.release.artist_nm && this.release.title)
      this.checkReleaseQuery(this.release.artist_nm, this.release.title)
  }

  encode = (encodeFile, zipfile) => {
		zipfile.encode = 'encoding'
    zipfile.encodingLocked = true;
    let strippath = encodeFile.replace('/tmp/uploads/', '')
    let tags = tagFromFile(this.filePattern, strippath)
    console.log(tags)

		this.http.post<any>(environment.musicDBapiSrc + '/encode', { encodeFile: encodeFile, tags: tags, coverart: this.release.albumArt }).subscribe( data => {
			zipfile.encode = 'encoded'
			console.log(data);
      zipfile.encodingLocked = false;

			if (data.size	) {

				this.uploaded.push(data);
        var index = this.uploaded.indexOf(zipfile);
        if (index !== -1) {
          this.uploaded.splice(index, 1);
        }
        this.uploadTable && this.uploadTable.renderRows();

			}
		})
	}

  fetchArtist(item) {
    this.http.get<any>(environment.apiSrc + '/artist-by-id/' + item.id).subscribe( data => { 
      console.log(data)
      if (data) {
        let artist = data;
        if(this.id !== 'search'){
          this.release.artist = artist;
          this.releaseForm.patchValue({ artist: { name: artist.name, hometown: artist.hometown,  country: artist.country, website: artist.website, email: artist.email, pronouns: artist.pronouns, bio: artist.bio }, artist_nm: artist.name, aliases: artist.aliases, related: artist.related }, { emitEvent: true })
        }
        if (!this.release.artist_nm)
          this.release.artist_nm = artist.name;
        // this.artistAliases = artist.aliases.map(i => ({id: i.id, name: i.name}) )
        // this.relatedArtists = artist.related.map(i => ({id: i.id, name: i.name}) )

			  // this.release.artist_nm = artist.name;

        // this.artistFound = response.data[0];
        // this.countryInput = response.data[0].country
        // this.hometownInput = response.data[0].hometown;

      }
    })
  }

  populateFromUpload = ($ev) => {
    let f = $ev[0];
    console.log($ev);
    console.log(f.info);
		if (f.info.compilation) {
			this.release.release_type = 'Compilation';
		} else {
			this.http.get<any>(environment.apiSrc + '/artist-by-name/' + f.info.artist).subscribe( data => { 
				if (data && data.length >= 1) {
          let artist = data[0];
          
          if (f.info.artist.toUpperCase() === artist.name.toUpperCase() ){
            this.release.artist = artist;
            this.releaseForm.patchValue({ artist: { name: artist.name, hometown: artist.hometown,  country: artist.country, website: artist.website, email: artist.email, pronouns: artist.pronouns, bio: artist.bio, aliases: artist.aliases, related: artist.related }, artist_nm: f.info.artist })
          }
          else {
            this.releaseForm.patchValue({ artist: { name: f.info.artist }, artist_nm: f.info.artist })
          }
          // this.artistAliases = artist.aliases.map(i => ({id: i.id, name: i.name}) )
          // this.relatedArtists = artist.related.map(i => ({id: i.id, name: i.name}) )

					// this.artistFound = response.data[0];
					// this.countryInput = response.data[0].country
					// this.hometownInput = response.data[0].hometown;

				}
        else {
          this.releaseForm.patchValue({ artist: { name: f.info.artist }, artist_nm: f.info.artist })

        }
			})
			this.artistIndex()
		}
		this.release.albumArt = f.info.albumart;
		this.release.title = f.info.album;
		this.release.release_year = f.info.year;
    this.releaseForm.patchValue({ artist_nm: f.info.artist, title: f.info.album, release_year: f.info.year })
		// var bla = _.filter(this.genresnew, { genre: f.info.genre } );
		/*$scope.genresnew.find(function findCherries(fruit) {
			return fruit.genre === 'Electronic';
			}
		)*/
		// console.log(bla)
		// if(bla && bla.length > 0 ) 
		// {
		// 	this.release.genres = [ { genre: bla[0].genre , id: bla[0].id } ];
		// 	this.gs.selected = [ bla[0].id ]
		// }
  }

  getTracks = (artist, album, library_no) => {
		return this.http.post<any>(environment.musicDBapiSrc + '/release-search-tracks', { artist: artist,  album: album, library_no: library_no }).subscribe( data => {

			this.release.dBTracks = data.sort((a : any, b : any) => Number(a.discnum) - Number(b.discnum) || Number(a.tracknum)- Number(b.tracknum));

      const uniqueLibraryNo = new Set(this.release.dBTracks.map(v => v.library_no ));
			const uniqueFilePath = new Set(this.release.dBTracks.map(v => v.filepath ));

			if (uniqueFilePath.size === 1 && uniqueLibraryNo.size === 1) {
				var path = this.release.dBTracks[0].fullpath.replace('/mnt/fela', '')
				// $scope.folder = path.substring(0, path.lastIndexOf("/"));
				if (this.release.fela) {
          if(this.release.fela === path.substring(0, path.lastIndexOf("/"))) {
					  this.folder = this.release.fela;
          }
          else {
					  this.folder = path.substring(0, path.lastIndexOf("/"));
            this.misfiled = true;
          }
				} else {
					this.folder = path.substring(0, path.lastIndexOf("/"));
				}
				this.trackHasLibrary = this.release.dBTracks[0].library_no;
			}
			else {
				this.folder = 'Multiple Location or Not Found'
				this.trackHasLibrary = undefined;
			}
    })
  }

  albumArt = (artist, album, year, library_no) => {
		console.log(library_no)
		// album = album.replace('(SINGLE)', '').trim()
		this.http.post<any>(environment.musicDBapiSrc + '/coverart', { artist: artist, album: album, year: year, library_no: library_no}, { responseType: 'text' as 'json' } ).subscribe( data => {
      this.release.albumArt = data; 
      console.log(this.release.albumArt) 
    });
	};

  removeAlias(artist: any, ): void {
    const index = this.releaseForm.get('artist.aliases')!.value.indexOf(artist);

    if (index >= 0) {
      this.releaseForm.get('artist.aliases')!.value.splice(index, 1);
      // this.artistAliases.splice(index, 1);

      this.announcer.announce(`Removed artist alias ${artist}`);
    }
    this.releaseForm.updateValueAndValidity();
  }

  editAlias(artist: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeAlias(artist);
      return;
    }

    // Edit existing fruit
    const index = this.releaseForm.get('artist.aliases')!.value.indexOf(artist);
    // const index = this.artistAliases.indexOf(artist);
    if (index >= 0) {
      this.releaseForm.get('artist.aliases')!.value[index].name = value;
    }
    this.releaseForm.updateValueAndValidity();
  }

  selectedAlias(event: any): void {
    let program = event.option.value;
    this.releaseForm.get('artist.aliases')!.value.push({ id: program.id, name: program.name, slug: program.slug});
    this.aliasInput.nativeElement.value = '';
    this.aliasControl.setValue('');
    this.releaseForm.updateValueAndValidity();
  }
  
  removeRelated(artist: any, ): void {
    // const index = this.relatedArtists.indexOf(artist);
    const index = this.releaseForm.get('artist.related')!.value.indexOf(artist);

    if (index >= 0) {
      this.releaseForm.get('artist.related')!.value.splice(index, 1);

      this.announcer.announce(`Removed related artist ${artist}`);
    }
    this.releaseForm.updateValueAndValidity();
  }

  editRelated(artist: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeRelated(artist);
      return;
    }

    // Edit existing fruit
    const index = this.releaseForm.get('artist.related')!.value.indexOf(artist);
    // const index = this.relatedArtists.indexOf(artist);
    if (index >= 0) {
      this.releaseForm.get('artist.related')!.value[index].name = value;
    }
    this.releaseForm.updateValueAndValidity();
  }

  selectedRelated(event: any): void {
    let program = event.option.value;
    this.releaseForm.get('artist.related')!.value.push({ id: program.id, name: program.name, slug: program.slug});
    this.relatedInput.nativeElement.value = '';
    this.relatedControl.setValue('');
    this.releaseForm.updateValueAndValidity();
  }


  removeGenre(genre: any, ): void {
    const index = this.releaseForm.get('genres')!.value.indexOf(genre);

    if (index >= 0) {
      this.releaseForm.get('genres')!.value.splice(index, 1);

      this.announcer.announce(`Removed Genre ${genre}`);
    }
    this.releaseForm.updateValueAndValidity();
  }

  editGenre(genre: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeGenre(genre);
      return;
    }

    // Edit existing fruit
    const index = this.releaseForm.get('genres')!.value.indexOf(genre);
    if (index >= 0) {
      this.releaseForm.get('genres')!.value[index].genre = value;
    }
    this.releaseForm.updateValueAndValidity();
  }

  selectedLabel(event: any): void {
    let label = event.option.value;
    this.releaseGenres.push({ label: label.label});
    this.genreInput.nativeElement.value = '';
    this.genreControl.setValue('');
    this.releaseForm.updateValueAndValidity();
  }

  selectedGenre(event: any): void {
    let genre = event.option.value;
    this.releaseForm.get('genres')!.value.push({id: genre.id, genre: genre.genre});
    this.genreInput.nativeElement.value = '';
    this.genreControl.setValue('');
    this.releaseForm.updateValueAndValidity();
  }

  removeTag(tag: any, ): void {
    const index = this.releaseForm.get('tags')!.value.indexOf(tag);

    if (index >= 0) {
      this.releaseForm.get('tags')!.value.splice(index, 1);

      this.announcer.announce(`Removed Tag ${tag}`);
    }
    this.releaseForm.updateValueAndValidity();
  }

  editTag(tag: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeTag(tag);
      return;
    }

    // Edit existing fruit
    const index = this.releaseForm.get('tags')!.value.indexOf(tag);
    if (index >= 0) {
      this.releaseForm.get('tags')!.value[index].theme_desc = value;
    }
    this.releaseForm.updateValueAndValidity();
  }

  selectedTag(event: any): void {
    let tag = event.option.value;
    this.releaseForm.get('tags')!.value.push({ theme_id: tag.theme_id, theme_desc: tag.theme_desc});
    this.tagInput.nativeElement.value = '';
    this.tagControl.setValue('');
    this.releaseForm.updateValueAndValidity();
  }

  dateFilter = (d: Moment | null): boolean => {
    const day = (d || moment()).day();
    // Prevent Saturday and Sunday from being selected.
    return day === 1;
  };

  moveRelease = (library_no, current, destination) => {
    this.http.post<any>(environment.musicDBapiSrc + '/moveRelease', { library_no: library_no, current: current, destination: destination } ).subscribe( data => { 
			console.log(data);
			this.release.dBTracks = data.titles;
			this.release.fela = data.record.fela;
			this.correctMisfiled = false;
			this.getLocations();

			var favtemp = this.SessionService.get('favourites');
			this.favourites = JSON.parse(favtemp);

			const uniqueLibraryNo = new Set(this.release.dBTracks.map(v => v.library_no ));
			const uniqueFilePath = new Set(this.release.dBTracks.map(v => v.filepath ));

			if (uniqueFilePath.size === 1 && uniqueLibraryNo.size === 1) {
				var path = this.release.dBTracks[0].fullpath.replace('/mnt/fela', '')
				// $scope.folder = path.substring(0, path.lastIndexOf("/"));
				if (this.release.fela) {
					this.folder = this.release.fela;
				} else {
					this.folder = path.substring(0, path.lastIndexOf("/"));
				}
				this.trackHasLibrary = this.release.dBTracks[0].library_no;
			}
			else {
				this.folder = 'Multiple Locations or Not Found'
				this.trackHasLibrary = undefined;
			}

			for (var i = 0; i < this.release.dBTracks.length; i++) {
				this.release.dBTracks[i].artist = this.release.dBTracks[i].artistName
				this.release.dBTracks[i].album = this.release.dBTracks[i].albumName



				this.favourites && this.favourites.playlist && this.favourites.playlist.map( item => { 
          if(this.release.dBTracks[i].title_id === item.title_id) { this.release.dBTracks[i].favourite = true }  } )
				this.release.dBTracks[i].duration = secondsToHMS(this.release.dBTracks[i].length);
				var text = this.release.dBTracks[i].fullpath;
				var link;
				if (navigator.platform.search('Win') !== -1) {
					link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
				}
				if (navigator.platform.search('Mac') !== -1) {
					link = text.replace('/mnt/fela', '/Volumes/audio')
				}
				if (navigator.platform.search('Linux') !== -1) {
					link = text;
				}
				this.release.dBTracks[i].ospath = link;
			}
		});
  };

  misfiledHandle = () => {
    this.correctMisfiled = true;
  };

  linkRelease = (library_no, dBTracks) => {
    const trackIds = dBTracks.map( i => i.title_id)
		this.http.post(environment.musicDBapiSrc + '/linkRelease', { library_no: library_no, trackIds: trackIds} ).subscribe( 
      data => { console.log(data); this.getTracks(this.release.artist_nm, this.release.title, library_no); 
    });
  };


  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(target ) {
    this.prepareFilesList(target.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  uploadCover() { 
    if (!this.files.length) {
      return;
    } else {
      const formData = new FormData();
      formData.append('file', this.files[0]);
      formData.append('library_no', this.release.library_no);

      this.http.post<any>(environment.musicDBapiSrc + '/coverupload', formData,  {responseType: 'text' as 'json'}).subscribe(
        data => { 
          this.release.albumArt = data;
          this.deleteFile(0);
         }
      )
    }
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadCover();
    // this.uploadFilesSimulator(0);
  }



  prepareMediaList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadMedia();
    // this.uploadFilesSimulator(0);
  }

  getEventMessage = (event, file, chunkNumber, percentTotal) => {
    // console.log(event, file);
    // console.log(chunkNumber)
    if (event && event.type === HttpEventType.UploadProgress) {
      if(event.loaded &&
        event.total) {
        const percentDone = Math.round(100 * event.loaded / event.total);
        percentTotal[chunkNumber] = percentDone;
        let percentChunks = Math.round( percentTotal.reduce((sum, a) => sum + a, 0) / percentTotal.length );
        file.progress = percentChunks;
      }
    } 
  }

  uploadMedia() { 
    if (!this.files.length) {
      return;
    } else {
      this.files.forEach( async (file, n) => {

        const chunkSize = 10485760;
        let chunkNumber = 0;
        const chunkTotal = Math.ceil(file.size / chunkSize);
        let percentTotal = Array(chunkTotal).fill(0) 
         for( let offset = 0; offset < file.size; offset += chunkSize ){
          const filename = file.name;
          const chunk = file.slice( offset, offset + chunkSize );
          const formData = new FormData();
          formData.append('_chunkSize', String(chunkSize));
          formData.append('_currentChunkSize', String(chunk.size));
          formData.append('_chunkNumber', String(chunkNumber));
          formData.append('_totalSize', String(file.size));
          formData.append('file', chunk, filename);
            const resp = await this.http.post<any>(environment.musicDBapiSrc + '/upload', formData, {
              observe: 'events',
              reportProgress: true,
            }).pipe(  tap(event => this.getEventMessage(event, file, chunkNumber, percentTotal)) ).toPromise() 

              if (resp && resp.type === HttpEventType.Response) {
              if(resp.body) {
                console.log('Upload complete');
                console.log(resp.body);
                if (resp.body.mime && resp.body.mime === 'application/zip') { 
                  this.zips.push(resp.body)
                  let index = this.files.indexOf(file);
                  this.deleteFile(index);
                }
                else {
                  this.uploaded.push(resp.body)
                  let index = this.files.indexOf(file);
                  this.deleteFile(index);
                  this.uploaded = this.uploaded.sort((a : any, b : any) => Number(a.info.discnum) - Number(b.info.discnum) || Number(a.info.tracknum)- Number(b.info.tracknum) );
                  this.uploadTable && this.uploadTable.renderRows();
                }
                
              }
            }
          chunkNumber++;
        }
        
      })
    }
  }

  onMediaDropped($event) {
    this.prepareMediaList($event);
  }

  mediaBrowseHandler(target ) {
    this.prepareMediaList(target.files);
  }

  includeMP3 = (encodeFile, zipfile) => {
		zipfile.encode = 'encoding'

		let strippath = encodeFile.replace('/tmp/uploads/', '')
		let tags = tagFromFile(this.filePattern, strippath)
		console.log(tags)

		this.http.post<any>(environment.musicDBapiSrc + '/includeMP3', { encodeFile: encodeFile, tags: tags, coverart: this.release.albumArt }).subscribe( data => {
			zipfile.encode = 'encoded'
      console.log(data);
      if (data.size	) {
        this.uploaded.push(data);
        this.uploaded = this.uploaded.sort((a : any, b : any) => Number(a.info.discnum) - Number(b.info.discnum) || Number(a.info.tracknum)- Number(b.info.tracknum) );
        this.uploadTable && this.uploadTable.renderRows();
      }
		 //console.log($scope.release.albumArt);
	 })
	}

  isImage = (extension) => ['jpg','jpeg','png','gif'].includes(extension);

  zipDownload = (encodeFile, zipfile) => {

		this.http.get(environment.musicDBapiSrc + '/zipDownload', { params: {encodeFile: encodeFile }, responseType: 'text' as 'json'} ).subscribe( data => {
			 console.log(data);
			 this.release.albumArt = data
		 })
	}

  getLocations = () => {
		let locations:{library?:string, hotbin?:string, bna?:string, aotw?:string, first_nations?:string} = {};	
		// let artist_index = $scope.artistIndex();

		let substitution = fileSubstitution('a|||::":\::>>*////*<<*\\\\\\**a?cc.$$......    ');
		console.log(substitution)
		if(this.release.release_type === 'SOUNDTRACK') {
			locations.library = `/${this.region.value} Artists/_Soundtracks/${this.release.title.replace(/\//g,'_')} - ${this.release.artist_nm.replace(/\//g,'_')}${ (this.felaFlags.length > 0) ? ' (' + this.felaFlags.join(' ') + ')': '' }`
		}
		else if (this.release.artist_nm === 'Various Artists'){
			locations.library = `/${this.region.value} Artists/_Various Artists/${fileSubstitution(this.release.title)}`
		}
		else{
			locations.library = `/${this.region.value} Artists/${this.artistIndex()}/${fileSubstitution(this.release.artist_nm)}${ (this.felaFlags.length > 0) ? ' (' + this.felaFlags.join(' ') + ')': '' }/${fileSubstitution(this.release.title)}`
		}
		if (this.release.hotbin) {
			locations.hotbin =  `/Hot Bin/${this.hotbinDate?.format('yyyy-MM-MMMM')}/${this.region.value} Artists/${fileSubstitution(this.release.artist_nm)}${ (this.felaFlags.length > 0) ? ' (' + this.felaFlags.join(' ') + ')': '' }/${fileSubstitution(this.release.title)}`
		}
		if (this.release.hotbin && this.release.bna) {
			locations.bna = `/Hot Bin/${this.hotbinDate?.format('yyyy-MM-MMMM')}/Best New Arrivals/${this.region.value} Artists/${fileSubstitution(this.release.artist_nm)}${ (this.felaFlags.length > 0) ? ' (' + this.felaFlags.join(' ') + ')': '' }/${fileSubstitution(this.release.title)}`
		}
		if (this.release.aotw && this.release.aotw_week) {
			locations.aotw = `/Hot Bin/AOTW/${moment(this.release.aotw_week).format('yyyy-MM-dd')} - ${fileSubstitution(this.release.artist_nm)}${ (this.felaFlags.length > 0) ? ' (' + this.felaFlags.join(' ') + ')': '' }/${fileSubstitution(this.release.title)}`
		}
		if (this.release.first_nations) {
			if (this.release.artist_nm === 'Various Artists') {
				locations.first_nations = `/Indigenous Artists/_Various Artists/${fileSubstitution(this.release.title)}`
			}
			else {
				locations.first_nations = `/Indigenous Artists/${this.region.value} Artists/${fileSubstitution(this.release.artist_nm)}${ (this.felaFlags.filter(i => i != 'I').length > 0) ? ' (' + this.felaFlags.filter(i => i != 'I').join(' ') + ')': '' }/${fileSubstitution(this.release.title)}`
	  	}
	  }
		console.log(locations)
    if (locations.library) 
      this.releaseForm.patchValue( {fela: locations.library}, { emitEvent: false } )
		return locations
	}

  artistIndex = () => {
		if (this.release.artist_nm && this.release.artist_nm[0] &&  this.release.artist_nm[0].search(/[a-z]/i) === -1) {
			return '0-9'
	 	}
		else if (this.release.artist_nm && this.release.artist_nm[0] &&  this.release.artist_nm.toUpperCase().search(/^THE /i) > -1) {
			return this.release.artist_nm[4].toUpperCase()
		}
		else {
			return this.release.artist_nm ? this.release.artist_nm[0].toUpperCase() : ''
		}
	}

  scanFolder = (folder) => {
		console.log(folder)
		this.http.post<any>(environment.musicDBapiSrc + '/scanFolder', { folder: folder }).subscribe( data => {
			console.log(data)
			data.items.forEach((item, i) => {
				if(item.info.part_of_a_set) {
					let parts = item.info.part_of_a_set.split('/')
					if (parts.length === 2) {
						item.info.discnum = parts[0]
						item.info.disctotal = parts[1]
					}
					if (parts.length === 1) {
						item.info.discnum = parts[0]
					}
				}
				item.info.duration = secondsToHMS(item.info.length)
				item.info.albumart = data.albumart
			})
      if (data.items && data.items.length > 0)
			  this.uploaded = data.items
			if (data.length > 0 ) {
				this.release.fela = folder;
				this.releaseForm.updateValueAndValidity()
				// $scope.$apply()
			}
			this.release.dBTracks = data.titles.sort((a : any, b : any) => Number(a.discnum) - Number(b.discnum) || Number(a.tracknum)- Number(b.tracknum));
			// this.release.dBTracks = data.titles.sort((a : any, b : any) => Number(a.info.discnum) - Number(b.info.discnum) || Number(a.info.tracknum)- Number(b.info.tracknum));
			var favtemp = this.SessionService.get('favourites');
			this.favourites = JSON.parse(favtemp);

			const uniqueLibraryNo = new Set(this.release.dBTracks.map(v => v.library_no ));
			const uniqueFilePath = new Set(this.release.dBTracks.map(v => v.filepath ));

			if (uniqueFilePath.size === 1 && uniqueLibraryNo.size === 1) {
				var path = this.release.dBTracks[0].fullpath.replace('/mnt/fela', '')
				// $scope.folder = path.substring(0, path.lastIndexOf("/"));

				this.folder = path.substring(0, path.lastIndexOf("/"));
				this.release.fela = this.folder;
				this.releaseForm.updateValueAndValidity()

				this.trackHasLibrary = this.release.dBTracks[0].library_no;
			}
			else {
				this.folder = 'Multiple Locations or Not Found'
				this.trackHasLibrary = undefined;
			}

			for (var i = 0; i < this.release.dBTracks.length; i++) {
				// this.release.dBTracks[i].artist = this.release.dBTracks[i].artistName
				// this.release.dBTracks[i].album = this.release.dBTracks[i].albumName



				this.favourites && this.favourites.playlist && this.favourites.playlist.map( item => { 
          if(this.release.dBTracks[i].title_id === item.title_id) { 
            this.release.dBTracks[i].favourite = true 
          }  
        })
				this.release.dBTracks[i].duration = secondsToHMS(this.release.dBTracks[i].length);
				var text = this.release.dBTracks[i].fullpath;
				var link;
				if (navigator.platform.search('Win') !== -1) {
					link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
				}
				if (navigator.platform.search('Mac') !== -1) {
					link = text.replace('/mnt/fela', '/Volumes/audio')
				}
				if (navigator.platform.search('Linux') !== -1) {
					link = text;
				}
				this.release.dBTracks[i].ospath = link;
			}

		 //console.log($scope.release.albumArt);
	 } )
	 // $http.post($rootScope.musicDBapiSrc + '/tracksFromFolder', { folder: folder }).then(function(response) {
		//  // debugger
	 // })
	}

  linkFolder = (value) => {
    console.log('linked folder')
    this.scanFolder(this.folder)
  }

  update = (value) => {
    console.log(value)
    this.selectedFolder = value;
    this.folder = value.folder

    this.release.fela = value.folder;
    if(this.release.title && this.release.artist_nm && this.region.value)
      this.suggestedLocations = this.getLocations();

    if(this.release.fela && this.suggestedLocations.library !== this.release.fela) {
      this.misfiled = true;
    } else {
      this.misfiled = false;
    }
    // $scop
  }


}
