import { Component } from '@angular/core';
import { StudioService } from '../studio.service';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-studio-delegation',
  templateUrl: './studio-delegation.component.html',
  styleUrls: ['./studio-delegation.component.scss']
})
export class StudioDelegationComponent {

  constructor(private StudioService: StudioService, private socket: SocketService) {

    this.onAir = this.StudioService.getOnAir()
    this.studio = this.StudioService.getStudio()
    this.delegation = this.StudioService.getDelegationStatus()
    
    this.StudioService.onMessage().subscribe( (data: any) => { 
      if(data.onAir)
        this.onAir = data.onAir; 
      if (data.studio)
        this.studio = data.studio;
      if (data.delegationStatus)
        this.delegation = data.delegationStatus;
    });
  }

  ngOnInit() {
    this.socket.send({ 
      delegationRequest: { channel: 'fm', source: 'studio1', studio: this.studio }
    });
  }

  onAir = false;
  studio = 4;

  delegation: any;

  fmSelect = (source) => {
    if(this.delegation.fm[source].active) {
      this.socket.send({ 
        releaseDelegation: { channel: 'fm', target: source, source: this.studio }
      });
    } else {
      this.socket.send({ 
        requestDelegation: { channel: 'fm', target: source, source: this.studio }
      });
    }
    
    console.log('fm select ' + source)
  }

  zdSelect = (source) => {
    if(this.delegation.zd[source].active) {
      this.socket.send({ 
        releaseDelegation: { channel: 'zd', target: source, source: this.studio }
      });
    } else {
      this.socket.send({ 
        requestDelegation: { channel: 'zd', target: source, source: this.studio }
      });
    }
    console.log('zd select ' + source)
  }
}
