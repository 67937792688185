<mat-toolbar>
  <span>Zedders</span>
  <span style="flex: 1"></span>
  <button mat-button class="caps" aria-label="Search">Search</button>
  <button mat-button class="caps" aria-label="Add Zedder">Add Zedder</button>
</mat-toolbar>

<mat-toolbar class="quick-search" style="position: sticky; top: 0; z-index: 150">
    <mat-form-field class="full-width">
      <input matInput placeholder="Quick Search" (keydown.enter)="quickSearch($event)" [value]="searchString">
    </mat-form-field>
</mat-toolbar>

  <section [hidden]="searchResults.length === 0" class="search-results mat-elevation-z8">
    <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"><a (click)="$event.stopPropagation()" [routerLink]="'/zedders/' + element.id">{{element.firstname}} {{element.lastname}}</a></td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="email">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let element">{{element.phone}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="subnumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Sub No </th>
        <td mat-cell *matCellDef="let element">{{element.subnumber}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="expiry">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Expiry </th>
        <td mat-cell *matCellDef="let element">{{element.expiry}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>    
  </section>
  <mat-toolbar style="position: sticky; top: 0; z-index: 150;">
    <mat-toolbar-row>
      <h2>{{zedder.firstname}} {{zedder.lastname}}</h2>
    </mat-toolbar-row>
    <mat-toolbar-row style="height: 24px;" ng-show="zedder.id">
      <ul class="inline" style="width: 100%;">
        <li>
          <strong>Zedder ID #:</strong> {{zedder.id}}
        </li>
        <li>
          <strong>Created:</strong> {{zedder.created_at | date:'medium'}}
        </li>
        <li>
          <strong>Last Updated:</strong> {{zedder.updated_at | date:'medium'}}
        </li>
        <li>
          {{activeSub(zedder.expiry) ? 'Active Subscriber' : 'Past Subscriber' }}
        </li>
        <li style="float: right;" [hidden]="!message">{{message}}</li>
      </ul>
    </mat-toolbar-row>
</mat-toolbar>



<div class="info-container mat-typography">
  <div style="flex: 1">
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Details</mat-card-title>
      </mat-card-header>
      <mat-card-content [formGroup]="zedderForm">
        <mat-form-field class="full-width">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="firstname" value="">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="lastname" value="">
        </mat-form-field>

        <div style="display: flex; gap: 8px;">
          <mat-form-field style="flex-grow: 1;">
            <mat-label>Pronouns</mat-label>
            <input matInput placeholder="Pronouns" formControlName="pronouns" value="">
          </mat-form-field>

          <mat-form-field style="flex-grow: 1;">
            <mat-label>Gender</mat-label>
            <mat-select matNativeControl formControlName="gender">
              <mat-option *ngFor="let gender of genders; index as id" [value]="id">
                {{gender}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 210px;">
            <mat-label>Birthday</mat-label>
            <input matInput [matDatepicker]="dobpicker" formControlName="dob">
            <mat-datepicker-toggle matIconSuffix [for]="dobpicker"></mat-datepicker-toggle>
            <mat-datepicker #dobpicker></mat-datepicker>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" value="">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Phone</mat-label>
          <input matInput placeholder="Phone" formControlName="phone" value="">
        </mat-form-field>

        <div style="display: flex; gap: 8px;">
          <mat-form-field style="flex-grow: 1;">
            <mat-label>Sub #</mat-label>
            <input matInput placeholder="Pronouns" formControlName="subnumber" value="">
          </mat-form-field>

          <mat-form-field style="flex-grow: 1;">
            <mat-label>Last Subscription Type</mat-label>
            <mat-select matNativeControl formControlName="subtype">
              <mat-option *ngFor="let subtype of subCategories" [value]="subtype.subtypeid">
                {{subtype.subtypecode}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 210px;">
            <mat-label>Last Expiry</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="expiry">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <mat-checkbox formControlName="zedletter">Receive Zedletter</mat-checkbox>
        <mat-checkbox formControlName="acknowledgement">On-Air Acknowledgement</mat-checkbox>

        <div formGroupName="delivery_address">
        <mat-form-field class="full-width">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Address" formControlName="address" value="">
        </mat-form-field>

        <div style="display: flex; gap: 8px;">
          <mat-form-field style="flex-grow: 1;">
            <mat-label>Suburb</mat-label>
            <input matInput placeholder="Suburb" formControlName="locality" value="">
          </mat-form-field>

          <mat-form-field style="flex-grow: 1;">
            <mat-label>State</mat-label>
            <input matInput placeholder="State" formControlName="region" value="">
          </mat-form-field>


          <mat-form-field style="flex-grow: 1;">
            <mat-label>Country</mat-label>
            <mat-select matNativeControl formControlName="country">
              <mat-option *ngFor="let country of countries" [value]="country.alpha">
                {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field class="full-width">
            <mat-label>Country</mat-label>
            <input matInput placeholder="Country" formControlName="country" value="">
          </mat-form-field> -->

          <mat-form-field style="width: 160px;">
            <mat-label>Post Code</mat-label>
            <input matInput placeholder="Postal Code" formControlName="postcode" value="">
          </mat-form-field>
        </div>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Notes</mat-label>
          <textarea matInput placeholder="Notes" formControlName="subcomment" ></textarea>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Announcer</mat-label>
          <mat-chip-grid #announcerChip aria-label="Announcer">
            <mat-chip-row *ngFor="let show of shows"
                          (removed)="remove(show)"
                          [editable]="true"
                          (edited)="edit(show, $event)"
                          [aria-description]="'press enter to edit ' + show.name">
              {{show.name}}
              <button matChipRemove [attr.aria-label]="'remove ' + show.name">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Show..."
            #showInput
            [formControl]="announcerControl"
            [matAutocomplete]="announcerComplete"
            [matChipInputFor]="announcerChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #announcerComplete="matAutocomplete" 
            (optionSelected)="selected($event)">
            <mat-option *ngFor="let program of filteredOptions | async" [value]="program">
              {{program.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        
        <mat-form-field class="full-width">
          <mat-label>Backup Announcer</mat-label>
          <mat-chip-grid #backupAnnouncerChip aria-label="Backup Announcer">
            <mat-chip-row *ngFor="let show of backupShows"
                          (removed)="removeBackup(show)"
                          [editable]="true"
                          (edited)="edit(show, $event)"
                          [aria-description]="'press enter to edit ' + show.name">
              {{show.name}}
              <button matChipRemove [attr.aria-label]="'remove ' + show.name">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Show..."
            #backupShowInput
            [formControl]="announcerControl"
            [matAutocomplete]="backupAnnouncerComplete"
            [matChipInputFor]="backupAnnouncerChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #backupAnnouncerComplete="matAutocomplete" 
            (optionSelected)="backupSelected($event)">
            <mat-option *ngFor="let program of filteredOptions | async" [value]="program.name">
              {{program.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
<!-- 
        <mat-form-field class="full-width">
          <mat-label>Backup Announcer</mat-label>
          <mat-chip-grid #backupChipGrid aria-label="Backup Announcer">
            <mat-chip-row *ngFor="let show of backupShows"
                          (removed)="remove(show)"
                          [editable]="true"
                          (edited)="edit(show, $event)"
                          [aria-description]="'press enter to edit ' + show.name">
              {{show.name}}
              <button matChipRemove [attr.aria-label]="'remove ' + show.name">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input placeholder="Add Show..."
                   [matChipInputFor]="backupChipGrid"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="add($event)"/>
          </mat-chip-grid>
        </mat-form-field> -->
        
        <mat-checkbox formControlName="volunteer_status">Volunteer</mat-checkbox>
      </mat-card-content>
    </mat-card>

    <mat-card [hidden]="!zedder.volunteer" class="info-card" *ngFor="let volunteer of zedder.volunteer" style="background-color: rgb(90, 45, 78);">
      <mat-card-header>
          <mat-card-title style="display: flex; width: 100%">
            <div>Volunteer</div>
            <div style="flex: 1"></div>
            <div><a style="color: white" [routerLink]="'/volunteers/' + volunteer.id"><b>{{volunteer.id}}</b></a></div>
          </mat-card-title>
      </mat-card-header>
      <mat-card-content>

      <mat-form-field class="full-width">
        <mat-label>Induction Completed</mat-label>
        <input matInput placeholder="Completion Date" readonly="true" [value]="volunteer.completed_orientation_date">
      </mat-form-field>
      
      <app-availability-toggle [availability]="volunteer.availability" />


      <mat-form-field class="full-width">
        <mat-label>Roles</mat-label>
        <mat-chip-grid #role aria-label="Roles">
          <mat-chip-row *ngFor="let position of volunteer.positions">
            {{position.position}}
          </mat-chip-row>
          <input [matChipInputFor]="role" readonly="true" />
        </mat-chip-grid>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Current Role</mat-label>
        <input matInput placeholder="Current Role" readonly="true" [value]="volunteer.roles">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Departments</mat-label>
        <mat-chip-grid #dept aria-label="Departments">
          <mat-chip-row *ngFor="let department of volunteer.departments">
            {{department.department}}
          </mat-chip-row>
          <input [matChipInputFor]="dept" readonly="true" />
        </mat-chip-grid>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Skills</mat-label>
        <mat-chip-grid #skill aria-label="Skills">
          <mat-chip-row *ngFor="let skill of volunteer.skills">
            {{skill.skill}}
          </mat-chip-row>
          <input [matChipInputFor]="skill" readonly="true" />
        </mat-chip-grid>
      </mat-form-field>

      <!-- <mat-form-field class="full-width">
        <mat-label>Other Skills</mat-label>
        <input matInput placeholder="Other Skills" readonly="true" [value]="volunteer.subskilldesc">
      </mat-form-field> -->

      <mat-form-field class="full-width">
        <mat-label>Training</mat-label>
        <mat-chip-grid #training aria-label="Training">
          <mat-chip-row *ngFor="let training of volunteer.training">
            {{training.training}}
          </mat-chip-row>
          <input [matChipInputFor]="training" readonly="true" />
        </mat-chip-grid>
      </mat-form-field>


      <mat-form-field class="full-width">
        <mat-label>Professional Qualifications</mat-label>
        <mat-chip-grid #qualifications aria-label="Qualifications">
          <mat-chip-row *ngFor="let qualification of volunteer.qualifications">
            {{qualification.qualification}}
          </mat-chip-row>
          <input [matChipInputFor]="qualifications" readonly="true" />
        </mat-chip-grid>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Project History</mat-label>
        <textarea matInput placeholder="Project History" readonly="true" [value]="volunteer.volunteer_availability"></textarea>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Announcer History</mat-label>
        <input matInput placeholder="Previous Roles" readonly="true" [value]="volunteer.announcer_history">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Previous Roles</mat-label>
        <input matInput placeholder="Previous Roles" readonly="true" [value]="volunteer.roles_history">
      </mat-form-field>

      </mat-card-content>
    </mat-card>
  </div>


  <div style="flex: 1">
    <mat-card class="info-card" style="background-color: hsl(224, 47%, 40%);">
      <mat-card-header style="background-color: hsl(224, 47%, 32%);">
        <mat-card-title>Subscriptions</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <a mat-list-item *ngFor="let sub of sortSubByDate('expirydate');" [routerLink]="'/subscribers/' + sub.subid">
            <span matListItemTitle>{{ sub.subnumber }} {{sub.subfirstname}} {{sub.sublastname}} <em>{{sub.nameoncard }}</em></span>
            <span matListItemLine>{{ sub.subscription.subtypecode }} <em>{{sub.petname}}{{sub.subbandname}}</em></span>
            <span matListItemLine><strong>Expiry</strong> {{ sub.expirydate | date: 'dd/MM/yyyy'}} <em>(Created {{ sub.created_at | date: 'dd/MM/yyyy'}})</em></span>
          </a>
        </mat-list>
      </mat-card-content>
    </mat-card>

    <mat-card class="info-card">
      <mat-card-header style="background-color: hsl(224, 47%, 32%);">
        <mat-card-title>Related</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <a *ngFor="let sub of sortRelatedByDate('expirydate');" [routerLink]="'/subscribers/' + sub.subid" mat-list-item>
            <span matListItemTitle>{{ sub.subnumber }} {{sub.subfirstname}} {{sub.sublastname}} <em>{{sub.nameoncard }}</em></span>
            <span matListItemLine>{{ sub.subscription.subtypecode }} <em>{{sub.petname}}{{sub.subbandname}}</em></span>
            <span matListItemLine><strong>Expiry</strong> {{ sub.expirydate | date: 'dd/MM/yyyy'}} <em>(Created {{ sub.created_at | date: 'dd/MM/yyyy'}})</em></span>
          </a>
        </mat-list>
      </mat-card-content>
    </mat-card>

    <mat-card class="info-card" style="background-color: hsl(180, 47.2%, 40%);">
      <mat-card-header style="background-color: hsl(180, 47.2%, 32%);">
        <mat-card-title>Orders</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <a *ngFor="let order of sortByDate('orders', 'created_at');" [routerLink]="'/orders/' + order.order_id" mat-list-item [ngClass]="{'invalid': !order.invoice_no }">
            <span matListItemTitle><b>#{{order.order_id}}</b> {{order.created_at | date: 'dd/MM/yyyy'}}</span>
            <span matListItemLine><b>${{order.total}}</b></span>
            <span *ngFor="let item of order.items_json" matListItemLine>{{ item.name }}</span>
          </a>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>

</div>

<div class="floating-fab" [hidden]="!formEdited">
  <!-- <button (click)="saveZedder()" mat-fab color="primary" matTooltip="Save Subscriber" aria-label="Save Subscriber">
    <mat-icon>edit</mat-icon>
  </button> -->
  <button [disabled]="submitLocked" (click)="searchResultsSelection.id ? goto(searchResultsSelection.id) : id === 'search' ? searchZedders() : saveZedder()" mat-fab color="primary" aria-label="Save Subscriber">
		<mat-icon>{{searchResultsSelection.id ? 'arrow_forward' : id === 'search' ? 'search' : id === 'new' ? 'add' : 'edit' }}</mat-icon>
	</button>

</div>