import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, Inject, inject} from '@angular/core';
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {NgFor} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ZedderService } from '../zedder.service';
import { CountriesService } from '../countries.service';
import { environment } from 'src/environments/environment';

export interface Zedder {
  id: string;
}
@Component({
  selector: 'app-merge-zedders',
  templateUrl: './merge-zedders.component.html',
  styleUrls: ['./merge-zedders.component.scss']
})
export class MergeZeddersComponent {
  constructor(
    private ZedderService: ZedderService,
    private CountriesService: CountriesService,
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
  ) {

    CountriesService.query().subscribe( data => { this.countries = data });

    this.zedderControl.valueChanges.subscribe( value =>
      {
        this.zeddersForm.clear();
        this.zedders = [];
        this.subscribers = [];
        this.related = [];
        
        value.forEach((id, i) => {
          this.ZedderService.get(id).subscribe((subPromise) => {

            let group = new UntypedFormGroup({
              firstname: new UntypedFormControl({value: subPromise.firstname, disabled: true }),
              lastname: new UntypedFormControl({value: subPromise.lastname, disabled: true }),
              subnumber: new UntypedFormControl({value: subPromise.subnumber, disabled: true }),
              expiry: new UntypedFormControl({value: subPromise.expiry, disabled: true }),
              subtypecode: new UntypedFormControl({value: subPromise.subscription && subPromise.subscription.subtypecode, disabled: true }),
              email: new UntypedFormControl({value: subPromise.email, disabled: true }),
              phone: new UntypedFormControl({value: subPromise.phone, disabled: true }),
              address: new UntypedFormControl({value: subPromise.delivery_address && subPromise.delivery_address.address, disabled: true }),
              locality: new UntypedFormControl({value: subPromise.delivery_address && subPromise.delivery_address.locality, disabled: true }),
              region: new UntypedFormControl({value: subPromise.delivery_address && subPromise.delivery_address.region, disabled: true }),
              country: new UntypedFormControl({value: subPromise.delivery_address && subPromise.delivery_address.country, disabled: true }),
              postcode: new UntypedFormControl({value: subPromise.delivery_address && subPromise.delivery_address.postcode, disabled: true }),
              zedletter: new UntypedFormControl({value: subPromise.zedletter, disabled: true }),

            })
            this.zeddersForm.push(group)
            if (i === this.zedderIds.length - 1) {
              console.log(this.zedders)
    
              this.zedders.forEach(z => {
                this.subscribers = [ ...this.subscribers, ...z.subscribers ];
                this.related = [ ...this.related, ...z.related ]
                })
              console.log(this.subscribers)
              console.log(this.related)
            }

            this.zedders.push(subPromise);

          });
        })
      }
    );
  }

  
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  zedderIds: Zedder[] = [];
  zedderControl = new UntypedFormControl(['angular']);

  zeddersForm: FormArray<FormGroup> = new UntypedFormArray([])

  countries: any[] = []
  zedders: any[] = [];
  subscribers: any[] = [];
  related: any[] = [];

	defaults = { id: null, firstname: null, lastname: null, sub: null, email: null, phone: null, address: null, zedletter: null }

	default = null


  announcer = inject(LiveAnnouncer);


  mergeZedders() {
		this.http.post(environment.apiSrc + '/mergeZedders', { zedders: this.zedderIds.map(i => i.id), main: this.default, items: this.defaults } ).subscribe( data => {
			alert('Merge success!')
		})
  }

  isDefault = (id) => (
		this.default === id ? true : false
	)


  makeDefault(section, id) {
		console.log(id)

		if(section === 'id') {
			this.defaults = {	id: id, firstname: id, lastname: id, sub: id, email: id, phone: id, address: id, zedletter: id}
			this.default = id
		}
		else {
			this.defaults[section] = id
		}
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our zedder
    if (value) {
      this.zedderIds.push({id: value});
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(zedder: Zedder): void {
    const index = this.zedderIds.indexOf(zedder);

    if (index >= 0) {
      this.zedderIds.splice(index, 1);

      this.announcer.announce(`Removed ${zedder}`);
    }
  }

  edit(zedder: Zedder, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove zedder if it no longer has a name
    if (!value) {
      this.remove(zedder);
      return;
    }

    // Edit existing zedder
    const index = this.zedderIds.indexOf(zedder);
    if (index >= 0) {
      this.zedderIds[index].id = value;
    }
  }

  updateZedder(event) {
    console.log(event);
  }
}
