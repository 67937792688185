<div data-simplebar data-simplebar-direction="rtl" style="background-color: #424242;">
	<div style="display: flex; padding: 16px;">
		<div (click)="click(info.library_no)" class="album-info-container">

			<div class="album-artwork cover large" (click)="play(info.artist_nm, info.title, info.library_no)" [style.background-image]="'url(' + musicDBapiSrc + '/coverArt/1000/' + info.library_no  + ')'">
				<div class="bg">
					<mat-icon class="favourite" (click)="favourite(info, $event)">star_border</mat-icon>
					<mat-icon class="play" (click)="play(info.artist_nm, info.title, info.library_no, $event); click(info.library_no);">play_circle_filled</mat-icon>
					<mat-icon  mat-icon-button [matMenuTriggerFor]="coverMenu" class="more" aria-label="more">more_horiz</mat-icon>
					<mat-menu #coverMenu="matMenu">
						<button mat-menu-item (click)="queue(info.artist_nm, info.title, info.library_no, $event)"> 
								Add to queue
						</button>
						<button mat-menu-item disabled="disabled" (click)="addToPlayList()">
								Add to playlist
						</button>
						<mat-divider></mat-divider>
						<button mat-menu-item (click)="release(info.library_no)">
								Goto release
						</button>
					</mat-menu>
				</div>
			</div>

			<div class="album-information" flex id="aotw-summary" style="margin-left: 1em; flex: 1">
				<div class="mat-subtitle-1">
					<b><a [routerLink]="'/releases/artists/' + info.artist_id">{{info.artist_nm}}</a></b>
					<span style="font-style: italic; float: right; font-size: 75%">{{info.artist_hometown}} {{info.album_origin && '/ ' + info.album_origin }}</span>
					<br />
					 {{info.title}}
				</div>
			 <div *ngIf="futureDate(info.embargo_until)" style="font-weight: 700; font-style: italic">This release has been embargoed, tracks can only be previewed pre-release for reviewing purposes and are NOT FOR AIRPLAY. It will be released from embargo on {{info.embargo_until | date: 'EEEE, MMMM d'}}</div>
			 <div *ngIf="tracks">
					<app-album-track-list [favourite]="favourite" [tracks]="tracks" [play]="play"></app-album-track-list>
					<!-- <album-track-list favourite="$ctrl.favourite" order="['discnum', 'tracknum']" tracks="tracks" play="$ctrl.play"></album-track-list> -->
				</div>
			 <div *ngIf="futureDate(info.release_date)" style="font-weight: 700">Release Date: {{info.release_date | date: 'EEEE, MMMM d'}}</div>
			 <p>{{info.bio}}</p>
			 <p style="font-style: italic">{{info.comments}}</p>
			 <p style="font-style: italic">{{info.review}}</p>
			 <p>{{info.release_year}} <span style="font-style: italic">{{info.album_label}}</span></p>

				<div layout="row" layout-wrap="">
					<span class="genre-tag" *ngFor="let genre of info.genres">{{genre.genre}}</span>
				</div>

			</div>
		</div>
	</div>
</div>

<style>


</style>
