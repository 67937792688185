import { Inject, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from "@angular/common";
import { UIRouter } from "@uirouter/core";
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers'
import { SessionService } from '../session.service';
import { PlaylistService } from '../playlist.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export class ReleasesBaseComponent {

	// @HostListener('window:hashchange', ['$event'])
	// hashChangeHandler(e) {
	// 	let hash = window.location.hash.substring(1);
	// 	if(hash !== this.hot100Year ) {
	// 		this.hot100Year = window.location.hash.substring(1);
	// 		this.selectedIndex = this.hot100years.findIndex(i => i === Number(this.hot100Year));

	// 		// this.hot100YearTracks = this.hot100tracks.filter(i => i.year === Number(this.hot100Year) );
	// 	}
	// }

	constructor(
		protected router: Router,
    public location: Location,
    protected http: HttpClient,
		// @Inject('$rootScope') protected $rootScope: any,
		protected PlaylistService: PlaylistService,
		protected SessionService: SessionService,
	) {}

		favourites: any = {};

		release = (library_no) => {
			// console.log(this.router.urlService)
			this.router.navigateByUrl('/releases/' + library_no);
			// this.router.urlService.url('/releases/' + library_no);
			// this.location.go('/releases/' + library_no);
		};

		click = (library_no) => {
			this.searchSub = '';
			// console.log(this.router.urlService)
			this.router.navigateByUrl('/releases/' + library_no);
			// this.router.urlService.url('/releases/' + library_no);
			// this.location.go('/releases/' + library_no);
		};

		queueRelease = (artist, album, library_no, $event) => {
			this.http.get<any>(environment.musicDBapiSrc + '/title-by-library-id/' + library_no).subscribe( data => {
				let dBTracks : any[] = data;
				this.PlaylistService.prepForBroadcast({action: 'addToQueue', id: library_no, playlist: dBTracks.sort((a, b) => Number(a.discnum) - Number(b.discnum) || Number(a.tracknum)- Number(b.tracknum) )});
			});
			$event.stopPropagation();
	
		};
		
		searchSub: string = '';
		artistResult = [];
		searchResult = [];
		// releases = [];
		tracks = [];
		tempTracks = [];
		message = '';

		clearSearch = () => {
			// $location.replace($location.path())
			this.searchSub = '';
			this.artistResult = [];
			this.searchResult = [];
			this.tracks = [];
			this.tempTracks = [];
		}
	

		quickSearch = (event) => {
			this.clearSearch();
			let searchString = event.target.value;
			this.searchSub = searchString;

			console.log(this.searchSub);
			// this.searchOpen();
	
			// this.router.locationService.url('#' + this.searchSub);
	
			this.http.get<any>(environment.apiSrc + '/artist-by-name/' + searchString).subscribe( data => {
				this.artistResult = data;
			});
	
			this.http.post<any>(environment.apiSrc + '/releaseQuickSearch', {searchString: searchString}).subscribe( data => {
				this.searchResult = data;
				this.sortData(this.lastSort);
				// this.releases = data;
			});
	
			this.http.post<any>(environment.musicDBapiSrc + '/trackQuickSearch', { searchString: searchString}).subscribe( data => {
				this.tracks = data.length && data.sort( (a,b) => { return (a.artist_id === b.artist_id) ? (a.album_id === b.album_id) ? (a.tracknum - b.tracknum): (a.album_id - b.album_id) : (a.artist_id - b.artist_id) }) ;
			})
	
			this.http.post<any>(environment.musicDBapiSrc + '/mairlist/search', { searchString: searchString}).subscribe( data => {
				this.tempTracks = data.filter( i => i.exists );
			})
	
	
		}

		favourite = (dBTrack, $event) => {
			if($event)
				$event.stopPropagation()
			//prep store
			var favtemp = this.SessionService.get('favourites');
			this.favourites = favtemp ? JSON.parse(favtemp) : []
			if (! this.favourites || ! this.favourites.id ) {
				var id = Math.random().toString(36).slice(2);
				this.favourites = { playlist: [], id: id };
			}
			
			if(!dBTrack.title_id) {
				this.http.get<any>(environment.musicDBapiSrc + '/title-by-library-id/' + dBTrack.library_no).subscribe( data => {
					data.forEach(dBTrack => {
						if (this.favourites.playlist.map(i => i.title_id).includes(dBTrack.title_id)){ // in favourite - remove
							dBTrack.favourite = false;
							let removeIndex = this.favourites.playlist.map(item => item.title_id).indexOf(dBTrack.title_id)
							~removeIndex && this.favourites.playlist.splice(removeIndex, 1);
						}
						else  { // not in playlist - insert
							dBTrack.artist = dBTrack.artist.name
							dBTrack.favourite = true;
							this.favourites.playlist.push(dBTrack)
						}
					});
					this.SessionService.set('favourites', JSON.stringify(this.favourites) );
					console.log(this.favourites);
				});
				console.log('release??')
			} else {
				console.log('attempting favourite?')
	
				if (this.favourites.playlist.map(i => i.title_id).includes(dBTrack.title_id)) { // in favourite - remove
					dBTrack.favourite = false;
					let removeIndex = this.favourites.playlist.map(item => item.title_id).indexOf(dBTrack.title_id)
					~removeIndex && this.favourites.playlist.splice(removeIndex, 1);
				}
				else { // not in playlist - insert
					dBTrack.favourite = true;
					this.favourites.playlist.push(dBTrack)
				}
	
				this.SessionService.set('favourites', JSON.stringify(this.favourites) );
				console.log(this.favourites);
	
			}
	
		}

		play = (...args) => {
			if (args.length === 2) {
				this.playTrack(args[0], args[1]);
			}
			if (args.length === 4) {
				this.playRelease(args[0], args[1], args[2], args[3]);
			}
			console.log(args.length);
		}

		playTemp = (item) => {
			this.PlaylistService.prepForBroadcast({action: 'queueTempAndPlay', playlist: [ item ]})
			// let file = item.filename;
			// let url = environment.musicDBapiSrc + '/temporary/dl'
			// this.http.post<any>(url, { file: file, share: 'temporary' }).subscribe( data => {
			// 	var token = data.token
			// 	var url = environment.dbUrl + '/fela/' + token;
			// 	// var url = environment.musicDBapiSrc + '/fela/' + token;
			// 	this.$rootScope.player.src = url;
			// 	this.$rootScope.player.play()
			// });
		}
		
		playTrack = (dBTrack, $event) => {
			console.log('play the track')
			console.log($event)
			console.log(dBTrack)
			this.PlaylistService.prepForBroadcast({action: 'queueSingleAndPlay', playlist: [ dBTrack ]})
			// return this.http.get<any>(environment.musicDBapiSrc + '/dl/' + dBTrack.title_id).subscribe( data => {
			// 	var token = data.token
			// 	// var url = environment.musicDBapiSrc + '/fela/' + token
			// 	var url = environment.dbUrl + '/fela/' + token
			// 	this.$rootScope.player.src = url;
			// 	// $rootScope.player.addtrack = url;
			// 	this.$rootScope.player.play();
			// })

		}

		playRelease = (artist, album, library_no, $event) => {
			$event.stopPropagation();
			$event.preventDefault();
			console.log('play the release')
			this.http.get<any>(environment.musicDBapiSrc + '/title-by-library-id/' + library_no).subscribe( data => {
				var dBTracks = data;
				dBTracks.forEach( i => {
					if(i.part_of_a_set) {
						if(i.part_of_a_set.includes('/')){
							let parts = i.part_of_a_set.split('/');
							i.discnum = parts[0];
							i.disctotal = parts[1];
						}
						else {
							i.discnum = i.part_of_a_set;
							i.disctotal = null;
						}
					}
				})

				this.PlaylistService.prepForBroadcast({id: library_no, playlist: dBTracks.sort((a, b) => Number(a.discnum) - Number(b.discnum) || Number(a.tracknum)- Number(b.tracknum) ) });
			});

		}

		lastSort: Sort = { active: 'library_no', direction: '' };
		sortedData: any[] = [];
		displayedColumns: string[] = ['library_no', 'artist', 'title', 'label', 'year'];

		sortData(sort: Sort) {
			this.lastSort = sort;
			const data = this.searchResult.slice();
	
			if (!sort.active || sort.direction === '') {
				this.sortedData = data;
				return;
			}
	
			this.sortedData = data.sort((a: any, b: any) => {
				const isAsc = sort.direction   === 'asc';
				switch (sort.active) {
					case 'library_no':
						return compare(a.library_no, b.library_no, isAsc);
					case 'artist':
						return compare(a.artist_nm, b.artist_nm, isAsc);
					case 'title':
						return compare(a.title, b.title, isAsc);
					case 'label':
						return compare(a.album_label, b.album_label, isAsc);
					case 'year':
						return compare(a.year, b.year, isAsc);
					default:
						return 0;
				}
			});
		}
		uploadColumns: string[] = ['artwork', 'number', 'title', 'duration', 'artist', 'year', 'play', 'bitrate'];
		searchResultsSelection = {}
		clickedRows = (row) => {
			console.log(row);
			this.searchResultsSelection = row;
			// this.fetchZedder(row.id);
		};

		playTmp = (track) => {
			this.PlaylistService.prepForBroadcast({action: 'queueTempAndPlay', playlist: [ track ]})

			// console.log(track)
			// return this.http.get<any>(environment.musicDBapiSrc + '/dltmp/' + btoa(dBTrack.url)).subscribe( data => {
			// 	var token = data.token
			// 	// var url = environment.musicDBapiSrc + '/fela/' + token
			// 	var url = environment.dbUrl + '/fela/' + token
			// 	this.$rootScope.player.src = url;
			// 	this.$rootScope.player.play()
			// })
		}
	}