import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { PositionsService } from '../positions.service';

export interface Positions {
  id: number;
  position: string;
}
 
@Component({
  selector: 'positions',
  templateUrl: './manage-positions.component.html',
  styleUrls: ['./manage-positions.component.scss']
})

export class ManagePositionsComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    id: new UntypedFormControl({ value: 0, disabled: true}),
    position: new UntypedFormControl('')
  });
  lastSort: Sort = { active: "position", direction: "asc" };
  editPositions: Positions = {position: '', id: 0};
  subCategories: Positions[] = [];
  sortedData: Positions[];
  displayedColumns: string[] = ['id', 'position', 'edit'];

  constructor(public PositionsService: PositionsService) {
    this.sortedData = this.subCategories.slice();
    // this.PositionsService = PositionsService;
    PositionsService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'skill':
          return compare(a.position, b.position, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      id: el.id,
      position: el.position
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      id: 0,
      position: ''
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().id === 0) { //new
      let position: any = {};
      position.position = this.interestForm.value.position;
      this.PositionsService.save(position).subscribe(res => {
        this.PositionsService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      this.PositionsService.get(this.interestForm.value.id).subscribe(
        position => {
          position.position =  this.interestForm.value.position;
          this.PositionsService.update(position).subscribe(response => { 
            let interests : Positions[] = [ {
              id: response.id,
              position: response.position
            }]
      
            const existing = this.subCategories.reduce((a,t)=> (a[t.id] = t, a), {}),
            changed = interests.reduce((a,t)=> (a[t.id] = t, a), {})
      
            this.subCategories = Object.values({...existing, ...changed})
            // this.sortedData = this.subCategories.slice(); 
            this.sortData(this.lastSort);
          });
       });
      
    }
   
  }
  
}
