import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sponsorship-comms-dialog',
  templateUrl: './sponsorship-comms-dialog.component.html',
  styleUrls: ['./sponsorship-comms-dialog.component.scss']
})
export class SponsorshipCommsDialogComponent {
  constructor(
    private http: HttpClient,
  ){
    this.http.get(environment.dbUrl + '/sponsorship.html',  {responseType: 'text'}).subscribe(
     data =>  { this.sponsorshipMessage = data; }
    )
  }

  sponsorshipMessage;

}