<h2 mat-dialog-title>Rename File</h2>
<mat-dialog-content>
  <div><em>{{data.file.filename}}</em></div>
	<mat-form-field class="example-full-width">
		<mat-label>Rename to</mat-label>
		<input matInput [(ngModel)]="data.rename">
		<mat-hint>The file extension (.{{data.extension}}) is important, please maintain the same extension or things might go wrong!</mat-hint>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="abort">Cancel</button>
  <button mat-button [mat-dialog-close]="data.rename" cdkFocusInitial>Rename</button>
</mat-dialog-actions>