<mat-toolbar>
  <mat-toolbar-row>
    Welcome
  </mat-toolbar-row>
</mat-toolbar>

<div class="mat-typography" style="background-color: #161616; gap: 8px; padding: 8px;">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recent Supporters</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="position: relative; width: calc(100vw - 32px); height: calc(100vw - 32px)">
      <canvas baseChart 
        type="bubble"
        height="400"
        width="400"
        [options]="bubbleChartOptions"
        [data]="bubbleChartData"
        [legend]="false"
        (chartClick)="test($event)"
      >
      </canvas>
    </div>
  </mat-card-content>
  </mat-card>

  <mat-card *ngIf="aotw">
    <mat-card-header style="align-items: center; background-color: hsl(196.1, 85.8%, 33.1%);">
      <mat-card-title>Album of the week</mat-card-title>
      <div class="sticker aotw"></div>
    </mat-card-header>
    <mat-card-content>
      <app-album-info class="aotw" [info]="aotw" [release]="click" [play]="play" [queue]="queueRelease" [favourite]="favourite" ></app-album-info>
    </mat-card-content>
  </mat-card>
</div>