import { Component, Inject, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-programs-report',
  templateUrl: './programs-report.component.html',
  styleUrls: ['./programs-report.component.scss']
})
export class ProgramsReportComponent {
  @ViewChild( 'announcerTable' ) announcerTable!: MatTable<any>;
  constructor(
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
  ) {

    const grid = this.http.post<any>(environment.musicDBapiSrc + '/grid', {});
    forkJoin(grid).subscribe( data => { 
      const [ grid ] = data;

      grid.fm.blocks.forEach( 
        program => program.program.announcers.forEach( announcer => {
          this.announcers.push({
            ...announcer, 
            program: program.program, 
            keywords: program.program.keywords.map(keyword => keyword.tag).join(', ') })
        }) 
      )

      grid.zd.blocks.forEach( 
        program => program.program.announcers.forEach( announcer => {
          this.announcers.push({
            ...announcer, 
            program: program.program, 
            keywords: program.program.keywords.map(keyword => keyword.tag).join(', ') })
        }) 
      )

      this.announcers = this.announcers.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2.id === obj1.id && obj2.program.id === obj1.program.id)) === i
      )

      this.sortData(this.lastSort);

      this.announcerTable.renderRows();
    })
  }
  announcers: any[] = [];
  sortedData: any[] = [];
   
  searchResultsSelection: any = {}
  
  lastSort: Sort = { active: 'name', direction: 'asc' };
  
  displayedColumns: string[] = ['id', 'name', 'pronouns', 'email', 'phone', 'expiry', 'program', 'keywords', 'rds'];
  displayedFilters: string[] = ['id-filter', 'name-filter', 'pronouns-filter', 'email-filter', 'phone-filter', 'expiry-filter', 'program-filter', 'keywords-filter', 'rds-filter'];

  filters: any = {};

  exportCols = [
    {
      field: 'program.name',
      displayName: 'Program',
    },
    {
        field: 'firstname',
        displayName: 'Firstname',
    },
    {
      field: 'lastname',
      displayName: 'Lastname',
    },
    {
      field: 'pronouns',
      displayName: 'Pronouns',
    },
    {
      field: 'id',
      displayName: 'ZedderID',
    },
    {
      field: 'email',
      displayName: 'Email',
    },
    {
      field: 'phone',
      displayName: 'Phone',
    },
    {
      field: 'expiry',
      displayName: 'Sub Expiry',
    },
    {
      field: 'keywords',
      displayName: 'Keywords',
    },
  ];

  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    // this.location.go('/orders/' + row.order_id)
  };

  exportCSV = () => {
		console.log(this.exportCols);

		const flatList = this.announcers.map( order => 
      this.exportCols.map(i => { 
        let keys = i.field.replace(/]/g,"").replace(/\[/g,".").split(".");
        let value = keys.reduce((obj,i) => obj && obj[i] , order)
        return {key: i.displayName ? i.displayName : i.field,  value: value === null ? '' : value };
      }) 
    );
    

		console.log(flatList);
		            
    let headerNames = flatList[0].map( i => i .key );
		const csv = [
			headerNames.join(','), // header row first
			...flatList.map( order => order.map( i => i.value && JSON.stringify(i.value).replace(/\\"/g, '""')).join(',') )
		].join('\r\n')

		console.log(csv)

   
		let title = 'Announcers-report'; 
		const blob = new File([csv], title + '.csv', { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);
	};

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.announcers.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'name':
          return compare(a.firstname + a.lastname, b.firstname + b.lastname, isAsc);
        case 'program':
          return compare(a.program.name, b.program.name, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'keywords':
          return compare(a.keywords, b.keywords, isAsc);
        case 'phone':
          return compare(a.phone, b.phone, isAsc);
        case 'expiry':
          return compare(a.expiry, b.expiry, isAsc);
        case 'merch':
          return compare(a.hasMerch, b.hasMerch, isAsc);
        case 'total':
          return compare(a.total, b.total, isAsc);
        case 'transaction':
          return compare(a.transaction[0].service, b.transaction[0].service, isAsc);
        case 'processed':
          return compare(a.processed, b.processed, isAsc);
        case 'updated':
          return compare(a.updated_at, b.updated_at, isAsc);
        default:
          return 0;
      }
    });
  }

  applyFilter(event: any, filter: string) {

    let filterFunction;
    let filterValue = 'value' in event ? event.value : (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase();

    switch(filter) {
      case 'id':
        if(!filterValue)
          delete this.filters.id;
        else 
          this.filters = { ...this.filters, id: filterValue};
        filterFunction = i => i.id.toString().includes(filterValue);
        break;
      case 'email':
        if(!filterValue)
          delete this.filters.email;
        else 
          this.filters = { ...this.filters, email: filterValue };
        filterFunction = i => i.email.includes(filterValue);
        break;
      case 'phone':
        if(!filterValue)
          this.filters.zedder && delete this.filters.zedder.phone;
        else 
          this.filters = { ...this.filters, zedder: { ...this.filters.zedder, phone: filterValue } };
        filterFunction = i => i.zedder.phone.includes(filterValue);
        break;
      case 'name':
        if(!filterValue)
          delete this.filters.name;
        else 
          this.filters = { ...this.filters, name: filterValue };
        filterFunction = i => (i.firstname  + ' ' + i.lastname).trim().toLowerCase().includes(filterValue);
        break;
      case 'program':
        if(!filterValue)
          this.filters.program && delete this.filters.program.name;
        else 
          this.filters = { ...this.filters, program: { ...this.filters.program, name: filterValue } };
        filterFunction = i => (i.program.name).trim().toLowerCase().includes(filterValue);
        break;
      case 'keywords':
        if(!filterValue)
          delete this.filters.keywords;
        else 
          this.filters = { ...this.filters, keywords: filterValue };
        filterFunction = i => i.keywords.toLowerCase().includes(filterValue);
        break;
      case 'merch':
        if(!filterValue)
          delete this.filters.hasMerch;
        else 
          this.filters = { ...this.filters, hasMerch: filterValue};
        filterFunction = i => i.hasMerch === (filterValue === 'true');
        break;
      case 'processed':
        if(!filterValue)
          delete this.filters.processed;
        else 
          this.filters = { ...this.filters, processed: filterValue};
        filterFunction = i => i.processed === filterValue;
        break;
      default:
        filterFunction = i => i;
    }

    console.log(this.filters)
    if(filterValue)
      this.sortedData = this.announcers.slice().filter( filterFunction )
    else
      this.sortedData = this.announcers.slice()

  }
}
