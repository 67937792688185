import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { SubtypesService } from '../subtypes.service';

export interface Interests {
  subtypeid: number;
  subtypecode: string;
  subtypedescription: string;
  subtypevalue: number;
  active: boolean;
}
 
@Component({
  selector: 'subtypes',
  templateUrl: './manage-subtypes.component.html',
  styleUrls: ['./manage-subtypes.component.scss']
})

export class ManageSubtypesComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    subtypeid: new UntypedFormControl({ value: 0, disabled: true }),
    subtypecode: new UntypedFormControl(''),
    subtypedescription: new UntypedFormControl(''),
    subtypevalue: new UntypedFormControl(0),
    active: new UntypedFormControl(false)
  });
  lastSort: Sort = { active: "subtypecode", direction: "asc" };
  editInterests: Interests = {subtypecode: '', subtypedescription: '', subtypeid: 0,  subtypevalue: 0, active: false};
  subCategories: Interests[] = [];
  sortedData: Interests[];
  displayedColumns: string[] = ['subtypeid', 'subtypecode', 'subtypedescription', 'subtypevalue', 'active', 'edit'];

  constructor(public SubtypesService: SubtypesService) {
    this.sortedData = this.subCategories.slice();
    // this.InterestsService = InterestsService;
    SubtypesService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'subtypeid':
          return compare(a.subtypeid, b.subtypeid, isAsc);
        case 'subtypecode':
          return compare(a.subtypecode, b.subtypecode, isAsc);
        case 'subtypevalue':
          return compare(a.subtypevalue, b.subtypevalue, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      subtypeid: el.subtypeid,
      subtypecode: el.subtypecode,
      subtypedescription: el.subtypedescription,
      subtypevalue: el.subtypevalue,
      active: el.active
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      subtypeid: 0,
      subtypecode: ''
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().subtypeid === 0) { //new
      let subsubtypecode: any = {};
      subsubtypecode.subtypecode = this.interestForm.value.subtypecode;
      this.SubtypesService.save(res => {
        this.SubtypesService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      
      this.SubtypesService.get(this.interestForm.getRawValue().subtypeid).subscribe( 
        subsubtypecode => {
          subsubtypecode.subtypecode =  this.interestForm.value.subtypecode;
          this.SubtypesService.update(subsubtypecode).subscribe(response => { 
            let interests : Interests[] = [ {
              subtypeid: response.subtypeid,
              subtypecode: response.subtypecode,
              subtypedescription: response.subtypedescription,
              subtypevalue: response.subtypevalue,
              active: response.active
            }]
      
            const existing = this.subCategories.reduce((a,t)=> (a[t.subtypeid] = t, a), {}),
            changed = interests.reduce((a,t)=> (a[t.subtypeid] = t, a), {})
      
            this.subCategories = Object.values({...existing, ...changed})
            // this.sortedData = this.subCategories.slice(); 
            this.sortData(this.lastSort);
          });
        }
      );
      
    }
   
  }
  
}
