<div class="delegation-panel">
	<mat-card style="width: 316px; border-radius: 4px;">
	<mat-card-header style="border-top-right-radius: 4px;
border-top-left-radius: 4px;">
		<mat-card-title>
			Studio Delegation
		</mat-card-title>
	</mat-card-header>
	<mat-card-content style="border-bottom-right-radius: 4px;
border-bottom-left-radius: 4px;">
		<div style="display: flex; flex-direction: row; gap: 4px;">
			<div class="stations">
				<div class="station">FM</div>
				<button mat-raised-button (click)="fmSelect('studio1')" [disabled]="delegation.fm.studio1.locked" [ngClass]="delegation.fm.studio1.active && delegation.fm.studio1.released ? 'red flash' : delegation.fm.studio1.active ? 'red' : 'grey'">Studio 1</button>

				<button mat-raised-button (click)="fmSelect('studio2')" [disabled]="delegation.fm.studio2.locked" [ngClass]="delegation.fm.studio2.active && delegation.fm.studio2.released ? 'red flash' : delegation.fm.studio2.active ? 'red' : 'grey'">Studio 2</button>

				<button mat-raised-button (click)="fmSelect('studio3')" [disabled]="delegation.fm.studio3.locked" [ngClass]="delegation.fm.studio3.active && delegation.fm.studio3.released ? 'red flash' : delegation.fm.studio3.active ? 'red' : 'grey'">Studio 3</button>

				<button mat-raised-button (click)="fmSelect('auto')" [disabled]="delegation.fm.auto.locked" [ngClass]="delegation.fm.auto.active && delegation.fm.auto.released ? 'yellow flash' : delegation.fm.auto.active ? 'yellow' : 'grey'">Automation</button>

				<button mat-raised-button (click)="fmSelect('external')" [disabled]="delegation.fm.external.locked" [ngClass]="delegation.fm.external.active && delegation.fm.external.released ? 'yellow flash' : delegation.fm.external.active ? 'yellow' : 'grey'">External</button>
			</div>

			<div class="stations">
				<div class="station">ZD</div>
				<button mat-raised-button (click)="zdSelect('studio1')" [disabled]="delegation.zd.studio1.locked" [ngClass]="delegation.zd.studio1.active && delegation.zd.studio1.released ? 'red flash' : delegation.zd.studio1.active ? 'red' : 'grey'">Studio 1</button>

				<button mat-raised-button (click)="zdSelect('studio2')" [disabled]="delegation.zd.studio2.locked" [ngClass]="delegation.zd.studio2.active && delegation.zd.studio2.released ? 'red flash' : delegation.zd.studio2.active ? 'red' : 'grey'">Studio 2</button>

				<button mat-raised-button (click)="zdSelect('studio3')" [disabled]="delegation.zd.studio3.locked" [ngClass]="delegation.zd.studio3.active && delegation.zd.studio3.released ? 'red flash' : delegation.zd.studio3.active ? 'red' : 'grey'">Studio 3</button>

				<button mat-raised-button (click)="zdSelect('auto')" [disabled]="delegation.zd.auto.locked" [ngClass]="delegation.zd.auto.active && delegation.zd.auto.released ? 'yellow flash' : delegation.zd.auto.active ? 'yellow' : 'grey'">Automation</button>

				<button mat-raised-button (click)="zdSelect('external')" [disabled]="delegation.zd.external.locked" [ngClass]="delegation.zd.external.active && delegation.zd.external.released ? 'yellow flash' : delegation.zd.external.active ? 'yellow' : 'grey'">External</button>
			</div>
		</div>
	</mat-card-content>
</mat-card>
</div>