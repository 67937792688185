  <mat-toolbar>
		<mat-toolbar-row>
			<h2 style="flex: 1">
				Bulk Orders
			</h2>
		</mat-toolbar-row>
	</mat-toolbar>

  <div style="display: flex; flex-direction: row; background-color: rgb(48,48,48); overflow: hidden; height: calc(100vh - 128px);" class="mat-typography">
      <div style="overflow-y: scroll;">
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 16px;">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 8px; width: 100%; padding: 16px 0; background-color: hsl(235, 27%, 25%);">
            <div class="number" style="background-color: hsl(235, 27%, 35%);">1</div>
            <ul>
              <li>Print on Canon Pixma TS9560</li>
              <li>Rear Feed Tray</li>
              <li>Address side of card facing you</li>
              <li>Card inserted rotated 180 degrees to the Canon preview screen</li>
              <li>Allow 45 mins until touch dry</li>
            </ul>

            <button mat-raised-button style="width: calc(100% - 32px);" (click)="fetchPrintableSubcards()" data-access-level='user' matTooltip="Bulk Subcards">
              PRINTABLE SUBCARDS
            </button>

            <div class="subcodes">
              <div (click)="cardIndex !== n ? cardIndex = n : cardIndex = -1" *ngFor="let subcode of subcodes; index as n" [style.opacity]="cardIndex >= n ? '0.5' : ''" [ngClass]="subcode.card">
              </div>
            </div>
            <div style="align-self: start;
            padding: 0 24px;">{{ subcodes.length > 0 ? subcodes.length + ' cards' : ''}}</div>
          </div>

          <!-- <div class="subcard sheet">
            <div [class.print]="key >= subcardOffset" (click)="setSubcardOffset(key)" *ngFor="let value of subcardPage; index as key"></div>
          </div>

          <ul>
            <li>Print on HP Laserjet</li>
            <li>Label side down in tray</li>
            <li>Top of page towards you</li>
            <li>Scale 100%</li>
            <li>Spray sheets with MATT SPRAY <em>after</em> printing</li>
          </ul>

          <button mat-raised-button style="width: calc(100% - 32px);" (click)="fetchBulkSubCards()" data-access-level='user' matTooltip="Bulk Subcards">
            BULK SUBCARDS
          </button> -->

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 16px; width: 100%; padding: 16px 0; background-color: hsl(255, 27%, 25%);">
            <div class="number" style="background-color: hsl(255, 27%, 35%);">2</div>
            <div class="label sheet">
              <div [class.print]="key >= labelOffset" (click)="setLabelOffset(key)" *ngFor="let val of labelPage; index as key"></div>
            </div>
            <ul>
              <li>Print on OKI or HP Laserjet</li>
              <li>Label side down in tray</li>
              <li>Top left of page in bottom right corner of tray</li>
              <li>Scale 100%</li>
            </ul>
            <button style="width: calc(100% - 32px);" (click)="fetchBulkEnvelopeLabels()" data-access-level='user' mat-raised-button matTooltip="Bulk Envelope Labels">
              BULK ENVELOPE LABELS
            </button>
          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 16px; width: 100%; padding: 16px 0; background-color: hsl(275, 27%, 25%);">
            <div class="number" style="background-color: hsl(275, 27%, 35%);">3</div>
            <ul>
              <li>Generating packing slip can take some time</li>
              <li>Click button once</li>
              <li>Please be patient</li>
            </ul>
            <button style="width: calc(100% - 32px);" (click)="fetchBulkPackingSlips()" data-access-level='user' mat-raised-button matTooltip="Bulk Packing Slip">
              BULK PACKING SLIPS
            </button>
          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 16px; width: 100%; padding: 16px 0; background-color: hsl(295, 27%, 25%);">
            <div class="number" style="background-color: hsl(295, 27%, 35%);">4</div>
            <ul>
              <li>Import the generated CSV into Australia Post's Bulk package tool</li>
              <li>Requires admin account to pay for postage</li>
            </ul>
            <button style="width: calc(100% - 32px);" (click)="fetchPackageCsv()" data-access-level='user' mat-raised-button matTooltip="Bulk packages for Auspost">
              AUSTPOST PACKAGE CSV
            </button>
          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 16px; width: 100%; padding: 16px 0; background-color: hsl(315, 27%, 25%);">
            <div class="number" style="background-color: hsl(315, 27%, 35%);">5</div>
            <ul>
              <li>Changes status of all <em>CHECKED</em> orders to <em>PROCESSING</em></li>
              <li>A CSV file will be produced with an index of all orders affected</li>
            </ul>
            <button style="width: calc(100% - 32px);" (click)="moveCheckedToProcessing()" data-access-level='user' mat-raised-button matTooltip="Mark checked as processing">
              MARK AS PROCESSING
            </button>
          </div>
        </div>
    
  
      </div>
      <div style="width: 100%; padding: 6px 0;">
        <embed [src]="content" style="width:100%; height: 100%;" />
      </div>
  
  </div>