<mat-toolbar>
	<h2>Save grid</h2>
	<span style="flex: 1"></span>
	<mat-icon mat-dialog-close="abort">close</mat-icon>
</mat-toolbar>

<mat-dialog-content [formGroup]="gridForm" style="display: flex; flex-direction: column;" >
	<mat-form-field class="full-width">
		<mat-label>Grid Title</mat-label>
		<input matInput formControlName="name">
	</mat-form-field>

	<mat-form-field>
		<mat-label>Start Date</mat-label>
		<input matInput [matDatepicker]="startPicker" formControlName="start_date">
		<mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
		<mat-datepicker #startPicker></mat-datepicker>
	</mat-form-field>

	<mat-form-field>
		<mat-label>End Date</mat-label>
		<input matInput [matDatepicker]="endPicker" formControlName="end_date">
		<mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
		<mat-datepicker #endPicker></mat-datepicker>
	</mat-form-field>

	<mat-form-field>
		<mat-label>Station</mat-label>
		<mat-select formControlName="station">
			<mat-option [value]=""></mat-option>
			<mat-option *ngFor="let station of stations" [value]="station.id">{{station.station}}</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-checkbox formControlName="draft">Draft</mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions align="end">

	<button *ngIf="renamed" mat-button [mat-dialog-close]="{ action: 'saveAs', data: gridForm.value }">
		Save New Copy
	</button>

	<button color="primary" *ngIf="renamed" mat-raised-button [mat-dialog-close]="{ action: 'rename', data: gridForm.value }">
		Rename & Save
	</button>

	<button color="primary" *ngIf="!renamed" mat-button [mat-dialog-close]="{ action: 'save', data: gridForm.value }">
		Save
	</button>
</mat-dialog-actions>