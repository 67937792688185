<div class="background-overlay" *ngIf="playlistVisible ||  infoVisible" (click)="closeOverlay()"></div>
<audio id="player" #player></audio>
<div *ngIf="playlistState.visible"  style="min-height: 48px; padding: 0;" [style.background-color]="playlistVisible ? 'rgb(23, 22, 22)' : 'transparent'" [class.info-overlay]=" playlistVisible ||  infoVisible" >
	<!-- PLAYLIST -->
	<div *ngIf="playlistVisible">
		<mat-toolbar style="background-color: #171616;">
			<div style="flex: 1">Playlist</div>
			<button mat-icon-button (click)="shuffle()">
				<mat-icon>
					shuffle
				</mat-icon>
			</button>
			<a mat-icon-button (click)="downloadPlaylist()" mat-icon-button [href]="fileUrl" download="playlist.m3u">
				<mat-icon>save_alt</mat-icon>
			</a>
			<button mat-icon-button (click)="togglePlaylist()">
				<mat-icon>
					close
				</mat-icon>
			</button>
		</mat-toolbar>
		<div id="playlist" style="position: relative; top: 0; left: 0px; height: 60vh; width: 100%; overflow: auto; font-size: 10pt;">
			<div id="playlist-scroll" 
			cdkDropList (cdkDropListDropped)="drop($event)" style="display: flex; flex-direction: column;">
				<div *ngFor="let track of playlist.playlist; index as key" cdkDrag [ngClass]="playlistClass(key)" class="track">
					<div style="display: flex; flex-direction: row; padding: 8px; gap: 8px;">
						<div>{{key + 1}}</div>
						<div><a class="track-text" (click)="jumpTo(key)"><span>{{track.artist && track.artist.name ? track.artist.name : track.artist }}</span> <span>{{track.title}}</span></a> </div>
						<div style="flex: 1"></div>
						<mat-icon class="delete" (click)="deleteTrack(track, key)" style="font-size: 14px; text-align: center" aria-label="delete">
							delete
						</mat-icon>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<!-- RELEASE INFO -->
	<div style="position: relative; top: 0; left: 0px; max-height: 60vh; width: 100%; overflow: auto; font-size: 10pt;" *ngIf="release && release.library_no && infoVisible">
		<app-album-info [info]="release" [release]="click" [play]="play"></app-album-info>
	</div>

	<!-- PLAYER -->
	<div class="player" style="flex: 1">
		<div class="player-container" style="display: flex;" >
			<div class="transport" style="display: flex; gap: 8px;">
				<button mat-icon-button (click)="prev()">
					<mat-icon>skip_previous</mat-icon>
				</button>
				<button mat-icon-button (click)="playState === 'playing' ? player.pause() : player.play()">
					<mat-icon>{{ playState === 'playing' ? 'pause' : 'play_arrow' }}</mat-icon>
				</button>
				<button mat-icon-button (click)="next()">
					<mat-icon>skip_next</mat-icon>
				</button>
				<button [style.background-color]="playlistVisible ? 'white' : ''" [style.color]="playlistVisible ? 'black' : ''" mat-icon-button (click)="togglePlaylist()">
					<mat-icon ng-style="playlistVisible ? { 'color': 'black'} : {}">
						playlist_play
					</mat-icon>
				</button>
				<div style="font-size: 10px; line-height: 48px; flex: 1;">
					{{currentTime | hms}}
					<mat-slider style="width: calc(100% - 78px); margin-top: -2px;" min="0" max="100" step="0.1" aria-label="red" (click)="what($event)" id="red-slider">
						<input matSliderThumb [(ngModel)]="posPercent" #slider>
					</mat-slider>
					{{duration | hms}}
				</div>
			</div>
			<div class="details" style="display: flex; flex: 1;">
				<div class="warnings" flex="nogrow">
					<button class="mini tool-icon" mat-icon-button (click)="languageWarning(track)" matTooltip="Language Warning">
						<mat-icon [ngStyle]="track.language_warning ? { color: 'rgb(163, 105, 81)' } : { color: '#444' }" [svgIcon]="track.language_warning ? 'emoticon-poop' : 'emoticon-poop-outline'" style="font-size: 14px; line-height: 22px; text-align: center; "></mat-icon>
					</button>
					<button class="mini" mat-icon-button (click)="contentWarning(track)" matTooltip="Content Warning">
						<mat-icon [ngStyle]="track.content_warning ? { color: '#f1bf28' } : {color: '#444' }" style="font-size: 14px; line-height: 22px">{{ track.content_warning ? 'report_problem' : 'change_history'
						}}</mat-icon>
					</button>
					<button class="mini" mat-icon-button (click)="favourited = ! favourited; favourite(track)" matTooltip="Favourite">
						<mat-icon [ngStyle]="favourited ? { color: '#f1bf28' } : {color: '#444' }" [class.favourite]="favourited" style="font-size: 16px; line-height: 22px">{{ favourited ? 'star' : 'star_border' }}</mat-icon>
					</button>
				</div>
				<div class="artist-title">
					<div>
						{{track.artist}} - {{track.title}}
					</div>
				</div>
			</div>

			<!-- <mat-icon (click)="player.volume -= 0.1">volume_down</mat-icon>
			<mat-icon (click)="player.volume += 0.1">volume_up</mat-icon>
			{{playState}} -->
		</div>
		<div style="
			align-items: center;
			justify-content: center;
			display: flex;
			margin: 0; flex: 0 1 auto;
		">
			
			<img class="artwork" *ngIf="track.library_no" (click)="getInfo(track.library_no)" [src]="musicDBapiSrc + '/coverArt/150/' + track.library_no" />
		</div>
	</div>
</div>
