<h2 mat-dialog-title>Program <em>#{{data.program.id}}</em></h2>
<mat-dialog-content [formGroup]="programForm">
	<mat-form-field class="full-width">
		<mat-label>Name</mat-label>
		<input matInput formControlName="name">
	</mat-form-field>
	<mat-form-field class="full-width">
		<mat-label>Slug</mat-label>
		<input matInput formControlName="slug">
	</mat-form-field>
	<mat-form-field class="full-width">
		<mat-label>Presenters</mat-label>
		<input matInput formControlName="presenters">
	</mat-form-field>
	<div style="display: flex; flex-direction: row; gap: 8px;">
		<mat-form-field class="full-width">
			<mat-label>Duration</mat-label>
			<input type="number" matInput formControlName="duration">
		</mat-form-field>
		<mat-form-field class="full-width">
			<mat-label>Studio Preference</mat-label>
			<input type="number" matInput formControlName="studio">
		</mat-form-field>
	</div>

	<mat-form-field class="full-width">
		<mat-label>RDS Category</mat-label>
		<mat-select formControlName="rdscat">
			<mat-option *ngFor="let cat of rdsCats" [value]="cat">
				{{cat}}
			</mat-option>
		</mat-select>
		<mat-hint>Choose closest, this is for people with RDS FM tuners</mat-hint>
	</mat-form-field>

	<mat-form-field class="full-width">
		<mat-label>Keywords</mat-label>
		<mat-chip-grid #keywordsChip aria-label="Keywords">
			<mat-chip-row *ngFor="let keyword of programForm.get('keywords')!.value"
										(removed)="removeKeyword(keyword)"
				>
					{{keyword.tag}}
				<button matChipRemove [attr.aria-label]="'remove ' + keyword.tag">
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
		</mat-chip-grid>
		<input placeholder="Search Keywords..."
			#keywordInput
			[formControl]="keywordControl"
			[matAutocomplete]="keywordComplete"
			[matChipInputFor]="keywordsChip"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(matChipInputTokenEnd)="add($event)"
			/>
		<mat-autocomplete 
			autoActiveFirstOption 
			#keywordComplete="matAutocomplete" 
			(optionSelected)="selectedKeyword($event)">
			<mat-option *ngFor="let keyword of keywordOptions | async" [value]="keyword">
				{{keyword.tag}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

	<mat-form-field class="full-width">
		<mat-label>Announcers</mat-label>
		<mat-chip-grid #announcerChip aria-label="Announcer">
			<mat-chip-row *ngFor="let annoucer of programForm.get('announcers')!.value"
										(removed)="removeAnnouncer(annoucer)"
				>
				{{annoucer.firstname}} {{annoucer.lastname}} {{annoucer.expiry}}
				<button matChipRemove [attr.aria-label]="'remove ' + annoucer.name">
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
		</mat-chip-grid>
		<input placeholder="Search Announcers..."
			#announcerInput
			[formControl]="announcerControl"
			[matAutocomplete]="announcerComplete"
			[matChipInputFor]="announcerChip"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			/>
		<mat-autocomplete 
			autoActiveFirstOption 
			#announcerComplete="matAutocomplete" 
			(optionSelected)="selectedAnnouncer($event)">
			<mat-option *ngFor="let annoucer of announcerOptions | async" [value]="annoucer">
				{{annoucer.firstname}} {{annoucer.lastname}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

	<mat-form-field class="full-width">
		<mat-label>Backup Announcers</mat-label>
		<mat-chip-grid #backupChip aria-label="Announcer">
			<mat-chip-row *ngFor="let announcer of programForm.get('backup')!.value"
										(removed)="removeBackup(announcer)"
				>
				{{announcer.firstname}} {{announcer.lastname}} {{announcer.expiry}}
				<button matChipRemove [attr.aria-label]="'remove ' + announcer.name">
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
		</mat-chip-grid>
		<input placeholder="Search Backup Announcers..."
			#backupInput
			[formControl]="backupControl"
			[matAutocomplete]="backupComplete"
			[matChipInputFor]="backupChip"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			/>
		<mat-autocomplete 
			autoActiveFirstOption 
			#backupComplete="matAutocomplete" 
			(optionSelected)="selectedBackup($event)">
			<mat-option *ngFor="let annoucer of backupOptions | async" [value]="annoucer">
				{{annoucer.firstname}} {{annoucer.lastname}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="abort">Cancel</button>
  <button *ngIf="!saved" mat-button (click)="saveProgram()" cdkFocusInitial>Save</button>
	<button *ngIf="saved" mat-button [mat-dialog-close]="programForm.value" cdkFocusInitial>Save</button>
</mat-dialog-actions>