<mat-toolbar>
  <span *ngIf="hot100s" flex>Hot100 Archive</span>
  <span *ngIf="!hot100s">{{ type === 'releases/browse' ? 'Hotbin' : 'Archive'}} {{ range ? 'Range' : ''}} {{dateString}}{{ range ? ' - ' : ''}}{{endDateString}}</span>
  <span style="flex: 1"></span>
  <button *ngIf="type === 'releases/archive'" (click)="setHot100s()" matTooltip="Hot100 Archive" mat-icon-button aria-label="Hot100 Archive">
    <mat-icon>mood</mat-icon>
  </button>
  <button [color]="sort === 'ALPHA' ? 'primary' : ''" [class.mat-mdc-mini-fab]="sort === 'ALPHA'" (click)="toggleSort()" matTooltip="Sort by Alpha" mat-icon-button aria-label="Sort by Alpha">
    <mat-icon>sort_by_alpha</mat-icon>
  </button>
  <button (click)="exportCSV()" matTooltip="Export month as spreadsheet" mat-icon-button aria-label="export month as spreadsheet">
    <mat-icon>table_chart</mat-icon>
  </button>
  <button [color]="showFilters ? 'primary' : ''" (click)="toggleFilters()" matTooltip="Filter by Genre" mat-icon-button aria-label="Filter by Genre">
    <mat-icon>filter_alt</mat-icon>
  </button>
</mat-toolbar>

<div class="mat-typography">

  <!-- <p>Selected date: {{selected}}</p>
  <p>Date string: {{dateString}} / {{dateString | lowercase}}</p>
  <p>Start: {{startDate | date}} / End: {{endDate | date }}</p> -->

  <section *ngIf="hot100s && hot100tracks.length">
    <app-album-track-list [order]="order" [tracks]="hot100tracks" [play]="play"></app-album-track-list>

    <!-- <album-track-list tracks="hot100tracks" play="play"></album-track-list> -->
    <mat-tab-group (selectedTabChange)="hot100Select($event)" [selectedIndex]="selectedIndex">
      <mat-tab *ngFor="let tab of hot100tabs"
              [disabled]="tab.disabled"
              [label]="tab.title">
      </mat-tab>
    </mat-tab-group>
    <div class="cover-grid">
      <div *ngFor="let item of hot100YearTracks" style="width: 150px; padding: 5px;">
        <div (click)="albumListing(item, $event)" [style.background-image]="'url( ' + musicDBapiSrc +'/coverArt/150/' + item.library_no  + '), url(/assets/vinyl_record.svg)'" class="cover" [class.aotw]="item.aotw" [class.has-embargo]="item.embargo">
          <div *ngIf="!item.title_id && !item.library_no" class="embargo">
            <div>
              <div style="display: inline; font-size: 14px; line-height: 19px; vertical-align: top;">NO MEDIA</div>
            </div>
          </div>
          <div *ngIf="!item.title_id && item.library_no" class="embargo">
            <div>
              <div style="display: inline; font-size: 14px; line-height: 19px; vertical-align: top;">VINYL</div>
            </div>
          </div>

          <div class="bg">
            <mat-icon svgIcon="mairlist" *ngIf="onAir" (click)="appendTrack(item)" style="color: #fa8817; min-width: 24px; min-height: 14px; height: 14px; width: 14px; padding-bottom: 3px;"></mat-icon>
            <mat-icon class="favourite" (click)="favourite(item,  $event); item.fav = !item.fav; $event.stopPropagation()" >{{ item.fav ? 'star' : 'star_border' }}</mat-icon>
            <mat-icon class="play" (click)="play(item, $event); albumListing(item, $event)">play_circle_filled</mat-icon>
            <mat-icon  mat-icon-button [matMenuTriggerFor]="coverMenu" class="more" aria-label="more" (click)="$event.stopPropagation()">more_horiz</mat-icon>
            <mat-menu #coverMenu="matMenu" (click)="$event.stopPropagation()">
              <button mat-menu-item (click)="queue(item.artist_nm, item.title, item.library_no)"> 
                Add to queue
              </button>
              <button mat-menu-item disabled="disabled" (click)="addToPlayList()">
                Add to playlist
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="release(item.library_no)">
                Goto release
              </button>
            </mat-menu>
          </div>
        </div>
        <span style="padding-right: 8px;"><b><i>#{{item.tracknum}}</i></b></span><span><b>{{item.artistName}}</b></span><br />
        {{item.title}} 
      </div>
    </div>
  </section>

  <section *ngIf="showFilters && !hot100s">
    <div style="display: flex; flex-wrap: wrap; padding: 8px 16px;">
      <span (click)="filterReleases(item, $event)" [class.selected]="item.selected" class="genre-tag" *ngFor="let item of genreTags">{{item.genre}}<span class="badge">{{item.count}}</span></span>
    </div>
  </section>
  
  <section *ngIf="filtered.length && !hot100s">
    <div style="display: flex; position: sticky; align-items: center;
    top: 0;
    margin: 0;
    z-index: 150;
    background-color: #353535;">
      <h3 style="flex: 1">
        Filtered

      </h3>
      <button (click)="playFiltered()" mat-icon-button matTooltip="Playlist">
        <mat-icon>playlist_play</mat-icon>
      </button>
    </div>
		<app-cover-player [showYear]="showYear" [releases]="filtered" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>

  <section *ngIf="aotw.length && !hot100s">
    <h3>
      Album Of The Week 
    </h3>

		<app-cover-player [showYear]="showYear"[releases]="aotw" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>


  <section *ngIf="bna.length && !hot100s">
    <h3>
      Best New Arrivals 
    </h3>

		<app-cover-player [showYear]="showYear" [releases]="bna" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>

  <section *ngIf="local.length && !hot100s">
    <h3>
       Local
    </h3>

		<app-cover-player [showYear]="showYear" [releases]="local" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>

  <section *ngIf="ausnz.length && !hot100s">
    <h3>
       Australia / New Zealand
    </h3>

		<app-cover-player [showYear]="showYear" [releases]="ausnz" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>

  <section *ngIf="indigenous.length && !hot100s">
    <h3>
      First Nations / Indigenous
    </h3>

		<app-cover-player [showYear]="showYear" [releases]="indigenous" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>

  <section *ngIf="os.length && !hot100s">
    <h3>
      Overseas
    </h3>

		<app-cover-player [showYear]="showYear" [releases]="os" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>

  <section *ngIf="female.length && !hot100s">
    <h3>
      Female
    </h3>

		<app-cover-player [showYear]="showYear" [releases]="female" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>
 
  <section *ngIf="nbgf.length && !hot100s">
    <h3>Gender Diverse</h3>
    <h4>Gender Fluid / Non-Binary / Transgender / Agender / Gender Non Conforming</h4>

		<app-cover-player [showYear]="showYear" [releases]="nbgf" [release]="click" [play]="play" [queue]="queueRelease" [more]="more" [favourite]="favourite" [selection]="selection"></app-cover-player>
  </section>

</div>

<button class="month-button" (click)="isOpen = !isOpen" mat-fab color="warn" cdkOverlayOrigin #trigger="cdkOverlayOrigin" aria-label="Show month calendar">
  <mat-icon>date_range</mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (overlayOutsideClick)="closeOverlay($event)"
>

  <mat-card class="calendar-card" [style.width]="range ? '600px' : ''">
    <mat-card-header style="position: sticky; top: 0; ">
      <mat-card-subtitle style="display: flex; padding: 8px; width: 100%">
        <button mat-button [class.bold]="!range" (click)="range = false; start = false;">Hot Bin Month</button>
        <div style="flex: 1"></div>
        <button mat-button [class.bold]="range" (click)="range = true; start = true;">Range</button>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="overflow: scroll; display: flex">
      <ng-container *ngIf="!range">
        <app-month-calendar style="flex: 1" (dateChanged)="dateChanged($event)" [selectedDate]="selected"/>
      </ng-container>
      <ng-container *ngIf="range">
        <app-month-calendar style="flex: 1" (dateChanged)="startDateChanged($event)" [selectedDate]="selectedStart" />
        <app-month-calendar style="flex: 1" (dateChanged)="endDateChanged($event)" [selectedDate]="selectedEnd" />
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-template>