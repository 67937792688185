import { Component, ElementRef, Inject, Input, ViewChild, inject } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Countries, ProgramsShort, Subtypes } from '../zeddb.types';
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers'
import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { Transition } from "@uirouter/angular";
import { E } from '@angular/cdk/keycodes';
import { MatChipEditedEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ContactService } from '../contact.service';
import { CountriesService } from '../countries.service';
import { SubtypesService } from '../subtypes.service';
import { PrizesService } from '../prizes.service';
import { ProgramsService } from '../programs.service';
import { ContactCategoriesService } from '../contact-categories.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

export interface Programs {
  programid: number,
  programname: string;
}

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent {
  // @Input() contact: any = { contact: { id: 0 } };
  
  @ViewChild('categoryInput')
  categoryInput!: ElementRef<HTMLInputElement>;

  constructor(
    public ContactService: ContactService,
    public CountriesService: CountriesService,
    public SubtypesService: SubtypesService, 
    public PrizesService: PrizesService,
    public ProgramsService: ProgramsService,
    public ContactCategoriesService: ContactCategoriesService,
    // @Inject('$rootScope') private $rootScope: any,
    public location: Location,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    // protected trans: Transition,
  ){

    CountriesService.query().subscribe(response => { 
      this.countries = response; 
    });
    ContactCategoriesService.query().subscribe(response => { 
      this.contactCats = response; 
    });

    this.lockObservable.subscribe( value => {
      // console.log('lock: ', value)
      this.formDisabled = value;
      if (value) {
        this.contactForm.disable();
        this.categoryControl.disable();

      }
      else {
        this.contactForm.enable();
        this.categoryControl.enable();
      }
    })

    this.contactForm.valueChanges.subscribe( () => {
      console.log('CHANGED!', this.contactForm)
      this.contact = Object.assign(this.contact, this.contactForm.value);
      this.formEdited = true;
    });


    this.categoryOptions = this.categoryControl.valueChanges.pipe( 
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchCategories(name) : this.contactCats.slice())
    )
  }
  
  ngOnInit() {

    this.route.data.subscribe(({ contact }) => {
      this.contact = contact;
      this.id =  this.route.snapshot.paramMap.get('id');

      if (this.id === 'search') {
        let searchString = this.route.snapshot.fragment;
        if (searchString && searchString !== '') {
          this.searchString = searchString;
          this.http.post<any>(environment.apiSrc + '/contactSearch', { searchString: searchString }).subscribe(data => {
            this.searchResults = data;
            this.sortData(this.lastSort);
          })
        }
        this.contactForm.reset();
      }

      if (this.id === 'search' || this.id === 'new') {
        this.lockObservable.next(false);
      }
      else {
        this.searchResultsSelection = {};
        this.populateContact();
        this.lockObservable.next(false);
      }

    })


  }

  get refForm() {
    return this.contactForm.get('individuals') as FormArray;
  }

  contact: any = { contact: { id: 0 } };
  // params = this.trans.params();
  id: any = this.route.snapshot.paramMap.get('id');

  contactCats: any[] = [];
  countries: Countries[] = [];

  submitLocked = false;
  formEdited = false;
  contactForm = new UntypedFormGroup({
    org_nm: new UntypedFormControl(null), 
    individuals:  new UntypedFormArray([]),
    notes: new UntypedFormControl(null), 
    work_ph: new UntypedFormControl(null), 
    email: new UntypedFormControl(null), 
    website: new UntypedFormControl(null), 
    org_abn: new UntypedFormControl(null), 
    contactcategories: new UntypedFormControl([]),
    address:  new UntypedFormGroup({
      address: new UntypedFormControl(null), 
      locality: new UntypedFormControl(null), 
      region: new UntypedFormControl(null), 
      country: new UntypedFormControl(null), 
      postcode: new UntypedFormControl(null), 
    }),
    log: new UntypedFormControl(null), 
  });

  searchForm = new UntypedFormGroup({
    contactname: new UntypedFormControl(null), 
    createdDateStart: new UntypedFormControl(null), 
    createdDateEnd: new UntypedFormControl(null), 
    releaseYearStart: new UntypedFormControl(null), 
    releaseYearEnd: new UntypedFormControl(null),
  });
  
  lockObservable = new Subject<boolean>();
  formDisabled = false; 

  searchString = '';
  searchResults: any = [];
  searchResultsSelection: any = {}
  lastSort: Sort = { active: 'id', direction: '' };
  sortedData: any[] = [];
  displayedColumns: string[] = ['id', 'name', 'email', 'phone'];

  announcer = inject(LiveAnnouncer);
  categoryControl = new UntypedFormControl('');
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  categoryOptions!: Observable<any>;

  saveContact = () => {
    console.log(this.contact)
    this.submitLocked = true;
    if(this.id === 'new') {
      this.ContactService.save(this.contact).subscribe( (contact) => {
        this.contact.contact_no = contact.contact_no
        this.submitLocked = false;
        if(this.contact.contact_no)
          this.router.navigateByUrl('/contacts/' + this.contact.contact_no);
      });
    }
    else {
      this.ContactService.update( this.contact ) .subscribe(() => {
        this.submitLocked = false;
      });
    }
    
  }

  searchCategories = (query) => {
    var results = query ? this.contactCats.filter((category) => { return category.category.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  searchContacts = () => {
    // remove null / undefined / empty arrays
    const reducer = (obj) => Object.entries(obj).reduce((a,[k,v]) => (v === false || v == null || v === '' || ( v instanceof Array && v.length === 0 ) ? a : (a[k]= v instanceof Object && !(v instanceof Array) ? reducer(v) : v ,  a)), {})
    
    let searchFrom; Object.entries( this.searchForm.value ).forEach( ([k,v]) => searchFrom = { ...searchFrom, [k]: v instanceof Object && v['_isAMomentObject'] ? (v as any).format('YYYY-MM-DD') : v } );

    let searchTerms = reducer( { ...this.contact, ...searchFrom })

    delete searchTerms['delivery_address'];
    delete searchTerms['billing_address'];
    delete searchTerms['pledge'];

    console.log(searchTerms)

    this.http.get<any>(environment.apiSrc + '/subscribers', { params: searchTerms }).subscribe( data => { 
      this.searchResults = data;
      this.sortData(this.lastSort);
    })
  }

  removeMember = (index) => {
    let individual = this.contactForm.get('individuals') as FormArray;
    individual.removeAt(index);
  }
  addMember = () => {
    let individual = this.contactForm.get('individuals') as FormArray;
    individual.push(new UntypedFormGroup({
      individual_id: new UntypedFormControl(null),
      name: new UntypedFormControl(null), 
      position_title: new UntypedFormControl(null), 
      email: new UntypedFormControl(null),
      mobile_phone: new UntypedFormControl(null),
      work_phone: new UntypedFormControl(null),
      notes: new UntypedFormControl(null),
    }))
  }

  quickSearch = (event) => {
    let searchString = event.target.value;
    this.searchString = searchString;
    this.router.navigateByUrl('/contacts/search#' + searchString);
    this.http.post<any>(environment.apiSrc + '/contactSearch', { searchString: searchString }).subscribe(data => {
      this.searchResults = data;
      this.sortData(this.lastSort);
    })
  }

  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    this.fetchContact(row.contact_no);
  };


  activeSub = (sub) => new Date(sub) > new Date();

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.searchResults.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.subid, b.subid, isAsc);
        case 'name':
          return compare(a.sublastname + a.subfirstname, b.sublastname + b.subfirstname, isAsc);
        case 'email':
          return compare(a.subemail, b.subemail, isAsc);
        case 'subnumber':
          return compare(a.subsubnumber, b.subsubnumber, isAsc);
        case 'expiry':
          return compare(a.expirydate, b.expirydate, isAsc);
        default:
          return 0;
      }
  });
  }


  goto = (id) => {
    this.lockObservable.next(false);
    this.searchResultsSelection = {};
    this.router.navigateByUrl('/contacts/' + id);
  }

  fetchContact = (id: number) => {
    this.ContactService.get(id).subscribe( res => {
      this.contact = res;
      this.populateContact();
      this.lockObservable.next(true);
    });
  }

  populateContact = () => {
    this.contactForm.patchValue({
      org_nm: this.contact.org_nm, 
      notes: this.contact.notes, 
      work_ph: this.contact.work_ph, 
      email: this.contact.email, 
      website: this.contact.website, 
      org_abn: this.contact.org_abn, 
      address: {
        address: this.contact.street_nm && ( this.contact.street_no ? this.contact.street_no + ' ' : '') + this.contact.street_nm, 
        locality: this.contact.suburb_nm, 
        region: this.contact.state, 
        country: this.contact.country && this.contact.country.substring(0,3), 
        postcode: this.contact.postcode, 
      },
      contactcategories: this.contact.contactcategories ? this.contact.contactcategories : [],
      log: this.contact.log, 
    }, { emitEvent: false });
  
    let individuals = this.contactForm.get('individuals') as FormArray;
    individuals.clear({ emitEvent: false });

    this.contact.individuals.forEach(i => 
      individuals.push(new UntypedFormGroup({
        individual_id: new UntypedFormControl(i.individual_id),
        name: new UntypedFormControl(i.name), 
        position_title: new UntypedFormControl(i.position_title), 
        email: new UntypedFormControl(i.email),
        mobile_phone: new UntypedFormControl(i.mobile_phone),
        work_phone: new UntypedFormControl(i.work_phone),
        notes: new UntypedFormControl(i.notes),
      })
      , { emitEvent: false }))

    if(this.contact.contact_nm && !this.contact.individuals.some( a => a.name.toLowerCase === this.contact.contact_nm.toLowerCase ) ) {
      individuals.push(new UntypedFormGroup({
        individual_id: new UntypedFormControl(null),
        name: new UntypedFormControl(this.contact.contact_nm), 
        position_title: new UntypedFormControl(null), 
        email: new UntypedFormControl(this.contact.email),
        mobile_phone: new UntypedFormControl(this.contact.mobile_ph),
        work_phone: new UntypedFormControl(this.contact.work_ph),
        notes: new UntypedFormControl(null),
      })
      , { emitEvent: false } );
    }
  }

  removeCategory(category: any, ): void {
    const index = this.contactForm.get('contactcategories')!.value.indexOf(category);

    if (index >= 0) {
      this.contactForm.get('contactcategories')!.value.splice(index, 1);

      this.announcer.announce(`Removed Category ${category}`);
    }
    this.contactForm.updateValueAndValidity();
  }

  editCategory(category: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeCategory(category);
      return;
    }

    // Edit existing fruit
    const index = this.contactForm.get('contactcategories')!.value.indexOf(category);
    if (index >= 0) {
      this.contactForm.get('contactcategories')!.value[index].theme_desc = value;
    }
    this.contactForm.updateValueAndValidity();
  }

  selectedCategory(event: any): void {
    let category = event.option.value;
    this.contactForm.get('contactcategories')!.value.push({ id: category.id, category: category.category});
    this.categoryInput.nativeElement.value = '';
    this.categoryControl.setValue('');
    this.contactForm.updateValueAndValidity();
  }
}

