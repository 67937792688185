<h2 mat-dialog-title>Live Reads</h2>

<mat-dialog-content>
<div *ngFor="let liveRead of data.liveReads">
	<div style="padding: 1em; font-size: 125%; line-height: 160%">
		<p style="font-size: 75%; color: #aaa; float: right">{{liveRead.commence_date}} - {{liveRead.complete_date}}</p>
		<h3>{{liveRead.title}}</h3>
		<p [innerHTML]="liveRead.script"></p>
	</div>
</div>
</mat-dialog-content>
