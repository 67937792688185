import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { PlaylistService } from '../playlist.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { SessionService } from '../session.service';
import { FavouriteService } from '../favourite.service';
import { throttle } from 'lodash';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';

interface Playlist {
  id: string;
  playlist: any[];
}
@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent {
  @ViewChild('player')
  player!: ElementRef<HTMLAudioElement>;

  constructor( 
    private http: HttpClient, 
    private playerPlaylist: PlaylistService,
    private location: Location,
    private SessionService: SessionService,
    private FavouriteService: FavouriteService,
		private router: Router,
		private renderer: Renderer2
  ) {

    this.playerPlaylist.onMessage().subscribe( data => {
      console.log("OOOOOOH")
      console.log(data)
      if(data.action) {
        switch(data.action){
					case 'queueTempAndPlay':
            console.log(data.value);
            this.playlist.playlist = this.playlist.playlist.concat(data.value.playlist);
            this.playlistState.visible = true;
						this.playlistState.position = this.playlist.playlist.length - 1;
						this.playlistState.playing = true;
            this.play(this.playlist.playlist[this.playlistState.position]);
            break;
					case 'queueSingleAndPlay':
            console.log(data.value);
            this.playlist.playlist = this.playlist.playlist.concat(data.value.playlist);
            this.playlistState.visible = true;
						this.playlistState.position = this.playlist.playlist.length - 1;
						this.playlistState.playing = true;
            this.play(this.playlist.playlist[this.playlistState.position]);
            break;
          case 'addAndPlay':
            console.log(data.value);
            this.playlist = data.value;
            this.playlistState.visible = true;
            this.playlistState.position = 0;
            this.playlistState.playing = true;
            this.play(this.playlist.playlist[this.playlistState.position]);
            break;
          case 'addToQueue':
            console.log(data.value);
            this.playlist.playlist = this.playlist.playlist.concat(data.value.playlist);
            this.playlistState.visible = true;
            break;
          case 'next':
            if (this.playlist.playlist.length >= this.playlistState.position + 1) {
              this.playlistState.position++;
              this.playlistState.playing = true;
              this.play(this.playlist.playlist[this.playlistState.position]);
            }
            break;
          case 'prev':
            if (this.playlistState.position - 1 >= 0) {
              this.playlistState.position--;
              this.playlistState.playing = true;
              this.play(this.playlist.playlist[this.playlistState.position]);
            }
            break;
          case 'jumpTo':
            this.playlistState.position = data.value;
            this.playlistState.playing = true;
            this.play(this.playlist.playlist[this.playlistState.position]);
            break;
					case 'toggleVisibility': 
						this.playlistState.visible = !this.playlistState.visible;
						break;
          default:
            console.log('and what')
        }
      }

			if(this.playlistState.visible) {
				this.renderer.addClass(document.body, 'has-player')
			}
      // this.value = PlaylistService.value;
      // console.log(PlaylistService.value);
    });

   this.FavouriteService.onMessage().subscribe( data =>  {
      if (data.value === 'addFavourite' || data.value === 'update'){
          let favtemp: any = SessionService.get('favourites');
          favtemp =	JSON.parse(favtemp);
          if (this.playlist.id === favtemp.id) {
            let current: any = this.playlist.playlist[this.playlistState.position]
            let position = favtemp.playlist.map(function(o) { return o.title_id; }).indexOf(current.title_id);
            this.playlistState.position = position;
            this.playlist = favtemp
          }
  
      }
    });

  }

  ngAfterViewInit() {

    const player = this.player.nativeElement;

    player.addEventListener("timeupdate", throttle(  event => {

      this.posPercent = (player.currentTime / player.duration) * 100;
      this.currentTime = player.currentTime;
    }, 1000 ))

    player.addEventListener("pause", () => {
      console.log('paused?')
      this.playState = 'paused'
    })

    player.addEventListener("playing", () => {

      this.mediaSession.setActionHandler('play', async () => {
        // Resume playback
        await player.play();
      });
    
      this.mediaSession.setActionHandler('pause', () => {
        // Resume playback
        player.pause();
      });
    
      this.mediaSession.setActionHandler('previoustrack', () => {
        this.prev();
      });
    
      this.mediaSession.setActionHandler('nexttrack', () => {
        this.next();
      });
    
      this.mediaSession.setActionHandler('seekto', (details) => {
        player.currentTime = Number(details.seekTime);
      });
      
    });


    player.addEventListener("ended", () => {
      if(this.playlistState.playing) {
        this.playlistState.position++,
        this.playerPlaylist.sendMessage({action: 'refresh' });
        this.play(this.playlist.playlist[this.playlistState.position])
      }
    })

    player.addEventListener("loadeddata", () => {
      // console.log(player.audioTracks)
      this.duration = player.duration
      this.updatePositionState(this.player);

    })

    player.addEventListener("play", () => {
      this.playlistState.visible = true;
      console.log('playing?')
       this.playState = 'playing'
    })

    this.mediaSession.setActionHandler('play', async () => {
      // Resume playback
      await player.play();
    });
  
    this.mediaSession.setActionHandler('pause', () => {
      // Resume playback
       player.pause();
    });
  
    this.mediaSession.setActionHandler('previoustrack', () => {
      this.prev();
    });
  
    this.mediaSession.setActionHandler('nexttrack', () => {
      this.next();
    });
  
    this.mediaSession.setActionHandler('seekto', (details) => {
      player.currentTime = Number(details.seekTime);
    });
    
    

  }

  mediaSession: MediaSession = navigator.mediaSession;
	musicDBapiSrc = environment.musicDBapiSrc;
	playlistVisible = false;
	duration = 0;
	posPercent = 0;
	currentTime = 0;
	track: any = {};
	playState =  'stopped'
	playlistState = { visible: false, position: 0, playing: false }
	playlist: Playlist = { id: '', playlist: []}
  infoVisible: boolean = false;
  release: any;
  favourites: any;
  favourited: any;
  fileUrl: any;


	drop(event: any ) {
    moveItemInArray(this.playlist.playlist, event.previousIndex, event.currentIndex);
    // this.SessionService.set('favourites', JSON.stringify(this.favourites) );
    // this.FavouriteService.prepForBroadcast('update');
  }
	shuffle() {
		this.playlist.playlist = this.playlist.playlist.map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
	}

	what(event) {
		console.log(event)
		var	seek = (this.posPercent / 100 * this.duration )
		this.player.nativeElement.currentTime = seek;
	}
	playlistClass = (key) => {
		if ( key === this.playlistState.position ) {
			return 'playing';
		}
		if ( key < this.playlistState.position ) {
			return 'past';
		}
		if ( key > this.playlistState.position ) {
			return 'future';
		}
		return '';
		
	}
	
	languageWarning(dBTrack) {
		dBTrack.language_warning = !dBTrack.language_warning
		this.http.get<any>(environment.musicDBapiSrc + '/language-warning/' + dBTrack.title_id + '/' + dBTrack.language_warning)
	}

	contentWarning(dBTrack) {
		dBTrack.content_warning = !dBTrack.content_warning
		this.http.get<any>(environment.musicDBapiSrc + '/content-warning/' + dBTrack.title_id + '/' + dBTrack.content_warning)
	}

	updatePositionState(player) {
		if ('setPositionState' in this.mediaSession) {
			if (player.duration) {

				this.mediaSession.setPositionState({
					duration: player.duration,
					playbackRate: player.playbackRate,
					position: player.currentTime,
				});
			}
			
		}
	}


	deleteTrack = (track, key) => {
		this.playlist.playlist.splice(key, 1);
	}

	next() {
		this.playerPlaylist.sendMessage({action: 'next' });
	}

	prev() {
		this.playerPlaylist.sendMessage({action: 'prev' });
	}

	jumpTo(index) {
		this.playerPlaylist.sendMessage({action: 'jumpTo', value: index });
	}

	play(track) {
		console.log(track)
		if(track && track.title_id && track.title_id !== -1) { //file from database

			this.http.get<any>(environment.musicDBapiSrc + '/dl/' + track.title_id).subscribe( data => {

				let metadata: {title: string, artist: string, album: string, artwork: {src: string, sizes: string, type: string }[] } = {
					title: track.title,
					artist: track.artist && track.artist.name ? track.artist.name : track.artist,
					album: track.album.name,
					artwork: track.library_no ? [
						{ src: environment.musicDBapiSrc + '/coverArt/512/' + track.library_no + '.png', sizes: '512x512', type: 'image/png' },
						{ src: environment.musicDBapiSrc + '/coverArt/384/' + track.library_no + '.png', sizes: '384x384', type: 'image/png' },
						{ src: environment.musicDBapiSrc + '/coverArt/256/' + track.library_no + '.png', sizes: '256x256', type: 'image/png' },
						{ src: environment.musicDBapiSrc + '/coverArt/192/' + track.library_no + '.png', sizes: '192x192', type: 'image/png' },
						{ src: environment.musicDBapiSrc + '/coverArt/128/' + track.library_no + '.png', sizes: '128x128', type: 'image/png' },
						{ src: environment.musicDBapiSrc + '/coverArt/96/' + track.library_no + '.png', sizes: '96x96', type: 'image/png' },
					] : []
				}
			
				this.mediaSession.metadata = new MediaMetadata(metadata);

				track.artist = typeof track.artist === 'object' ? track.artist.name : track.artist;
				this.track = track;
				if(track.library_no) {
					this.http.get<any>(environment.apiSrc + '/releases/' + track.library_no).subscribe( data => { this.release = data })
				}
				// this.favourited = false;
				var token = data.token
				// var url = environment.musicDBapiSrc + '/fela/' + token
				var url = environment.dbUrl + '/fela/' + token
				this.player.nativeElement.src = url;
				// $rootScope.player.addtrack = url;
				this.player.nativeElement.play()
				var favtemp = this.SessionService.get('favourites') && JSON.parse(this.SessionService.get('favourites'));
				this.favourited = favtemp ? favtemp.playlist.map( item => item.title_id).includes(track.title_id) : false;
				this.track.favourite = favtemp ? favtemp.playlist.map( item => item.title_id).includes(track.title_id) : false;
				this.updatePositionState(this.player);

			})
		}
		else {
			if (track.title_id === -1) {
				track.file = track.pivot.file;
			} 
			let file = track && track.file ? track.file : track.filename;
			if (!file) {
				this.http.get<any>(environment.musicDBapiSrc + '/dltmp/' + btoa(track.url)).subscribe( data => {
					track.title = track.url;
					this.track = track;
					var token = data.token
					var url = environment.dbUrl + '/fela/' + token;
					// var url = environment.musicDBapiSrc + '/fela/' + token;
					this.player.nativeElement.src = url;
					this.player.nativeElement.play()
					this.updatePositionState(this.player);
				});
			}
			else {
				let url = environment.musicDBapiSrc + '/temporary/dl'
				this.http.post<any>(url, { file: file, share: track.share ? track.share : 'temporary' }).subscribe( data => {
					track.title = track.filename;
					this.track = track;
					var token = data.token
					var url = environment.dbUrl + '/fela/' + token;
					// var url = environment.musicDBapiSrc + '/fela/' + token;
					this.player.nativeElement.src = url;
					this.player.nativeElement.play()
					this.updatePositionState(this.player);
				});
			}

		}

		
	}

	closeOverlay = () => {
		this.infoVisible = false;
		this.playlistVisible = false
	}
	getInfo = (library_no) => {
		if (!this.infoVisible) {
			this.http.get<any>(environment.apiSrc + '/releases/' + library_no).subscribe( data => { 
				this.release = data;
				this.playlistVisible = false;
				this.infoVisible = true;
			})
		}
		else {
			this.infoVisible = false;
		}
	}
	
	dragControlListeners = {
    containment: '#playlist-scroll',
		scrollableContainer: '#playlist-scroll',
		containerPositioning: 'relative',
		orderChanged: ev => { 
			console.log(this.playlist.playlist)
			console.log(this.track)
			this.playlistState.position =  this.playlist.playlist.findIndex( (i: any) => i.title_id === this.track.title_id)
			console.log(this.playlistState.position)

		}
	}

	downloadPlaylist = () => {

		var data = "#EXTM3U \r\n";
		for (var i = 0; i < this.playlist.playlist.length; i++) {
			let fav: any = this.playlist.playlist[i];
			let text = fav.fullpath;
			let link;
			if (navigator.platform.search('Win') !== -1) {
				console.log('win');
				link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
			}

			if (navigator.platform.search('Mac') !== -1) {
				console.log('mac');
				link = text.replace('/mnt/fela', '/Volumes/audio')
			}

			if (navigator.platform.search('Linux') !== -1) {
				console.log('lin');
				console.log(text);
				link = text;

			}

			data += "#EXTINF:" + fav.title_id + ", " + fav.artist + " - " + fav.title +"\r\n";
			data += link + "\r\n";
		}

		var blob = new Blob([data], { type: 'text/plain' })

		var url = window.URL || window.webkitURL;

		console.log(url)
		this.fileUrl = url.createObjectURL(blob);
		console.log(this.fileUrl)
	}

	togglePlaylist = () => {
		this.playlistVisible = !this.playlistVisible;
		if(this.playlistVisible)
			this.infoVisible = false;
	}

	click(library_no){
		// $http.get(environment.apiSrc + '/releases/' + library_no).then(function(response){
		// 	this.release = response.data;
		// })
		// this.location.go('/releases/' + library_no);
		this.router.navigateByUrl('/releases/' + library_no);
		console.log(library_no)
	}

	favourite(dBTrack) {
		var favtemp = this.SessionService.get('favourites');
		this.favourites = favtemp && JSON.parse(favtemp);
		if (! this.favourites || ! this.favourites.id) {
			var id = Math.random().toString(36).slice(2);
			this.favourites = { playlist: [], id: id };
		}
		if (!dBTrack.favourite) {
			dBTrack.favourite = true;
			this.favourites.playlist.push(dBTrack)

		} else {
			dBTrack.favourite = false;
			var removeIndex = this.favourites.playlist.map(function(item) { return item.title_id; })
													.indexOf(dBTrack.title_id);
			~removeIndex && this.favourites.playlist.splice(removeIndex, 1);
		}

		this.SessionService.set('favourites', JSON.stringify(this.favourites) );
		this.FavouriteService.sendMessage('addFavourite');
		console.log(this.favourites);
	}



	

}
