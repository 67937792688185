import { Component, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers'
import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription, interval } from 'rxjs';
import { V } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements AfterViewInit {
  @ViewChild( 'ordersTable' ) ordersTable!: MatTable<any>;
  @ViewChild( 'ordersPaginator', {static: false} ) paginator: MatPaginator = <MatPaginator>{};
  @ViewChild( 'tableContainer') tableContainer!: ElementRef;
  constructor(
    private http: HttpClient,
    public location: Location,
    private router: Router
    // @Inject('$rootScope') private $rootScope: any,
  ){
  }

  ngOnInit() {

    this.updateColumns()

    this.subscription = interval(300000)
      .subscribe(x => { this.fetchOrders(); });


    console.log(this.paginator)
    this.fetchOrders();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe( data =>{ this.fetchOrders() } )
  }

  private subscription!: Subscription;

  orders: any = [];
  sortedData: any[] = [];
  displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'total',  'items', 'merch', 'transaction', 'processed', 'updated' ];
  displayedFilters: string[] = ['id-filter', 'name-filter', 'email-filter', 'phone-filter',  'total-filter', 'items-filter', 'merch-filter', 'transaction-filter', 'processed-filter', 'updated-filter'];

  allColumns: any[] = [
    { 'column': 'id', 'visible': true},
    { 'column': 'name', 'visible': true},
    { 'column': 'invoice', 'visible': false},
    { 'column': 'email', 'visible': true},
    { 'column': 'phone', 'visible': true}, 
    { 'column': 'total', 'visible': true},  
    { 'column': 'items', 'visible': true},
    { 'column': 'merch', 'visible': true}, 
    { 'column': 'transaction', 'visible': true}, 
    { 'column': 'payer', 'visible': false},
    { 'column': 'transtatus', 'visible': false}, 
    { 'column': 'processed', 'visible': true}, 
    { 'column': 'created', 'visible': false },
    { 'column': 'updated', 'visible': true }
  ];
  
  lastSort: Sort = { active: 'id', direction: '' };
  page = 1;
  searchResultsSelection: any = {}
  filters: any = {};
  resultsLength = 40000;
  resultsLoading: boolean = false;

  changeColumns = (column, $event) => {
    console.log(column, $event);
    this.allColumns[column].visible = $event.checked;
    this.updateColumns();
  }

  updateColumns = () => {
    this.displayedColumns = this.allColumns.filter(i => i.visible).map(i => i.column)
    this.displayedFilters = this.allColumns.filter(i => i.visible).map(i => i.column + '-filter')
  }

  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    this.router.navigateByUrl('/orders/' + row.order_id)
  };


	fetchOrders = () => {
    this.resultsLoading = true;
    return this.http.post<any>(environment.apiSrc + '/new-filter-orders', {reception: true, page: (this.paginator ? this.paginator.pageIndex + 1 : 1), pageSize: (this.paginator && this.paginator.pageSize ), ...this.filters }).subscribe(
      data => { 
        data.orders.forEach((i,n ) => {i.hasMerch = i.items_json && i.items_json.some(i => i.type === 'merch')})
        this.orders = data.orders;
        this.resultsLength = data.total;
        this.sortData(this.lastSort);
        this.ordersTable && this.ordersTable.renderRows();
        this.resultsLoading = false;
        this.tableContainer.nativeElement.scrollTo({ top: 0, left: 0, behaviour: 'smooth'})
      }
    );
	}

  applyFilter(event: any, filter: string) {

    this.paginator.pageIndex = 0;
    let filterFunction;
    let filterValue = 'value' in event ? event.value : (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase();

    switch(filter) {
      case 'id':
        if(!filterValue)
          delete this.filters.order_id;
        else 
          this.filters = { ...this.filters, order_id: filterValue};
        filterFunction = i => i.order_id.toString().includes(filterValue);
        break;
      case 'email':
        if(!filterValue)
          this.filters.zedder && delete this.filters.zedder.email;
        else 
          this.filters = { ...this.filters, zedder: { ...this.filters.zedder, email: filterValue } };
        filterFunction = i => i.zedder.email.includes(filterValue);
        break;
      case 'phone':
        if(!filterValue)
          this.filters.zedder && delete this.filters.zedder.phone;
        else 
          this.filters = { ...this.filters, zedder: { ...this.filters.zedder, phone: filterValue } };
        filterFunction = i => i.zedder.phone.includes(filterValue);
        break;
      case 'name':
        if(!filterValue)
          this.filters.zedder && delete this.filters.zedder.name;
        else 
          this.filters = { ...this.filters, zedder: { ...this.filters.zedder, name: filterValue } };
        filterFunction = i => (i.zedder.firstname  + ' ' + i.zedder.lastname).trim().toLowerCase().includes(filterValue);
        break;
      case 'merch':
        if(!filterValue)
          delete this.filters.hasMerch;
        else 
          this.filters = { ...this.filters, hasMerch: filterValue};
        filterFunction = i => i.hasMerch === (filterValue === 'true');
        break;
      case 'processed':
        if(!filterValue)
          delete this.filters.processed;
        else 
          this.filters = { ...this.filters, processed: filterValue};
        filterFunction = i => i.processed === filterValue;
        break;
      default:
        filterFunction = i => i;
    }

    // console.log(this.filters)
    // if(filterValue)
    //  this.sortedData = this.orders.slice().filter( filterFunction )
    // else
    //   this.sortedData = this.orders.slice()

    this.fetchOrders();
    // return this.http.post<any>(environment.apiSrc + '/new-filter-orders', {reception: true, page: (this.paginator ? this.paginator.pageIndex + 1 : 1), ...this.filters }).subscribe(
    //   data => { 
    //     data.orders.forEach((i,n ) => {i.hasMerch = i.items_json && i.items_json.some(i => i.type === 'merch')})
    //     this.resultsLength = data.total;
    //     this.orders = data.orders;
    //     this.sortData(this.lastSort);
    //     this.ordersTable && this.ordersTable.renderRows();

    //   }
    // );
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.orders.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.order_id, b.order_id, isAsc);
        case 'name':
          return compare(a.zedder.firstname + a.zedder.lastname, b.zedder.firstname + b.zedder.lastname, isAsc);
        case 'invoice':
          return compare(a.invoice_no, b.invoice_no, isAsc);
        case 'email':
          return compare(a.zedder.email, b.zedder.email, isAsc);
        case 'phone':
          return compare(a.zedder.phone, b.zedder.phone, isAsc);
        case 'merch':
          return compare(a.hasMerch, b.hasMerch, isAsc);
        case 'total':
          return compare(a.total, b.total, isAsc);
        case 'transaction':
          return compare(a.transaction[0].service, b.transaction[0].service, isAsc);
        case 'processed':
          return compare(a.processed, b.processed, isAsc);
        case 'updated':
          return compare(a.updated_at, b.updated_at, isAsc);
        default:
          return 0;
      }
    });
  }
}
