import { HttpClient } from '@angular/common/http';
import { Component, inject, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { slugify } from '../helpers';
import { KeywordsService } from '../keywords.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  program: any;
}
@Component({
  selector: 'app-program-dialog',
  templateUrl: './program-dialog.component.html',
  styleUrls: ['./program-dialog.component.scss']
})
export class ProgramDialogComponent {
  @ViewChild('keywordInput')
  keywordInput!: ElementRef<HTMLInputElement>;
  
  @ViewChild('announcerInput')
  announcerInput!: ElementRef<HTMLInputElement>;
  
  @ViewChild('backupInput')
  backupInput!: ElementRef<HTMLInputElement>;

  constructor(
    // @Inject('$rootScope') private $rootScope: any,
    private KeywordsService: KeywordsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<any>,
    private http: HttpClient,
  ){
    this.KeywordsService.query().subscribe( data => { this.keywords = data });
    if (data.program && data.program.id) {
      this.http.get<any>(environment.musicDBapiSrc + '/schedule-program/' + data.program.id).subscribe(
        data =>  {
          this.programForm.patchValue({ 
            id: data.id,
            name: data.name,
            slug: data.slug,
            hour: data.hour,
            day: data.day,
            presenters: data.presenters,
            duration: data.duration,
            studio: data.studio,
            rdscat: data.rdscat,
            keywords: data.keywords,
            announcers: data.announcers,
            backup: data.backup,
          }, {emitEvent: false})
        }
      );
    }
    else {
      this.programForm.patchValue({ 
        id: data.program.id,
        name: data.program.name,
        slug: data.program.slug,
        hour: data.program.hour,
        day: data.program.day,
        presenters: data.program.presenters,
        duration: data.program.duration,
        studio: data.program.studio,
        rdscat: data.program.rdscat,
        keywords: data.program.keywords,
        announcers: data.program.announcers,
        backup: data.program.backup,
      }, {emitEvent: false})
    }
  }
  
  keywords: any;
   
  ngOnInit() {

    this.keywordOptions = this.keywordControl.valueChanges.pipe( 
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.searchKeywords(name) : this.keywords.slice())
    );

    this.announcerOptions = this.announcerControl.valueChanges.pipe( 
      filter((value) => value?.length > 2),
      switchMap( value => value ? this.searchZedders(value) : [])
    );

    this.backupOptions = this.backupControl.valueChanges.pipe( 
      filter((value) => value?.length > 2),
      switchMap( value => value ? this.searchZedders(value) : [])
    );

    this.programForm.controls['name'].valueChanges.subscribe( name => {
      this.programForm.patchValue({ slug: slugify(name) })
    })
  }

  searchKeywords = (query) => {
    var results = query ? this.keywords.filter((tag) => { return tag.tag.toLowerCase().replace('-', ' ').indexOf(query.toLowerCase().replace('-', ' ')) >= 0 }) : [];
    return results;
  }

  readonly separatorKeysCodes = [COMMA] as const;


  announcer = inject(LiveAnnouncer);

  saved = false;

  programForm = new UntypedFormGroup({
    id: new FormControl<number|null>(null), 
    name: new FormControl<string|null>(null),
    slug: new FormControl<string|null>(null),
    hour: new FormControl<string|null>(null),
    day: new FormControl<string|null>(null),
    presenters: new FormControl<string|null>(null),
    duration: new FormControl<number|null>(null),
    studio: new FormControl<number|null>(null),
    rdscat: new FormControl<number|null>(null),
    keywords: new FormControl<any[]|null>([]),
    announcers: new FormControl<any[]|null>([]),
    backup: new FormControl<any[]|null>([]),
  })

  keywordControl = new UntypedFormControl('bzzzzp');
  keywordOptions!: Observable<any>;

  announcerControl = new UntypedFormControl('bzzzzp');
  announcerOptions!: Observable<any>;

  backupControl = new UntypedFormControl('tst');
  backupOptions!: Observable<any>;

  rdsCats = [ 'NEWS', 'AFFAIRS', 'INFORMATION', 'SPORTS', 'TALK', 'ARTS', 'SCIENCE', 'FACTUAL', 'DOCUMENTARY', 'POP', 'ROCK', 'EASY', 'OTHER', 'JAZZBLUES', 'COUNTRY', 'OLDIES', 'FOLK', 'EDUCATION', 'DRAMA', 'LIGHT', 'CLASSIC', 'WEATHER', 'FINANCE', 'CHILDRENS', 'RELIGIOUS', 'TALKBACK', 'TRAVEL', 'LEISURE', 'NATIONAL' ]

  searchZedders = (zedder) => {
		return this.http.post<any>(environment.apiSrc + '/zedders', { searchString: zedder.toLowerCase() }).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
	}

  selectedKeyword(event: any): void {
    let keyword = event.option.value;
    this.programForm.get('keywords')!.value.push({ id: keyword.id, tag: keyword.tag, slug: keyword.slug  });
    this.keywordInput.nativeElement.value = '';
    this.keywordControl.setValue('');
    this.programForm.updateValueAndValidity();
  }

  removeKeyword(keyword: any, ): void {
    // const index = this.relatedArtists.indexOf(artist);
    const index = this.programForm.get('keywords')!.value.indexOf(keyword);

    if (index >= 0) {
      this.programForm.get('keywords')!.value.splice(index, 1);

      this.announcer.announce(`Removed Keyword ${keyword}`);
    }
    this.programForm.updateValueAndValidity();
  }

  add(event:any) {
    console.log(event);
    let keyword: string = event.value;
    keyword = keyword.trim();
    keyword = keyword[0].toUpperCase() + keyword.slice(1).toLowerCase();
    console.log(keyword);
    this.programForm.get('keywords')!.value.push({ id: null, tag: keyword, slug: slugify(keyword) });
    this.keywordInput.nativeElement.value = '';
    this.keywordControl.setValue('');
    this.programForm.updateValueAndValidity();
  }

  selectedAnnouncer(event: any): void {
    let announcer = event.option.value;
    this.programForm.get('announcers')!.value.push({ id: announcer.id, firstname: announcer.firstname, lastname: announcer.lastname, expiry: announcer.expiry});
    this.announcerInput.nativeElement.value = '';
    this.announcerControl.setValue('');
    this.programForm.updateValueAndValidity();
  }

  removeAnnouncer(announcer: any, ): void {
    // const index = this.relatedArtists.indexOf(artist);
    const index = this.programForm.get('announcers')!.value.indexOf(announcer);

    if (index >= 0) {
      this.programForm.get('announcers')!.value.splice(index, 1);

      this.announcer.announce(`Removed Announcer ${announcer}`);
    }
    this.programForm.updateValueAndValidity();
  }

  selectedBackup(event: any): void {
    let announcer = event.option.value;
    this.programForm.get('backup')!.value.push({ id: announcer.id, firstname: announcer.firstname, lastname: announcer.lastname, expiry: announcer.expiry});
    this.backupInput.nativeElement.value = '';
    this.backupControl.setValue('');
    this.programForm.updateValueAndValidity();
  }

  removeBackup(announcer: any, ): void {
    // const index = this.relatedArtists.indexOf(artist);
    const index = this.programForm.get('backup')!.value.indexOf(announcer);

    if (index >= 0) {
      this.programForm.get('backup')!.value.splice(index, 1);

      this.announcer.announce(`Removed Announcer ${announcer}`);
    }
    this.programForm.updateValueAndValidity();
  }

  saveProgram() {
    this.saved = true;
    this.http.post(environment.musicDBapiSrc +'/program', this.programForm.value ).subscribe( data => {
      this.dialogRef.close(this.programForm.value)
    });
  }
}
