import { Component, ElementRef, ViewChild, Inject, Injectable, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Transition } from "@uirouter/angular";
import { Location } from "@angular/common";
import { filter } from 'lodash';
import { UIRouter } from "@uirouter/core";
import { SessionService } from '../session.service';

import * as moment from 'moment';
import { ReleasesBaseComponent } from './releases.base.component';
import { PlaylistService } from '../playlist.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-releases-archive',
  templateUrl: './releases-archive.component.html',
  styleUrls: ['./releases-archive.component.scss']
})
export class ReleasesArchiveComponent extends ReleasesBaseComponent {
  @ViewChild('scrollElement', { read: ElementRef }) scrollEl!: ElementRef;
  
	@HostListener('window:hashchange', ['$event'])
	hashChangeHandler(e) {
		let hash = window.location.hash.substring(1);
		if(hash !== this.hot100Year ) {
			this.hot100Year = window.location.hash.substring(1);
			this.selectedIndex = this.hot100years.findIndex(i => i === Number(this.hot100Year));

			// this.hot100YearTracks = this.hot100tracks.filter(i => i.year === Number(this.hot100Year) );
		}
	}
	

  constructor(
		router: Router,
    location: Location,
    http: HttpClient,
		// @Inject('$rootScope') $rootScope: any,
		PlaylistService: PlaylistService,
		SessionService: SessionService,
    private route: ActivatedRoute,
    // private trans: Transition,  
  ) {
    super(router, location, http, PlaylistService, SessionService)

  }

	ngOnInit() {

		this.route.data.subscribe(({ type }) => {
      this.type = type;
    })

		this.route.paramMap.subscribe((params) => {
      this.params = { month: params.get('month'), endMonth: params.get('endMonth') };

			if (this.params['endMonth'] && this.params['month']  ) {
				let startdate =  moment(this.params['month'], 'YYYY-MMMM').toDate();
				this.selectedStart = startdate;
				this.startDate = startdate;
				this.dateString = moment(startdate).format('YYYY-MMMM');
	
				let enddate =  moment(this.params['endMonth'], 'YYYY-MMMM').toDate();
				this.selectedEnd = enddate;
				this.endDate = enddate;
				this.endDateString = moment(enddate).format('YYYY-MMMM');
				this.range = true;
				this.getReleases()
			}
	
			else if (this.params['month']) {
				if(this.params['month'] === 'hot100') {
					this.toggleHot100s();
					if(this.params['#']) {
						this.hot100Year = this.params['#'];
					}
				}
				else {
					let date =  moment(this.params['month'], 'YYYY-MMMM').toDate();
	
					this.changeSelected(date);   
	
					this.getReleases()
				} 
			}
	
			else { 
				this.getReleases()
			}

			if ( this.type === 'releases/browse' ) {
				this.showYear = false;
				this.http.get<any>(environment.apiSrc + '/AOTW/' + this.hotBinMonth ).subscribe( data => {
					this.aotw = data;
				})
			}
			console.log(this.params)
    });

		let monthYear = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(this.selected);
    let monthString = new Intl.DateTimeFormat('en-AU', { month: 'long' }).format(this.selected);
    this.dateString = monthYear + '-' + monthString;

	}

	musicDBapiSrc = environment.musicDBapiSrc;
	
	type: string | undefined;

  releases: any[] = [];
  hotbin: any[] = [];
  genreTags: any[] = [];

  ausnz: any = [];
  female: any = [];
  nbgf: any = [];
  indigenous: any = [];
  local: any = [];
  os: any = [];
  bna: any = [];
	aotw: any = [];

	showYear: boolean = true;
	range: boolean = false;
	start: boolean = false;

  sort: string = '';
	toggleSort = () => {
		this.sort = this.sort === 'ALPHA' ? 'RECENT' : 'ALPHA';
		this.groupReleases()
	}

	order: (a: any, b: any) => number = (a,b) => b.year - a.year || a.tracknum - b.tracknum;
  hot100s: boolean = false;
	hot100years: any[] = [];
	hot100tabs: any[] = [];
	hot100tracks: any[] = [];
	hot100YearTracks: any[] = [];
	hot100Year: string = ''
	
	setHot100s = () => {
    this.router.navigateByUrl('/releases/archive/hot100');
	}

	playFiltered = () => {
			this.filtered.forEach( release => { this.http.get<any>(environment.musicDBapiSrc + '/title-by-library-id/' + release.library_no).subscribe( data => {
				let dBTracks : any[] = data;
				this.PlaylistService.prepForBroadcast({action: 'addToQueue', id: release.library_no, playlist: dBTracks.sort((a, b) => Number(a.discnum) - Number(b.discnum) || Number(a.tracknum)- Number(b.tracknum) )});
			});  
		})
	}

	hot100Select = ($event) => {
		console.log($event)
		this.hot100YearTracks = this.hot100tracks.filter(i => i.year === $event.tab.textLabel );
		// this.trans.router.locationService.url('#' + $event.tab.textLabel);
		this.router.navigateByUrl('/releases/archive/hot100#' + $event.tab.textLabel)
	}

	selectedIndex: number = 0;

	filtered: any[] = [];
	showFilters: boolean = false;
	toggleFilters = () => {
		this.showFilters = !this.showFilters;
	}

	filterReleases = (item, $event) => {
		item.selected = ! item.selected
		let untagged: any[] = []

		let selected = this.genreTags.filter( i => (i.selected) ).map( i => i.id )
		if (selected.indexOf(-1) !== -1) { //UNTAGGED
			untagged = this.hotbin.filter( i => ( i.genres.length === 0 ))
		}
		let filteredOR = this.releases.filter(a => a.genres.map(m => m.id).some(b => selected.includes(b) ))

		// let filtered = this.releases.filter(a => a.genres.map(g => g.genre).every(i => item.genre.includes(i)));
		// let filtered = this.hotbin.filter( i => ( _.intersection( selected, i.genres.map(g => g.id)) .length !== 0  ) )
		this.filtered = filteredOR.concat(untagged)
	}

	exportCSV = () => {
		console.log(this.releases);
		console.log(this.exportCols);

		const filtered = this.releases.map( release => 
			this.exportCols.map(i => i.field)
			.filter(key => key in release) // line can be removed to make it inclusive
			.reduce((obj2, key) => (obj2[key] = release[key], obj2), {})
		)

		console.log(filtered);
		

		const items = filtered
		const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
		const header = Object.keys(items[0])
		const headerNames = this.exportCols.map(i => i.displayName ? i.displayName : i.field);
		const csv = [
			headerNames.join(','), // header row first
			...items.map(row => header.map(fieldName => (JSON.stringify(row[fieldName], replacer)).replace(/\\"/g, '""')).join(','))
		].join('\r\n')

		console.log(csv)

		let title = this.type === 'releases/browse' ? 'DigitalPigeonhole' : 'Archive'; 
		const blob = new File([csv], title + '-' + this.dateString + '.csv', { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);
	};

  // queueRelease = () => {};
  more = () => {};
	fav: boolean = false;
	albumListing = (item, event) => {};
	onAir = false;
	appendTrack = (item) => {};
  queue = (...args) => {};
	addToPlayList = () => {};
	// release = (item) => {};
	selection: any = [];

  // params = this.trans.params();
  params: any;
  selected: Date = new Date();
  selectedStart: Date = new Date();
  selectedEnd: Date = new Date();
  // selected: Date = new Date('2021-07-01');
  startDate: any;
  endDate: any;
  dateString: string = '';
	endDateString: string = '';
  isOpen = false;
	dateRange: { start: string | null, end: string | null } = { start: null, end: null };

	hotBinMonth = moment().startOf('month').format('YYYY-MMMM');

  closeOverlay(event) { 
    console.log(event)
    event.stopPropagation();

    this.isOpen = false;
  }

	
  changeSelected(selected: Date) {
    let monthYear = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(selected);
    let monthString = new Intl.DateTimeFormat('en-AU', { month: 'long' }).format(selected);
    this.selected = selected;
    this.dateString = monthYear + '-' + monthString;
    let year = selected.getFullYear(), month = selected.getMonth();
    this.startDate = new Date(year, month, 1);
    this.endDate = new Date(year, month + 1, 0);
		this.hotBinMonth = moment(this.endDate).startOf('month').format('YYYY-MMMM');
		this.dateRange = { start: null, end: null };
  }

  dateChanged(event: Date) {
    this.changeSelected(event);
    // this.trans.router.stateService.go(this.type, { month: this.dateString.toLowerCase(), endMonth: null });
			this.router.navigateByUrl( this.type + '/' + this.dateString.toLowerCase() )
			// this.ngRouter.navigate([this.type, { month: this.dateString.toLowerCase()}]);
	}


	startDateChanged(event: Date) {
		let selected = event;
		let monthYear = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(selected);
    let monthString = new Intl.DateTimeFormat('en-AU', { month: 'long' }).format(selected);
		let datestring = monthYear + '-' + monthString;
    this.selectedStart, this.startDate = selected;
		this.dateRange.start = datestring.toLowerCase();
		
		if(this.dateRange.start && this.dateRange.end)
			this.router.navigateByUrl( this.type + '/' + this.dateRange.start + '/' + this.dateRange.end )
    	// this.ngRouter.navigate([this.type, { month: this.dateRange.start, endMonth: this.dateRange.end }]);
		// this.trans.router.stateService.go(this.type, { month: this.dateRange.start, endMonth: this.dateRange.end });
    // this.ngRouter.navigate([this.type, { month: this.dateString.toLowerCase(), endMonth: null }]);
  }


  endDateChanged(event: Date) {
		let selected = event;
		let monthYear = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(selected);
    let monthString = new Intl.DateTimeFormat('en-AU', { month: 'long' }).format(selected);
		let datestring = monthYear + '-' + monthString;
    this.selectedEnd, this.endDate = selected;
		this.dateRange.end = datestring.toLowerCase();

		if(this.dateRange.start && this.dateRange.end)
			this.router.navigateByUrl( this.type + '/' + this.dateRange.start + '/' + this.dateRange.end )
		// this.ngRouter.navigate([this.type, { month: this.dateRange.start, endMonth: this.dateRange.end }]);
		// this.trans.router.stateService.go(this.type, { month: this.dateRange.start, endMonth: this.dateRange.end });
    // this.trans.router.stateService.go(this.type, { month: this.dateString.toLowerCase() });
  }


  getReleases = () => {
		let startOfMonth, endOfMonth, params
		if(this.endDate) {
			console.log(moment(this.endDate))
			let hotbinRange: string[] = [];
			let nmonths = Math.floor( moment(this.endDate).diff(moment(this.startDate), 'months', true) ); 
			for(let i = 0; i <= nmonths; i++) { 
				hotbinRange.push(moment(this.startDate).add(i, 'months').format('YYYY-MMMM'));
			}
			startOfMonth = moment(this.startDate).startOf('month').format('YYYY-MM-DD');
			endOfMonth   = moment(this.endDate).endOf('month').format('YYYY-MM-DD');
			params = this.type === 'releases/archive' ?  { noHotbin: true, entered_dt: startOfMonth, entered_dtend: endOfMonth  } : { hotbinRange: JSON.stringify(hotbinRange) };
			// params = { entered_dt: startOfMonth, entered_dtend: endOfMonth }
		}
		else{
			startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
			endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
			params = this.type === 'releases/archive' ? { noHotbin: true, entered_dt: startOfMonth, entered_dtend: endOfMonth  } :  { hotbin: this.hotBinMonth }
		}
		console.log(startOfMonth)
		console.log(endOfMonth)
		this.http.get<any>(environment.apiSrc + '/releases', {params: params }).subscribe( (data) => {

			this.releases = data.sort((a, b) => a.artist_nm.localeCompare(b.artist_nm));
			this.groupReleases();

			// $scope.aotw = filter(response.data, function(item){ return item.aotw });	
			this.hotbin = this.releases;

			this.genreTags = [];

			data.forEach( item => {
				if( item.genres.length === 0 ) {
					console.log('UNTAGGED')
					let i = this.genreTags.findIndex( x => x.genre === 'UNTAGGED');
					if( i === -1){
						this.genreTags.push ({genre: 'UNTAGGED', count: 1, id: -1})
					}
					else {
						this.genreTags[i].count++
					}
				}
				item.genres.forEach( genre => {
					let i = this.genreTags.findIndex( x => x.genre === genre.genre );
					if( i === -1){
						genre.count = 1;
						this.genreTags.push (genre)
					}
					else {
						this.genreTags[i].count++
					}
				})
			});
			this.genreTags.sort((a, b) => (a.count < b.count) ? 1 : -1 )
			console.log(this.genreTags)

		});
  }
  groupReleases = () => {
		let dateSort = (a, b) => (a.library_no < b.library_no) ? 1 : -1;
		let alphaSort = (a, b) => a.artist_nm.localeCompare(b.artist_nm);
		let sortAlg = (this.sort === 'ALPHA') ? alphaSort : dateSort;
		this.female = filter(this.releases, function(item){ return item.cont_female }).sort( sortAlg);
		this.nbgf = filter(this.releases, function(item){ return (item.nonbinary || item.genderfluid || item.genderdiverse ) }).sort( sortAlg );
		this.indigenous = filter(this.releases, function(item){ return item.first_nations }).sort( sortAlg );
		this.ausnz = filter(this.releases, function(item){ return item.cont_ausnz && !item.cont_local }).sort(sortAlg);
		this.local = filter(this.releases, function(item){ return item.cont_local }).sort(sortAlg);
		this.os = filter(this.releases, function(item){ return ! item.cont_local && !item.cont_ausnz }).sort(sortAlg);
		this.bna = filter(this.releases, function(item){ return item.bna }).sort(sortAlg);
	}

	toggleHot100s = () => {
		this.hot100s = !this.hot100s;

		if(this.hot100s && this.hot100tracks.length === 0) {
			let hot100 = this.http.get<any>(environment.musicDBapiSrc + '/hot100-list').subscribe( data => {
				this.hot100years = [...new Set( data.map(i => i.year) )]
				this.hot100tabs = this.hot100years.map(i => ({ title: i }))

				if (this.hot100Year) {
					this.selectedIndex = this.hot100years.findIndex(i => i === Number(this.hot100Year));
				}

				this.hot100tracks = data.map(i => { 
					if(!i.track) {i.track = {} }
					i.track.artist = i.track.artistName ? i.track.artistName : i.artist;
					i.track.artistName  = i.track.artistName ? i.track.artistName : i.artist;
					i.track.title = i.track.title ? i.track.title : i.title;
					i.track.library_no = i.track.library_no ? i.track.library_no : i.library_no;
					i.track.tracknum = i.rank; 
					i.track.year = i.year; 
					return i.track } );
				this.hot100YearTracks = this.hot100tracks.filter(i => i.year === this.hot100years[0] );

			})
		}
	}

	exportCols = [
		{
			field: 'artist_nm',
			displayName: 'Artist',
		}, {
			field: 'title',
			displayName: 'Title',
		}, {
			field: 'library_no',
			displayName: 'Record #',
		}, {
			field: 'release_year',
			displayName: 'Year'
		}, {
			field: 'entered_dt',
			displayName: 'Created'
		}, {
			field: 'aotw',
		},
		{
			field: 'aotw_week',
		},
		{
			field: 'bna',
		},
		{
			field: 'hotbin',
		},
		{
			field: 'cont_ausnz',
		},
		{
			field: 'cont_local',
		},
		{
			field: 'first_nations',
		},
		{
			field: 'album_origin',
		},
		{
			field: 'artist_hometown',
		},
		{
			field: 'release_type',
		},
		{
			field: 'album_label',
		},
		{
			field: 'cont_female',
		},
		{
			field: 'nonbinary',
		},
		{
			field: 'genderfluid',
		},
		{
			field: 'bio',
		}
	];
}
