<div class="studio-status pseudo-button" matTooltipShowDelay="1000" [ngClass]="delegation && delegation.fm['studio' + studio].active ? 'on-air' : onAir === 'requested' ? 'requested' : onAir ? 'connected' : 'off-air'" (click)="toggleOnAir()" style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
	<div *ngIf="onAir === true">
		<div [class.spin]="delegationPanel" class="delegation-button" cdkOverlayOrigin  #trigger="cdkOverlayOrigin" (click)="toggleDelegation($event)">
			<img  src="/assets/PBIcon.png" />
		</div>	
		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="trigger"
			[cdkConnectedOverlayOpen]="delegationPanel"
			[cdkConnectedOverlayPositions]="positionPairs"
		>
			<app-studio-delegation style="margin: 8px;"></app-studio-delegation>
		</ng-template>
	</div>
	<span>{{ delegation && delegation.fm['studio' + studio].active ? 'On-air' : 'Studio' }}</span>
	<div class="studio pseudo-button" (click)="changeStudios($event)">{{studio}}</div>
</div>

