import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { QualificationService } from '../qualification.service';

export interface Qualifications {
  id: number;
  qualification: string;
}
 
@Component({
  selector: 'qualifications',
  templateUrl: './manage-qualifications.component.html',
  styleUrls: ['./manage-qualifications.component.scss']
})

export class ManageQualificationsComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    id: new UntypedFormControl({ value: 0, disabled: true }),
    qualification: new UntypedFormControl('')
  });
  lastSort: Sort = { active: "qualification", direction: "asc" };
  editQualifications: Qualifications = {qualification: '', id: 0};
  subCategories: Qualifications[] = [];
  sortedData: Qualifications[];
  displayedColumns: string[] = ['id', 'qualification', 'edit'];

  constructor(public QualificationService: QualificationService) {
    this.sortedData = this.subCategories.slice();
    // this.QualificationsService = QualificationsService;
    QualificationService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':           
          return compare(a.id, b.id, isAsc);
        case 'qualification':
          return compare(a.qualification, b.qualification, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      id: el.id,
      qualification: el.qualification
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      id: 0,
      qualification: ''
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().id === 0) { //new
      let subskill: any = {};
      subskill.skill = this.interestForm.getRawValue().skill;

      this.QualificationService.save(subskill).subscribe( res => {
        this.QualificationService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      this.QualificationService.get(this.interestForm.getRawValue().id).subscribe( subskill => {
        subskill.qualification = this.interestForm.getRawValue().qualification;
        this.QualificationService.update(subskill).subscribe(response => { 
        let interests : Qualifications[] = [ {
          id: response.id,
          qualification: response.qualification
        }]
  
        const existing = this.subCategories.reduce((a,t)=> (a[t.id] = t, a), {}),
        changed = interests.reduce((a,t)=> (a[t.id] = t, a), {})
  
        this.subCategories = Object.values({...existing, ...changed})
        // this.sortedData = this.subCategories.slice(); 
        this.sortData(this.lastSort);
      });
       });
      
    }
   
  }
  
}
