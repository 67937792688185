import { Component, Input, Inject } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';
import { Sponsorship } from '../sponsorship';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Transition } from "@uirouter/angular";
import * as moment from 'moment';
import { Location } from "@angular/common";
import { PromoService } from '../promo.service';
import { PromoFolderService } from '../promo-folder.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { EditorComponent } from '@tinymce/tinymce-angular';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss']
})
export class CampaignDetailsComponent extends Sponsorship {
  // @Input() promo: any = { campaigns: [] };
  
  constructor(
		// @Inject('$rootScope') private $rootScope: any,
    private PromoService: PromoService,
		private PromoFolderService: PromoFolderService,
    public location: Location,
    private http: HttpClient,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ){
    super();
    this.orgOptions = this.promoForm.get('contact.name')!.valueChanges.pipe( 
      filter((value) => value?.length > 1),
      switchMap( value => value ? this.searchOrg(value) : [])
    )

    this.promoForm.valueChanges.subscribe( () => {
      console.log('CHANGED!', this.promoForm)
      this.formEdited = true;
      this.formValid = this.promoForm.status === 'VALID'; 

    });

    this.PromoFolderService.query().subscribe( data => { this.promoFolder = data; } )
  }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');

    this.route.data.subscribe(({ promo }) => {
      this.promo = promo;
    })

    if (this.id === 'search' || this.id === 'new') {

    }
    else {

			let max;

      this.promo.campaigns.forEach( spot => {
        let [start, end] = [new Date(spot.commence_date), new Date(spot.complete_date)];
        spot.scheduled.forEach(a => { a.projected = [] })
        console.log(start, end);
        spot.hide = moment(spot.complete_date).isBefore(moment())
        let lastDate = new Date(start);
        let nextDate = new Date(start);
        let total = 0;
    
        while (nextDate.setDate(nextDate.getDate() + 7).valueOf() < end.valueOf() &&  (max && total < max  || !max ) ) {
          let scheduled = (spot.scheduled.filter(i => new Date(i.date) < nextDate && i.recurring ));
          scheduled.forEach((a, i) => {
            if(max && total < max || !max)  {
              if(!a.projected.length) {
                console.log('new date')
                let startingDate = new Date(a.date);
                startingDate.setHours(a.hour);
                a.dateTime = new Date(startingDate);
                a.projected = [startingDate]
                total ++;
              }
  
              let newDate = new Date(a.projected.at(-1));
              newDate.setDate(newDate.getDate() + 7);
              if (newDate <= end)
              {	
                a.projected.push(new Date(newDate));
                total ++;
              }
            }
            
          })
          
          lastDate = new Date (nextDate);
        }
        spot.projectedTotal = total;
        console.log(spot.scheduled)
        console.log(spot.projectedTotal);
      });
      this.populateCampaign();
 
    }
  }

  promo: any;

  // params = this.trans.params();
  // id = this.params['id'];
  id?: string | null;

  submitLocked = false;

  formEdited = false;
  formValid = false;
  promoForm = new UntypedFormGroup({
    title: new UntypedFormControl(null), 
    contact_id: new UntypedFormControl(null), 
    contact: new UntypedFormGroup({
      id: new UntypedFormControl(null), 
      name: new UntypedFormControl(null), 
    }),
    campaigns: new UntypedFormArray([]),
  });

  orgOptions!: Observable<any>;
  mediaOptions: Observable<any>[] = [];

  promoFolder: any[] = [];
  // campaign: any[] = [];

  init: EditorComponent['init'] = {
    menubar: false, 
    height: '350px',
    paste_as_text: true, 
    toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter  alignright alignjustify | bullist numlist outdent indent | link paste removeformat | code',
    plugins: 'lists code link image table code help wordcount',
    base_url: '/tinymce', // Root for resources
    suffix: '.min'        // Suffix to use when loading resources
  };

  searchOrg = (artist) => {
		return this.http.get<any>(environment.apiSrc + '/contactsuggest/' + artist).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
	}

  searchMedia = (media: string): any[]  => {
    let filter = this.promoFolder.filter( i => i.file.toLowerCase().includes(media.toLowerCase()) );
    console.log(filter);
    return filter;
  }

  fetchContact(item) {
    this.http.get<any>(environment.apiSrc + '/contacts/' + item.contact_no).subscribe( data => { 
      console.log(data)
     this.promoForm.patchValue( { contact_id: data.contact_no, contact: { id: data.contact_no, name: data.org_nm } });
    })
  }

  fetchSpots(item) {

  }

  gotoContact() {
    let id = this.promoForm.get('contact.id')?.value;
    this.router.navigateByUrl('/contacts/' + id)
  }

  addCampaign(type) {

		// if (type === 'live-read') {
		// 	this.promo.campaigns[$scope.promo.campaigns.length] = { promo_id: $scope.promo.id, campaign_type: 2 };
		// } else if (type === 'spot'){
		// 	$scope.promo.campaigns[$scope.promo.campaigns.length] = { promo_id: $scope.promo.id, campaign_type: 1 };
		// } else if (type === 'giveaway'){
		// 	$scope.promo.campaigns[$scope.promo.campaigns.length] = { promo_id: $scope.promo.id, campaign_type: 3 };
		// }

    let campaigns = this.promoForm.get('campaigns') as FormArray;

    campaigns.insert(0, new UntypedFormGroup({
      id: new UntypedFormControl(null),
      title: new UntypedFormControl(null), 
      script: new UntypedFormControl(null), 
      media_location: new UntypedFormControl(null),
      media_length: new UntypedFormControl(null),
      runs_week: new UntypedFormControl(null),
      runs_campaign: new UntypedFormControl(null),
      recurring: new UntypedFormControl(null),
      commence_date: new UntypedFormControl(null),
      complete_date: new UntypedFormControl(null),
      campaign_cat: new UntypedFormControl(null),
      instructions: new UntypedFormControl(null),
      winnerspershow: new UntypedFormControl(null),
      promo_id: new UntypedFormControl(this.promo.promo_id),
      campaign_type: new UntypedFormControl( type === 'live-read' ? 2 : type === 'spot' ? 1 : 3 )
    }))

    let options = campaigns.controls[0].get('media_location')!.valueChanges.pipe( 
      startWith(''),
      map( value => value ? this.searchMedia(value) : this.promoFolder.slice())
    )
    this.mediaOptions.unshift(options)
	}

  get refForm() {
    return this.promoForm.get('campaigns') as FormArray;
  }

  populateCampaign() {
    this.promoForm.patchValue({
      title: this.promo.title, 
      contact_id: this.promo.contact.contact_no,
      contact: {
        id: this.promo.contact.contact_no,
        name:  this.promo.contact.org_nm, 
      },
    }, { emitEvent: false });
  
    let campaigns = this.promoForm.get('campaigns') as FormArray;
    campaigns.clear();

    this.promo.campaigns.sort( (a: any, b:any) => Date.parse(b.complete_date) - Date.parse(a.complete_date)).forEach((campaign, i) => {
      campaigns.push(new UntypedFormGroup({
        id: new UntypedFormControl(campaign.id),
        title: new UntypedFormControl(campaign.title), 
        script: new UntypedFormControl(campaign.script), 
        media_location: new UntypedFormControl(campaign.media_location),
        media_length: new UntypedFormControl(campaign.media_length),
        runs_week: new UntypedFormControl(campaign.runs_week),
        runs_campaign: new UntypedFormControl(campaign.runs_campaign),
        recurring: new UntypedFormControl(campaign.recurring),
        commence_date: new UntypedFormControl(campaign.commence_date),
        complete_date: new UntypedFormControl(campaign.complete_date),
        instructions: new UntypedFormControl(campaign.instructions),
        winnerspershow: new UntypedFormControl(campaign.winnerspershow),
        campaign_cat: new UntypedFormControl(campaign.campaign_cat),
        promo_id: new UntypedFormControl(campaign.promo_id),
        campaign_type: new UntypedFormControl( campaign.campaign_type )
      }), { emitEvent: false } )

      let options = campaigns.controls[campaigns.length -1].get('media_location')!.valueChanges.pipe( 
        startWith(''),
        map( value => value ? this.searchMedia(value) : this.promoFolder.slice())
      )
      this.mediaOptions.push(options)
    })
  }

  displayFn(item?: any): string | undefined {
    return item ? item.itemName : undefined;
  }


  setMedia(media, campaignId) {
    console.log(media, campaignId);
    let campaign = (this.promoForm.get('campaigns') as FormArray).controls[campaignId];
    campaign.patchValue({ media_length: media.splitlength});
  }

  saveCampaign() {
    if (this.id ) {
      console.log('update');

      this.submitLocked = true;

      if (this.formValid) {
        this.promo = Object.assign(this.promo, this.promoForm.value);
        this.promo.campaigns.forEach( item => { 
          item.commence_date = typeof item.commence_date === 'object' ? item.commence_date.format('YYYY-MM-DD') : item.commence_date;
          item.complete_date = typeof item.complete_date === 'object' ? item.complete_date.format('YYYY-MM-DD') : item.complete_date;
        }); 
        delete this.promo.scheduled;
      }

      this.PromoService.update(this.promo).subscribe( promo => {
        this.submitLocked = false;
        this.promo = promo;
        this.populateCampaign();
      });
    }
    else {
      console.log('insert');
      this.submitLocked = true;

      if (this.formValid) {
        this.promo = Object.assign(this.promo, this.promoForm.value);
        this.promo.campaigns.forEach( item => { 
          item.commence_date = item.commence_date.format('YYYY-MM-DD');
          item.complete_date = item.complete_date.format('YYYY-MM-DD');
        }); 
        delete this.promo.scheduled;
      }
      console.log(this.promo);

      this.PromoService.save(this.promo).subscribe( promo => {

        this.submitLocked = false;
        alert('Promo added');
        this.router.navigateByUrl('/sponsorship/' + promo.id);
      });

    }
  }

  deleteFullCampaign(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data:{ 
        title: 'Delete Full Campaign',
        message: 'This will permanently delete all campains under it and everything previously scheduled?',
        confirm: 'Delete',
        abort: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
			console.log('deleting sponsorship campaign', id);
      if(result === 'confirm') {
        this.http.delete(environment.apiSrc + '/promo/' + id).subscribe( data => {
          alert('Sponsorship Deleted');
          this.router.navigateByUrl('/sponsorship');
        });
      }
      else {
        console.log('Cancelled Deletion');
      }
		})
	}

  deleteCampaign(id, index) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data:{ 
        title: 'Delete Campaign',
        message: 'Delete campaign #'+ id +'?',
        confirm: 'Delete',
        abort: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result === 'confirm') {
        console.log('I will delete');
        this.http.delete<any>(environment.apiSrc  + '/campaign/' + id ).subscribe( () => {
          console.log('deleted');
          let campaigns = this.promoForm.get('campaigns') as FormArray;
          campaigns.removeAt(index);
        });
      }
      else {
        console.log('Cancelled Deletion');
      }
    });
  }
}
