import { Component, Inject, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { compare } from '../helpers';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MerchItemDetailsComponent } from '../merch-item-details/merch-item-details.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-merch-inventory',
  templateUrl: './merch-inventory.component.html',
  styleUrls: ['./merch-inventory.component.scss']
})
export class MerchInventoryComponent {
  @ViewChild( 'announcerTable' ) announcerTable!: MatTable<any>;
  constructor(
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
    public dialog: MatDialog,
  ) {

    const grid = this.http.get<any>(environment.apiSrc + '/merch/inventory').subscribe( data => { 
      this.inventory = data;

      this.inventory.forEach( i => {
        this.controls.push( 
          new UntypedFormGroup({ 
           id: new UntypedFormControl(i.id),
           inventory: new UntypedFormControl(i.inventory)  })
         );
      })

      this.sortData(this.lastSort);

      this.announcerTable.renderRows();
    })
  }
  inventory: any[] = [];
  sortedData: any[] = [];
   
  searchResultsSelection: any = {}
  
  lastSort: Sort = { active: 'name', direction: 'asc' };
  
  displayedColumns: string[] = ['id', 'name', 'itemType', 'value', 'inventory', 'edit'];
  displayedFilters: string[] = ['id-filter', 'name-filter', 'itemType-filter', 'value-filter', 'inventory-filter', 'edit-filter'];

  filters: any = {};

  controls: FormArray<FormGroup> = new UntypedFormArray([]); 

  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    row.selected = !row.selected;
    // this.location.go('/orders/' + row.order_id)
  };

  editInventory(row, event, index) {
    let newValue = Number(event.target.value);
    console.log('edited row id:' + row.id + 'paramId: ' + row.param_id +' newValue:' + newValue + ' oldValue:' + row.inventory );
    this.sortedData[index].inventory = newValue;
    this.inventory.find(x => x.param_id === row.param_id).inventory = newValue;
    this.controls.controls[index].patchValue({ inventory: newValue } );
    this.http.put<any>(environment.apiSrc + '/merch/inventory', { id: row.id, paramId: row.param_id, value: newValue}).subscribe( data => console.log(data));
  }

  editItem(item, event) {
    event.stopPropagation();
    console.log(item);
    this.dialog.open(MerchItemDetailsComponent, {
      data: {
        ...item
      },
    });
  }
  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.inventory.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'itemType':
          return compare(a.type, b.type, isAsc);
        case 'value':
          return compare(a.value, b.value, isAsc);
        case 'inventory':
          return compare(a.inventory, b.inventory, isAsc);
        case 'edit':
          return compare(a.edit, b.edit, isAsc);
        default:
          return 0;
      }
    });

    this.controls.clear();

    this.sortedData.forEach( i => {
      this.controls.push( 
        new UntypedFormGroup({ 
          id: new UntypedFormControl(i.id),
          inventory: new UntypedFormControl(i.inventory) 
        })
      );
    })

  }

  applyFilter(event: any, filter: string) {

    let filterFunction;
    let filterValue = 'value' in event ? event.value : (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase();

    switch(filter) {
      case 'id':
        if(!filterValue)
          delete this.filters.id;
        else 
          this.filters = { ...this.filters, id: filterValue};
        filterFunction = i => i.id.toString().includes(filterValue);
        break;
      case 'itemType':
        if(!filterValue)
          delete this.filters.itemType;
        else 
          this.filters = { ...this.filters, itemType: filterValue };
        filterFunction = i => i.type && i.type.toLowerCase().includes(filterValue);
        break;
      case 'value':
        if(!filterValue)
          delete this.filters.value;
        else 
          this.filters = { ...this.filters, zedder: filterValue };
        filterFunction = i => i.value.toLowerCase().includes(filterValue);
        break;
      case 'name':
        if(!filterValue)
          delete this.filters.name;
        else 
          this.filters = { ...this.filters, name: filterValue };
        filterFunction = i => i.name.toLowerCase().includes(filterValue);
        break;
      case 'inventory':
        if(!filterValue)
          delete this.filters.inventory;
        else 
          this.filters = { ...this.filters, inventory: filterValue };
        filterFunction = i => i.inventory.includes(filterValue);
        break;
      default:
        filterFunction = i => i;
    }

    console.log(this.filters)
    if(filterValue)
      this.sortedData = this.inventory.slice().filter( filterFunction )
    else
      this.sortedData = this.inventory.slice()

    this.controls.clear();

    this.sortedData.forEach( i => {
      this.controls.push( 
        new UntypedFormGroup({ 
          id: new UntypedFormControl(i.id),
          inventory: new UntypedFormControl(i.inventory) 
        })
      );
    })
  }
}
