import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, Inject, inject} from '@angular/core';
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {NgFor} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ArtistsService } from '../artists.service';
import { CountriesService } from '../countries.service';
import { environment } from 'src/environments/environment';

export interface Artist {
  id: string;
}
@Component({
  selector: 'app-merge-artists',
  templateUrl: './merge-artists.component.html',
  styleUrls: ['./merge-artists.component.scss']
})
export class MergeArtistsComponent {
  constructor(
    private ArtistsService: ArtistsService,
    private CountriesService: CountriesService,
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
  ) {

    CountriesService.query().subscribe(data => { this.countries = data });

    this.artistControl.valueChanges.subscribe( value =>
      {
        this.artistsForm.clear();
        this.artists = [];
        this.releases = [];
        this.related = [];
        
        value.forEach((id, i) => {
          this.ArtistsService.get(id).subscribe((subPromise) => {
            this.artists.push(subPromise);

            let group = new UntypedFormGroup({
              name: new UntypedFormControl({value: subPromise.name, disabled: true }),
              bio: new UntypedFormControl({value: subPromise.bio, disabled: true }),
              email: new UntypedFormControl({value: subPromise.email, disabled: true }),
              hometown: new UntypedFormControl({value: subPromise.hometown, disabled: true }),
            })
            this.artistsForm.push(group)
            if (i === this.artistIds.length - 1) {
              console.log(this.artists)
    
              this.artists.forEach(z => {
                this.releases = [ ...this.releases, ...z.releases ];
                this.related = [ ...this.related, ...z.related ]
                })
              console.log(this.releases)
              console.log(this.related)
            }
          });
        })
      }
    );
  }

  
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  artistIds: Artist[] = [];
  artistControl = new UntypedFormControl(['angular']);

  artistsForm: FormArray<FormGroup> = new UntypedFormArray([])

  countries: any[] = []
  artists: any[] = [];
  releases: any[] = [];
  related: any[] = [];

	defaults = { id: null, name: null, bio: null, email: null, hometown: null, country: null }

	default = null


  announcer = inject(LiveAnnouncer);


  mergeArtists() {
		this.http.post(environment.apiSrc + '/mergeArtists', { artists: this.artistIds.map(i => i.id), main: this.default, items: this.defaults } ).subscribe( data => {
			alert('Merge success!')
		})
  }

  isDefault = (id) => (
		this.default === id ? true : false
	)


  makeDefault(section, id) {
		console.log(id)

		if(section === 'id') {
			this.defaults = {	id: id, name: id, bio: id, email: id, hometown: id, country: id }
			this.default = id
		}
		else {
			this.defaults[section] = id
		}
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our artist
    if (value) {
      this.artistIds.push({id: value});
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(artist: Artist): void {
    const index = this.artistIds.indexOf(artist);

    if (index >= 0) {
      this.artistIds.splice(index, 1);

      this.announcer.announce(`Removed ${artist}`);
    }
  }

  edit(artist: Artist, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove artist if it no longer has a name
    if (!value) {
      this.remove(artist);
      return;
    }

    // Edit existing artist
    const index = this.artistIds.indexOf(artist);
    if (index >= 0) {
      this.artistIds[index].id = value;
    }
  }

  updateArtist(event) {
    console.log(event);
  }
}
