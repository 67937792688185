<mat-list style="padding: 0" cdkDropList [cdkDropListData]="files" as-sortable="$ctrl.sortableOptions" data-ng-model="files">
	<div mat-subheader style="display: flex; margin: 0; padding: 8px; position: sticky; z-index: 100; top: 0; background: #373737;">
		<div style="flex: 1; line-height: 48px; padding-left: 8px;">
			<span *ngFor="let f of path; let last = last">
				<span class="folder-handle" (click)="changeDir({folder: f.path, filename: ''})">{{f.handle | backslash}}</span>
				<span *ngIf="!last"> / </span>
			</span>
		</div>
		<div flex="none" *ngIf="!options.zedDigital">
			<button *ngIf="linkFolder" matTooltip="Link Folder" mat-icon-button aria-label="Link Folder" (click)="link()">
				<mat-icon>link</mat-icon>
			</button>
		</div>
	</div>
		<mat-list-item *ngFor="let item of files" data-as-sortable-item cdkDrag [cdkDragDisabled]="item.isDir">
			<div style="display: flex;" class="folder-item" >
				<div [ngClass]="options.zedDigital ? 'zd' : ''" style="flex: 1; line-height: 48px;" (click)="item.isDir ? clickDir(item): clickFile($event, item)"  [style.font-weight]="item.isDir ? 800 : 400">
					{{item.filename | backslash }}
				</div>

				<button *ngIf="!item.isDir" class="item-play" matTooltip="Play" mat-icon-button aria-label="Play" (click)="options.temporary ? playTemp(item) : playFela(item)">
					<mat-icon>play_arrow</mat-icon>
				</button>

				<button *ngIf="options.zedDigital || options.temporary" mat-icon-button [matMenuTriggerFor]="itemMenu" class="more item-menu" aria-label="more"><mat-icon>more_horiz</mat-icon></button>

				<mat-menu #itemMenu="matMenu">
					<button mat-menu-item *ngIf="options.zedDigital || options.temporary" (click)="renameFile(item, $event)"> 
						Rename
					</button>
					<button mat-menu-item *ngIf="options.zedDigital" (click)="archiveFile(item, $event)">
						Archive
					</button>
					<button mat-menu-item *ngIf="options.zedDigital" [disabled]="!bruceFile" (click)="sendToBruce(item, $event)">
						Send to Bruce
					</button>
					<mat-divider></mat-divider>
					<button mat-menu-item *ngIf="options.zedDigital || options.temporary" (click)="deleteFile(item, $event)">
						Delete
					</button>
				</mat-menu>

				<!-- <button *ngIf="!item.isDir && options.drag" mat-icon-button aria-label="Drag" (click)="playFela(item)" class="item-drag" data-as-sortable-item-handle>
					<mat-icon>drag_indicator</mat-icon>
				</button> -->
			</div>
		</mat-list-item>
</mat-list>