import { Component, Inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { KeyValue } from '@angular/common';
import { playlistToM3U } from '../helpers';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SponsorshipCommsDialogComponent } from '../sponsorship-comms-dialog/sponsorship-comms-dialog.component';
import { GridProgram, GridDays, GridHour, GridHours } from '../zeddb.types';
import { Sponsorship } from '../sponsorship';
import { map, startWith, filter, switchMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PromoFolderService } from '../promo-folder.service';
import { environment } from 'src/environments/environment';
import { PlaylistService } from '../playlist.service';
import { AuthenticationService } from '../authentication.service';
import { StudioService } from '../studio.service';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-spot-roster',
  templateUrl: './spot-roster.component.html',
  styleUrls: ['./spot-roster.component.scss']
})
export class SpotRosterComponent extends Sponsorship {
  constructor(
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
		private PromoFolderService: PromoFolderService,
		private socket: SocketService,
    public dialog: MatDialog,
    private PlaylistService: PlaylistService,
    private AuthenticationService: AuthenticationService,
    private StudioService: StudioService
  ) {
    super();
    this.fetchData()
    PromoFolderService.query().subscribe( data => {this.promofolder =  data});
    this.onAir = this.StudioService.getOnAir()
    this.studio = this.StudioService.getStudio()
    this.StudioService.onMessage().subscribe( data => { 
      console.log(data);
      if(data.onAir)
        this.onAir = data.onAir; 
      if (data.studio)
        this.studio = data.studio;
    });

  }

  ngOnInit() {
    this.giveaway.valueChanges.subscribe( value => {
      console.log(value);
    })
  }

  thedate = moment().startOf('isoWeek');
  fmGrid: GridDays = {
    'Monday': {},
    'Tuesday': {},
    'Wednesday': {},
    'Thursday': {},
    'Friday': {},
    'Saturday': {},
    'Sunday': {},
  };
  tabIndex = moment().isoWeekday() - 1;

  promofolder: any[] = [];

  onAir: boolean = false; 
  studio: number | null = null; 

  filteredOptions: Observable<any[]>[] = [];
  giveaway = new UntypedFormGroup({});

  winnerIndex = 0;

  ngAfterViewInit() {
    this.showDialog()
  } 

  fetchData() {
    var theweek = this.thedate.format('YYYY-MM-DD')
		var endweek = moment(this.thedate).add(7, 'days').format('YYYY-MM-DD');

    let fmGridQuery = this.http.get(environment.musicDBapiSrc + '/grid')
    let scheduleQuery = this.http.get(environment.apiSrc + '/schedule/week/' + theweek)
    let liveReadsQuery = this.http.get(environment.apiSrc + '/campaign-by-date/2/' + theweek + '/' + endweek)
    forkJoin(
      fmGridQuery,
      scheduleQuery,
      liveReadsQuery
    ).subscribe( data => { 
      console.log(data)
      let fmGrid: any = data[0];
      let schedule: any = data[1];
      let liveReads: any = data[2];

      Object.values(fmGrid.gridByBlockWeek.Monday).filter((i:any) => i.hour >= 6 && i.hour < 9 || i.hour >= 15 && i.hour < 18 ).forEach( (p:any) => { p.liveReads = liveReads.filter(f => ( moment(this.thedate).day(p.day % 7).isSameOrAfter(moment(f.commence_date), 'day') && moment(this.thedate).day(p.day % 7).isSameOrBefore(moment(f.complete_date), 'day') )).map(a => ({ ...a, 'script': this.testme(a.script, p.day % 7) })) }) 

			Object.values(fmGrid.gridByBlockWeek.Tuesday).filter((i:any) => i.hour >= 6 && i.hour < 9 || i.hour >= 15 && i.hour < 18 ).forEach( (p:any) => { p.liveReads = liveReads.filter(f => ( moment(this.thedate).day(p.day % 7).isSameOrAfter(moment(f.commence_date), 'day') && moment(this.thedate).day(p.day % 7).isSameOrBefore(moment(f.complete_date), 'day') )).map(a => ({ ...a, 'script': this.testme(a.script, p.day % 7) })) }) 

			Object.values(fmGrid.gridByBlockWeek.Wednesday).filter((i:any) => i.hour >= 6 && i.hour < 9 || i.hour >= 15 && i.hour < 18 ).forEach( (p:any) => { p.liveReads = liveReads.filter(f => ( moment(this.thedate).day(p.day % 7).isSameOrAfter(moment(f.commence_date), 'day') && moment(this.thedate).day(p.day % 7).isSameOrBefore(moment(f.complete_date), 'day') )).map(a => ({ ...a, 'script': this.testme(a.script, p.day % 7) })) }) 

			Object.values(fmGrid.gridByBlockWeek.Thursday).filter((i:any) => i.hour >= 7 && i.hour < 9 || i.hour >= 15 && i.hour < 18 ).forEach( (p:any) => { p.liveReads = liveReads.filter(f => ( moment(this.thedate).day(p.day % 7).isSameOrAfter(moment(f.commence_date), 'day') && moment(this.thedate).day(p.day % 7).isSameOrBefore(moment(f.complete_date), 'day') )).map(a => ({ ...a, 'script': this.testme(a.script, p.day % 7) })) }) 

			Object.values(fmGrid.gridByBlockWeek.Friday).filter((i:any) => i.hour >= 6 && i.hour < 9 || i.hour >= 16 && i.hour < 18 ).forEach( (p:any) => { p.liveReads = liveReads.filter(f => ( moment(this.thedate).day(p.day % 7).isSameOrAfter(moment(f.commence_date), 'day') && moment(this.thedate).day(p.day % 7).isSameOrBefore(moment(f.complete_date), 'day') )).map(a => ({ ...a, 'script': this.testme(a.script, p.day % 7) })) }) 

      this.fmGrid = { 'Monday': fmGrid.gridByBlockWeek.Monday, 'Tuesday': fmGrid.gridByBlockWeek.Tuesday, 'Wednesday': fmGrid.gridByBlockWeek.Wednesday, 'Thursday': fmGrid.gridByBlockWeek.Thursday, 'Friday': fmGrid.gridByBlockWeek.Friday, 'Saturday': fmGrid.gridByBlockWeek.Saturday, 'Sunday': fmGrid.gridByBlockWeek.Sunday };

      schedule.forEach(schedule => {
				let scheduletime = moment(schedule.projectedDate ? schedule.projectedDate : schedule.date).hour(schedule.hour).minute(0).second(0);
				let past = moment().isSameOrAfter(scheduletime);
				let hour = scheduletime.format('HH:mm');
				// let day = scheduletime.isoWeekday() - 1;
				let day = scheduletime.format('dddd');


				if (schedule.campaign && schedule.campaign.campaign_type === 3 ) {
					schedule.campaign.campaign_schedule_id = schedule.id;

					if(Number.isInteger(schedule.campaign.winnerspershow) && schedule.campaign.winnerspershow > 0 ) {
            if (!this.giveaway.controls[day])
              this.giveaway.addControl(day, new UntypedFormGroup({}) )

            if (!this.giveaway.controls[day][hour])
              (this.giveaway.controls[day] as FormGroup).addControl(hour, new UntypedFormArray([]))

            let givewayControl: FormArray = (this.giveaway.controls[day] as FormGroup).controls[hour] as FormArray;
            
            schedule.campaign.winnerIndex = this.winnerIndex;


            for(let i=0; i < schedule.campaign.winnerspershow; i++) { 

              givewayControl.push(new UntypedFormControl(schedule.winners && schedule.winners[i]));
              let winnersControl = givewayControl.at(i);
              this.filteredOptions[this.winnerIndex + i] = winnersControl.valueChanges.pipe(
                filter(value => value?.length > 1),
                debounceTime(250),
                switchMap(value => value ? this.searchZedders(value) : [])
              );
  
              
              // winnersControl.valueChanges.subscribe( value => { console.log(value) })

						} 

            console.log(givewayControl)
            console.log(this.giveaway);
						this.fmGrid[day][hour].hasGiveaway = true;
						schedule.winners.forEach(i => { i.search = i.firstname + ' ' + i.lastname })
						schedule.campaign.winners = schedule.winners ? schedule.winners : [];
						let existingWinners = schedule.winners.length;
						for(let i=0; i < (schedule.campaign.winnerspershow - existingWinners); i++) { 						
							console.log('where')
							schedule.campaign.winners.push({search: ''});
						} 

            this.winnerIndex = this.winnerIndex + 2;
					} 
				}
				this.fmGrid[day][hour].past = past;
				if (!this.fmGrid[day][hour].promo) {
					this.fmGrid[day][hour].promo = [];
				}
				if(moment(theweek).add(7, 'days').isSameOrAfter(schedule.date)) { 
					this.fmGrid[day][hour].promo.push({...schedule.campaign, date: schedule.date, calDate: moment(theweek).add(day, 'days').format('YYYY-MM-DD') })
				}
			});

      console.log(fmGrid, schedule);
      let dayName = moment().format('dddd');
      console.log(dayName)
      this.getPlays(dayName);
    });
  }


  subscriberSearch = (searchTerm) => {
    return this.http.post(environment.apiSrc + '/zedders', { searchString: searchTerm}).subscribe( data => {
      return data;
    });
  };


  submitWinner = (item, giveaway, winner, x) =>  {

    console.log(x)
    let zedder = this.giveaway.value[item.day][item.hour][item.winner];
    console.log(zedder)
    item.submitted = true;
    
    if ( x.pivot ) {
      console.log('update', x.pivot);
      console.log(zedder, x.pivot)
      this.http.put(environment.apiSrc +'/scheduled-giveaway', { pivot_id: x.pivot.id, zedder_id: zedder ? zedder.id : null }).subscribe( response => {
        x.success = true;
      } );

      return;
    }
    if ( winner && zedder ) {
      console.log('new');
      console.log(item, giveaway, winner)
      console.log(zedder.id, giveaway.campaign_schedule_id, giveaway.id)
      console.log(zedder, giveaway, winner)

      this.http.post(environment.apiSrc +'/scheduled-giveaway', { zedder_id: zedder.id, campaign_schedule_id: giveaway.campaign_schedule_id, campaign_id: giveaway.id }).subscribe( response => {
        x.success = true;
      });
    }
    if ( winner && !zedder ) {
      console.log('no zedder')
      console.log(item, giveaway, winner)
    }
    if ( !winner )
      console.log('no winner')

  }

  getPlays(day) {
    let calday = this.thedate.isoWeekday(this.tabIndex + 1);
    console.log(day)
    console.log(calday.format('YYYY-MM-DD'))

    this.http.get<any>(environment.musicDBapiSrc + '/mairlist/promo/date/'+ calday.format('YYYY-MM-DD')).subscribe( data => {
      console.log(data);

      let betweenTimes = Object.values(this.fmGrid[day]).map( (i:any) => [moment(calday).hour(i.hour), moment(calday).hour(i.hour).add( i.duration, 'seconds' )])
      console.log(betweenTimes);

      data.map( play => betweenTimes.forEach( (t, i) =>  { if(moment(play.time).isBetween(t[0], t[1])) {
        // console.log((play.time), i, t);
        if (!this.fmGrid[day][t[0].format('HH:mm')].plays) { this.fmGrid[day][t[0].format('HH:mm')].plays = []; }
        let file = play.filename.replace(/^(.*[\\\/])?(.*?\.[^.]*?|)$/, '$2')
        // let file = play.filename.replace('S:\\SPONSORSHIP SPOTS\\', '')
        // file = file.replace('\\\\fela\\audio\\SPONSORSHIP SPOTS\\', '')
        let item = this.fmGrid[day][t[0].format('HH:mm')].promo && this.fmGrid[day][t[0].format('HH:mm')].promo.find(f => f.media_location === file);
        if (item) { 
          item.played = true; 
          play.checked = true 
        }
        this.fmGrid[day][t[0].format('HH:mm')].plays.push(play)

      }
      }))
    })
  }

  originalOrder = () => 0

  playTemp = (filename) => {
    let item = {filename: filename, share: 'fela'};
    this.PlaylistService.prepForBroadcast({action: 'queueTempAndPlay', playlist: [ item ]})
    // let file = item;
    // let url = environment.musicDBapiSrc + '/temporary/dl'
    // this.http.post<any>(url, { file: file, share: 'fela' }).subscribe( data => {
    //   var token = data.token
    //   var url =  environment.dbUrl + '/fela/' + token
    //   this.$rootScope.player.src = url;
    //   this.$rootScope.player.play()
    // });
  }

  totalLength = (array) => { 
    let total = array.map((i) => ( i.media_length ? i.media_length : 0 ) ).reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue));
    return Math.ceil(total);
  }

  mediaExists = (file) => {
    let result = this.promofolder.find( i => i.file === file);
    return result ? true : false;
  }

  displayFn(value: any): string {
    return value && value.firstname ? value.firstname + ' ' + value.lastname : '';
  }

  
  attachSpots = (hour) => {
    let spots = hour.promo.map( i => ( { length: i.media_length, title: i.title, file: 'S:\\SPONSORSHIP SPOTS\\' + i.media_location }))
    let data = playlistToM3U(spots);
    let studio = 'studio' + this.studio;
    this.http.post(environment.musicDBapiSrc + '/save-playlist', { file: studio, destination: '/Tech', playlist: data, share: 'temporary'}).subscribe( data => {
      this.socket.send({ appendTrack: { id: String(this.AuthenticationService.getUser().details.id).padStart(5, '0'), studio: this.studio, file: 'T:/Tech/Playlists/studio'+ this.studio + '.m3u' } });
    })
  }

  downloadSpots = (hour) => {
    let spots = hour.promo.map(i => i.id)
    console.log(hour)
    console.log(spots)
    console.log(this.thedate.day(hour.day))
    let url = environment.apiSrc + '/sponsorship/dl'
    this.http.post(url, { spots:  spots}, {responseType:'arraybuffer'}).subscribe(data => {
      var file = new Blob([data], { type: 'application/octet-stream' });
      // var fileUrl = URL.createObjectURL(file);
      // $sce.trustAsResourceUrl(fileUrl);
      var a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = 'spots-' + hour.program.slug + '-' + this.thedate.day(hour.day).format('YYYY-MM-DD') + ".zip";
      a.click();
    });
  }
  
  showDialog() {
    this.dialog.open(SponsorshipCommsDialogComponent, {
      height: '80vh',
      width: '640px',
      data: {
  
      },
    });
  }

  testme (script, day) {
    let theday = moment(this.thedate).day(day % 7);
    let tempDel = document.createElement('div');
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = script;
    let allDiv = tempDiv.querySelectorAll('li');
    allDiv.forEach(i => { 
      let regex = /^(([A-Z]\w+) ([0-9A-Z]\w+) ([A-Z]\w+)) [-–] /
      let matches = i.innerText.match(regex);
      if(matches){
        let year = new Date().getFullYear()
        let listingDate = moment(matches[1], 'dddd Do MMMM');
        // console.log(listingDate.format('YYYY-MM-DD'));
        if(listingDate.isSame(theday, 'day')) {
          i.innerHTML = i.innerHTML.replace(regex, '<b>TODAY/TONIGHT</b> - ')
          // console.log(i.innerHTML	)

        }
        if(listingDate.isBefore(theday, 'day')) {
          // console.log('past')
          // tempDel.appendChild(i);
          // tempDel.appendChild(i);
          // i.outerHTML = tempDel.outerHTML;]

          i.classList.add('deleted')
          // i.innerHTML = i.innerHTML.replace(regex, '<b>PAST</b> - ')
          // console.log(i.innerHTML	)
        }
        return matches[1];
      }
      else {
        return null
      }
    })
    
    return tempDiv.innerHTML;

  }


  searchZedders(searchTerm) {
    console.log(searchTerm);
		return this.http.post<any>(environment.apiSrc + '/zedders', { searchString: searchTerm }).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
	}


}
