<ng-container *ngIf="tracks.length >= 50">
	<cdk-virtual-scroll-viewport [style.height]="tracks.length < 50 ? ((tracks.length + tools) * 33)+ 'px' : '300px'"  itemSize="30" class="example-viewport" [maxBufferPx]="1200" cdkDropList cdkDropListSortingDisabled [cdkDropListDisabled]="!announcer">
		<div class="track-wrapper"  *cdkVirtualFor="let track of tracks">
			<app-track-list [track]="track" [favourite]="favourite" [play]="play" [order]="order" (onTrackSelected)="trackSelected(track)" />
		</div>
	</cdk-virtual-scroll-viewport>
</ng-container>

<ng-container *ngIf="tracks.length < 50" >
	<div cdkDropList cdkDropListSortingDisabled [cdkDropListDisabled]="!announcer">
		<div class="track-wrapper"  *ngFor="let track of tracks">
			<app-track-list [track]="track"  [favourite]="favourite" [play]="play" [order]="order" (onTrackSelected)="trackSelected(track)" />
		</div>
	</div>
</ng-container>
