import { Component, inject, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from "@angular/common";
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';
import { UIRouter } from "@uirouter/core";
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Transition } from "@uirouter/angular";
import { ReleasesBaseComponent } from '../releases-archive/releases.base.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipEditedEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { merge, cloneDeep } from "lodash";
import { SessionService } from '../session.service';
import { PlaylistService } from '../playlist.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

interface Artist { 
  id: number; 
  name: string; 
  hometown: string; 
  country: string;
  aliases: any[];
  related: any[]; 
  email: string;
  website: string; 
  pronouns: string; 
  bio: string;
  cont_ausnz: string;
  cont_local: boolean;
  cont_female: string;
  first_nations: boolean;
  genderdiverse: boolean;
  genderna?: string;
} 

@Component({
  selector: 'app-releases-artist',
  templateUrl: './releases-artist.component.html',
  styleUrls: ['./releases-artist.component.scss']
})
export class ReleasesArtistsComponent extends ReleasesBaseComponent implements OnInit {
  @ViewChild('aliasInput')
  aliasInput!: ElementRef<HTMLInputElement>;

  @ViewChild('relatedInput')
  relatedInput!: ElementRef<HTMLInputElement>;


  constructor(
    router: Router,
    location: Location,
    http: HttpClient,
		// @Inject('$rootScope') $rootScope: any,
		PlaylistService: PlaylistService,
		SessionService: SessionService,
    private AuthenticationService: AuthenticationService,
    // protected trans: Transition,
    private route: ActivatedRoute
  ) {
    super(router, location, http, PlaylistService, SessionService);

    this.artistForm.valueChanges.subscribe( () => {
      console.log('CHANGED!', this.artistForm)
      this.artist = merge(this.artist, this.artistForm.value);
      // this.release = Object.assign(this.release, this.releaseForm.value);
      // this.release = structuredClone(this.release, this.releaseForm.value);
      this.formEdited = true;
      
    });
  }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('artist');

    const term = isNaN(Number(this.id)) ? '/artist-releases/' : '/artist-releases-by-id/';
    console.log(term);
    this.http.get<any>(environment.apiSrc + term + this.id ).subscribe( data => { 

      let artist;
      if (data instanceof Array) {
        artist = data[0];
      }
      else {
        artist = data;
      }
      this.artist = artist;
      this.releases = [...this.releases, ...artist.releases ];

      this.artistForm.patchValue({
        name: artist.name, 
        hometown: artist.hometown, 
        country: artist.country, 
        email: artist.email, 
        website: artist.website, 
        pronouns: artist.pronouns, 
        bio: artist.bio,
        aliases: artist.aliases,
        related: artist.related
      }, { emitEvent: false });

      this.formDisabled = !this.AuthenticationService.requireAccess('music');

      if (this.formDisabled) {
        this.artistForm.disable(); 
        this.aliasControl.disable();
        this.relatedControl.disable();
      }


      this.http.get<any>( environment.musicDBapiSrc  + '/coverArt/1000/' + artist.id + '/artist', { responseType: 'blob' as 'json'}).subscribe( data => { 
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          this.artistCover =  reader.result;
        }
      });

    });

    this.aliasOptions = this.aliasControl.valueChanges.pipe( 
      filter((value) => value?.length > 2),
      switchMap( value => value ? this.searchArtists(value) : [])
    )

    this.relatedOptions = this.relatedControl.valueChanges.pipe( 
      filter((value) => value?.length > 2),
      switchMap( value => value ? this.searchArtists(value) : [])
    )

    this.hometownOptions = this.artistForm.get('hometown')!.valueChanges.pipe( 
      filter((value) => value?.length > 1),
      switchMap( value => value ? this.searchHometown(value) : [])
    )

    this.countryOptions = this.artistForm.get('country')!.valueChanges.pipe( 
      filter((value) => value?.length > 1),
      switchMap( value => value ? this.searchCountry(value) : [])
    )



  }

  formDisabled = false;
  formEdited = false;
  locked = false;

  artistForm = new UntypedFormGroup({
    name: new UntypedFormControl(null), 
    hometown: new UntypedFormControl(null), 
    country: new UntypedFormControl(null), 
    aliases: new UntypedFormControl([]),
    related: new UntypedFormControl([]),
    email: new UntypedFormControl(null),
    website: new UntypedFormControl(null), 
    pronouns: new UntypedFormControl(null), 
    bio: new UntypedFormControl(null),
  });

  artist: Artist = { 
    id: 0, 
    name: '', 
    hometown: '', 
    country: '', 
    aliases: [], 
    related: [], 
    email: '', 
    website: '', 
    pronouns: '', 
    bio: '',
    cont_ausnz: '',
    cont_local: false,
    cont_female: '',
    first_nations: false,
    genderdiverse: false
  };

  readonly separatorKeysCodes = [ENTER, COMMA] as const;


  files: any[] = [];
  releases: any[] = [];

  artistCover: any = null;
  // params = this.trans.params();
  // id = this.params['artist'];
  id: string | null = null;

  aliasOptions!: Observable<any>;
  aliasControl = new UntypedFormControl('');
  relatedControl = new UntypedFormControl('');

  announcer = inject(LiveAnnouncer);
  
  artistOptions!: Observable<any>;
  relatedOptions!: Observable<any>;
  hometownOptions!: Observable<any>;
  countryOptions!: Observable<any>;

  changeQuota = (cat) => {
    if (cat === 'a')
      this.artist.cont_ausnz = this.artist.cont_ausnz === 'A' ? '' : 'A';
    if (cat === 'nz')
      this.artist.cont_ausnz = this.artist.cont_ausnz === 'NZ' ? '' : 'NZ';
    if (cat === 'l')
      this.artist.cont_local = !this.artist.cont_local;
    if (cat === 'f')
     this.artist.cont_female = this.artist.cont_female === 'F' ? '' : 'F';
    if (cat === 'i')
      this.artist.first_nations = !this.artist.first_nations;
    if (cat === 'gnc')
      this.artist.genderdiverse = !this.artist.genderdiverse;

    this.artistForm.updateValueAndValidity();

  } 

  gotoArtist = (id) => {
    this.router.navigateByUrl('/releases/artists/'+ id)
  }
  saveArtist = () => {
    console.log(this.artist)
    this.locked = true;
    this.http.post<any>(environment.apiSrc + '/artist/' + this.artist.id, this.artist ).subscribe( data => { 
      this.locked = false;
		});
  }

  fileBrowseHandler(target ) {
    this.prepareFilesList(target.files);
  }


  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadCover();
    // this.uploadFilesSimulator(0);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  uploadCover() { 
    
    if (!this.files.length) {
      return;
    } else {
      const formData = new FormData();
      formData.append('file', this.files[0]);
      formData.append('artistId', String(this.artist.id));

      this.http.post<any>(environment.musicDBapiSrc + '/coverupload', formData,  {responseType: 'text' as 'json'}).subscribe(
        data => { 
          this.artistCover = data;
          this.deleteFile(0);
         }
      )
    }
  }




  removeAlias(artist: any, ): void {
    const index = this.artistForm.get('aliases')!.value.indexOf(artist);

    if (index >= 0) {
      this.artistForm.get('aliases')!.value.splice(index, 1);
      // this.artistAliases.splice(index, 1);

      this.announcer.announce(`Removed artist alias ${artist}`);
    }
    this.artistForm.updateValueAndValidity();
  }

  editAlias(artist: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeAlias(artist);
      return;
    }

    // Edit existing fruit
    const index = this.artistForm.get('aliases')!.value.indexOf(artist);
    // const index = this.artistAliases.indexOf(artist);
    if (index >= 0) {
      this.artistForm.get('artist.aliases')!.value[index].name = value;
    }
    this.artistForm.updateValueAndValidity();
  }

  selectedAlias(event: any): void {
    let program = event.option.value;
    this.artistForm.get('aliases')!.value.push({ id: program.id, name: program.name, slug: program.slug});
    this.aliasInput.nativeElement.value = '';
    this.aliasControl.setValue('');
    this.artistForm.updateValueAndValidity();
  }


  removeRelated(artist: any, ): void {
    // const index = this.relatedArtists.indexOf(artist);
    const index = this.artistForm.get('related')!.value.indexOf(artist);

    if (index >= 0) {
      this.artistForm.get('related')!.value.splice(index, 1);

      this.announcer.announce(`Removed related artist ${artist}`);
    }
    this.artistForm.updateValueAndValidity();
  }

  editRelated(artist: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeRelated(artist);
      return;
    }

    // Edit existing fruit
    const index = this.artistForm.get('related')!.value.indexOf(artist);
    // const index = this.relatedArtists.indexOf(artist);
    if (index >= 0) {
      this.artistForm.get('related')!.value[index].name = value;
    }
    this.artistForm.updateValueAndValidity();
  }

  selectedRelated(event: any): void {
    let program = event.option.value;
    this.artistForm.get('related')!.value.push({ id: program.id, name: program.name, slug: program.slug});
    this.relatedInput.nativeElement.value = '';
    this.relatedControl.setValue('');
    this.artistForm.updateValueAndValidity();
  }



  fetchArtist(item) {
    this.http.get<any>(environment.apiSrc + '/artist-by-id/' + item.id).subscribe( data => { 
      console.log(data)
      if (data) {
        let artist = data;
        // this.release.artist = artist;
        // this.artist.patchValue({ artist: { name: artist.name, hometown: artist.hometown,  country: artist.country, website: artist.website, email: artist.email, pronouns: artist.pronouns, bio: artist.bio }, artist_nm: artist.name, aliases: artist.aliases, related: artist.related }, { emitEvent: false })


      }
    })
  }

  searchArtists = (artist) => {
		return this.http.get<any>(environment.apiSrc + '/artist-suggest/' + artist).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
	}

  searchHometown = (hometown) => {
    return this.http.get<any>(environment.apiSrc + '/hometownsuggest/' + hometown).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
  }

  searchCountry = (country) => {
    return this.http.get<any>(environment.apiSrc + '/countrysuggest/' + country).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
  }


}
