import { Component, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UIRouter } from "@uirouter/core";
import { Location } from "@angular/common";
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { ReleasesBaseComponent } from '../releases-archive/releases.base.component';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray, DragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import { SessionService } from '../session.service';
import { PlaylistService } from '../playlist.service';
import { FavouriteService } from '../favourite.service';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute, Router, NavigationEnd, ChildActivationEnd } from '@angular/router';
import { P } from '@angular/cdk/keycodes';
import { filter, tap, buffer, takeUntil, switchMapTo, map, shareReplay } from 'rxjs/operators';

import { Subject, defer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AnnouncerPlaylistDialogComponent } from '../announcer-playlist-dialog/announcer-playlist-dialog.component';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss']
})
export class ReleasesComponent extends ReleasesBaseComponent {
  @ViewChild('quickSearchEl', { static: false }) quickSearchEl!: ElementRef<HTMLInputElement>;

  constructor(
    router: Router,
    location: Location,
    http: HttpClient,
		// @Inject('$rootScope') $rootScope: any,
		PlaylistService: PlaylistService,
	  SessionService: SessionService,
		private FavouriteService: FavouriteService,
    private changeDetectorRef: ChangeDetectorRef,
    private AuthenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private dragDrop: DragDrop
  ) {
    super(router, location, http, PlaylistService, SessionService);

    console.log(route);
    // this.uiRouter.transitionService.onStart( {},  ( transition ) => { 
    //   // this.routerState = transition.to();

    //   this.searchTerm = transition.router.globals.params['#'];
    //   if (this.searchTerm)
    //     this.quickSearch(this.searchTerm);
    // })

    this.route.params.subscribe(data => {
      console.log('CHANGES YO YO YO')
      this.route.firstChild?.data.subscribe(data => {
        this.routerState = data['type'];
      });
    })

  }


  ngOnInit(){

    this.zedderId = this.AuthenticationService.getUser().details?.id;
    this.SessionService.onchanges( () => this.getFavourites() )

    const routeEndEvent$ = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        tap(() => console.warn("END")),
        takeUntil(this.ngUnsubscribe$)
    );

    this.router.events
      .pipe(
        filter(e => e instanceof ChildActivationEnd && e.snapshot.component === this.route.component),
        buffer(routeEndEvent$),
        map(([ev]) => ev && (ev as ChildActivationEnd).snapshot.firstChild?.data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe( data => {
        this.routerState = data && data['type'];
      })
  }

  ngOnDestroy () {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }



  dragMoved(event) {
    // console.log(event);
    // console.log(this.dragDrop)
    if(Math.abs(event.distance.x) > Math.abs(event.distance.y) && event.distance.x < 50) {
      this.PlaylistService.sendPlaylistMessage(true);
      setTimeout( () => { 
        event.source._changeDetectorRef.detectChanges();
        // event.source._dragRef._dropContainer._cacheParentPositions()
        // console.log('changes?')
      } , 1000 );
      // event.source._dragRef.dropContainer['_cachePositions']();
    }
    else {
      this.PlaylistService.sendPlaylistMessage(false);
    }
    
  }

  requireAccess = (acl) => this.AuthenticationService.requireAccess(acl);

  private ngUnsubscribe$ = new Subject();

  searchTerm = null;
  showFavouiteList = false;
  qs = false;

  public draggingOutsideSourceList: boolean = false;

  zedderId = null;

  public routerState;

  downloadPlaylist() {

		var data = "#EXTM3U \r\n";
		for (var i = 0; i < this.favourites.playlist.length; i++) {
			var fav = this.favourites.playlist[i];
			var text = fav.fullpath;
			var link;
			if (navigator.platform.search('Win') !== -1) {
				console.log('win');
				link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
			}

			if (navigator.platform.search('Mac') !== -1) {
				console.log('mac');
				link = text.replace('/mnt/fela', '/Volumes/audio')
			}

			if (navigator.platform.search('Linux') !== -1) {
				console.log('lin');
				console.log(text);
				link = text;

			}

			data += "#EXTINF:" + fav.title_id + ", " + fav.artist + " - " + fav.title +"\r\n";
			data += link + "\r\n";
		}

    const blob = new File([data], 'playlist.m3u', { type: 'audio/x-mpegurl' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);

		// var blob = new Blob([data], { type: 'text/plain' })

		// var url = window.URL || window.webkitURL;

		// console.log(url)
		// this.fileUrl = url.createObjectURL(blob);
		// console.log(this.fileUrl)
  }

  savePlaylist() {

    const dialogRef = this.dialog.open(AnnouncerPlaylistDialogComponent, {
      data: {
        tracks: this.favourites.playlist.map( i => i.title_id) 
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'abort') {
        console.log(result);
       
      }
    });

    // this.http.post(environment.musicDBapiSrc + '/create-playlist', { 'name': 'test', 'zedder_id': this.zedderId, tracks: this.favourites.playlist.map( i => i.title_id) }).subscribe(
    //   data => {
    //     console.log(data);
    //   }
    // )
  }

  playPlaylist() {
    this.PlaylistService.prepForBroadcast({ playlist: this.favourites.playlist });
  }

  clearFavourites() {
    this.SessionService.unset('favourites');
  }

  drop(event: any ) {
    moveItemInArray(this.favourites.playlist, event.previousIndex, event.currentIndex);
    this.SessionService.set('favourites', JSON.stringify(this.favourites) );
    this.FavouriteService.prepForBroadcast('update');
    this.draggingOutsideSourceList = false;
  }

  onCdkDragDropped(event: CdkDragDrop<string>) {
    console.log(event.container !== event.item.dropContainer);
    this.draggingOutsideSourceList = false;

  }
  
  onCdkDragEntered(event: CdkDragEnter<string>) {
    console.log(event.container !== event.item.dropContainer);
    this.draggingOutsideSourceList = event.container !== event.item.dropContainer;
  }

  toggleQuickSearch = () => {
    this.qs = !this.qs;
    if(this.qs) {
      this.changeDetectorRef.detectChanges();
      this.quickSearchEl.nativeElement.focus();
    }
    else {
      this.searchSub = '';
    }
  }

  toggleFavouriteList = () => { 
    this.showFavouiteList = !this.showFavouiteList;
    if(this.showFavouiteList)
      this.getFavourites()
    console.log(this.showFavouiteList) 
  }

  getFavourites = () => {
		let favtemp = this.SessionService.get('favourites');
		this.favourites = favtemp ? JSON.parse(favtemp) : [];
    console.log(this.favourites);
  }

}
