const template = `<table style="padding: 0px; margin: 0px; width: 100%; background: #000000 url('https://zedletter.4zzz.org.au/skinning.jpg') repeat scroll 0% 0%; background-color: #000000; background-image: url('http://zedletter.4zzz.org.au/skinning.jpg'); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto;" width="100%" bgcolor="#000000">
<tbody>
  <tr style="background-image: url('none'); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto; background-color: #000000;" bgcolor="#000000">
    <td style="background-image: url('http://zedletter.4zzz.org.au/skinning.jpg'); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto;">
      <table style="padding: 10px; margin: 0px auto; background: #ffffff url('https://zedletter.4zzz.org.au/none') repeat scroll 0% 0%; border: 0px solid #eeeeee; width: 713px; min-height: 500px;" border="0" width="713" cellspacing="0" cellpadding="10">
        <tbody>
          <tr>
            <td style="paddinhg-bottom: 10px; text-align: center;">
                <img src="https://zedletter.4zzz.org.au/ZedletterHeader2023.png" alt="" width="800px" />
             </td>
          </tr>

          <tr style="min-height: 400px;">
            <td style="border-top: 7px solid #000;">

            </td>
          </tr>

          <tr>
            <td style="text-align: center; border-top: 7px solid #000; background: #fff;" colspan="3" align="center" height="30">
              <a href="http://twitter.com/4zzzradio" target="_blank">
                <img style="letter-spacing: normal; width: 42px; height: 42px; padding: 2px;" src="http://zedletter.4zzz.org.au/Xlogo.png" alt="" width="42" height="42" />
              </a>
              <a href="https://www.facebook.com/4zzzradio" target="_blank">
                <img style="white-space: normal; width: 42px; height: 42px; padding: 2px;" src="http://zedletter.4zzz.org.au/FB-logo.png" alt="" width="42" height="42" />
              </a>
              <a title="Instagram" href="http://instagram.com/4zzzradio" target="_blank">
                <img style="background-color: #ffffff; width: 42px; height: 42px; padding: 2px;" src="http://zedletter.4zzz.org.au/Insta-logo.png" alt="instagram" width="42" height="42" />
              </a>
              <br />
              <strong style="font-weight: 400; font-family: Verdana, Geneva, sans-serif; font-size: 10px;">
                 That's all for this week, be sure to check your inbox next week for the next instalment of Zedletter!
                <br data-highlightable="1" />
                 For more information hit
                <a href="http://4zzz.org.au" data-highlightable="1">
                   4zzz.org.au
                  <br style="float: none;" data-highlightable="1" />
                </a>
                <br />
                 [[UNSUBSCRIBE]]
                  <br />
                  <em>Respectfully acknowledging the traditional owners and custodians of the country on which we live and that their sovereignty over this land was never ceded.</em>

                <a href="http://www.4zzzfm.org.au" data-highlightable="1">
                  <br data-highlightable="1" />
                </a>
              </strong>
            </td>
          </tr>
          <tr>
          </tr>
        </tbody>
      </table>
      <p>
         
      </p>
    </td>
  </tr>
</tbody>
</table>
<p style="display: none;">
 
</p>
<p>
 
</p>
`;
export default template;
