import { Component, Input, Inject, ViewChild, ElementRef, Output, EventEmitter, OnChanges} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import{ MatDialog } from '@angular/material/dialog';
import { RenameDialogComponent } from '../rename-dialog/rename-dialog.component';
import { SendToBruceDialogComponent } from '../send-to-bruce-dialog/send-to-bruce-dialog.component';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlaylistService } from '../playlist.service';

@Component({
  selector: 'app-fela-browser',
  templateUrl: './fela-browser.component.html',
  styleUrls: ['./fela-browser.component.scss']
})
export class FelaBrowserComponent implements OnChanges {
  @ViewChild('scroll')
  scroll!: ElementRef;
  

  @Input() folder: string = '';
  @Input() sortableOptions!: string;
  @Input() tld: string = 'relative';
  @Input() playlistDate!: string;
  @Input() fileCallback!: (arg: string, arg1: string) => void;
  @Input() options: any = { zedDigital: false, temporary: false };
  @Input() program!: any;
  @Input() refresh?: any;
  @Output() out = new EventEmitter<any>();
  @Output() linkFolder = new EventEmitter<any>();


  constructor(
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
    public dialog: MatDialog,
    private PlaylistService: PlaylistService
  ) {
    console.log(this.folder);
    // this.fetchFolder(true);


  }

  // $scope.$on('uploaded', function (event, args) {
  //   console.log(args.folder);
  //   $scope.fetchFolder();
  // });

  ngOnInit() {
    console.log(this.folder);
    this.program = this.program
    this.tld = this.tld ? this.tld : 'relative';

    if( this.folder) {
      this.folder = this.folder;
      if(this.tld === 'relative') { 
        this.basePath = { handle: this.folder.split("/").pop(), path: this.folder }
      }
      if(this.tld === 'fela') { 
        this.basePath = { handle: 'FELA', path: '/'}
      }
      if(this.tld === 'temporary') { 
        this.basePath = {handle: 'TEMPORARY', path: '/'}
      }
      console.log(this.basePath)
      this.path = [this.basePath];

      this.fetchFolder(true);
      
    }	
    if (this.playlistDate)
      this.bruceFile = this.playlistDate;


    if (this.refresh) {
      this.refreshSubscription = this.refresh.asObservable().subscribe(data => {
        this.fetchFolder(true);
      })
    }

  }
  
  ngOnDestroy() {
    this.refreshSubscription && this.refreshSubscription.unsubscribe();
  }
  
  ngOnChanges(changes) {
    console.log(this.folder);
    console.log(changes);
    // debugger;

    this.program = this.program
    this.tld = this.tld ? this.tld : 'relative';

    if( this.folder) {
      this.folder = this.folder;
      if(this.tld === 'relative') { 
        this.basePath = { handle: this.folder.split("/").pop(), path: this.folder }
      }
      if(this.tld === 'fela') { 
        this.basePath = { handle: 'FELA', path: '/'}
      }
      if(this.tld === 'temporary') { 
        this.basePath = {handle: 'TEMPORARY', path: '/'}
      }
      console.log(this.basePath)
      this.path = [this.basePath];

      this.fetchFolder(true);
      
    }	
    if (this.playlistDate)
      this.bruceFile = this.playlistDate;

  }



  basePath: any = { handle: '', path: '' }
  path =[this.basePath];
  files: any[] = []
  bruceFile = '';
  refreshSubscription;

  makeDir = function () {
    
  }

  link = () => {
    this.linkFolder.emit(this.folder);
  }

  renameFile = (file, ev) => {
    const dialogRef = this.dialog.open(RenameDialogComponent, {
      data:{ 
        file: file,
        extension: file.filename.slice(file.filename.lastIndexOf("." ) + 1),
        rename: file.filename
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result !== 'abort') {
        console.log('I will rename');
        
        this.http.post<any>(environment.musicDBapiSrc  + '/renameZDFile', { folder: file.folder, file: file.filename, destination: result } ).subscribe( () => {
          this.fetchFolder(true)
        });
      }
      else {
        console.log('Cancelled Rename');
      }
    });
  };


  playTemp = (item) => {
    item.file = item.folder + '/' + item.filename;
    this.PlaylistService.prepForBroadcast({action: 'queueTempAndPlay', playlist: [ item ]})

    // let file = item.folder + '/' + item.filename;
    // let url = environment.musicDBapiSrc + '/temporary/dl'
    // this.http.post<any>(url, { file: file, share: 'temporary' }).subscribe( data => { 
    //   var token = data.token
    //   var url = environment.dbUrl + '/fela/' + token
    //   this.$rootScope.player.src = url;
    //   this.$rootScope.player.play()
    // });
  }


  playFela = (item) => {
    item.file = item.folder + '/' + item.filename;
    this.PlaylistService.prepForBroadcast({action: 'queueSingleAndPlay', playlist: [ item ]})
  //     let file = item.folder + '/' + item.filename;
  //   let url = environment.musicDBapiSrc + '/fela/dl'
  //   this.http.post<any>(url, { file: file, share: 'fela' }).subscribe( data => { 
  //     var token = data.token
  //     var url = environment.dbUrl + '/fela/' + token
  //     this.$rootScope.player.src = url;
  //     this.$rootScope.player.play()
  //   });
  }

  sendToBruce = (file, ev) => {
    const dialogRef = this.dialog.open(SendToBruceDialogComponent, {
      data:{ 
        file: file,
        rename: this.playlistDate
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result !== 'abort') {
        console.log('I will rename');
        this.http.post<any>(environment.musicDBapiSrc  + '/sendToBruce', { source: file.folder + '/' + file.filename, destination: result } ).subscribe( () => {
          this.fetchFolder(true)
        });
      }
      else {
        console.log('Cancelled Send');
      }
    });
  };


  deleteFile = (file, ev) => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data:{ 
        title: 'Delete File',
        message: 'Delete file '+ file.filename +'?',
        confirm: 'Delete',
        abort: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result === 'confirm') {
        console.log('I will delete');
        this.http.post<any>(environment.musicDBapiSrc  + '/deleteZDFile', { file: file.folder + '/' + file.filename } ).subscribe( () => {
          this.fetchFolder(true)
        });
      }
      else {
        console.log('Cancelled Deletion');
      }
    });
  };

  archiveFile = (file, ev) => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data:{ 
        title: 'Archive File',
        message: file.filename + ' will be moved to archive folder.',
        confirm: 'Archive',
        abort: 'Cancel',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result === 'confirm') {
        console.log('I will archive');
        this.http.post<any>(environment.musicDBapiSrc  + '/archiveZDFile', { file: file.folder + '/' + file.filename, destination: '/Digital Radio/Zed Digital PROGRAMS/' + this.program.name + '/' } ).subscribe( () => {
          this.fetchFolder(true)
        });
      }
      else {
        console.log('Cancelled Archiving');
      }
    });
  };

  fetchFolder = (change) => {
    if (!this.folder)
      return;

    let folder = this.folder.slice(-1) === '/' ? this.folder : this.folder + '/'
    let params: { folder: string, share?: string} = {
      folder: folder
    }
    if (this.options && this.options.temporary) {
      params = {
        ...params, share: 'temporary'
      }
    }
    this.http.post<any>(environment.musicDBapiSrc + '/list-fela', params).subscribe(  data => {
      this.files = data.files.sort((a,b) => { 
        if (a.isDir && !b.isDir)
          return -1;
        if (!a.isDir && b.isDir)
          return 1;
        if (a.isDir && b.isDir || !a.isDir && !b.isDir) {
          return a.filename.localeCompare(b.filename);
        }
      });
  
      this.folder = data.folder
      if (change) {
        this.out.emit(data)
        // this.scroll.nativeElement.scrollTop = 0
      }
      let lastFolder = '';
      let path: any[] = [];
      let filepath
      // var filepath = $scope.folder.split('/')

      
      if (this.basePath.path === '/') {
        filepath = this.folder?.split('/')
      } else {
        filepath = this.folder?.replace(this.basePath.path, this.basePath.handle).split('/')
      }
      filepath.forEach( (i, k) => {

        path.push({ 
          'handle': k === 0 ? this.basePath.handle : i, 
          'path': (this.basePath.path === '/' ? '' : this.basePath.path) + '/' + lastFolder + ( i === this.basePath.handle ? '' : i )   });
        console.log(i);
        if (k !== 0) { 
          lastFolder = lastFolder + i + '/';
        }
      })
      this.path = path;
      console.log(path)
    })
  }

  changeDir = folder => {
    if (folder.filename && folder.filename !== '') {
      this.folder = folder.folder + '/' + folder.filename;
    } else { 
      this.folder = folder.folder;
    }
    this.fetchFolder(true);
     // $http.get($rootScope.musicDBapiSrc + '/list-fela', {
    // 	params: {folder: $scope.folder}
    // }).then(function(response) {
    // 	$scope.files = response.data.files;
    // 	$scope.folder = response.data.folder
    // 	$scope.$ctrl.out(response.data)
    // 	$element[0].parentNode.scrollTop = 0
    // 	var bla = '';
    // 	var path = [];
    // 	var filepath = $scope.folder.split('/')
    // 	filepath.forEach(function (i, k) { 
    // 		path.push({ 'handle': k === 0 ? 'FELA' : i, 'path': bla + i + '/' });
    // 		console.log(i);
    // 		bla = bla + i + '/';
    // 		console.log(bla) 
    // 	})
    // 	$scope.path = path;
    // })
  }
  // $scope.folder = ctrl.folder
  // variable = JSON.parse($element.attr("sortable"));
  clicktest = function(test) { console.log(test) }

  clickDir = (item) => {
    // $scope.$ctrl.out(item);
    this.changeDir(item) 
  }
  clickFile = ($event, item) => {
    this.fileCallback($event, item.folder + '/' + item.filename)
    console.log(item, 'was clicked')
  }

}
