import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, inject, Inject, ViewChild } from '@angular/core';
import { FormArray, FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';

export interface DialogData {
  tracks: any;
  playlist: any;
}

@Component({
  selector: 'app-announcer-playlist-dialog',
  templateUrl: './announcer-playlist-dialog.component.html',
  styleUrls: ['./announcer-playlist-dialog.component.scss']
})
export class AnnouncerPlaylistDialogComponent {
  @ViewChild('contributorInput')
  contributorInput!: ElementRef<HTMLInputElement>;
  
  constructor(
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<any>,
    private AuthenticationService: AuthenticationService,
  ) {

  }

  readonly separatorKeysCodes = [COMMA] as const;
  announcer = inject(LiveAnnouncer);

  saved = false;
  zedderId = null;
  files: any[] = [];
  coverImage?: string;

  contributorControl = new UntypedFormControl('bzzzzp');
  contributorOptions!: Observable<any>;

  ngOnInit(){
    this.zedderId = this.AuthenticationService.getUser().details?.id;

    console.log(this.data)
    console.log(this.zedderId)

    if(this.data.playlist) {
      this.playlistForm.patchValue({ id: this.data.playlist.id, name: this.data.playlist.name, pin: this.data.playlist.pin, archive: this.data.playlist.archive, contributors: this.data.playlist.contributors })
      this.coverImage = environment.musicDBapiSrc + '/coverArt/150/' + this.data.playlist.id + '/playlist';
    }

    this.contributorOptions = this.contributorControl.valueChanges.pipe( 
      filter((value) => value?.length > 2),
      switchMap( value => value ? this.searchZedders(value) : [])
    );

  }


  searchZedders = (zedder) => {
		return this.http.post<any>(environment.apiSrc + '/zedders', { searchString: zedder.toLowerCase() }).pipe(
      map((data) => {
        if (!data) {
          return [];
        }
        return data;
      })
    )
	}

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  onCoverDropped($event) {
    this.prepareCoverList($event);
  }

  prepareCoverList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadCover();
    // this.uploadFilesSimulator(0);
  }


  selectedContributor(event: any): void {
    let contributor = event.option.value;
    this.playlistForm.get('contributors')!.value.push({ id: contributor.id, firstname: contributor.firstname, lastname: contributor.lastname, expiry: contributor.expiry});
    this.contributorInput.nativeElement.value = '';
    this.contributorControl.setValue('');
    this.playlistForm.updateValueAndValidity();
  }

  removeContributor(contributor: any, ): void {
    // const index = this.relatedArtists.indexOf(artist);
    const index = this.playlistForm.get('contributors')!.value.indexOf(contributor);

    if (index >= 0) {
      this.playlistForm.get('contributors')!.value.splice(index, 1);

      this.announcer.announce(`Removed Announcer ${contributor}`);
    }
    this.playlistForm.updateValueAndValidity();
  }

  coverBrowseHandler(target ) {
    this.prepareCoverList(target.files);
  }

  uploadCover() { 
    if (!this.files.length) {
      return;
    } else {
      this.coverImage = URL.createObjectURL(this.files[this.files.length - 1]);
    }
  }
  
  playlistForm = new UntypedFormGroup({
    id: new FormControl<number|null>(null), 
    name: new FormControl<string|null>(null),
    contributors: new FormControl<any[]|null>([]),
    archive: new FormControl<boolean>(false),
    pin: new FormControl<boolean>(false),
   });


   savePlaylist() {
    this.saved = true;

    if (this.data.playlist) {
      this.http.post<any>(environment.musicDBapiSrc + '/rename-playlist', { 'name': this.playlistForm.value.name, 'id': this.playlistForm.value.id, 'pin': this.playlistForm.value.pin, 'archive': this.playlistForm.value.archive, 'contributors': this.playlistForm.value.contributors.map(i => i.id) } ).subscribe( data => {

        if (this.files.length) {
          const formData = new FormData();
          formData.append('file', this.files[this.files.length - 1]);
          formData.append('playlistId', data.id);
          this.http.post<any>(environment.musicDBapiSrc +'/coverupload', formData, {responseType: 'text' as 'json'}).subscribe(
            data => { 
              this.coverImage = data.imageUrl;
              this.deleteFile(this.files.length - 1);
    
              this.dialogRef.close(data)
  
             }
          )
        }
        
      })
    }
    else {
      this.http.post<any>(environment.musicDBapiSrc + '/create-playlist', { 'name': this.playlistForm.value.name, 'zedder_id': this.zedderId, tracks: this.data.tracks } ).subscribe(

      data => {

        data.id;
        const formData = new FormData();
        formData.append('file', this.files[this.files.length - 1]);
        formData.append('playlistId', data.id);
        this.http.post<any>(environment.musicDBapiSrc +'/coverupload', formData, {responseType: 'text' as 'json'}).subscribe(
          data => { 
            this.coverImage = data.imageUrl;
            this.deleteFile(this.files.length - 1);
  
            this.dialogRef.close(data)

           }
        )
        console.log(data);
      }
    )}
    // this.http.post(environment.musicDBapiSrc +'/fakePlaylist', this.playlistForm.value ).subscribe( data => {
    // });
   }
}
