<mat-toolbar>
	Merge Artists
</mat-toolbar>
<mat-toolbar>
	<mat-form-field class="full-width">
		<mat-label>Artist ID</mat-label>
		<mat-chip-grid (change)="updateArtist($event)" #chipGrid aria-label="Enter artists" [formControl]="artistControl">
			<mat-chip-row *ngFor="let artist of artistIds"
										(removed)="remove(artist)"
										[editable]="true"
										(edited)="edit(artist, $event)"
										[aria-description]="'press enter to edit ' + artist.id">
				{{artist.id}}
				<button matChipRemove [attr.aria-label]="'remove ' + artist.id">
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input placeholder="Enter artist IDs to merge"
						 [matChipInputFor]="chipGrid"
						 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						 [matChipInputAddOnBlur]="addOnBlur"
						 (matChipInputTokenEnd)="add($event)"/>
		</mat-chip-grid>
	</mat-form-field>
	
</mat-toolbar>
<section style="background: #212121;" class="mat-typography">
	<div style="display: flex; gap: 8px; padding: 8px;">
		<div style="flex: 1;" [style.width]="'calc(' + (100 / artists.length )+ 'vw - 180px)'" *ngFor="let artist of artists; index as $index">
			<mat-card [formGroup]="artistsForm.controls[$index]">
				<mat-card-header>
					<mat-card-title>
							<div style="display: flex; justify-content: space-between;">

								<div>
									<ul class="inline">
										<li>
											<strong>Artist ID #:</strong> {{artist.id}}
										</li>
										<li>
											<strong>Created:</strong> {{artist.created_at | date:'medium'}}
										</li>
										<li>
											<strong>Last Updated:</strong> {{artist.updated_at | date:'medium'}}
										</li>
									</ul>
								</div>

								<div>
									<mat-icon (click)="makeDefault('id', artist.id)" color="warn">{{ defaults.id === artist.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
								</div>
							</div>
					</mat-card-title>
				</mat-card-header>

				<mat-card-content>

					<mat-form-field class="full-width">
						<mat-label>Artist Name</mat-label>
						<input matInput formControlName="name">
						<mat-icon matSuffix (click)="makeDefault('name', artist.id)">{{ defaults.name === null ? 'check_box_outline_blank' : defaults.name === artist.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Bio</mat-label>
						<textarea matInput formControlName="bio" ></textarea>
						<mat-icon matSuffix (click)="makeDefault('bio', artist.id)">{{ defaults.bio === null ? 'check_box_outline_blank' : defaults.bio === artist.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Email</mat-label>
						<input matInput formControlName="email" >
						<mat-icon matSuffix (click)="makeDefault('email', artist.id)">{{ defaults.email === null ? 'check_box_outline_blank' : defaults.email === artist.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Hometown</mat-label>
						<input matInput formControlName="hometown" >
						<mat-icon matSuffix (click)="makeDefault('hometown', artist.id)">{{ defaults.hometown === null ? 'check_box_outline_blank' : defaults.hometown === artist.id ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

				</mat-card-content>
			</mat-card>
		</div>

		<div style="flex: 1; display: flex; flex-direction: column; gap: 8px;">
			<mat-card *ngIf="releases.length" class="info-card" style="background-color: hsl(224, 47%, 40%);">
				<mat-card-header style="background-color: hsl(224, 47%, 32%);">
					<mat-card-title>Subscriptions</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<mat-list>
						<a mat-list-item *ngFor="let release of releases;" [href]="'/releases/' + release.library_no">
							<span matListItemTitle>{{ release.artist_nm }} - {{release.title}}</span>
							<span matListItemLine>{{ release.release_year }}</span>
							<span matListItemLine><strong>Expiry</strong> {{ release.album_label }}</span>
						</a>
					</mat-list>
				</mat-card-content>
			</mat-card>
	
			<mat-card *ngIf="related.length" class="info-card">
				<mat-card-header style="background-color: hsl(224, 47%, 32%);">
					<mat-card-title>Related</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<mat-list>
						<a *ngFor="let artist of related" [href]="'/releases/artists/' + artist.id" mat-list-item>
							<span matListItemTitle>{{artist.name}}</span>
						</a>
					</mat-list>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</section>

<button mat-fab [hidden]="!default" class="floating-fab" (click)="mergeArtists()" aria-label="Merge Artist" matTooltip="Merge Artist">
	<mat-icon>
		merge_type
	</mat-icon>
</button>
