import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { PrizesService } from '../prizes.service';

export interface Interests {
  radiothonprizeid: number;
  radiothonprize: string;
  radiothonprizeyear: string;
  prizetype: string;
  currentyear: boolean;
}
 
@Component({
  selector: 'prizes',
  templateUrl: './manage-prizes.component.html',
  styleUrls: ['./manage-prizes.component.scss']
})

export class ManagePrizesComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    radiothonprizeid: new UntypedFormControl({ value: 0, disabled: true }),
    radiothonprize: new UntypedFormControl(''),
    radiothonprizeyear: new UntypedFormControl(''),
    prizetype: new UntypedFormControl(''),
    currentyear: new UntypedFormControl(false)
  });
  lastSort: Sort = { active: "radiothonprize", direction: "asc" };
  editInterests: Interests = {radiothonprize: '', radiothonprizeyear: '', radiothonprizeid: 0,  prizetype: '', currentyear: false};
  subCategories: Interests[] = [];
  sortedData: Interests[];
  displayedColumns: string[] = ['radiothonprizeid', 'radiothonprize', 'radiothonprizeyear', 'prizetype', 'currentyear', 'edit'];

  constructor(public PrizesService: PrizesService) {
    this.sortedData = this.subCategories.slice();
    // this.InterestsService = InterestsService;
    PrizesService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'radiothonprizeid':
          return compare(a.radiothonprizeid, b.radiothonprizeid, isAsc);
        case 'radiothonprize':
          return compare(a.radiothonprize, b.radiothonprize, isAsc);
        case 'radiothonprizeyear':
          return compare(a.radiothonprizeyear, b.radiothonprizeyear, isAsc);
        case 'prizetype':
          return compare(a.prizetype, b.prizetype, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      radiothonprizeid: el.radiothonprizeid,
      radiothonprize: el.radiothonprize,
      radiothonprizeyear: el.radiothonprizeyear,
      prizetype: el.prizetype.prizetypename,
      currentyear: el.currentyear
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      radiothonprizeid: 0,
      radiothonprize: '',
      radiothonprizeyear: '',
      prizetype: '',
      currentyear: false
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().radiothonprizeid === 0) { //new
      let subradiothonprize: any = {};
      subradiothonprize.radiothonprize = this.interestForm.value.radiothonprize;
      subradiothonprize.radiothonprizeyear =  this.interestForm.value.radiothonprizeyear;
      subradiothonprize.currentyear =  this.interestForm.value.currentyear;
      this.PrizesService.save(res => {
        this.PrizesService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      this.PrizesService.get(this.interestForm.getRawValue().radiothonprizeid).subscribe( subradiothonprize => {
        subradiothonprize.radiothonprize =  this.interestForm.value.radiothonprize;
        subradiothonprize.radiothonprizeyear =  this.interestForm.value.radiothonprizeyear;
        subradiothonprize.currentyear =  this.interestForm.value.currentyear;
        this.PrizesService.update(subradiothonprize).subscribe(response => { 
          let interests : Interests[] = [ {
            radiothonprizeid: response.radiothonprizeid,
            radiothonprize: response.radiothonprize,
            radiothonprizeyear: response.radiothonprizeyear,
            prizetype: response.prizetype.prizetypename,
            currentyear: response.currentyear
          }]
    
          const existing = this.subCategories.reduce((a,t)=> (a[t.radiothonprizeid] = t, a), {}),
          changed = interests.reduce((a,t)=> (a[t.radiothonprizeid] = t, a), {})
    
          this.subCategories = Object.values({...existing, ...changed})
          // this.sortedData = this.subCategories.slice(); 
          this.sortData(this.lastSort);
        });
       });
      
    }
   
  }
  
}
