	<h2 mat-dialog-title>Sponsorship Notes</h2>
	<mat-dialog-content style="height: 100%; padding: 0;">
			<editor
				apiKey="no-api-key"
				[disabled]="false"
				[(ngModel)]="sponsorshipMessage"
				id="editor"
				name="sponsorship"
				[init]="{ plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table contextmenu paste colorpicker',
									base_url: '/tinymce',
									suffix: '.min',
									menubar: false,
									height: '600px',
								}"
				[inline]="false"
				toolbar="insertfile undo redo | styleselect | bold italic | alignleft aligncenter  alignright alignjustify | bullist numlist outdent indent | link image charmap"
			>
			</editor>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close="abort">Cancel</button>
		<button mat-button (click)="saveNotes()" mat-dialog-close  cdkFocusInitial>Save</button>
	</mat-dialog-actions>