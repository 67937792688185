import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-availability-toggle',
  templateUrl: './availability-toggle.component.html',
  styleUrls: ['./availability-toggle.component.scss']
})
export class AvailabilityToggleComponent {
  @Input() availability!: string;
  @Input() update!: (row: number, col: number) => void;
  data = [{
    "avail-1-0": 0,
    "avail-0-0": 0,
    "avail-0-1": 0,
    "avail-1-1": 0,
    "avail-4-0": 0,
    "avail-4-1": 0,
    "avail-3-1": 0,
    "avail-3-0": 0,
    "avail-2-0": 0,
    "avail-2-1": 0,
    "avail-0-2": 0,
    "avail-0-3": 0,
    "avail-1-3": 0,
    "avail-1-2": 0,
    "avail-2-2": 0,
    "avail-2-3": 0,
    "avail-3-3": 0,
    "avail-3-2": 0,
    "avail-4-3": 0,
    "avail-4-2": 0,
    "avail-5-3": 0,
    "avail-5-2": 0,
    "avail-5-1": 0,
    "avail-5-0": 0,
    "avail-6-0": 0,
    "avail-6-1": 0,
    "avail-6-2": 0,
    "avail-6-3": 0
  }];
  rows = ['Morning', 'Afternoon', 'Evening', 'Late'];
  cols = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  states = ['never', 'sometimes', 'always'];

  ngOnInit() {
    if ( this.availability ) this.data = JSON.parse(this.availability);
  }

  ngOnChanges() {
    if ( this.availability ) this.data = JSON.parse(this.availability);
  }
}
