import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

export interface DialogData {
  id: number;
  name: string;
  shortcode: string;
  description: string;
}
@Component({
  selector: 'app-merch-item-details',
  templateUrl: './merch-item-details.component.html',
  styleUrls: ['./merch-item-details.component.scss']
})
export class MerchItemDetailsComponent {
  constructor(
    // @Inject('$rootScope') private $rootScope: any,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<any>,
    private http: HttpClient,
  ) {
    this.merchForm.patchValue(data);

    this.http.get<any>(environment.apiSrc + '/merch/item/' + data.id).subscribe(
			data => {
				this.merchForm.patchValue({
          name: data.name,
          shortcode: data.shortcode,
          description: data.description
        });

				// _this.locals.item = response.data;
			}
		);
  }

  merchForm = new UntypedFormGroup({
    id: new UntypedFormControl(),
    name: new UntypedFormControl(),
    shortcode: new UntypedFormControl(),
    description: new UntypedFormControl(),
  })
}
