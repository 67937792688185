<div cdkScrollable cdkDropListGroup style="height: calc(100vh - 64px); overflow-y: scroll;" >

  <mat-toolbar style="height: 48px; position: sticky; top: 0; left: 0;">

    <button (click)="prevDate()" matTooltip="Previous week" aria-label="previous week" mat-icon-button>
      <mat-icon>
        arrow_back
      </mat-icon>
    </button>

    <div style="flex: 1"></div>

    <button (click)="showCalendar()" matTooltip="Datefinder" aria-label="Datefinder" mat-button>
      <span>Week {{thedate.week()}} - {{thedate.format('DD/MM/YYYY')}}</span> 
    </button>

    <div style="flex: 1"></div>

    <button (click)="editNotes()" matTooltip="Sponsorship notes" aria-label="edit-notes" mat-icon-button>
      <mat-icon>
        chat
      </mat-icon>
    </button>

    <button (click)="showLiveReads()" matTooltip="Weeks Live Reads" aria-label="live-reads" mat-icon-button>
      <mat-icon>
        article
      </mat-icon>
    </button>


    <button (click)="checkPlayed()" matTooltip="Check played" aria-label="check played" mat-icon-button>
      <mat-icon>
        done_outline
      </mat-icon>
    </button>

    <button (click)="spotListHidden = !spotListHidden" matTooltip="Toggle Spot List" aria-label="toggle spot list" mat-icon-button>
      <mat-icon>
        {{ spotListHidden ? 'visibility' : 'visibility_off' }}
      </mat-icon>
    </button>

    <button (click)="nextDate()" matTooltip="Next week" aria-label="next week" mat-icon-button>
      <mat-icon>
        arrow_forward
      </mat-icon>
    </button>

  </mat-toolbar>

  <table>
    <tr>
      <th></th><th *ngFor="let theday of thedays">{{theday}}</th>
    </tr>
    <tr *ngFor="let thehour of thehours; index as hourindex">
      <td>{{thehour}}</td>
      <!-- @for (theday of thedays; track theday; let i = $index) { -->
      <ng-container *ngFor="let theday of thedays; index as dayindex">
        <td *ngIf="gridWeek[thedate.format('YYYY-MM-DD')] && gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex]" [attr.rowspan]="gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex].durationHours">
          <div style="height: 100%; display: flex; flex-direction: column;">
            <div>
              <strong>{{gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex].program.name}}</strong>
            </div>
            <div style="font-size: 75%; color: rgb(255, 255, 255); line-height: 85%; padding-bottom: 6px;" >
              <span style="padding-right: 0.5em;" *ngFor="let keyword of gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex].program.keywords">{{keyword.tag}}</span>
            </div>

            <div flex class="list-unstyled" style="min-height: 1em; flex: 1" 
              cdkDropList 
              [cdkDropListData]="{dayIndex: dayindex, hourIndex: hourindex, thedate: thedate, show: gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex] }" 
              [cdkDropListEnterPredicate]="datePredicate" 
              (cdkDropListDropped)="drop($event, gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex])" 
              cdkDropListAutoScrollStep="10" 
              ui-on-drop="onDrop($event,$data,gridflip[thedate.format('YYYY-MM-DD')][thehour][i].promo, gridflip[thedate.format('YYYY-MM-DD')][thehour][i])">
            <div cdkDrag 
              ui-draggable="true" 
              [cdkDragData]="{ commenceDate: promo.commence_date, completeDate: promo.complete_date, spot: promo, dayIndex: dayindex, hourIndex: hourindex, day: theday, hour: thehour }"
              drag="promo" 
              class="spot" 
              [class]="itemClass(promo)"
              *ngFor="let promo of gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex].promo; index as $index" layout="row">
              <ng-container *ngIf="dateCompare(promo)">
                <span class="title" flex>{{promo.title}}{{promo.played ? ' ✔︎' : ''}}</span>
                
                <span [class]="campaignIds[promo.campaign_cat]" (click)="recurring(promo, 'add')" class="end-recurring" *ngIf="promo.calDate !== promo.date && promo.calDate !== promo.recur_end">
                  <!-- <md-tooltip md-direction="bottom">End recurring</md-tooltip> -->
                  ⇥
                </span>
                <span (class)="campaignIds[promo.campaign_cat]" (click)="recurring(promo, 'remove')" class="recurring-end" *ngIf="promo.calDate === promo.recur_end">
                  <!-- <md-tooltip md-direction="bottom">End recurring</md-tooltip> -->
                </span>
              </ng-container>
            </div>
          </div>
          <div style="color:rgb(168, 168, 168); font-size: 0.9em;" *ngIf="gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex].promo.length">
            <em>{{totalLength(gridWeek[thedate.format('YYYY-MM-DD')][thehour][dayindex].promo) | hms}}</em>
          </div>
        </div>


        </td>
      </ng-container>
      <!-- } -->
    </tr>
  </table>

  <div *ngIf="!spotListHidden" cdkDrag style="background-color: rgba(255, 255, 255, 0.9); border: 3px solid rgb(33, 33, 33); border-radius: 7px 7px 4px 4px; z-index: 3; position: fixed; left: 100px; top: 150px; min-width: 200px; max-height: 400px;">
    <div class="handle" cdkDragHandle style="width: 100%; height: 20px; border-radius: 3px 3px 0 0; background-color: rgb(33, 33, 33); display: flex; align-items: center;" >
      <span style="color: white; font-weight: bold; padding-left: 0.5em;">Spots</span>
      <div style="flex: 1"></div>
      <div *ngFor="let colour of campaignColours" [style]="campaignCatStyle(colour)" style="width: 10px; height: 10px; margin: 2px 1px" (click)="toggleCampaigns(colour)"></div>
    </div>

    <div id="spot-list" style="padding: 3px 0; overflow: hidden; height: 358px">
      
      <ul #spotList class="list-unstyled" style="height: 374px; overflow-y: scroll; overscroll-behavior: none; padding-left: 2px; padding-right: 2px; margin: 0; scroll-snap-align: none" 
        [cdkDropListAutoScrollDisabled]="true" 
        [cdkDropListSortingDisabled]="true"
        (cdkDropListDropped)="returnDrop($event)" 
        cdkDropList>
        <!-- <li ng-style="spotsStyling(spot)" drag="spot"
          on-drop-success="dropSuccessHandler($event,$index,the_weekly_spots)"
          ng-repeat="spot in the_weekly_spots | filter: filterByCampaignType | orderBy: 'title' track by $index " layout="row"> -->
        <li [style]="spotsStyling(spot)"
          *ngFor="let spot of filterWeeklySpots()" style="display: flex; align-items: columns; width: 100%;" 
            [cdkDragData]="{ commenceDate: spot.commence_date, completeDate: spot.complete_date, spot: spot }"
            (cdkDragStarted)="dragStarted($event)"
            (cdkDragEnded)="dragEnded($event)" 
            cdkDrag>
          <!-- <div style="min-width: 30px; padding: 3px;">{{spot.spotsRemaining}}</div> -->
          <div class="play" (click)="playTemp(spot.media_location)" style="padding: 3px;">
            <mat-icon style="height: 12px; width: 12px; min-height: 12px; min-width: 12px; font-size: 12px;" aria-label="play">
              play_arrow
            </mat-icon>
          </div>
          <div style="padding: 3px 6px; flex: 1">{{spot.title}}</div>
          <div style="color:rgb(108, 108, 108); text-align: right; padding: 3px; font-style: oblique; font-size: 0.85em">{{spot.media_length | hms}}</div>
          <div style="min-width: 45px; padding: 3px; text-align: right; font-size: 0.85em">{{ spot.runs_campaign ? (spot.runs_total ? spot.runs_total : 0 ) + '/' + spot.runs_campaign + 'c' : '' }} {{ spot.runs_week ? (spot.spotsThisWeek ? spot.spotsThisWeek : 0) + '/' + spot.runs_week + 'w' : '' }}</div>
          <div style="min-width: 25px; padding: 3px; text-align: right; font-size: 0.85em">{{spot.spotsRemaining >= 0 ? spot.spotsRemaining : spot.runs_campaign ?  spot.runs_campaign - spot.runs_total : spot.runs_week }}</div>
          <div style="min-width: 10px; padding: 3px;">{{spot.recurring ? '*' : ' '}}</div>
          <div *cdkDragPreview class="spot" [class]="itemClass(spot)"><span style="padding: 2px 16px;">{{spot.title}}</span></div>
          <div class="placeholder" *cdkDragPlaceholder></div>
        </li>
        <div style="min-height: 16px;"></div>
      </ul>
    </div>
  </div>
</div>