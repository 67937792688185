<mat-toolbar>
	Merge Contacts
</mat-toolbar>
<mat-toolbar>
	<mat-form-field class="full-width">
		<mat-label>Contact ID</mat-label>
		<mat-chip-grid (change)="updateContact($event)" #chipGrid aria-label="Enter contacts" [formControl]="contactControl">
			<mat-chip-row *ngFor="let contact of contactIds"
										(removed)="remove(contact)"
										[editable]="true"
										(edited)="edit(contact, $event)"
										[aria-description]="'press enter to edit ' + contact.id">
				{{contact.id}}
				<button matChipRemove [attr.aria-label]="'remove ' + contact.id">
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input placeholder="Enter contact IDs to merge"
						 [matChipInputFor]="chipGrid"
						 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						 [matChipInputAddOnBlur]="addOnBlur"
						 (matChipInputTokenEnd)="add($event)"/>
		</mat-chip-grid>
	</mat-form-field>
	
</mat-toolbar>
<section style="background: #212121;" class="mat-typography">
	<div style="display: flex; gap: 8px; padding: 8px;">
		<div style="flex: 1;" [style.width]="'calc(' + (100 / contacts.length )+ 'vw - 180px)'" *ngFor="let contact of contacts; index as $index">
			<mat-card [formGroup]="contactsForm.controls[$index]">
				<mat-card-header>
					<mat-card-title>
							<div style="display: flex; justify-content: space-between;">

								<div>
									<ul class="inline">
										<li>
											<strong>Contact ID #:</strong> {{contact.contact_no}}
										</li>
										<li>
											<strong>Created:</strong> {{contact.created_at | date:'medium'}}
										</li>
										<li>
											<strong>Last Updated:</strong> {{contact.updated_at | date:'medium'}}
										</li>
									</ul>
								</div>

								<div>
									<mat-icon (click)="makeDefault('contact_no', contact.contact_no)" color="warn">{{ defaults.contact_no === contact.contact_no ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
								</div>
							</div>
					</mat-card-title>
				</mat-card-header>

				<mat-card-content>

					<mat-form-field class="full-width">
						<mat-label>Organisation Name</mat-label>
						<input matInput formControlName="org_nm">
						<mat-icon matSuffix (click)="makeDefault('org_nm', contact.contact_no)">{{ defaults.org_nm === null ? 'check_box_outline_blank' : defaults.org_nm === contact.contact_no ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Website</mat-label>
						<input matInput formControlName="website" >
						<mat-icon matSuffix (click)="makeDefault('website', contact.contact_no)">{{ defaults.website === null ? 'check_box_outline_blank' : defaults.website === contact.contact_no ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>ABN</mat-label>
						<input matInput formControlName="org_abn" >
						<mat-icon matSuffix (click)="makeDefault('org_abn', contact.contact_no)">{{ defaults.org_abn === null ? 'check_box_outline_blank' : defaults.org_abn === contact.contact_no ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Email</mat-label>
						<input matInput formControlName="email" >
						<mat-icon matSuffix (click)="makeDefault('email', contact.contact_no)">{{ defaults.email === null ? 'check_box_outline_blank' : defaults.email === contact.contact_no ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Phone</mat-label>
						<input matInput formControlName="work_ph" >
						<mat-icon matSuffix (click)="makeDefault('work_ph', contact.contact_no)">{{ defaults.work_ph === null ? 'check_box_outline_blank' : defaults.work_ph === contact.contact_no ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Address</mat-label>
						<input matInput  formControlName="street_nm">
						<mat-icon matSuffix (click)="makeDefault('address', contact.contact_no)">{{ defaults.address === null ? 'check_box_outline_blank' : defaults.address === contact.contact_no ? 'check_box' : 'indeterminate_check_box' }}</mat-icon>
					</mat-form-field>


					<div style="display: flex; gap: 8px;">
						<mat-form-field class="full-width">
							<mat-label>Suburb</mat-label>
							<input matInput  formControlName="suburb_nm">
						</mat-form-field>

						<mat-form-field class="full-width">
							<mat-label>State</mat-label>
							<input matInput  formControlName="state">
						</mat-form-field>

						<mat-form-field class="full-width">
							<mat-label>Country</mat-label>
							<mat-select  formControlName="country">
								<mat-option *ngFor="let country of countries" value="{{country.alpha}}">
									{{country.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="full-width">
							<mat-label>Postal Code</mat-label>
							<input matInput name="postalCode"  formControlName="postcode" placeholder="1234"
						 >

						</mat-form-field>
					</div>

				</mat-card-content>
			</mat-card>
		</div>

		<div style="flex: 1; display: flex; flex-direction: column; gap: 8px;">
			<mat-card *ngIf="individuals.length" class="info-card" style="background-color: hsl(224, 47%, 40%);">
				<mat-card-header style="background-color: hsl(224, 47%, 32%);">
					<mat-card-title>Individuals</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<mat-list>
						<a mat-list-item *ngFor="let ind of individuals;" [href]="'/subscribers/' + ind.individual_id">
							<span matListItemTitle>{{ ind.individual_id }} {{ind.name}}</span>
							<span matListItemLine><em>{{ind.work_phone}}</em></span>
							<span matListItemLine>{{ ind.email}}</span>
						</a>
					</mat-list>
				</mat-card-content>
			</mat-card>
	
			<mat-card *ngIf="contactcategories.length" class="info-card">
				<mat-card-header style="background-color: hsl(224, 47%, 32%);">
					<mat-card-title>Categories</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<mat-list>
						<a *ngFor="let cat of contactcategories" mat-list-item>
							<span matListItemTitle>{{ cat.category }}</span>
							<span matListItemLine>{{cat.id}}</span>
						</a>
					</mat-list>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</section>

<button mat-fab [hidden]="!default" class="floating-fab" (click)="mergeContacts()" aria-label="Merge Contact" matTooltip="Merge Contact">
	<mat-icon>
		merge_type
	</mat-icon>
</button>
