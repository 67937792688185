import { Component, Input, Inject, ViewChild } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, concat, toArray } from 'rxjs';
import { FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatCalendar, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { StudioService } from '../studio.service';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-program-playlists',
  templateUrl: './program-playlists.component.html',
  styleUrls: ['./program-playlists.component.scss']
})
export class ProgramPlaylistsComponent {
  @Input('program') public program;
  @ViewChild(MatCalendar) calendar?: MatCalendar<Date>;
  
  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
		private socket: SocketService,
		// @Inject('$rootScope') private $rootScope: any,
    public StudioService: StudioService
  ){
    this.pureHttp = new HttpClient(handler);
    this.studio = Number(StudioService.studio) ? Number(StudioService.studio) : 1;

    this.episodeForm.valueChanges.subscribe(change => { 
      this.episodeUpdated = (this.episodeForm.status === 'VALID');

      if (this.episodeForm.value.start && this.episodeForm.value.startTime && this.episodeForm.value.endTime) {
        let start = typeof this.episodeForm.value.start === 'object' ? this.episodeForm.value.start.format('YYYY-MM-DD') + ' ' + this.episodeForm.value.startTime : this.episodeForm.value.start + ' ' + this.episodeForm.value.startTime
        let end = typeof this.episodeForm.value.start === 'object' ? this.episodeForm.value.start.format('YYYY-MM-DD') + ' ' + this.episodeForm.value.endTime : this.episodeForm.value.start + ' ' + this.episodeForm.value.endTime
  
  
        this.episode = { ...this.episode, start: start, end: end }
      }

      console.log(change)
      console.log(this.episodeForm.touched)
      console.log(this.episodeForm.status)
      console.log(this.episodeForm.pristine)
    })
  }

  ngOnInit(){
    this.bannerImage = this.program.bannerImageUrl;
    this.coverImage = this.program.profileImageUrl;
  }

  ngOnChanges(){
    this.fetchShow(this.program.slug)
  }

  pureHttp: HttpClient;

  selectedDate?: any;

  availableEpisodes: any[] = [];
  bannerImage?: string;
  coverImage?: string;
  episodeImage?: string;

  episode: any; 

  studio: number;

  stream = false;

  episodeUpdated = false; 


  programForm = new UntypedFormGroup( {
    name: new UntypedFormControl(),
    broadcasters: new UntypedFormControl(),
    description: new UntypedFormControl(),
    twitterHandle: new UntypedFormControl(),
    facebookPage: new UntypedFormControl()
  })

  episodeForm = new UntypedFormGroup( {
    id: new UntypedFormControl(),
    title: new UntypedFormControl(),
    description: new UntypedFormControl(),
    imageUrl: new UntypedFormControl(),
    smallImageUrl: new UntypedFormControl(),
    time: new UntypedFormControl(),
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
    startTime: new UntypedFormControl(),
    endTime: new UntypedFormControl(),
    duration: new UntypedFormControl(),
    playlist: new UntypedFormArray([
      new UntypedFormGroup( {
        new: new UntypedFormControl(true),
        id: new UntypedFormControl(),
        artist: new UntypedFormControl(''),
        startTime: new UntypedFormControl(),
        time: new UntypedFormControl(),
        title: new UntypedFormControl(''),
        track: new UntypedFormControl(''),
        quotas: new UntypedFormControl(),
        contentDescriptors: new UntypedFormGroup({
          isAustralian: new UntypedFormControl(false),
          isLocal: new UntypedFormControl(false),
          isFemale: new UntypedFormControl(false),
          isGenderNonConforming: new UntypedFormControl(false),
          isIndigenous: new UntypedFormControl(false),
          isNew: new UntypedFormControl(false)
        })
      })
    ]),
  })

  files: any[] = [];

  onCoverDropped($event) {
    this.prepareCoverList($event);
  }


  onBannerDropped($event) {
    this.prepareBannerList($event);
  }

  onEpisodeImageDropped($event) {
    this.prepareBannerList($event);
  }


  coverBrowseHandler(target ) {
    this.prepareCoverList(target.files);
  }

  bannerBrowseHandler(target ) {
    this.prepareBannerList(target.files);
  }


  episodeImageHandler(target ) {
    this.prepareImageList(target.files);
  }

  prepareImageList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadEpisodeImage();
    // this.uploadFilesSimulator(0);
  }

  prepareCoverList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadCover();
    // this.uploadFilesSimulator(0);
  }

  prepareBannerList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadBanner();
    // this.uploadFilesSimulator(0);
  }

  uploadCover() { 
    if (!this.files.length) {
      return;
    } else {
      const formData = new FormData();
      formData.append('file', this.files[0]);

      this.http.post<any>('https://data.4zzz.org.au/parma/edit/program/profile/' + this.program.slug, formData).subscribe(
        data => { 
          this.coverImage = data.imageUrl;

          this.deleteFile(0);
         }
      )
    }
  }

  uploadBanner() { 
    if (!this.files.length) {
      return;
    } else {
      const formData = new FormData();
      formData.append('file', this.files[0]);

      this.http.post<any>('https://data.4zzz.org.au/parma/edit/program/banner/' + this.program.slug, formData).subscribe(
        data => { 
          this.bannerImage = data.imageUrl;
          this.deleteFile(0);
         }
      )
    }
  }


  uploadEpisodeImage() { 
    if (!this.files.length) {
      return;
    } else {
      const formData = new FormData();
      formData.append('file', this.files[0]);

      this.http.post<any>('https://data.4zzz.org.au/parma/edit/episode/image/' + this.program.slug + '/' + this.episode.id, formData).subscribe(
        data => { 
          this.episodeImage = data.imageUrl;
          this.deleteFile(0);
         }
      )
    }
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  fetchShow(slug: string) {
    let editProgram = this.pureHttp.get<any>('https://data.4zzz.org.au/parma/edit/'+ this.program.slug);

    let program = this.pureHttp.get<any>('https://4zzz.org.au/airnetapi/rest/stations/4ZZZ/programs/'+ this.program.slug);
    let episodes = this.pureHttp.get<any>('https://data.4zzz.org.au/parma/edit/episodes/'+ this.program.slug);

    concat(editProgram, forkJoin([program, episodes])).pipe(toArray()).subscribe( data => {
      const [editProgram, [program, episodes]  ] = data;

      console.log(editProgram, program, episodes); 

      const allEpisodes = episodes.previousEpisodes && episodes.nextEpisodes  ? [...episodes.previousEpisodes, ...episodes.nextEpisodes ] : episodes.nextEpisodes ? [...episodes.nextEpisodes ] : [];

      const lastEpisode = allEpisodes[allEpisodes.length - 1];

      const [ startDate, startTime ] = lastEpisode.start.split(' ');

      lastEpisode.startTime = new Date(lastEpisode.start);
      lastEpisode.endTime = new Date(lastEpisode.end);

      this.selectedDate = moment(lastEpisode.startTime);
      this.availableEpisodes = allEpisodes;
      this.episode = lastEpisode;
      this.selectedDate = moment(lastEpisode.startTime);

      this.bannerImage = program.bannerImageUrl;
      this.coverImage = program.profileImageUrl;
      this.programForm.patchValue({
        name: program.name,
        broadcasters: program.broadcasters,
        description: program.description,
        twitterHandle: program.twitterHandle,
        facebookPage: program.facebookPage,
      }, { emitEvent: false })

      this.fetchEpisode(this.program.slug, this.episode.id);
    })
  }
  
  fetchEpisodes() {
    let episodes = this.pureHttp.get<any>('https://data.4zzz.org.au/parma/edit/episodes/'+ this.program.slug).subscribe( episodes => {
        const allEpisodes = episodes.previousEpisodes && episodes.nextEpisodes  ? [...episodes.previousEpisodes, ...episodes.nextEpisodes ] : episodes.nextEpisodes ? [...episodes.nextEpisodes ] : [];

        const lastEpisode = allEpisodes[allEpisodes.length - 1];

        const [ startDate, startTime ] = lastEpisode.start.split(' ');

        lastEpisode.startTime = new Date(lastEpisode.start);
        lastEpisode.endTime = new Date(lastEpisode.end);

        this.availableEpisodes = allEpisodes;
        this.calendar?.updateTodaysDate();
      }
    )
  }
  
  fetchEpisode( programSlug, episodeId) {
    this.pureHttp.get<any>('https://data.4zzz.org.au/parma/edit/episode/' + programSlug + '/' + episodeId ).subscribe( data => {
      console.log(data)

      const [ startDate, startTime ] = data.iso8601StartTime.split(' ');
      const [ endDate, endTime ] = data.iso8601EndTime.split(' ');

      this.episode.start = data.iso8601StartTime;
      this.episode.end = data.iso8601EndTime;

      const playlist = this.parseAmrapPlaylist( data.playlist );

      this.episode.playlist = playlist;

      this.episodeForm.patchValue(
        { 
          id: this.episode.id,
          title: data.userTitle,
          description: data.notes,
          imageUrl: data.image,
          smallImageUrl: null,
          time: moment(data.iso8601StartTime),
          start: data.iso8601StartTime,
          end: data.iso8601EndTime,
          startTime: startTime,
          endTime: endTime,
          duration: moment(data.iso8601EndTime).diff(moment(data.iso8601StartTime), 'seconds'),
        },
        { emitEvent: false }
      )
      console.log(this.episodeForm.value);

      this.episodeImage = data.image;	

      let playlistForm = this.episodeForm.get('playlist') as FormArray;
      
      playlistForm.clear({ emitEvent: false }) 

      playlistForm.push(new UntypedFormGroup( {
        new: new UntypedFormControl(true),
        id: new UntypedFormControl(),
        artist: new UntypedFormControl(''),
        startTime: new UntypedFormControl(),
        time: new UntypedFormControl(),
        title: new UntypedFormControl(''),
        track: new UntypedFormControl(''),
        quotas: new UntypedFormControl(),
        contentDescriptors: new UntypedFormGroup({
          isAustralian: new UntypedFormControl(false),
          isLocal: new UntypedFormControl(false),
          isFemale: new UntypedFormControl(false),
          isGenderNonConforming: new UntypedFormControl(false),
          isIndigenous: new UntypedFormControl(false),
          isNew: new UntypedFormControl(false)
        })
      }), { emitEvent: false });

      playlist.forEach( item => {

        let quotas: string[] = [];
        for (let i in item.contentDescriptors ) { 
          if (item.contentDescriptors[i]) 
            quotas.push(i) 
        } 

        let playlistFormGroup = new UntypedFormGroup( {
          new: new UntypedFormControl(false),
          id: new UntypedFormControl(item.id),
          artist: new UntypedFormControl(item.artist),
          startTime: new UntypedFormControl(item.startTime),
          time: new UntypedFormControl(item.time),
          title: new UntypedFormControl(item.title),
          track: new UntypedFormControl(item.track),
          quotas: new UntypedFormControl(quotas),
          contentDescriptors: new UntypedFormGroup({
            isAustralian: new UntypedFormControl(item.contentDescriptors.isAustralian),
            isLocal: new UntypedFormControl(item.contentDescriptors.isLocal),
            isFemale: new UntypedFormControl(item.contentDescriptors.isFemale),
            isGenderNonConforming: new UntypedFormControl(item.contentDescriptors.isGenderNonConforming),
            isIndigenous: new UntypedFormControl(item.contentDescriptors.isIndigenous),
            isNew: new UntypedFormControl(item.contentDescriptors.isNew)
          })
        });
        playlistFormGroup.disable();

        playlistForm.insert(-1, 
          playlistFormGroup, { emitEvent: false } 
        )
      })

      console.log(playlist)
    });
  }

  deleteTrack(track, key) {
    if(track.id) {
      const payload = new HttpParams()
        .set('id', track.id)

			this.pureHttp.post('https://data.4zzz.org.au/parma/edit/episode/delete-track/'+ this.program.slug +'/'+ this.episode.id, payload ).subscribe( i => {
				// this.playlists.splice(key, 1);

        let playlistForm = this.episodeForm.get('playlist') as FormArray;
        playlistForm.removeAt(key);
			})

		}
		else {
      let playlistForm = this.episodeForm.get('playlist') as FormArray;
			playlistForm.removeAt(key);
		}
  }

  get refForm() {
    return this.episodeForm.get('playlist') as FormArray;
  }

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate;

      let hasClass = false;

      this.availableEpisodes.forEach( i =>  { 
        if( date.isSame( moment.utc(i.start), 'day') ) { 
          hasClass = true; 
        }
      });
      // Highlight the 1st and 20th day of each month.
      return hasClass ? 'episode' : '';
    }

    return '';
  };

  editTrack(event, context) {
    if (context.disabled) {
      context.enable() 
    }
    else {
      let item = context.value;
      console.log(event, context)
      const [episodeDate, epsiodeTime] = this.episode.start.split(' ');

      let payload = new HttpParams()
        .set('id', item.id)
        .set('artist', item.artist)
        .set('title', item.title)
        .set('track', item.title)
        .set('contentDescriptors[isAustralian]', item.contentDescriptors.isAustralian)
        .set('contentDescriptors[isLocal]', item.contentDescriptors.isLocal)
        .set('contentDescriptors[isFemale]', item.contentDescriptors.isFemale)
        .set('contentDescriptors[isGenderNonConforming]', item.contentDescriptors.isGenderNonConforming)
        .set('contentDescriptors[isIndigenous]', item.contentDescriptors.isIndigenous)
        .set('contentDescriptors[isNew]', item.contentDescriptors.isNew);

      if(item.startTime)
        payload = payload.append('startTime', moment(episodeDate + ' ' + item.startTime).format('hh:mm:ss'))

      console.log(payload.toString());
      this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/edit-track/'+ this.program.slug +'/'+ this.episode.id, payload ).subscribe( data => {
        console.log(data);
        let amrapTrack = this.parseAmrapPlaylist(data.tracks).find( i => (i.artist === item.artist && i.title === item.title) );
        context.patchValue(amrapTrack);
        context.disable();
      })
    }

    // context.disabled ? context.enable() : context.disable();
  }

  addTrack(event, context) {
    console.log(event, context)
    let item = context.value;
    const [episodeDate, epsiodeTime] = this.episode.start.split(' ');

    let payload = new HttpParams()
      .set('artist', item.artist)
      .set('title', item.title)
      .set('track', item.title)
      .set('contentDescriptors[isAustralian]', item.contentDescriptors.isAustralian)
      .set('contentDescriptors[isLocal]', item.contentDescriptors.isLocal)
      .set('contentDescriptors[isFemale]', item.contentDescriptors.isFemale)
      .set('contentDescriptors[isGenderNonConforming]', item.contentDescriptors.isGenderNonConforming)
      .set('contentDescriptors[isIndigenous]', item.contentDescriptors.isIndigenous)
      .set('contentDescriptors[isNew]', item.contentDescriptors.isNew);

    if (item.startTime)
      payload = payload.append('startTime',  moment(episodeDate + ' ' + item.startTime).format('hh:mm:ss'))

    this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/add-track/'+ this.program.slug +'/'+ this.episode.id, payload ).subscribe( data => {

      let amrapTrack = this.parseAmrapPlaylist(data.tracks).find( i => (i.artist === item.artist && i.title === item.title) );

      if (item.new && amrapTrack) {
        let playlistForm = this.episodeForm.get('playlist') as FormArray;
        let playlistFormGroup = new UntypedFormGroup( {
          id: new UntypedFormControl(amrapTrack.id),
          new: new UntypedFormControl(false),
          artist: new UntypedFormControl(amrapTrack.artist),
          startTime: new UntypedFormControl(amrapTrack.startTime),
          time: new UntypedFormControl(item.time),
          title: new UntypedFormControl(amrapTrack.title),
          track: new UntypedFormControl(amrapTrack.track),
          contentDescriptors: new UntypedFormGroup({
            isAustralian: new UntypedFormControl(amrapTrack.contentDescriptors.isAustralian),
            isLocal: new UntypedFormControl(amrapTrack.contentDescriptors.isLocal),
            isFemale: new UntypedFormControl(amrapTrack.contentDescriptors.isFemale),
            isGenderNonConforming: new UntypedFormControl(amrapTrack.contentDescriptors.isGenderNonConforming),
            isIndigenous: new UntypedFormControl(amrapTrack.contentDescriptors.isIndigenous),
            isNew: new UntypedFormControl(amrapTrack.contentDescriptors.isNew)
          })
        })
        playlistFormGroup.disable();
    
        playlistForm.insert(-1, 
          playlistFormGroup, { emitEvent: false } 
        )

        context.reset()
      }
      else {
        console.log(item)
        console.log(data);
        context.patchValue(amrapTrack);
        context.disabled ? context.enable() : context.disable();
      }
    })

  }

  toggleQuotas(context, quota) {
    context.value['contentDescriptors'][quota] = !context.value['contentDescriptors'][quota];
  }

  drop(event: CdkDragDrop<string[]>) {
    const playlist = this.episodeForm.get('playlist') as FormArray;
    
    console.log(  event.previousIndex, event.currentIndex);

    const currentGroup = playlist.at(event.previousIndex);
    playlist.removeAt(event.previousIndex);
    playlist.insert(event.currentIndex, currentGroup);

    let order = playlist.controls.filter(i => i.value.id).map(i => i.value.id) 

    console.log(order);

    const payload = new HttpParams({
      fromObject: { 'order[]': order }
    })

    this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/reorder-tracks	/'+ this.program.slug +'/'+ this.episode.id, payload ).subscribe( data => {
      console.log(data);
    })
  }
  
  onDateSelected(date) {

    let foundEpisode = this.availableEpisodes.find(i => ( i.start.search(date.format('Y-MM-DD')) > -1 ));

    if(foundEpisode) {
      this.episode = foundEpisode;
      this.episode.playlist = [];

      this.fetchEpisode(this.program.slug, this.episode.id);
    }
    else {
      console.log('no ep found');
      this.episode = {};

      console.log(this.program);

      let startTime, endTime;
      if(this.program.hour) {
        startTime = moment(this.program.hour, ['h']).format("HH:mm:ss");
        endTime = moment(this.program.hour, ['h']).add(this.program.duration * 15, 'minutes').format("HH:mm:ss");
      }
      else if (this.program.start) {
        startTime = moment(this.program.start, ['HH:mm:ss']).format("HH:mm:ss");
        endTime = moment(this.program.start, ['HH:mm:ss']).add(this.program.duration, 'seconds').format("HH:mm:ss");
      }

      this.episodeForm.reset();
      let playlistForm = this.episodeForm.get('playlist') as FormArray;
      playlistForm.clear();
      this.episodeForm.patchValue( {
        start: date,
        startTime: startTime,
        endTime: endTime,
      })
    }
    console.log(date);
  }

  addEpisode() {
    let start = this.episodeForm.value.startTime

    let startTime: Moment = moment( this.episodeForm.value.start.format('YYYY-MM-DD') + ' ' + this.episodeForm.value.startTime) ;
    let endTime: Moment = moment( this.episodeForm.value.start.format('YYYY-MM-DD') + ' ' + this.episodeForm.value.endTime );

    let duration = (endTime.toDate().getTime() - startTime.toDate().getTime())  / 1000 / 60;

   
    const payload = new HttpParams()
      .set('start', startTime.format('YYYY-MM-DD HH:mm:ss') )
      .set('duration', duration)

    console.log(payload.toString());
    this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/create-episode/'+ this.program.slug, payload ).subscribe( data => {
      console.log(data);
      this.episode.id = data.episodeId;
      this.episodeForm.patchValue({id: data.episodeId});
      
      if (this.episodeForm.value.title) {
        const titlePayload = new HttpParams()
        .set('title', this.episodeForm.value.title )

        this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/title/'+ this.program.slug + '/' + data.episodeId, titlePayload ).subscribe( data => { } );
      }
  
      if(this.episodeForm.value.description) {
        const descriptionPayload = new HttpParams()
        .set('description', this.episodeForm.value.description )

        this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/description/'+ this.program.slug + '/' + data.episodeId, descriptionPayload ).subscribe( data => { } );
      }
    
      let playlistForm = this.episodeForm.get('playlist') as FormArray;
      
      playlistForm.clear({ emitEvent: false }) 

      playlistForm.push(new UntypedFormGroup( {
        new: new UntypedFormControl(true),
        id: new UntypedFormControl(),
        artist: new UntypedFormControl(''),
        startTime: new UntypedFormControl(),
        time: new UntypedFormControl(),
        title: new UntypedFormControl(''),
        track: new UntypedFormControl(''),
        quotas: new UntypedFormControl(),
        contentDescriptors: new UntypedFormGroup({
          isAustralian: new UntypedFormControl(false),
          isLocal: new UntypedFormControl(false),
          isFemale: new UntypedFormControl(false),
          isGenderNonConforming: new UntypedFormControl(false),
          isIndigenous: new UntypedFormControl(false),
          isNew: new UntypedFormControl(false)
        })
      }), { emitEvent: false });

      this.fetchEpisodes();
    })
  }

  editEpisode() {
    let start = typeof this.episodeForm.value.start === 'object' ? this.episodeForm.value.start : moment(this.episodeForm.value.start);

    let startTime: Moment = moment( start.format('YYYY-MM-DD') + ' ' + this.episodeForm.value.startTime) ;
    let endTime: Moment = moment( start.format('YYYY-MM-DD') + ' ' + this.episodeForm.value.endTime );

    let duration = (endTime.toDate().getTime() - startTime.toDate().getTime())  / 1000 / 60;
   
    const payload = new HttpParams()
      .set('start', startTime.format('YYYY-MM-DD HH:mm:ss') )
      .set('duration', duration)

    console.log(payload.toString());
    this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/time/'+ this.program.slug + '/' + this.episode.id, payload ).subscribe( data => {
      console.log(data)
    })

    if (this.episodeForm.value.title) {
      const titlePayload = new HttpParams()
      .set('title', this.episodeForm.value.title )

      this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/title/'+ this.program.slug + '/' + this.episode.id, titlePayload ).subscribe( data => { 
      } );
    }

    if (this.episodeForm.value.description) {
      const descriptionPayload = new HttpParams()
      .set('description', this.episodeForm.value.description )

      this.pureHttp.post<any>('https://data.4zzz.org.au/parma/edit/episode/description/'+ this.program.slug + '/' + this.episode.id, descriptionPayload ).subscribe( data => { 
        console.log(data)
      } );
    }
  }

  reconnectStream = () => {
		this.socket.send({ stream: 'connect' });
	}

  fetchLogger = () => {
    this.http.post<any>(environment.musicDBapiSrc + '/mairlist/logs', {
      start: moment(this.episode.start).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(this.episode.end).format('YYYY-MM-DD HH:mm:ss'),
      studio: this.studio 
    }).subscribe( data => {

      data.forEach( track => { 
        let playlistForm = this.episodeForm.get('playlist') as FormArray;
        let playlistFormGroup = new UntypedFormGroup( {
          new: new UntypedFormControl(false),
          id: new UntypedFormControl(null),
          artist: new UntypedFormControl(track.artist),
          startTime: new UntypedFormControl(moment( track.time ).format('HH:mm:ss')),
          time: new UntypedFormControl(moment( track.time ).format('HH:mm:ss')),
          title: new UntypedFormControl(track.title),
          track: new UntypedFormControl(track.title),
          contentDescriptors: new UntypedFormGroup({
            isAustralian: new UntypedFormControl(false),
            isLocal: new UntypedFormControl(false),
            isFemale: new UntypedFormControl(false),
            isGenderNonConforming: new UntypedFormControl(false),
            isIndigenous: new UntypedFormControl(false),
            isNew: new UntypedFormControl(false)
          })
        });
      
        playlistForm.insert(-1, 
          playlistFormGroup, { emitEvent: false } 
        )
      
      });

      this.episode.playlist = [...this.episode.playlist, ...data.map(track => ({ time:  moment( track.time ).subtract( track.playback_duration, 'seconds').toDate(), artist: track.artist, title: track.title, contentDescriptors: { isNew: false, isAustralian: false, isLocal: false, isFemale: false, isGenderNonConforming: false, isIndigenous: false }, edit: true }))]
    })
  }

  toggleStream = (event) => {
    this.stream = event.checked;
    console.log(this.stream);
    if (this.stream) {
      this.startStreamListener();
    }
    else {
      this.stopStreamListener();
    }
  }

  changeStudios = () => {
		switch (this.studio) {
			case 2:
				this.studio = 1;
				break;
			case 1:
				this.studio = 2;
				break;
			default:
				this.studio = 1;
				break;
		}
	}

  startStreamListener = () => {
		this.socket.connect();
		this.socket.onmessage( (ev) => { 
			if(ev.data){ 
				let message = JSON.parse(ev.data); 
				if ( 'trackLog' in message ) {
					let track = message['trackLog'];
					if ( !('status' in track) ) {
						track.time = new Date(track.time); 
						console.log(track);
						if((track.studio == this.studio) || !track.studio) {
							this.episode.playlist.push({ 
                time:  track.time, 
                artist: track.artist, 
                title: track.title, 
                contentDescriptors: { 
                  isNew: false, 
                  isAustralian: false, 
                  isLocal: false, 
                  isFemale: false, 
                  isGenderNonConforming: false, 
                  isIndigenous: false 
                }, 
                edit: true 
              });

              let playlistForm = this.episodeForm.get('playlist') as FormArray;
              let playlistFormGroup = new UntypedFormGroup( {
              new: new UntypedFormControl(false),
              id: new UntypedFormControl(null),
                artist: new UntypedFormControl(track.artist),
                startTime: new UntypedFormControl(moment( track.time ).subtract( track.playback_duration, 'seconds').format('HH:mm:ss')),
                time: new UntypedFormControl(moment( track.time ).subtract( track.playback_duration, 'seconds').format('HH:mm:ss')),
                title: new UntypedFormControl(track.title),
                track: new UntypedFormControl(track.title),
                contentDescriptors: new UntypedFormGroup({
                  isAustralian: new UntypedFormControl(false),
                  isLocal: new UntypedFormControl(false),
                  isFemale: new UntypedFormControl(false),
                  isGenderNonConforming: new UntypedFormControl(false),
                  isIndigenous: new UntypedFormControl(false),
                  isNew: new UntypedFormControl(false)
                })
              });
      
              playlistForm.insert(-1, 
                playlistFormGroup, { emitEvent: false } 
              )
            }
          }
				}
				
			}
		});
	}

  stopStreamListener = () => {
		// socket.close();
	}

  parseAmrapPlaylist = (html) => {
		let playlist : any[] = [];
		var node = document.createElement("div");
		node.innerHTML = html;
		Array.from(node.getElementsByTagName('tr')).forEach( el  => { 
			let track : { id?: Number, startTime?: string, artist?: string, title?:string, track?:string, contentDescriptors?: any, time?: any } = {}
			track.id = Number((el.getElementsByClassName('trackId')[0] as HTMLElement).innerText);
			track.startTime = String((el.getElementsByClassName('editStartTime')[0] as HTMLElement).textContent).trim();
			track.artist = el.dataset['artist'];
			track.title = el.dataset['title'];
			track.track = el.dataset['title'];
			track.contentDescriptors = {}
			el.querySelectorAll('.playlistEditPreSegment span > a').forEach(i => {
				const array = [...i.className.matchAll(/.* (\w+)-(\w+)Hint.*/g)]; 
				track.contentDescriptors = {...track.contentDescriptors, [array[0][1]]: array[0][2] === 'true' ? true : false } 
			})
			if(track.startTime !== '') {
				track.time = moment('1970-01-01 ' + track.startTime).toDate();
			}
			playlist.push(track);
		});
		return playlist
	}

  tinymceOptions = {
		menubar : false,
		plugins: [
				"advlist autolink lists link image charmap print preview anchor",
				"searchreplace visualblocks code fullscreen ",
				"insertdatetime media table contextmenu paste colorpicker"
		],
		paste_as_text: true,
		toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter  alignright alignjustify | bullist numlist outdent indent | link image charmap",
		charmap_append: [
			[0x2615, 'morning coffee'],
			[0x2600, 'sun'],
			[0x2601, 'cloud'],
			[0x1F63B, 'smiling heart cat'],
			[0x2708, ''],
			[0xFE0F, ''],
			[0x2795, ''],
			[0x27A1, ''],
			[0xFE0F, ''],
			[0x1F33B, ''],
			[0x1F98B, ''],
			[0x1F431, ''],
			[0x1F499, ''],
			[0x1F49A, ''],
			[0x2764, ''],
			[0xFE0F, ''],
			[0x1F370, ''],
			[0x2600, ''],
			[0xFE0F, ''],
			[0x1F60E, ''],
			[0x1F33C, ''],
			[0x1F51C, ''],
			[0x1F4A9, ''],
			[0x1F47E, ''],
			[0x1F47F, ''],
			[0x1F608, ''],
			[0x1F632, ''],
			[0x1F631, ''],
			[0x1F635, ''],
			[0x1F595, ''],
			[0x1F933, ''],
			[0x26A1, ''],
			[0xFE0F, ''],
			[0x1F525, ''],
			[0x2B50, ''],
			[0xFE0F, ''],
			[0x2B50, ''],
			[0xFE0F, ''],
			[0x2604, ''],
			[0xFE0F, ''],
			[0x1F3C9, ''],
			[0x1F34C, ''],
			[0x1F51C, ''],
			[0x3030, ''],
			[0xFE0F, ''],
			[0x2122, ''],
			[0xFE0F, ''],
			[0x1F4AC, ''],
			[0x1F4AD, ''],
			[0x1F494,''],
			[0x1F49D, ''],
			[0x1F369, ''],
			[0x2198, ''],
			[ 0xFE0E,'']
		]
	};

}
