import { Inject, Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private webSocket?: WebSocket;

  constructor(private AuthenticationService: AuthenticationService) {
    this.connect();
  }

  started: boolean = false;
  connections: any[] = [];
	stack : any[] = [];
  onmessageDefer;

  connect() {
    if(this.started) {
      console.log('socket already running');
      console.log(this.connections)
    } 
    else {
      this.started = true
      console.log('creating new socket');
      console.log(this.connections)
      const host = 'wss://data.4zzz.org.au:5085';
      this.webSocket = new WebSocket(host);
      this.webSocket.onopen = event => {
        if (this.onmessageDefer) {
            this.webSocket!.onmessage = this.onmessageDefer;
            this.onmessageDefer = null;
        }
        this.AuthenticationService.getUser()?.details && this.webSocket!.send(
          JSON.stringify({ uid: String(this.AuthenticationService.getUser().details?.id).padStart(5, '0') })
        );
      };
      this.webSocket.onclose = event => {
        this.started = false

        console.log('Socket is closed. Reconnect will be attempted in 1 second.', event.reason);
        setTimeout( () => {
          this.connect();
          this.webSocket!.onmessage = (ev) => this.connections.forEach( cb => cb(ev) );
        }, 1000);
      };
    }
    
    // if (onmessageDefer) {
    // 	console.log(socket.ws, onmessageDefer)
    // 	socket.ws.onmessage = onmessageDefer;
    // 	onmessageDefer = null;
    // }
  }

  send(data) {
    data = JSON.stringify(data);
    if (this.webSocket!.readyState == 1) {
      this.webSocket!.send(data);
    } else {
      this.stack.push(data);
    }
  }

  close() {
    this.webSocket!.close();
  }

  onmessage(callback: any) {
    this.connections = this.connections.filter(cb => cb.toString() !== callback.toString())
    this.connections.push(callback)
    console.log(this.connections)
    if (this.webSocket) {
        console.log("got socket, setting up message receiver")
        this.webSocket.onmessage = (ev) => this.connections.forEach( cb => cb(ev) );
    } else {
        console.log("no socket, deferring message receiver", this.webSocket)

        const onmessageDefer = (ev) => this.connections.forEach( cb => cb(ev) );
    }
  }
}
