import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  liveReads: any;
}

@Component({
  selector: 'app-live-reads-dialog',
  templateUrl: './live-reads-dialog.component.html',
  styleUrls: ['./live-reads-dialog.component.scss']
})
export class LiveReadsDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,) {}
}
