import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, Inject, inject} from '@angular/core';
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {NgFor} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ContactService } from '../contact.service';
import { CountriesService } from '../countries.service';
import { environment } from 'src/environments/environment';

export interface Contact {
  id: string;
}
@Component({
  selector: 'app-merge-contacts',
  templateUrl: './merge-contacts.component.html',
  styleUrls: ['./merge-contacts.component.scss']
})
export class MergeContactsComponent {
  constructor(
    private ContactService: ContactService,
    private CountriesService: CountriesService,
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
  ) {

    CountriesService.query().subscribe( data => { this.countries = data });

    this.contactControl.valueChanges.subscribe( value =>
      {
        this.contactsForm.clear();
        this.contacts = [];
        this.individuals = [];
        this.contactcategories = [];
        
        value.forEach((id, i) => {
          this.ContactService.get(id).subscribe((subPromise) => {
            this.contacts.push(subPromise);

            let group = new UntypedFormGroup({
              org_nm: new UntypedFormControl({value: subPromise.org_nm, disabled: true }),
              website: new UntypedFormControl({value: subPromise.website, disabled: true }),
              org_abn: new UntypedFormControl({value: subPromise.org_abn, disabled: true }),
              email: new UntypedFormControl({value: subPromise.email, disabled: true }),
              work_ph: new UntypedFormControl({value: subPromise.work_ph, disabled: true }),
              street_no: new UntypedFormControl({value: subPromise.street_no, disabled: true }),
              street_nm: new UntypedFormControl({value: subPromise.street_nm, disabled: true }),
              suburb_nm: new UntypedFormControl({value: subPromise.suburb_nm, disabled: true }),
              state: new UntypedFormControl({value: subPromise.state, disabled: true }),
              postcode: new UntypedFormControl({value: subPromise.postcode, disabled: true }),
              country: new UntypedFormControl({value: subPromise.country, disabled: true }),
            })
            this.contactsForm.push(group)
            if (i === this.contactIds.length - 1) {
              console.log(this.contacts)
    
              this.contacts.forEach(z => {
                this.individuals = [ ...this.individuals, ...z.individuals ];
                this.contactcategories = [ ...this.contactcategories, ...z.contactcategories ]
                })
              console.log(this.individuals)
              console.log(this.contactcategories)
            }
          });
        })
      }
    );
  }

  
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  contactIds: Contact[] = [];
  contactControl = new UntypedFormControl(['angular']);

  contactsForm: FormArray<FormGroup> = new UntypedFormArray([])

  countries: any[] = []
  contacts: any[] = [];
  individuals: any[] = [];
  contactcategories: any[] = [];

	defaults = { contact_no: null, org_nm: null, website: null, org_abn: null, email: null, work_ph: null, address: null }

	default = null


  announcer = inject(LiveAnnouncer);


  mergeContacts() {
		this.http.post(environment.apiSrc + '/mergeContacts',  { contacts: this.contactIds.map(i => i.id), main: this.default, items: this.defaults } ).subscribe( data => {
			alert('Merge success!')
		})
  }

  isDefault = (id) => (
		this.default === id ? true : false
	)


  makeDefault(section, id) {
		console.log(id)

		if(section === 'contact_no') {
			this.defaults = {	contact_no: id, org_nm: id, website: id, org_abn: id, email: id, work_ph: id, address: id }
			this.default = id
		}
		else {
			this.defaults[section] = id
		}
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our contact
    if (value) {
      this.contactIds.push({id: value});
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(contact: Contact): void {
    const index = this.contactIds.indexOf(contact);

    if (index >= 0) {
      this.contactIds.splice(index, 1);

      this.announcer.announce(`Removed ${contact}`);
    }
  }

  edit(contact: Contact, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove contact if it no longer has a name
    if (!value) {
      this.remove(contact);
      return;
    }

    // Edit existing contact
    const index = this.contactIds.indexOf(contact);
    if (index >= 0) {
      this.contactIds[index].id = value;
    }
  }

  updateContact(event) {
    console.log(event);
  }
}
