import { Component, Inject, Input } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Countries, ProgramsShort, Subtypes } from '../zeddb.types';
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers'
import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { Transition } from "@uirouter/angular";
import { E } from '@angular/cdk/keycodes';
import { CountriesService } from '../countries.service';
import { SubtypesService } from '../subtypes.service';
import { PrizesService } from '../prizes.service';
import { ProgramsService } from '../programs.service';
import { SubService } from '../sub.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

export interface Programs {
  programid: number,
  programname: string;
}


@Component({
  selector: 'app-subscriber-details',
  templateUrl: './subscriber-details.component.html',
  styleUrls: ['./subscriber-details.component.scss']
})
export class SubscriberDetailsComponent {
  // @Input() subscriber: any = { subscriber: { id: 0 } };

  constructor(
    public CountriesService: CountriesService,
    public SubtypesService: SubtypesService, 
    public PrizesService: PrizesService,
    public ProgramsService: ProgramsService,
    public SubService: SubService,
    // @Inject('$rootScope') private $rootScope: any,
    public location: Location,
    private http: HttpClient,
    // protected trans: Transition,
    private route: ActivatedRoute,
    private router: Router,
  ){
    CountriesService.query().subscribe(response => { 
      this.countries = response; 
    });

    SubtypesService.query().subscribe(response => { 
      this.subCategories = response; 
    });

    PrizesService.query().subscribe(response => { 
      this.prizes = response; 
    });

    ProgramsService.query().subscribe(response => { 
      this.programs = response; 
    });

    this.lockObservable.subscribe( value => {
      console.log('lock: ', value)
      this.formDisabled = value;
      if (value) {
        this.subscriberForm.disable()
      }
      else {
        this.subscriberForm.enable()
      }
    });

  }
  
  ngOnInit() {

    this.route.data.subscribe(({ subscriber }) => {
      this.subscriber = subscriber;
      this.id = this.route.snapshot.paramMap.get('id');

      if (this.id === 'search') {
        let searchString = this.route.snapshot.fragment;
        if (searchString && searchString !== '') {
          this.searchString = searchString;
          this.http.post<any>(environment.apiSrc + '/fuzzySearch', { searchString: searchString }).subscribe(data => {
            this.searchResults = data;
            this.sortData(this.lastSort);
          })
        }
        this.subscriberForm.reset();
      }

      this.subscriberForm.valueChanges.subscribe( () => {
        console.log('CHANGED!', this.subscriberForm)
        this.subscriber = Object.assign(this.subscriber, this.subscriberForm.value);
        this.formEdited = true;
      });


      if (this.id === 'search' || this.id === 'add') {
        this.lockObservable.next(false);
      }
      else {
      
        this.populateSubscriber();
        this.lockObservable.next(false);
        this.searchResultsSelection = {};

        this.diffZedder = this.subscriber.subemail !== this.subscriber.zedders[0].email;

        if(this.diffZedder) {
          this.http.post(environment.apiSrc + '/zedders-from-email', { 'email': this.subscriber.subemail }).subscribe( (data) => {
            this.newZedder = data;
          })
        }
      }
    })

  }

  

  get refForm() {
    return this.subscriberForm.get('bandmembers') as FormArray;
  }

  subscriber: any = { subscriber: { id: 0 } };
  // params = this.trans.params();
  id: any = this.route.snapshot.paramMap.get('id');

  programs: Programs[] = [];
  subCategories: Subtypes[] = [];
  countries: Countries[] = [];
  prizes: any = [];
  genders = ['Not Listed', 'Man', 'Woman', 'Other', 'Non-Binary']
  shirtSizes = ['S', 'M', 'L', 'XL', 'XXL', 'Small', 'Medium', 'Large', 'Extra-Large', 'Extra-Extra-Large'];
  
  lockObservable = new Subject<boolean>();

  submitLocked = false;
  formDisabled = false;
  formEdited = false;
  subscriberForm = new UntypedFormGroup({
    subtypeid: new UntypedFormControl(null), 
    subfirstname: new UntypedFormControl(null), 
    sublastname: new UntypedFormControl(null), 
    nameoncard: new UntypedFormControl(null), 
    subbusinessname: new UntypedFormControl(null), 
    subtype: new UntypedFormControl(null), 
    subcommunitygroup: new UntypedFormControl(null), 
    subartistname: new UntypedFormControl(null), 
    submusicianname: new UntypedFormControl(null), 
    subbandname: new UntypedFormControl(null), 
    bandmembers:  new UntypedFormArray([]),
    petname: new UntypedFormControl(null), 
    submobile: new UntypedFormControl(null), 
    subemail: new UntypedFormControl(null), 
    suburl: new UntypedFormControl(null), 
    gender: new UntypedFormControl(null), 
    delivery_address:  new UntypedFormGroup({
      address: new UntypedFormControl(null), 
      locality: new UntypedFormControl(null), 
      region: new UntypedFormControl(null), 
      country: new UntypedFormControl(null), 
      postcode: new UntypedFormControl(null), 
    }),
    pledge:  new UntypedFormGroup({
      radiothonprizeid: new UntypedFormControl(null), 
      radiothonprize2: new UntypedFormControl(null), 
      pledgenotes: new UntypedFormControl(null), 
    }),
    programid: new UntypedFormControl(null), 
    t_size: new UntypedFormControl(null), 
    subcomment: new UntypedFormControl(null), 
    paymentdate: new UntypedFormControl(null), 
    pay_it_forward: new UntypedFormControl({ value: null, disabled: true }), 
    pay_the_rent: new UntypedFormControl({ value: null, disabled: true }), 
    expirydate: new UntypedFormControl(null), 
    receiptnumber: new UntypedFormControl({ value: null, disabled: true }), 
    changeduser: new UntypedFormControl(null), 
    subnumber: new UntypedFormControl({value: null, disabled: true}), 
    orderid: new UntypedFormControl({value: null, disabled: true}), 
    createddate: new UntypedFormControl({value: null, disabled: true}), 
    changeddate: new UntypedFormControl({value: null, disabled: true}), 
    cart_customer_name: new UntypedFormControl({value: null, disabled: true}), 
    billing_address:  new UntypedFormGroup({
      address: new UntypedFormControl(null), 
      locality: new UntypedFormControl(null), 
      region: new UntypedFormControl(null), 
      country: new UntypedFormControl(null), 
      postcode: new UntypedFormControl(null), 
    }),
  });

  searchForm = new UntypedFormGroup({
    subnumber: new UntypedFormControl(null), 
    nameoncard: new UntypedFormControl(null), 
    bandmember: new UntypedFormControl(null), 
    createdDateStart: new UntypedFormControl(null), 
    createdDateEnd: new UntypedFormControl(null), 
    paymentDateStart: new UntypedFormControl(null), 
    paymentDateEnd: new UntypedFormControl(null),
  });
  

  searchString = '';
  searchResults: any = [];
  searchResultsSelection: any = {}
  lastSort: Sort = { active: 'expiry', direction: 'desc' };
  sortedData: any[] = [];
  displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'subnumber', 'expiry'];

  diffZedder = false;
  newZedder: any = [];

  saveSubscriber = () => {
    console.log(this.subscriber)
    this.submitLocked = true;
    this.SubService.update(this.subscriber).subscribe( () => {
      this.submitLocked = false;
    })
  }

  searchSubscribers = () => {
    // remove null / undefined / empty arrays
    const reducer = (obj) => Object.entries(obj).reduce((a,[k,v]) => (v === false || v == null || v === '' || ( v instanceof Array && v.length === 0 ) ? a : (a[k]= v instanceof Object && !(v instanceof Array) ? reducer(v) : v ,  a)), {})
    
    let searchFrom; Object.entries( this.searchForm.value ).forEach( ([k,v]) => searchFrom = { ...searchFrom, [k]: v instanceof Object && v['_isAMomentObject'] ? (v as any).format('YYYY-MM-DD') : v } );

    let searchTerms = reducer( { ...this.subscriber, ...searchFrom })

    delete searchTerms['zedder'];
    delete searchTerms['delivery_address'];
    delete searchTerms['billing_address'];
    delete searchTerms['pledge'];

    console.log(searchTerms)

    this.http.get<any>(environment.apiSrc + '/subscribers', { params: searchTerms }).subscribe( data => { 
      this.searchResults = data;
      this.sortData(this.lastSort);
    })
  }

  newZedderFromSub = () => {
    this.http.post(environment.apiSrc + '/new-zedder-from-subscription', { subid: this.subscriber.subid }).subscribe( data => {
      console.log(data);
    });
  }

  removeMember = (index) => {
    let bm = this.subscriberForm.get('bandmembers') as FormArray;
    bm.removeAt(index);
  }
  addMember = () => {
    let bm = this.subscriberForm.get('bandmembers') as FormArray;
    bm.push(new UntypedFormGroup({
      subbandmemberid: new UntypedFormControl(null),
      subbandmemberfirstname: new UntypedFormControl(null), 
      subbandmemberlastname: new UntypedFormControl(null), 
    }))
  }

  editSub = () => {
    let sub = this.subscriberForm.get('subnumber');
    sub?.disabled ? sub?.enable() : sub?.disable();
  }

  goto = (id) => {
    this.router.navigateByUrl('/subscribers/' + id);
  }

  genSub = () => {
		this.http.get<any[]>(environment.apiSrc + '/getfreshsub').subscribe( data => {
      data.forEach( i => {
        this.subscriberForm.patchValue({'subnumber': i.nextval});
        this.editSub();
      });
		});
	};

  quickSearch = (event) => {
    let searchString = event.target.value;
    this.searchString = searchString;
    this.router.navigateByUrl('/subscribers/search#' + searchString);
    this.http.post<any>(environment.apiSrc + '/fuzzySearch', { searchString: searchString }).subscribe(data => {
      this.searchResults = data;
      this.sortData(this.lastSort);
    })
  }


  fetchSubscriber(id) {
    this.SubService.get(id).subscribe( res => {
      this.subscriber = res;
      this.populateSubscriber();
      this.lockObservable.next(true);
    });
  }

  populateSubscriber() {
    this.subscriberForm.patchValue({
      subtypeid: this.subscriber.subtypeid, 
      subfirstname: this.subscriber.subfirstname, 
      sublastname: this.subscriber.sublastname, 
      nameoncard: this.subscriber.nameoncard, 
      subbusinessname: this.subscriber.subbusinessname, 
      subtype: this.subscriber.subtype, 
      subcommunitygroup: this.subscriber.subcommunitygroup, 
      subartistname: this.subscriber.subartistname, 
      submusicianname: this.subscriber.submusicianname, 
      subbandname: this.subscriber.subbandname, 
      petname: this.subscriber.petname, 
      submobile: this.subscriber.submobile, 
      subemail: this.subscriber.subemail, 
      suburl: this.subscriber.suburl, 
      gender: this.subscriber.gender, 
      delivery_address: {
        address: this.subscriber.delivery_address?.address, 
        locality: this.subscriber.delivery_address?.locality, 
        region: this.subscriber.delivery_address?.region, 
        country: this.subscriber.delivery_address?.country, 
        postcode: this.subscriber.delivery_address?.postcode, 
      },
      pledge: {
        radiothonprizeid: this.subscriber.pledge && this.subscriber.pledge.radiothonprizeid, 
        radiothonprize2: this.subscriber.pledge && this.subscriber.pledge.radiothonprize2, 
        pledgenotes: this.subscriber.pledge && this.subscriber.pledge.pledgenotes, 
      },
      programid: this.subscriber.programid, 
      t_size: this.subscriber.t_size, 
      subcomment: this.subscriber.subcomment, 
      paymentdate: this.subscriber.paymentdate, 
      pay_it_forward: this.subscriber.pay_it_forward, 
      pay_the_rent: this.subscriber.pay_the_rent, 
      expirydate: this.subscriber.expirydate, 
      receiptnumber: this.subscriber.receiptnumber, 
      changeduser: this.subscriber.changeduser, 
      subnumber: this.subscriber.subnumber, 
      orderid: this.subscriber.orderid, 
      createddate: this.subscriber.createddate, 
      changeddate: this.subscriber.changeddate, 
      cart_customer_name: this.subscriber.cart_customer_name, 
      billing_address:  {
        address: this.subscriber.billing_address && this.subscriber.billing_address.address, 
        locality: this.subscriber.billing_address && this.subscriber.billing_address.locality, 
        region: this.subscriber.billing_address && this.subscriber.billing_address.region, 
        country: this.subscriber.billing_address && this.subscriber.billing_address.country, 
        postcode: this.subscriber.billing_address && this.subscriber.billing_address.postcode, 
      },
    }, { emitEvent: false });
  
    let bm = this.subscriberForm.get('bandmembers') as FormArray;
    bm.clear({ emitEvent: false });
    this.subscriber.bandmembers.forEach(i => 
      bm.push(new UntypedFormGroup({
        subbandmemberid: new UntypedFormControl(i.subbandmemberid),
        subbandmemberfirstname: new UntypedFormControl(i.subbandmemberfirstname), 
        subbandmemberlastname: new UntypedFormControl(i.subbandmemberlastname), 
      }), { emitEvent: false }
    ))
  }
  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    this.fetchSubscriber(row.subid);
    this.lockObservable.next(false);
  };


  activeSub = (sub) => new Date(sub) > new Date();

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.searchResults.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.subid, b.subid, isAsc);
        case 'name':
          return compare(a.sublastname + a.subfirstname, b.sublastname + b.subfirstname, isAsc);
        case 'email':
          return compare(a.subemail, b.subemail, isAsc);
        case 'subnumber':
          return compare(a.subsubnumber, b.subsubnumber, isAsc);
        case 'expiry':
          return compare(a.expirydate, b.expirydate, isAsc);
        default:
          return 0;
      }
    });
  }
}
