import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[appNavScroll]'
})
export class NavScrollDirective {

  hostElement?: HTMLElement;
  overflow;
  leftVisible;
  rightVisible;
  
  constructor(private element: ElementRef) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.overflow = this.element.nativeElement.clientWidth - this.element.nativeElement.scrollWidth;
    this.checkVisible();
  }

  ngAfterViewInit(){
    this.overflow = this.element.nativeElement.clientWidth - this.element.nativeElement.scrollWidth;
    setTimeout(() => {
      this.checkVisible();
    });
  }

  checkVisible() {
    if(this.overflow < 0 && this.element.nativeElement.scrollLeft > 0) {
      this.leftVisible = true;
      this.element.nativeElement.classList.add("left");
    } else {
      this.leftVisible = false;
      this.element.nativeElement.classList.remove("left");
    }
    if(this.overflow < 0 && this.element.nativeElement.offsetWidth + this.element.nativeElement.scrollLeft < this.element.nativeElement.scrollWidth) {
      this.rightVisible = true;
      this.element.nativeElement.classList.add("right");
    }
    else {
      this.rightVisible = false;
      this.element.nativeElement.classList.remove("right");
    }

  }

  @HostListener("mousemove", ["$event"])
  applyCustomScroll(mouseEvent: MouseEvent): void {
    this.hostElement = this.element.nativeElement;
    const hostElementPosition = this.hostElement?.getBoundingClientRect();
    const rightEdgeWithoutScrollBar = hostElementPosition!.right - 10;

    if (mouseEvent.clientX >= rightEdgeWithoutScrollBar) {
      this.hostElement?.classList.add("custom-scroll-vertical-hover");
    } else {
      this.hostElement?.classList.remove("custom-scroll-vertical-hover");
    }
  }

  @HostListener("click", ["$event"])
  clickHandler(mouseEvent: MouseEvent): void {
    this.hostElement = this.element.nativeElement;
    const hostElementPosition = this.hostElement?.getBoundingClientRect();
    const rightEdgeWithoutScrollBar = hostElementPosition!.right - 10;

    const leftEdgeWithoutScrollBar = hostElementPosition!.left + 10;

    if (mouseEvent.clientX >= rightEdgeWithoutScrollBar) {
      console.log('clicked right')
      this.shiftRight();
    }

    if (mouseEvent.clientX <= leftEdgeWithoutScrollBar) {
      console.log('clicked left')
      this.shiftLeft();
    }
  }

  @HostListener("mouseout")
  removeCustomScroll(): void {
    this.hostElement?.classList.remove("custom-scroll-vertical-hover");
  }

  shiftLeft() {
    this.hostElement?.scrollTo({
      top: 0,
      left: this.hostElement?.scrollLeft + 200,
      behavior: "smooth",
    })
    setTimeout( () => this.checkVisible(), 500 ) 
  }


  shiftRight() {
    this.hostElement?.scrollTo({
      top: 0,
      left: this.hostElement?.scrollLeft - 200,
      behavior: "smooth",
    })
    setTimeout( () => this.checkVisible(), 500 ) 
  }
}
