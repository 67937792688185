<!-- <mat-toolbar>
  <span>Volunteers</span>
  <span style="flex: 1"></span>
  <button mat-button class="caps" aria-label="Search">Search</button>
  <button mat-button class="caps" aria-label="Add Subscription">Add Volunteer</button>
</mat-toolbar> -->

<mat-toolbar class="quick-search" style="position: sticky; top: 0; z-index: 150">
  <mat-form-field class="full-width">
    <input matInput placeholder="Quick Search" (keydown.enter)="quickSearch($event)" [value]="searchString">
  </mat-form-field>
</mat-toolbar>

<section [hidden]="searchResults.length === 0" class="search-results mat-elevation-z8">
  <div #tableContainer class="table-container">
    <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"><a (click)="$event.stopPropagation()" [routerLink]="'/volunteers/' + element.id">{{element.zedder.firstname}} {{element.zedder.lastname}}</a></td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="email">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element">{{element.zedder.email}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let element">{{element.zedder.phone}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="subnumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Sub No </th>
        <td mat-cell *matCellDef="let element">{{element.zedder.subnumber}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="active">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Active Volunteer </th>
        <td mat-cell *matCellDef="let element">{{element.active}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="roles">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Roles </th>
        <td mat-cell *matCellDef="let element">{{element.positions | join:'position' }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <ng-container matColumnDef="departments">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Departments </th>
        <td mat-cell *matCellDef="let element">{{element.current_departments | join:'department' }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>    
  </div>
  <div style="display: flex; align-items: center; background: #424242;">
    <button (click)="searchVolunteers()" mat-icon-button matTooltip="Refresh" matTooltipPosition="above" style="margin-left: 8px; color: #a8a8a8;">
      <mat-icon>refresh</mat-icon>
    </button>
    <button (click)="exportCSV()" matTooltip="Export period as spreadsheet" matTooltipPosition="above" mat-icon-button style="margin-left: 8px; color: #a8a8a8;" aria-label="export period as spreadsheet">
      <mat-icon>table_chart</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Columns" matTooltipPosition="above" style="margin-left: 8px; color: #a8a8a8;">
      <mat-icon>view_column</mat-icon>
    </button>
    <mat-menu #menu="matMenu" > 
      <div style="max-height: 300px; min-width: 150px; overflow-y: scroll;">
        <div *ngFor="let col of allColumns; index as c">
          <mat-checkbox [checked]="col.visible" (change)="changeColumns(c, $event)">
            {{col.column}}
          </mat-checkbox>
        </div>
      </div>
    </mat-menu>
    <mat-paginator 
      #volunteerPaginator
      style="flex: 1"
      [length]="resultsLength" 
      [pageSize]="10"
      showFirstLastButtons
      aria-label="Select page of orders">
    </mat-paginator>
  </div>
</section>

<mat-toolbar style="position: sticky; top: 0; z-index: 150;">
  <mat-toolbar-row>
    <h2>{{volunteer.zedder.firstname}} {{volunteer.zedder.lastname}}</h2>
  </mat-toolbar-row>
  <mat-toolbar-row style="height: 24px;" ng-show="zedder.id">
    <ul class="inline" style="width: 100%;">
      <li>
        <strong>ID #:</strong> {{volunteer.id}}
      </li>
			<li>
        <a [href]="'/zedders/' + volunteer.zedder_id"><strong>Zedder ID #:</strong> {{volunteer.zedder_id}}</a>
      </li>
      <li>
        <strong>Created:</strong> {{volunteer.created_at | date:'medium'}}
      </li>
      <li>
        <strong>Last Updated:</strong> {{volunteer.updated_at | date:'medium'}}
      </li>
      <!-- <li *ngIf="volunteer.zedder.id">
        <strong>Zedder ID #:</strong><a [href]="'/zedders/' + volunteer.zedder.id"> {{volunteer.zedder.id}}</a>
      </li> -->
      <li>
        {{activeSub(volunteer.zedder.expiry) ? 'Active Subscription' : 'Past Subscription' }}
      </li>
      
    </ul>
  </mat-toolbar-row>
</mat-toolbar>

<div class="info-container mat-typography" [formGroup]="volunteerForm">
  <div style="flex: 1">

		<mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Contact Information</mat-card-title>
      </mat-card-header>
      <mat-card-content formGroupName="zedder">
				<div style="display: flex; gap: 8px;">
					<mat-form-field class="full-width">
						<mat-label>First Name</mat-label>
						<input matInput placeholder="First Name" formControlName="firstname">
					</mat-form-field>
	
					<mat-form-field class="full-width">
						<mat-label>Last Name</mat-label>
						<input matInput placeholder="Last Name" formControlName="lastname">
					</mat-form-field>
				</div>

				<mat-form-field class="full-width">
					<mat-label>Pronouns</mat-label>
					<input matInput placeholder="Pronouns" formControlName="pronouns" value="">
				</mat-form-field>

				<mat-form-field class="full-width">
          <mat-label>Phone</mat-label>
					<a [disabled]="!(volunteer.zedder && volunteer.zedder.phone)" [href]="'tel:' + (volunteer.zedder && volunteer.zedder.phone)" mat-icon-button matSuffix>
            <mat-icon>phone</mat-icon>
          </a>
          <input matInput placeholder="Phone" formControlName="phone" value="">
        </mat-form-field>

				<mat-form-field class="full-width">
          <mat-label>Email</mat-label>
					<a [disabled]="!(volunteer.zedder && volunteer.zedder.email)" [href]="'mailto:' + (volunteer.zedder && volunteer.zedder.email)" mat-icon-button matSuffix>
            <mat-icon>email</mat-icon>
          </a>
          <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Sub #</mat-label>
					<input matInput placeholder="Sub #" formControlName="subnumber" value="">
				</mat-form-field>

				<div>{{activeSub(volunteer.zedder.expiry) ? 'Active Subscription' : 'Lapsed Subscription'}}</div>

			</mat-card-content>
		</mat-card>

		<mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Volunteer Information, Interests & Skills</mat-card-title>
      </mat-card-header>
      <mat-card-content>

				<div style="display: flex; gap: 8px;">
					<mat-form-field class="full-width">
						<mat-label>Contacted</mat-label>
						<input matInput [matDatepicker]="contactedPicker" formControlName="contacted">
						<mat-datepicker-toggle matIconSuffix [for]="contactedPicker"></mat-datepicker-toggle>
						<mat-datepicker #contactedPicker></mat-datepicker>
					</mat-form-field>

					<mat-form-field class="full-width">
						<mat-label>Responded</mat-label>
						<input matInput [matDatepicker]="respondedPicker" formControlName="responded">
						<mat-datepicker-toggle matIconSuffix [for]="respondedPicker"></mat-datepicker-toggle>
						<mat-datepicker #respondedPicker></mat-datepicker>
					</mat-form-field>
				</div>

				<mat-form-field class="full-width">
					<mat-label>General Info / Interests</mat-label>
					<textarea matInput placeholder="General Info / Interests" formControlName="general_info"></textarea>
				</mat-form-field>

				<mat-form-field class="full-width">
          <mat-label>Department Interests</mat-label>
          <mat-chip-grid #departmentChip aria-label="Tags">
            <mat-chip-row *ngFor="let department of volunteerForm.get('departments')!.value"
              (removed)="removeDepartment(department)"
              [editable]="false"
              (edited)="editDepartment(department, $event)"
              [aria-description]="'press enter to edit ' + department.department">
              {{department.department}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + department.department">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Departments..."
            #departmentInput
            [formControl]="departmentControl"
            [matAutocomplete]="departmentComplete"
            [matChipInputFor]="departmentChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #departmentComplete="matAutocomplete" 
            (optionSelected)="selectedDepartment($event)">
            <mat-option *ngFor="let department of departmentOptions | async" [value]="department">
              {{department.department}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>


				<mat-form-field class="full-width">
          <mat-label>Skills</mat-label>
          <mat-chip-grid #skillChip aria-label="Tags">
            <mat-chip-row *ngFor="let skill of volunteerForm.get('skills')!.value"
              (removed)="removeSkill(skill)"
              [editable]="false"
              (edited)="editSkill(skill, $event)"
              [aria-description]="'press enter to edit ' + skill.skill">
              {{skill.skill}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + skill.skill">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Skills..."
            #skillInput
            [formControl]="skillControl"
            [matAutocomplete]="skillComplete"
            [matChipInputFor]="skillChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #skillComplete="matAutocomplete" 
            (optionSelected)="selectedSkill($event)">
            <mat-option *ngFor="let skill of skillOptions | async" [value]="skill">
              {{skill.skill}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Other Skills</mat-label>
					<textarea matInput placeholder="Other Skills" formControlName="qualified"></textarea>
				</mat-form-field>


				<mat-form-field class="full-width">
          <mat-label>Qualifications</mat-label>
          <mat-chip-grid #qualificationChip aria-label="Tags">
            <mat-chip-row *ngFor="let qualification of volunteerForm.get('qualifications')!.value"
              (removed)="removeQualification(qualification)"
              [editable]="false"
              (edited)="editQualification(qualification, $event)"
              [aria-description]="'press enter to edit ' + qualification.qualification">
              {{qualification.qualification}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + qualification.qualification">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Qualifications..."
            #qualificationInput
            [formControl]="qualificationControl"
            [matAutocomplete]="qualificationComplete"
            [matChipInputFor]="qualificationChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #qualificationComplete="matAutocomplete" 
            (optionSelected)="selectedQualification($event)">
            <mat-option *ngFor="let qualification of qualificationOptions | async" [value]="qualification">
              {{qualification.qualification}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Important Information</mat-label>
					<textarea matInput placeholder="Important Information" formControlName="important_info"></textarea>
				</mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Notes</mat-label>
					<textarea matInput placeholder="Notes" formControlName="notes"></textarea>
				</mat-form-field>

			</mat-card-content>



		</mat-card>
	</div>

	<div style="flex: 1">
    <mat-card *ngIf="id === 'search'" class="search-card" >
      <mat-card-header>
        <mat-card-title>Search Paramaters</mat-card-title>
      </mat-card-header>
      <mat-card-content [formGroup]="searchForm">

        <mat-form-field class="full-width" subscriptSizing="dynamic">
          <mat-label>Vols without Induction</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate #dateRangeStart formControlName="createdDateStart" placeholder="Start date">
            <input matEndDate #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)" formControlName="createdDateEnd" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker style="width:316px" #picker></mat-date-range-picker>
        </mat-form-field>


        <mat-form-field class="full-width" subscriptSizing="dynamic">
          <mat-label>Induction Range</mat-label>
          <mat-date-range-input [rangePicker]="inductionPicker">
            <input matStartDate #inductionRangeStart formControlName="inductionDateStart" placeholder="Start date">
            <input matEndDate #inductionRangeEnd (dateChange)="inductionRangeChange(inductionRangeStart, inductionRangeEnd)" formControlName="inductionDateEnd" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="inductionPicker"></mat-datepicker-toggle>
          <mat-date-range-picker style="width:316px" #inductionPicker></mat-date-range-picker>
        </mat-form-field>

				<!-- <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Created Date Start</mat-label>
            <input matInput [matDatepicker]="createdDateStartPicker" formControlName="createdDateStart">
            <mat-datepicker-toggle matIconSuffix [for]="createdDateStartPicker"></mat-datepicker-toggle>
            <mat-datepicker #createdDateStartPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Created Date End</mat-label>
            <input matInput [matDatepicker]="createdDateEndPicker" formControlName="createdDateEnd">
            <mat-datepicker-toggle matIconSuffix [for]="createdDateEndPicker"></mat-datepicker-toggle>
            <mat-datepicker #createdDateEndPicker></mat-datepicker>
          </mat-form-field>
        </div>

				<div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Induction Date Start</mat-label>
            <input matInput [matDatepicker]="inductionDateStartPicker" formControlName="inductionDateStart">
            <mat-datepicker-toggle matIconSuffix [for]="inductionDateStartPicker"></mat-datepicker-toggle>
            <mat-datepicker #inductionDateStartPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Induction Date End</mat-label>
            <input matInput [matDatepicker]="inductionDateEndPicker" formControlName="inductionDateEnd">
            <mat-datepicker-toggle matIconSuffix [for]="inductionDateEndPicker"></mat-datepicker-toggle>
            <mat-datepicker #inductionDateEndPicker></mat-datepicker>
        </mat-form-field>
        </div> -->
			</mat-card-content>
    </mat-card>
    
		<mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Current Volunteer Status</mat-card-title>
      </mat-card-header>
      <mat-card-content>

				<div style="display: flex; gap: 8px; justify-content: space-between; align-items: center; padding-bottom: 16px;">
					<mat-checkbox formControlName="completed_orientation">Completed Induction</mat-checkbox>

					<mat-form-field subscriptSizing="dynamic">
						<mat-label>Induction Date</mat-label>
						<input matInput [matDatepicker]="inductionPicker" formControlName="completed_orientation_date">
						<mat-datepicker-toggle matIconSuffix [for]="inductionPicker"></mat-datepicker-toggle>
						<mat-datepicker #inductionPicker></mat-datepicker>
					</mat-form-field>

					<mat-checkbox formControlName="active">Active Volunteer</mat-checkbox>
				</div>

				<mat-form-field class="full-width">
          <mat-label>Internal Training</mat-label>
          <mat-chip-grid #trainingChip aria-label="Tags">
            <mat-chip-row *ngFor="let training of volunteerForm.get('training')!.value"
              (removed)="removeTraining(training)"
              [editable]="false"
              (edited)="editTraining(training, $event)"
              [aria-description]="'press enter to edit ' + training.training">
              {{training.training}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + training.training">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Trainings..."
            #trainingInput
            [formControl]="trainingControl"
            [matAutocomplete]="trainingComplete"
            [matChipInputFor]="trainingChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #trainingComplete="matAutocomplete" 
            (optionSelected)="selectedTraining($event)">
            <mat-option *ngFor="let training of trainingOptions | async" [value]="training">
              {{training.training}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

				<mat-form-field class="full-width">
          <mat-label>Current Role</mat-label>
          <mat-chip-grid #positionChip aria-label="Tags">
            <mat-chip-row *ngFor="let position of volunteerForm.get('positions')!.value"
              (removed)="removePosition(position)"
              [editable]="false"
              (edited)="editPosition(position, $event)"
              [aria-description]="'press enter to edit ' + position.position">
              {{position.position}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + position.position">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Positions..."
            #positionInput
            [formControl]="positionControl"
            [matAutocomplete]="positionComplete"
            [matChipInputFor]="positionChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #positionComplete="matAutocomplete" 
            (optionSelected)="selectedPosition($event)">
            <mat-option *ngFor="let position of positionOptions | async" [value]="position">
              {{position.position}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

				<mat-form-field class="full-width">
          <mat-label>Current Departments</mat-label>
          <mat-chip-grid #currentDepartmentChip aria-label="Department">
            <mat-chip-row *ngFor="let currentDepartment of volunteerForm.get('current_departments')!.value"
              (removed)="removeCurrentDepartment(currentDepartment)"
              [editable]="false"
              (edited)="editCurrentDepartment(currentDepartment, $event)"
              [aria-description]="'press enter to edit ' + currentDepartment.department">
              {{currentDepartment.department}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + currentDepartment.department">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Departments..."
            #currentDepartmentInput
            [formControl]="currentDepartmentControl"
            [matAutocomplete]="currentDepartmentComplete"
            [matChipInputFor]="currentDepartmentChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #currentDepartmentComplete="matAutocomplete" 
            (optionSelected)="selectedCurrentDepartment($event)">
            <mat-option *ngFor="let currentDepartment of currentDepartmentOptions | async" [value]="currentDepartment">
              {{currentDepartment.department}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Role Notes</mat-label>
					<textarea matInput placeholder="Role Notes" formControlName="roles"></textarea>
				</mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Role History</mat-label>
					<textarea matInput placeholder="Role History" formControlName="roles_history"></textarea>
				</mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Announcer History</mat-label>
					<textarea matInput placeholder="Announcer History" formControlName="announcer_history"></textarea>
				</mat-form-field>

				<mat-form-field class="full-width">
					<mat-label>Volunteer History</mat-label>
					<textarea matInput placeholder="Volunteer History" formControlName="project_history"></textarea>
				</mat-form-field>


				<label>Availability</label>

				<app-availability-toggle [availability]="volunteer.availability" [update]="changeAvailability"/>


			</mat-card-content>
    </mat-card>
	</div>
</div>

<div class="floating-fab" [hidden]="!formEdited">
	<button [disabled]="submitLocked" (click)="searchResultsSelection.id ? goto(searchResultsSelection.id) : id === 'search' ? searchVolunteers() : saveVolunteer()" mat-fab color="primary" aria-label="Save Subscriber">
		<mat-icon>{{searchResultsSelection.id ? 'arrow_forward' : id === 'search' ? 'search' : id === 'new' ? 'add' : 'edit' }}</mat-icon>
	</button>
</div>