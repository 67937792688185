import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenresService {

  private apiRoot: string;
  private apiHandle = "/genres";

  constructor(private http: HttpClient) {
    this.apiRoot = environment.apiSrc + this.apiHandle;
  }

  query(params?: { string: string }) {
    return this.http.get<any>(this.apiRoot, { params });
  }

  get(id, params?: { string: string }) {
    return this.http.get<any>(this.apiRoot + '/' + id, { params });
  }

  save(data: any) {
    return this.http.post<any>(this.apiRoot, data);
  }

  update(data: any) {
    return this.http.put<any>(this.apiRoot + '/' + data.id, data);
  }

  remove(data: any) {
    return this.http.delete<any>(this.apiRoot + '/' + data.id);
  }

}
