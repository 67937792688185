import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudioService {

  constructor() { }

  public studio: any;

  public onAir: boolean = false;

  public delegationStatus: any = {
    "fm": { 
      "studio1": { "active": false, "locked": false, "released": false },
      "studio2": { "active": true, "locked": false, "released": false },
      "studio3": { "active": false, "locked": false, "released": false },
      "studio4": { "active": false, "locked": false, "released": false },
      "auto": { "active": false, "locked": false, "released": false },
      "external": { "active": false, "locked": false, "released": false }
    },
    "zd": { 
      "studio1": { "active": false, "locked": false, "released": false },
      "studio2": { "active": false, "locked": false, "released": false },
      "studio3": { "active": false, "locked": false, "released": false },
      "studio4": { "active": false, "locked": false, "released": false },
      "auto": { "active": true, "locked": false, "released": false },
      "external": { "active": false, "locked": false, "released": false }
    }
  };

  private studioState = new Subject<any>();

  public setStudio(studio) {
    this.studio = studio;
  }

  public getStudio() {
    return this.studio;
  }

  public setDelegationStatus(delegationStatus) {
    this.delegationStatus = delegationStatus;
    this.sendMessage({ delegationStatus: delegationStatus });
  }

  public getDelegationStatus() {
    return this.delegationStatus;
  }

  public setOnAir(onAir) {
    this.onAir = onAir;
    if(!onAir) {
      this.sendMessage({ onAir: this.onAir, studio: this.studio })
    }
  }

  public getOnAir() {
    return this.onAir;
  }

  sendMessage(message: any) {
    this.studioState.next(message);
  }

  onMessage(): Observable<any> {
    return this.studioState.asObservable();
}
}
