import { Pipe, PipeTransform } from '@angular/core';

import { formatBytes } from './helpers';
import { formatNumber } from '@angular/common';
@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    return formatBytes(value);
  }

}
