
<mat-toolbar class="release-quick-search" style="position: sticky; top: 0; z-index: 150">
  <mat-form-field class="full-width">
    <input matInput placeholder="Search Releases" (keydown.enter)="quickSearch($event)" [value]="searchSub">
  </mat-form-field>
</mat-toolbar>

<section [hidden]="searchResult.length === 0" class="search-results mat-elevation-z8">
  <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="library_no">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element">{{element.library_no}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="artist">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Artist </th>
      <td mat-cell *matCellDef="let element"><a [routerLink]="'/releases/' + element.library_no">{{element.artist_nm}}</a></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="title">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Title </th>
      <td mat-cell *matCellDef="let element"><a [routerLink]="'/releases/' + element.library_no">{{element.title}}</a></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="label">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Label </th>
      <td mat-cell *matCellDef="let element">{{element.album_label}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="year">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Year </th>
      <td mat-cell *matCellDef="let element">{{element.release_year}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>    
</section>

<mat-toolbar style="position: sticky; top: 0; z-index: 150;" *ngIf="id !== 'search'">
  <mat-toolbar-row>
    <h2>{{release.artist && release.artist.name ? release.artist.name : release.artist_nm}} - {{release.title}}</h2>
  </mat-toolbar-row>
  <mat-toolbar-row style="height: 24px;" ng-show="zedder.id">
    <ul class="inline" style="width: 100%;">
      <li>
        <strong>ID #:</strong> {{release.library_no}}
      </li>
      <li>
        <strong>Artist ID:</strong> <a [routerLink]="'/releases/artists/' + (release.artist && release.artist.id)">{{release.artist && release.artist.id}}</a>
      </li>
      <li>
        <strong>Created:</strong> {{release.created_at | date:'medium'}}
      </li>
      <li>
        <strong>Last Updated:</strong> {{release.updated_at | date:'medium'}}
      </li>
      <li style="float: right;" [hidden]="!message">{{message}}</li>
    </ul>
    <!-- <button mat-stroked-button (click)="openSnackBar('Updated', 'OK!')">Show snack-bar</button> -->
  </mat-toolbar-row>
</mat-toolbar>

<div class="info-container mat-typography" [formGroup]="releaseForm">
  <div style="flex: 1">
    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Details</mat-card-title>
      </mat-card-header>
      <mat-card-content  formGroupName="artist">

        <mat-form-field class="full-width">
          <mat-label>Artist</mat-label>
          <input matInput placeholder="Artist" (blur)="checkRelease($event)" formControlName="name" [matAutocomplete]="artistComplete">
          <mat-autocomplete 
            autoActiveFirstOption 
            #artistComplete="matAutocomplete" 
            >
            <mat-option (click)="fetchArtist(artist)" *ngFor="let artist of artistOptions | async" [value]="artist.name">
              {{artist.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Artist Aliases</mat-label>
          <mat-chip-grid #aliasChip aria-label="Artists Aliases">
            <mat-chip-row *ngFor="let artist of releaseForm.get('artist.aliases')!.value"
              (removed)="removeAlias(artist)"
              [editable]="false"
              (edited)="editAlias(artist, $event)"
              [aria-description]="'press enter to edit ' + artist.name">
              {{artist.name}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + artist.name">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Artists..."
            #aliasInput
            [formControl]="aliasControl"
            [matAutocomplete]="aliasComplete"
            [matChipInputFor]="aliasChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #aliasComplete="matAutocomplete" 
            (optionSelected)="selectedAlias($event)">
            <mat-option *ngFor="let artist of aliasOptions | async" [value]="artist">
              {{artist.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Related Artists</mat-label>
          <mat-chip-grid #relatedChip aria-label="Related Artists">
            <mat-chip-row *ngFor="let artist of releaseForm.get('artist.related')!.value"
              (removed)="removeRelated(artist)"
              [editable]="false"
              (edited)="editRelated(artist, $event)"
              [aria-description]="'press enter to edit ' + artist.name">
              {{artist.name}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + artist.name">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Artists..."
            #relatedInput
            [formControl]="relatedControl"
            [matAutocomplete]="relatedComplete"
            [matChipInputFor]="relatedChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #relatedComplete="matAutocomplete" 
            (optionSelected)="selectedRelated($event)">
            <mat-option *ngFor="let artist of relatedOptions | async" [value]="artist">
              {{artist.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Artist Hometown</mat-label>
            <input matInput oninput="this.value = this.value.toUpperCase()" placeholder="Artist Hometown" formControlName="hometown" [matAutocomplete]="hometownComplete">
            <mat-autocomplete 
            autoActiveFirstOption 
            #hometownComplete="matAutocomplete" 
            >
            <mat-option *ngFor="let hometown of hometownOptions | async" [value]="hometown.artist_hometown">
              {{hometown.artist_hometown}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Country</mat-label>
            <input matInput oninput="this.value = this.value.toUpperCase()" placeholder="Country" formControlName="country" [matAutocomplete]="countryComplete">
            <mat-autocomplete 
            autoActiveFirstOption 
            #countryComplete="matAutocomplete" 
            >
            <mat-option *ngFor="let country of countryOptions | async" [value]="country.album_origin">
              {{country.album_origin}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" value="">
        </mat-form-field> 
        
        <mat-form-field class="full-width">
          <mat-label>Website</mat-label>
          <input matInput placeholder="Website" formControlName="website" value="">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Artist Pronouns</mat-label>
          <input matInput placeholder="Pronouns" formControlName="pronouns" value="">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Artist Bio</mat-label>
          <textarea matInput cdkTextareaAutosize placeholder="Artist Bio" formControlName="bio" ></textarea>
        </mat-form-field>

      </mat-card-content>
    </mat-card>

    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Release</mat-card-title>
      </mat-card-header>
      <mat-card-content>
         
        <mat-form-field class="full-width">
          <mat-label>Title</mat-label>
          <input (blur)="checkRelease($event)" matInput placeholder="Title" formControlName="title" value="">
        </mat-form-field>

        <div *ngIf="releaseCheck.length" style="padding: 16px 16px 32px 16px;">
          <strong>Hey! A release by this title and artist already exists. Please check if they are the same.</strong>
          <div *ngFor="let r of releaseCheck">
            <a style="line-height: 32px" [routerLink]="'/releases/' + r.library_no">{{r.artist_nm}} {{r.title}} - {{r.release_year}}</a>
          </div>
        </div>
        
        <div style="display: flex; gap: 8px;">

          <mat-form-field style="width: 410px;">
            <mat-label>Official Release Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="release_date">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Release Year</mat-label>
            <input matInput placeholder="Year" formControlName="release_year" value="">
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Album Label</mat-label>
          <input matInput oninput="this.value = this.value.toUpperCase()" placeholder="Album Label" formControlName="album_label" [matAutocomplete]="labelComplete" value="">
          <mat-autocomplete 
            autoActiveFirstOption 
            #labelComplete="matAutocomplete" 
            >
            <mat-option *ngFor="let label of labelOptions | async" [value]="label">
              {{label}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="release.cont_genre" class="full-width">
          <mat-label>Base Genre</mat-label>
          <mat-select matNativeControl formControlName="cont_genre">
            <mat-option *ngFor="let genre of genres" [value]="genre.genre_id">
              {{genre.genre_desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field class="full-width">
          <mat-label>Genre</mat-label>
          <mat-chip-grid #genreChip aria-label="Genre">
            <mat-chip-row *ngFor="let genre of releaseForm.get('genres')!.value"
              (removed)="removeGenre(genre)"
              [editable]="false"
              (edited)="editGenre(genre, $event)"
              [aria-description]="'press enter to edit ' + genre.genre">
              {{genre.genre}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + genre.genre">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Genre..."
            #genreInput
            [formControl]="genreControl"
            
            [matAutocomplete]="genreComplete"
            [matChipInputFor]="genreChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #genreComplete="matAutocomplete" 
            (optionSelected)="selectedGenre($event)">
            <mat-option *ngFor="let genre of genreOptions | async" [value]="genre">
              {{genre.genre}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>


        <mat-form-field class="full-width">
          <mat-label>Tags</mat-label>
          <mat-chip-grid #tagChip aria-label="Tags">
            <mat-chip-row *ngFor="let tag of releaseForm.get('tags')!.value"
              (removed)="removeTag(tag)"
              [editable]="false"
              (edited)="editTag(tag, $event)"
              [aria-description]="'press enter to edit ' + tag.theme_desc">
              {{tag.theme_desc}}
              <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + tag.theme_desc">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input placeholder="Search Tags / Themes..."
            #tagInput
            [formControl]="tagControl"
            [matAutocomplete]="tagComplete"
            [matChipInputFor]="tagChip"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          <mat-autocomplete 
            autoActiveFirstOption 
            #tagComplete="matAutocomplete" 
            (optionSelected)="selectedTag($event)">
            <mat-option *ngFor="let tag of tagOptions | async" [value]="tag">
              {{tag.theme_desc}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Release Info</mat-label>
          <textarea matInput cdkTextareaAutosize placeholder="Release Info" formControlName="bio" ></textarea>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Additonal Notes</mat-label>
          <textarea matInput cdkTextareaAutosize placeholder="Release Notes" formControlName="release_notes" ></textarea>
        </mat-form-field>




      </mat-card-content>
    </mat-card>

    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Release Support</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Promotions</mat-label>
            <mat-select matNativeControl formControlName="support_choice">
              <mat-option *ngFor="let promo of promos" [value]="promo">
                {{promo}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 410px;">
            <mat-label>Interview Date</mat-label>
            <input matInput [matDatepicker]="interviewPicker" formControlName="interview_dt">
            <mat-datepicker-toggle matIconSuffix [for]="interviewPicker"></mat-datepicker-toggle>
            <mat-datepicker #interviewPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Comments</mat-label>
          <textarea matInput cdkTextareaAutosize placeholder="Comments" formControlName="comments" ></textarea>
        </mat-form-field>

        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Reviewer</mat-label>
            <input matInput placeholder="Reviewer" formControlName="release_notes" />
          </mat-form-field>

          <mat-form-field style="width: 410px;">
            <mat-label>Review Date</mat-label>
            <input matInput [matDatepicker]="reviewPicker" formControlName="review_dt">
            <mat-datepicker-toggle matIconSuffix [for]="reviewPicker"></mat-datepicker-toggle>
            <mat-datepicker #reviewPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <mat-label>Review</mat-label>
          <textarea cdkTextareaAutosize matInput placeholder="Comments" formControlName="review" ></textarea>
        </mat-form-field>

      </mat-card-content>
    </mat-card>

  </div>
  <div style="flex: 1">
    
    <mat-card  *ngIf="user === 'zedviewer'">
      <div [style.background-image]="'url( //data.4zzz.org.au/' + release.library_no  + ')'" class="cover editor">
        <div class="bg">
          <mat-icon class="favourite" (click)="favourite(release, $event)">star_border</mat-icon>
          <mat-icon class="play" (click)="play(release.artist_nm, release.title, release.library_no, $event); click(release.library_no);">play_circle_filled</mat-icon>
          <mat-icon  mat-icon-button [matMenuTriggerFor]="coverMenu" class="more" aria-label="more">more_horiz</mat-icon>
          <mat-menu #coverMenu="matMenu">
            <button mat-menu-item (click)="queueRelease(release.artist_nm, release.title, release.library_no, $event)"> 
                Add to queue
            </button>
            <!-- <button mat-menu-item disabled="disabled" (click)="addToPlayList()">
                Add to playlist
            </button> -->
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="release(release.library_no)">
                Goto release
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-card>


    <mat-card *ngIf="requireAccess('user') && id !== 'search'">
      <!-- {{$rootScope.requireAccess('user') }} -->
      <div class="cover-drop" [hidden]="submitType === 'Search'" appFileDnd [accept]="'image/*'" (fileDropped)="onFileDropped($event)">
   
        <div [style.background-image]="release.albumArt ? 'url('+ release.albumArt +')' : release.library_no ? 'url( //data.4zzz.org.au/covers/' + release.library_no  + ')' : ''" class="cover editor">
          <div class="bg">
            <mat-icon *ngIf="id !== 'new'" class="favourite" (click)="favourite(release, $event)">star_border</mat-icon>
            <mat-icon *ngIf="id !== 'new'" class="play" (click)="play(release.artist_nm, release.title, release.library_no, $event); click(release.library_no);">play_circle_filled</mat-icon>
            <mat-icon  mat-icon-button [matMenuTriggerFor]="coverMenu" class="more" aria-label="more">more_horiz</mat-icon>
            <mat-menu #coverMenu="matMenu">
              <button [disabled]="id === 'new'" mat-menu-item (click)="queueRelease(release.artist_nm, release.title, release.library_no, $event)"> 
                  Add to queue
              </button>
              <!-- <button mat-menu-item disabled="disabled" (click)="addToPlayList()">
                  Add to playlist
              </button> -->
              <mat-divider></mat-divider>
              <button [disabled]="id === 'new'" mat-menu-item (click)="release(release.library_no)">
                  Goto release
              </button>
              <label mat-menu-item [disabled]="formDisabled" for="coverDropRef">Replace Cover</label>
            </mat-menu>
          </div>
        </div>
        <input type="file" #coverDropRef [accept]="'image/*'" id="coverDropRef" (change)="fileBrowseHandler($event.target)" />
      </div>
    </mat-card>

    <div class="media-drop" [hidden]="submitType === 'Search'" [accept]="'audio/*,.zip'" appFileDnd (fileDropped)="onMediaDropped($event)" *ngIf="id !== 'search' && requireAccess('music')">
      <input type="file" #mediaDropRef [accept]="'audio/*,.zip'" id="mediaDropRef" multiple (change)="mediaBrowseHandler($event.target)" />
      <label for="mediaDropRef">Drop tracks here</label>
    </div>
    
    <mat-card *ngIf="(files.length || uploaded.length || zips.length) && id !== 'search'" class="info-card" >
      <mat-card-header style="background-color: hsl(211.2, 47.2%, 32%);">
        <mat-card-title>Files</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let file of files; let i = index">
          <div style="display: flex" >
            <div style="flex: 1">
              <h4 class="name">
                {{ file?.name }}
              </h4>
            </div>
            <div class="size">
              {{ file?.size | bytes }}
            </div>
          </div>
          <mat-progress-bar mode="determinate" [value]="file?.progress"></mat-progress-bar>
        </div>
        <!-- <table *ngIf="uploaded.length" class="table uploads">
          <tr (click)="populateFromUpload(f)" *ngFor="let f of uploaded" style="font:smaller" [class.warning]="(formatBitrate(f.info.bitrate) < 320)">
            <td><img style="width: 50px; height:auto" [src]="f.info.albumart"></td>
            <td>{{(f.info.discnum) ? f.info.discnum + ' / ': ''}}{{f.info.tracknum}}</td>
            <td>{{f.info.title}}</td>
            <td>{{f.info.duration}}</td>
            <td>{{f.info.artist}}</td>
            <td>{{f.info.year}}<td>
            <td>
              <button class="play-icon" mat-icon-button aria-label="Play track" (click)="playTmp(f, $event)" >
                <mat-icon>play_circle_filled</mat-icon>
              </button>
            </td> 
            <td [matTooltip]="formatBitrate(f.info.bitrate) < 320 ? 'file is lower bitrate than recommended' : ''">{{formatBitrate(f.info.bitrate)}}</td>
          </tr>
        </table> -->
      </mat-card-content>

      <div class="zip-files" *ngFor="let zip of zips">
        <div style="display: flex; border-bottom: #6a6a6a solid 1px;">
          <div style="flex: 1">{{zip.name}}</div>
          <div>{{zip.info.files.length}} files / {{(zip.size | bytes )}}</div>
        </div>

        <div *ngFor="let file of zip.info.files"  style="display: flex;">
          <div style="flex: 1" (click)="(file.extension === 'wav') ? encode(zip.info.folder + '/' + file.name, file) : includeMP3(zip.info.folder + '/' + file.name, file)">{{file.name}}</div>
          <div *ngIf="file.extension === 'wav'" style="width: 32px; height: 32px; position: relative;" >
            <mat-spinner *ngIf="file.encodingLocked" diameter="28" style="position: absolute; top: 3px; left: 3px" />
            <button [disabled]="file.encodingLocked" style="position: absolute; top: 0; left: 1px" class="play-icon" mat-icon-button aria-label="encode" matTooltip="Encode and Add Track" (click)="encode(zip.info.folder + '/' + file.name, file)" >
              <mat-icon>change_circle</mat-icon>  
           </button> 
          </div>
          <button *ngIf="file.extension === 'mp3' || file.extension === 'm4a' || file.extension === 'flac' || file.extension === 'MP3' || file.extension === 'M4A' || file.extension === 'FLAC'" class="play-icon" mat-icon-button matTooltip="Add Track" aria-label="add track" (click)="includeMP3(zip.info.folder + '/' + file.name, file)" >
            <mat-icon>add_circle</mat-icon>  
         </button> 

          <button *ngIf="isImage(file.extension)" class="play-icon" mat-icon-button matTooltip="Cover Image" aria-label="Cover Image" (click)="zipDownload(zip.info.folder + '/' + file.name, file)" >
            <mat-icon>add_photo_alternate</mat-icon>  
         </button> 
        </div>
      </div>

      <table #uploadTable *ngIf="uploaded.length" mat-table matSort [dataSource]="uploaded" class="mat-elevation-z8">

        <ng-container matColumnDef="artwork">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element"> <img style="width: 50px; height:auto" [src]="element.info.albumart"> </td>
        </ng-container>

        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
          <td mat-cell *matCellDef="let element"> {{(element.info.discnum) ? element.info.discnum + ' / ': ''}}{{element.info.tracknum}}</td>
        </ng-container>
      
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Title </th>
          <td mat-cell *matCellDef="let element"> {{element.info.title}} </td>
        </ng-container>
      
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef> Duration </th>
          <td mat-cell *matCellDef="let element"> {{element.info.length | hms}} </td>
        </ng-container>
      
        <ng-container matColumnDef="artist">
          <th mat-header-cell *matHeaderCellDef> Artist </th>
          <td mat-cell *matCellDef="let element"> {{element.info.artist}} </td>
        </ng-container>
      
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef> Year </th>
          <td mat-cell *matCellDef="let element"> {{element.info.year}} </td>
        </ng-container>

        <ng-container matColumnDef="play">
          <th mat-header-cell *matHeaderCellDef>
            <button [disabled]="formDisabled" class="tool-icon"  matTooltip="Edit Tags" mat-icon-button aria-label="Edit Tags" cdkOverlayOrigin #tagTrigger="cdkOverlayOrigin" (click)="isTagUploadOpen = !isTagUploadOpen"  >
              <mat-icon>fingerprint</mat-icon>
            </button>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="tagTrigger"
              [cdkConnectedOverlayOpen]="isTagUploadOpen"
              [cdkConnectedOverlayPositions]="overlayPositions"
              (overlayOutsideClick)="closeTagUploadOverlay($event)"
            >
              <app-tag-editor [albumArt]="release.albumArt" [uploaded]="uploaded | sort:'info.tracknum'" (populateFromUpload)="populateFromUpload($event)" [library_no]="release.library_no"></app-tag-editor>
            </ng-template>
         </th>
          <td mat-cell *matCellDef="let element"> 
            <button *ngIf="element.mime !== 'audio/x-wav'" class="play-icon" mat-icon-button aria-label="play track" matTooltip="Play Track"  (click)="playTmp(element)" >
              <mat-icon>play_circle_filled</mat-icon>
            </button> 
            <div *ngIf="element.mime === 'audio/x-wav'" style="width: 32px; height: 32px; position: relative;" >
              <mat-spinner *ngIf="element.encodingLocked" diameter="28" style="position: absolute; top: 3px; left: 3px" />
              <button [disabled]="element.encodingLocked" style="position: absolute; top: 0; left: 1px" class="play-icon" mat-icon-button aria-label="encode" matTooltip="Encode and Add Track" (click)="encode(element.info.path + '/' + element.info.file, element)" >
                <mat-icon>change_circle</mat-icon>  
             </button> 
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="bitrate">
          <th mat-header-cell *matHeaderCellDef> Bitrate </th>
          <td mat-cell *matCellDef="let element"> <div [matTooltip]="formatBitrate(element.info.bitrate) < 320 ? 'file is lower bitrate than recommended' : ''">{{formatBitrate(element.info.bitrate)}}</div> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="uploadColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: uploadColumns;"></tr>
      </table>


    </mat-card>


    <mat-card class="info-card" *ngIf="id !== 'search'">
      <mat-card-header>
        <mat-card-title>Track Info</mat-card-title>
      </mat-card-header>

        <table *ngIf="release.dBTracks && release.dBTracks.length > 0" mat-table [dataSource]="release.dBTracks" multiTemplateDataRows class="mat-elevation-z8">

          <ng-container matColumnDef="tracknum">
            <th mat-header-cell *matHeaderCellDef> # </th>
            <td mat-cell *matCellDef="let element"> {{element.tracknum}} </td>
            <td mat-cell>what</td>
          </ng-container>
          
          <!-- Name Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Track Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
        
          <!-- Weight Column -->
          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duration </th>
            <td mat-cell *matCellDef="let element"> {{element.length | hms}} </td>
          </ng-container>
        
          <!-- Symbol Column -->
          <ng-container matColumnDef="artist">
            <th mat-header-cell *matHeaderCellDef> Artist </th>
            <td mat-cell *matCellDef="let element"> {{element.artist_nm || element.artist.name }}</td>
          </ng-container>
        
          <!-- Symbol Column -->
          <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef> Year </th>
            <td mat-cell *matCellDef="let element"> {{element.year}} </td>
          </ng-container>

           <!-- Symbol Column -->
           <ng-container matColumnDef="play">
            <th mat-header-cell *matHeaderCellDef>
              <button class="tool-icon"  matTooltip="Play release" mat-icon-button aria-label="Play release" (click)="playRelease(release.artist_nm, release.title, release.library_no, $event)" >
                <mat-icon>playlist_play</mat-icon>
              </button>
            </th>
            <td mat-cell *matCellDef="let element"> 
              <button class="play-icon" mat-icon-button aria-label="Play track" matTooltip="Play Track" (click)="play(element, $event)" >
                <mat-icon>play_circle_filled</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">
              <button [disabled]="formDisabled" class="tool-icon"  matTooltip="Edit Tags" mat-icon-button aria-label="Edit Tags" cdkOverlayOrigin #tagEditTrigger="cdkOverlayOrigin" (click)="isTagEditOpen = !isTagEditOpen"  >
                <mat-icon>fingerprint</mat-icon>
              </button>
              <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayPositions]="overlayPositions"
                [cdkConnectedOverlayOrigin]="tagEditTrigger"
                [cdkConnectedOverlayOpen]="isTagEditOpen"
                (overlayOutsideClick)="closeTagEditOverlay($event)"
              >
                <app-tag-editor [albumArt]="release.albumArt" [uploaded]="uploaded" (populateFromUpload)="populateFromUpload($event)" [folder]="release.fela"  [library_no]="release.library_no"></app-tag-editor>
              </ng-template>
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="trackColumns.length">
              <div class="example-element-detail track-tools" style="display: flex;"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="track-id">
                  <label>#</label>{{element.title_id}}
                </div>
                <div class="example-element-description track-info" style="flex-grow: 1;">
                  <label>Filename</label><a target="_blank" [href]="'file:///' + element.fullpath">{{element.filename}}</a>
                </div>
            
                <button *ngIf="onAir" class="tool-icon"  style="color: #fa8817;" matTooltip="Add to mAirList playlist" mat-icon-button aria-label="Add to mAirList playlist" (click)="appendTrack(element)">
                  <mat-icon svgIcon="mairlist"></mat-icon>
                </button>

                <button *ngIf="requireAccess('librarian')" class="tool-icon"  matTooltip="Delete Track" mat-icon-button aria-label="Delete Track" (click)="deleteTrack(element)" >
                  <mat-icon>delete_forever</mat-icon>
                </button>
            
                <button class="tool-icon" [style.color]="element.language_warning ? 'rgb(190, 123, 58)' : 'white'"  matTooltip="Language Warning" mat-icon-button aria-label="Language Warning" (click)="languageWarning(element)" >
                  <mat-icon [svgIcon]="element.language_warning ? 'emoticon-poop' : 'emoticon-poop-outline'"></mat-icon>
                </button>
            
                <button class="tool-icon" [style.color]="element.content_warning ? 'rgb(255, 231, 101)' : 'white'" matTooltip="Content Warning" mat-icon-button aria-label="Content Warning" (click)="contentWarning(element)" >
                  <mat-icon>{{ element.content_warning ? 'report_problem' : 'change_history'  }}</mat-icon>
                </button>
            
                <button class="tool-icon"  matTooltip="Copy track location" mat-icon-button aria-label="Copy track location" (click)="copyToClipboard(element.fullpath)" >
                  <mat-icon>assignment</mat-icon>
                </button>
            
                <button class="tool-icon"  matTooltip="Favourite" mat-icon-button aria-label="Favourite track" (click)="favourite(element, $event)" >
                  <mat-icon>{{ element.favourite ? 'star' : 'star_outline'  }}</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="trackColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: trackColumns;"
           [class.example-expanded-row]="expandedElement === row"
           (click)="expandedElement = expandedElement === row ? null : row">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>

        <div style="display: flex; padding: 8px;">
        
          <button class="library-icon"  matTooltip="Search FELA" mat-icon-button aria-label="Search FELA" [disabled]="formDisabled" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="isFelaOpen = !isFelaOpen" >
            <mat-icon>folder_special</mat-icon>
          </button>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isFelaOpen"
            (overlayOutsideClick)="closeOverlay($event)"
          >
            <mat-card class="fela-browser-card mat-elevation-z20">
              <app-fela-browser *ngIf="folder" (linkFolder)="linkFolder($event)" (out)="update($event)" [folder]="folder" [tld]="'fela'"></app-fela-browser>
            </mat-card>
          </ng-template>

          <div style="flex: 1; line-height: 36px; margin: 0 16px;">
            {{folder}} <span *ngIf="correctMisfiled"><span style="font-style: italic;">to</span><br/>{{suggestedLocations.library}}</span>
          </div>

          <button *ngIf="misfiled && correctMisfiled" class="library-icon"  matTooltip="Correct Misfiled" mat-icon-button aria-label="Correct Misfiled" (click)="moveRelease(release.library_no, folder, suggestedLocations)">
            <mat-icon>recommend</mat-icon>
          </button>

          <button *ngIf="misfiled && !correctMisfiled" class="library-icon"  matTooltip="Release misfiled, click to correct" mat-icon-button aria-label="Release misfiled, click to correct" (click)="misfiledHandle()">
            <mat-icon>build_circle</mat-icon>
          </button>

          <button class="library-icon" [disabled]="trackHasLibrary === release.library_no" [matTooltip]="trackHasLibrary === release.library_no ? 'Linked' : 'Not Linked'" mat-icon-button aria-label="Release misfiled, click to correct" (click)="trackHasLibrary !== release.library_no && linkRelease(release.library_no, release.dBTracks)">
            <mat-icon>{{trackHasLibrary === release.library_no ? 'link' : 'link_off'}}</mat-icon>
          </button>
        </div>
    </mat-card>

    <mat-card *ngIf="id === 'search'" class="search-card" >
      <mat-card-header>
        <mat-card-title>Search Paramaters</mat-card-title>
      </mat-card-header>
      <mat-card-content [formGroup]="searchForm">

        <mat-form-field>
          <mat-label>Start Date (Created)</mat-label>
          <input matInput [matDatepicker]="createdDateStartPicker" formControlName="createdDateStart">
          <mat-datepicker-toggle matIconSuffix [for]="createdDateStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #createdDateStartPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date (Created)</mat-label>
          <input matInput [matDatepicker]="createdDateEndPicker" formControlName="createdDateEnd">
          <mat-datepicker-toggle matIconSuffix [for]="createdDateEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #createdDateEndPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Date (Release Year)</mat-label>
          <input matInput [matDatepicker]="releaseDateStartPicker" formControlName="releaseYearStart">
          <mat-datepicker-toggle matIconSuffix [for]="releaseDateStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #releaseDateStartPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date (Release Year)</mat-label>
          <input matInput [matDatepicker]="releaseDateEndPicker" formControlName="releaseYearEnd">
          <mat-datepicker-toggle matIconSuffix [for]="releaseDateEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #releaseDateEndPicker></mat-datepicker>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="info-card">
      <mat-card-header>
        <mat-card-title>Music Department</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div style="display: flex; gap: 8px;">
          <mat-form-field required class="full-width">
            <mat-label>Region</mat-label>
            <mat-select matNativeControl [formControl]="region">
              <mat-option *ngFor="let region of regions" [value]="region">
                {{region}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field required class="full-width">
            <mat-label>Artist Representation</mat-label>
            <mat-select multiple matNativeControl [formControl]="selectedDiversity">
              <mat-option *ngFor="let reps of diversity" [value]="reps.tag">
                {{reps.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        

        <mat-form-field style="min-width: 50%;">
          <mat-label>Hot Bin Month</mat-label>
          <button style="--mdc-fab-container-color: transparent; box-shadow: none;" matSuffix class="month-button" mat-fab [disabled]="formDisabled" (click)="toggleHotbinOverlay()" cdkOverlayOrigin #hotbinTrigger="cdkOverlayOrigin" aria-label="Show month calendar">
            <mat-icon>date_range</mat-icon>
          </button>
          
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="hotbinTrigger"
            [cdkConnectedOverlayOpen]="isHotbinOpen"
            (overlayOutsideClick)="closeHotbinOverlay($event)"
          >
            <mat-card class="calendar-card">
              <app-month-calendar (dateChanged)="dateChanged($event)" [selectedDate]="selectedDate"/>
            </mat-card>
          </ng-template>

          <input matInput placeholder="Hotbin Month" formControlName="hotbin" value="">
        </mat-form-field>

        <div>
        <mat-checkbox formControlName="bna">Best New Arrival</mat-checkbox>
        
          <div>
            <mat-checkbox style="min-width: 33.3%;" formControlName="aotw">Album of the Week</mat-checkbox>
            <mat-form-field *ngIf="releaseForm.controls['aotw'].value">
              <mat-label>AOTW Week Starting</mat-label>
              <input formControlName="aotw_week" matInput [matDatepickerFilter]="dateFilter" [matDatepicker]="AOTWpicker">
              <mat-hint>D/M/Y</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="AOTWpicker"></mat-datepicker-toggle>
              <mat-datepicker #AOTWpicker></mat-datepicker>
            </mat-form-field>
          </div>
        
          <div>
            <mat-checkbox style="min-width: 33.3%" formControlName="embargo">Embargo</mat-checkbox>
            <mat-form-field *ngIf="releaseForm.controls['embargo'].value">
              <mat-label>Embargo Until</mat-label>
              <input formControlName="embargo_until" matInput [matDatepicker]="embargoPicker">
              <mat-hint>D/M/Y</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="embargoPicker"></mat-datepicker-toggle>
              <mat-datepicker #embargoPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div style="margin: 10px 0">
          <div style="display: flex; align-items: center;">
            <mat-label style="flex: 1; font-size: 75%; padding-left: 0.25em;">Library Locations</mat-label>

            <button [disabled]="formDisabled" class="play-icon" matTooltip="Check Locations" mat-icon-button aria-label="Check Locations" (click)="checkLocations()" >
              <mat-icon>help_outline</mat-icon>
            </button>
          </div>
          <div style="display: flex; flex-direction: column;" style="margin: 3px 3px 30px 3px;">
            <div class="locations library" style="display: flex;">
              <div style="flex: 1" *ngIf="suggestedLocations && suggestedLocations.library">
                {{suggestedLocations.library}}
              </div>
              <div *ngIf="libLocations.locations.library">
                {{ libLocations.locations.library.dir ? libLocations.locations.library.link ? '🔗' : '📁' : '' }}
                {{ libLocations.locations.library.exists && libLocations.locations.library.exists ? '✔︎' : '✘' }}
              </div>
            </div>
            <div class="locations hotbin" *ngIf="release.hotbin" style="display: flex;">
              <div style="flex: 1" *ngIf="suggestedLocations.hotbin">
                {{suggestedLocations.hotbin}}
              </div>
              <div *ngIf="libLocations.locations.hotbin">
                {{ libLocations.locations.hotbin.dir ? libLocations.locations.hotbin.link ? '🔗' : '📁' : '' }}
                {{ libLocations.locations.hotbin.exists ? '✔︎' : '✘' }}
              </div>
            </div>
            <div class="locations bna" *ngIf="release.hotbin && release.bna" style="display: flex;">
              <div style="flex: 1" *ngIf="suggestedLocations.bna">
                {{suggestedLocations.bna}}
              </div>
              <div *ngIf="libLocations.locations.bna">
                {{ libLocations.locations.bna.dir ? libLocations.locations.bna.link ? '🔗' : '📁' : '' }}
                {{ libLocations.locations.bna.exists ? '✔︎' : '✘' }}
              </div>
            </div>
            <div class="locations aotw" *ngIf="release.aotw && release.aotw_week" style="display: flex;">
              <div style="flex: 1" *ngIf="suggestedLocations.aotw">
                {{suggestedLocations.aotw}}
              </div>
              <div *ngIf="libLocations.locations.aotw">
                {{ libLocations.locations.aotw.dir ? libLocations.locations.aotw.link ? '🔗' : '📁' : '' }}
                {{ libLocations.locations.aotw.exists ? '✔︎' : '✘' }}
              </div>
            </div>
            <div class="locations first-nations" style="display: flex;">
              
              <div style="flex: 1" *ngIf="suggestedLocations.first_nations">
                {{suggestedLocations.first_nations}}
              </div>
              <div *ngIf="suggestedLocations.first_nations && libLocations.locations.first_nations">
                {{ libLocations.locations.first_nations.dir ? libLocations.locations.first_nations.link ? '🔗' : '📁' : '' }}
                {{ libLocations.locations.first_nations.exists ? '✔︎' : '✘' }}
              </div>
            </div>
            <div style="font-size: 80%; font-style: italic" *ngFor="let note of libLocations.notes" class="locations notes">
              <div>{{note}}</div>
            </div>
          </div>
        </div>


        <div style="display: flex; gap: 8px;">
          <mat-form-field required class="full-width">
            <mat-label>Release Type</mat-label>
            <mat-select matNativeControl formControlName="release_type">
              <mat-option *ngFor="let type of types" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field required class="full-width">
            <mat-label>Sent Format</mat-label>
            <mat-select matNativeControl formControlName="format_submitted">
              <mat-option *ngFor="let format of formats" [value]="format">
                {{format}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="display: flex; gap: 8px;">
          <mat-form-field class="full-width">
            <mat-label>Quantity</mat-label>
            <input matInput placeholder="Quantity Sent" formControlName="release_numgiven" >
          </mat-form-field>

          <mat-form-field required class="full-width">
            <mat-label>Lib. Format</mat-label>
            <mat-select matNativeControl formControlName="format_stored">
              <mat-option *ngFor="let format of formats" [value]="format">
                {{format}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Condition</mat-label>
            <input matInput placeholder="Condition" formControlName="media_cond" >
          </mat-form-field>
          
        </div>

        <mat-form-field class="full-width">
          <mat-label>Physical Location</mat-label>
          <textarea matInput placeholder="Physical Location" formControlName="release_location" ></textarea>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Submitted by</mat-label>
          <input matInput placeholder="Submitted by" formControlName="modify_user" >
        </mat-form-field>

      </mat-card-content>
    </mat-card>


    <!-- <mat-card>
      <app-fela-browser *ngIf="folder" (linkFolder)="linkFolder($event)" (out)="update($event)" [folder]="folder" [tld]="'fela'"></app-fela-browser>
    </mat-card> -->


      <!-- <app-tag-editor [albumArt]="release.albumArt" [library_no]="release.library_no" (populateFromUpload)="populateFromUpload($event)"></app-tag-editor> -->
  </div>
  

</div>



<div class="floating-fab" [hidden]="!formEdited">
  <div  style="width: 64px; height: 64px; position: relative;">
    <mat-spinner *ngIf="submitLocked" diameter="72" style="position: absolute; top: 0; left: 0" />
    <button [disabled]="submitLocked || id !== 'search' && !(release.artist_nm && region.value && release.title)" (click)="id === 'search' ? searchReleases() : saveRelease()" mat-fab color="primary" aria-label="Example icon button with a delete icon" style="position: absolute; top: 8px; left: 8px">
      <mat-icon>{{id === 'search' ? 'search' : id === 'new' ? 'add' : 'edit' }}</mat-icon>
    </button>
  </div>
</div>