import { R } from '@angular/cdk/keycodes';
import { Component, Input, Output, Inject, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss']
})
export class TagEditorComponent implements OnChanges {
  @Input() uploaded: any[] = []
  @Input() library_no: number = 0;
	@Input() albumArt?;
	@Input() folder: string = '';

	@Output() populateFromUpload: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
  ) {
    
  }

	populate = () => { 
		this.populateFromUpload.emit(this.tracks);
	}

  myGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null),
    album: new UntypedFormControl(null),
    artist: new UntypedFormControl(null),
    albumartist: new UntypedFormControl(null),
    year: new UntypedFormControl(null),
    tracknum: new UntypedFormControl(null),
    totaltracks: new UntypedFormControl(null),
    discnum: new UntypedFormControl(null),
    disctotal: new UntypedFormControl(null)
  })

	tracks: any[] = [];
	data: any;
  saving = false;
  index = -1;
	artist = '';
	album = '';
	mime = '';
  albumart;
  // folder = ''
  track: any = {};
  audioMime = (item) => (
		item.mime === 'audio/mpeg' || 
		item.mime === 'audio/x-m4a' || 
		item.mime === 'audio/mp4' || 
		item.mime === 'audio/flac'
	);

  ngOnInit() {
		this.index = -1;

		this.tracks = this.uploaded.filter(i => this.audioMime(i))
		this.tracks.forEach( (i , n)=> {
			if(n === 0) {
				this.artist = i.info.albumartist ? i.info.albumartist : i.info.artist;
				this.album = i.info.album;
				this.albumart = i.info.albumart;
				this.mime = i.mime;
			}

			if(i.info.part_of_a_set) {
				if(i.info.part_of_a_set.includes('/')){
					let parts = i.info.part_of_a_set.split('/');
					i.info.discnum = parts[0];
					i.info.disctotal = parts[1];
				}
				else {
					i.info.discnum = i.info.part_of_a_set;
					i.info.disctotal = null;
				}
			}
		})
		this.bulkFiles()
		if (this.tracks && this.tracks.length > 0) this.patchIndex(this.index);

		if(!isNaN(this.library_no)) {
			console.log('got a number yo')
			this.http.post<any>(environment.musicDBapiSrc + '/metadataFromLibraryNo', { 'library_no': this.library_no }).subscribe( data => { 
				this.data = data;
				let tracks = data.items.filter(i => ["audio/mp4", "audio/x-m4a", "audio/mpeg", "audio/flac"].includes(i.mime));
				if (data.albumart) { 
          this.albumart = data.albumart;
					tracks.forEach(i => i.info.albumart = data.albumart );
				}
				tracks.forEach( (i , n)=> {
					if(n === 0) {
						this.artist = i.info.albumartist ? i.info.albumartist : i.info.artist;
						this.album = i.info.album;
					}

					if(i.info.part_of_a_set) {
						if(i.info.part_of_a_set.includes('/')){
							let parts = i.info.part_of_a_set.split('/');
							i.info.discnum = parts[0];
							i.info.disctotal = parts[1];
						}
						else {
							i.info.discnum = i.info.part_of_a_set;
							i.info.disctotal = null;
						}
					}
				})
				this.tracks = [ ...this.tracks, ...tracks ]
				this.bulkFiles()
				if (tracks && tracks.length > 0) this.patchIndex(this.index);
			})
		}

		
		for(let tag in this.myGroup.controls) {
			this.myGroup.controls[tag].valueChanges.subscribe( changes => { 
				if(this.index === -1) { 
					// console.log(changes, tag, this.index);
					this.tracks.forEach(i => i.info[tag] = changes)
					// console.log(this.tracks)
				}
				else {
					this.tracks[this.index].info[tag] = changes;
				}
			});
		}
		// merge(... {console.log(this.myGroup.controls[i] )}this.myGroup.controls.map(control => control.valueChanges))
  	// 	.subscribe( changes => { console.log(changes, this.index); });

		this.myGroup.valueChanges.subscribe( 
      changes => { 
				console.log(changes, this.index); 
				this.artist = changes.albumartist ? changes.albumartist : changes.artist;
				this.album = changes.album;
			}
    )
  }

	ngOnChanges(changes: SimpleChanges): void {
		 console.log(this.albumArt)
	}


	// getExampleUpload = function(ctrl) {
	// 	let index = this.uploaded.map(e => e.mime).indexOf('application/zip')
	// 	if (index >= 0) {
	// 		const zip = this.uploaded[index]
	// 		const folder = zip.info.folder
	// 		const files = zip.info.files.map(x => x.name)
	// 		const extensions = files.map(x => x.split('.').pop() )
	// 		const accepted_types = ['wav', 'aiff', 'aif']
	// 		const uncompressedAudio = files.filter(i => accepted_types.includes( i.split('.').pop()))
	// 		console.log(files)
	// 		console.log(extensions)
	// 		if(ctrl.file) {
	// 			this.exampleTags = this.checkPattern(folder + '/' + ctrl.file.name)
	// 			this.exampleFiles = this.replace('/tmp/uploads/', '') + '/' + ctrl.file.name
	// 		} else {
	// 			this.exampleTags = this.checkPattern(folder + '/' + uncompressedAudio[0])
	// 			this.exampleFiles = folder.replace('/tmp/uploads/', '') + '/' + uncompressedAudio[0]
	// 		}
	// 		ctrl.exampleTags = this.exampleTags
	// 		ctrl.exampleFiles =  this.exampleFiles
	// 	}
	// }

  filter = (arr: any[] = []) => {
    return arr.filter( i =>  ["audio/mp4", "audio/x-m4a", "audio/mpeg", "audio/flac"].includes(i.mime) );
  }

  filterAndLimit = (arr: any[] = []) => {
    let filtered = arr.filter( i => ["audio/mp4", "audio/x-m4a", "audio/mpeg", "audio/flac"].includes(i.mime) );
    return filtered[0];
  }

	bulkFiles = () => {
		if(this.tracks.length < 1)
			return;
		let tags = Object.keys(this.tracks[0].info);
		let tracks = this.tracks;
		let track = {};
		tags.forEach(key => track[key] = (tracks.every((val, i, arr) => val.info[key] === arr[0].info[key] )) ? tracks[0].info[key] : '(different for all ' + tracks.length + ' tracks)' 	)
		this.track = { mime: this.mime, info: track }
	}

	patchIndex = (index) => {

		let track;
		if(index === -1) { 
			track = { ...this.track }
		} else {
			track = { ...this.tracks[index] }
		}
		this.myGroup.patchValue({
			title: track.info.title, 
			album: track.info.album, 
			artist: track.info.artist, 
			albumartist: track.info.albumartist, 
			year: track.info.year,
			tracknum: track.info.tracknum,
			totaltracks: track.info.totaltracks,
			discnum: track.info.discnum,
			disctotal: track.info.disctotal
		}, { emitEvent: false } )
	
	}
  nextItem = (ev) => {
		this.index++;
		this.patchIndex(this.index);
		// this.track = [this.tracks[this.index]]
	}

  prevItem = (ev) => {
		this.index--;
		if (this.index === -1) {
			this.bulkFiles()
		}
		this.patchIndex(this.index);

	}

  saveTags = () => {
		this.saving = true;
		let coverart = this.tracks[0].info.albumart;
		let tags: Tag[] = [];
		interface Tag {
			file: string,
			tags: {
				artist: string,
				album_artist: string,
				title: string,
				track: string,
				album: string,
				disc: string,
				TRACKTOTAL: string,
				date: number,
			}
		}
		this.tracks.forEach( (i, n) => {
			let info = i.info;
			tags[n] = {
				file: info.path + '/' + info.file,
				tags: {
					artist: info.artist ,
					album_artist: info.albumartist,
					title: info.title,
					track: (info.totaltracks) ? info.tracknum + '/' + info.totaltracks : info.tracknum,
					album: info.album,
					disc: (info.disctotal) ? info.discnum + '/' + info.disctotal : info.discnum,
					['TRACKTOTAL']: info.totaltracks,
					date: info.year
				}
			}
		});
		console.log(tags);
		console.log(coverart);
		console.log('yes')
		this.http.post<any>(environment.musicDBapiSrc + '/editTags', {
			tags: tags,
			coverart: coverart,
			library_no: this.library_no,
			folder: this.folder
		}).subscribe( data => {
			this.saving = false;
			data.files.forEach(i => { 
				if(data.albumart) {
					i.info.albumart = data.albumart;
				}
				let b = this.uploaded.findIndex(u => u.name === i.name);
				this.uploaded[b] = i; 
			})
			// zipfile.encode = 'encoded'
			console.log(data);
			if (data.size	) {
				this.uploaded = data;
			}
		})
	}

  editArt = () => {
		this.tracks.forEach(i => i.info.albumart = this.albumArt)
		this.albumart = this.albumArt;
		// this.track.forEach(i => i.info.albumart = this.albumArt)
	}

  testme = (tag) => {
		if(event?.target)
			this.tracks.forEach(i => i.info[tag] = (event?.target as HTMLInputElement).value)
	}
}
