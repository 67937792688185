import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], element: any ): any[] {
    if(value) {
      for(const key in element) {
        value = value.filter( i =>  i[key] === element[key]);
      }
      return value;
    }
    return value;
  }
  
}
