<mat-card>
	<mat-card-header>
		<mat-card-title>
			Item ID: {{data.id}}
		</mat-card-title>
	</mat-card-header>
	<mat-card-content [formGroup]="merchForm" style="display: flex; flex-direction: column; gap: 8px;">
		<mat-form-field>
			<mat-label>Name</mat-label>
			<input matInput formControlName="name" placeholder="Name">
		</mat-form-field>

		<mat-form-field>
			<mat-label>Slug</mat-label>
			<input matInput formControlName="shortcode" placeholder="Shortcode/Slug">
		</mat-form-field>

		<mat-form-field>
			<mat-label>Description</mat-label>
			<textarea matInput formControlName="description" placeholder="description"></textarea>
		</mat-form-field>

	</mat-card-content>

	<mat-card-actions>
		<button mat-raised-button disabled>
			Save
		</button>
	</mat-card-actions>
</mat-card>