import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-releases',
  templateUrl: './manage-releases.component.html',
  styleUrls: ['./manage-releases.component.scss']
})
export class ManageReleasesComponent {

}
