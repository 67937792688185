import { 
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener
 } from '@angular/core';

 import { minimatch } from 'minimatch'

@Directive({
  selector: '[appFileDnd]'
})
export class FileDndDirective {
  @HostBinding('class.fileover') fileOver!: boolean;
  @HostBinding('class.fileenter') fileEnter!: boolean;
  @HostBinding('class.filedenied') fileDenied!: boolean;
  @Input() accept = ''; 
  @Input() multiple!: boolean; 
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('window:dragenter', ['$event']) onWindowDragEnter(evt: any): void {
    // evt.preventDefault();
    // evt.stopPropagation();
    this.fileEnter = true;
  }

  @HostListener('window:dragleave', ['$event']) onWindowDragLeave(evt: any): void {
    // evt.preventDefault();
    // evt.stopPropagation();
    this.fileOver = false;
    this.fileEnter = false;
    this.fileDenied = false;
  }

  @HostListener('window:drop', ['$event']) onWindowDropLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.fileEnter = false;
    this.fileDenied = false;
  }


  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    // evt.preventDefault();
    // evt.stopPropagation();
    this.fileOver = true;
    // console.log(evt.dataTransfer);
    let items = evt.dataTransfer.items;
    if (items.length > 0) {
      let denied = true;
      this.accept.split(',').forEach( (i: string) => { 
        for (const item of items) {
          if(minimatch(item.type, i)) {
            evt.preventDefault();
            denied = false;
          }
        }
      }) 
      this.fileDenied = denied;
    }
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    // evt.preventDefault();
    // evt.stopPropagation();
    this.fileOver = false;
    this.fileEnter = false;
    this.fileDenied = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {

    // console.log(evt.dataTransfer.files);
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      // let accepted = false;
      // this.accept.split(',').forEach( (i: string) => { 
      //   for (let n = 0; n < files.length; n++) {
      //     let file = files[n];
      //     console.log( file, i, file.type, minimatch(file.type, i))
      //   }
      // }) 
      if(!this.fileDenied) 
        this.fileDropped.emit(files);
    }
    this.fileOver = false;
    this.fileEnter = false;
    this.fileDenied = false;
  }
}
