import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';

import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;
const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});

@Component({
  selector: 'app-month-calendar',
  templateUrl: './month-calendar.component.html',
  styleUrls: ['./month-calendar.component.scss']
})
export class MonthCalendarComponent {
  @ViewChild('scrollElement', { read: ElementRef }) scrollEl!: ElementRef;
  @Input() selectedDate! : Date;
  @Output() dateChanged = new EventEmitter<Date>();
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key)
    if (event.key == 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      let focus = this.scrollEl.nativeElement.querySelector(':focus') ? this.scrollEl.nativeElement.querySelector(':focus') : this.scrollEl.nativeElement.querySelector('.selected')
      let arr: any[] = [];
      let index = arr.indexOf.call (focus.parentNode.parentNode.children, focus.parentNode); 
      focus.parentElement.parentElement.parentElement.nextElementSibling.children[1].children[index].firstChild.focus({ focusVisible: true })
    }
    if (event.key == 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();
      let focus = this.scrollEl.nativeElement.querySelector(':focus') ? this.scrollEl.nativeElement.querySelector(':focus') : this.scrollEl.nativeElement.querySelector('.selected')
      let arr: any[] = [];
      let index = arr.indexOf.call (focus.parentNode.parentNode.children, focus.parentNode); 
      focus.parentElement.parentElement.parentElement.previousElementSibling.children[1].children[index].firstChild.focus({ focusVisible: true })
    }
    if (event.key == 'ArrowRight') {
      event.preventDefault();
      event.stopPropagation();
      let focus = this.scrollEl.nativeElement.querySelector(':focus') ? this.scrollEl.nativeElement.querySelector(':focus') : this.scrollEl.nativeElement.querySelector('.selected')

      if(focus && focus.parentElement.nextElementSibling ) {
        focus.parentElement.nextElementSibling.firstChild.focus({ focusVisible: true })
      }
      else if (focus && focus.parentElement.parentElement.parentElement.nextElementSibling) {
        focus.parentElement.parentElement.parentElement.nextElementSibling.children[1].firstChild.firstChild.focus({ focusVisible: true })
      }
    }
    if (event.key == 'ArrowLeft') {
      event.preventDefault();
      event.stopPropagation();
      let focus = this.scrollEl.nativeElement.querySelector(':focus') ? this.scrollEl.nativeElement.querySelector(':focus') : this.scrollEl.nativeElement.querySelector('.selected')

      if(focus && focus.parentElement.previousElementSibling ) {
        focus.parentElement.previousElementSibling.firstChild.focus({ focusVisible: true })
      }
      else if (focus && focus.parentElement.parentElement.parentElement.previousElementSibling) {
        focus.parentElement.parentElement.parentElement.previousElementSibling.children[1].children[11].firstChild.focus({ focusVisible: true })
      }
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
    this.dates = Object.values(groupBy(this.dateRange('2000-01-01', '2030-12-01'), i => i.year));
  }

  ngOnInit() {
    if(this.selectedDate) this.selected = this.selectedDate;
    this.selectedMonth = this.selected.getMonth() + 1;
    this.selectedYear = this.selected.getFullYear();
  }

  ngOnChanges() {
    if(this.selectedDate) this.selected = this.selectedDate;
    this.selectedMonth = this.selected.getMonth() + 1;
    this.selectedYear = this.selected.getFullYear();
  }

  ngAfterViewInit() {

    this.scrollEl.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    let index = this.dates.findIndex(i => i[0].year === this.selectedYear);
    this.scrollEl.nativeElement.children[index].scrollIntoView({ behavior: "instant", block: "start" });
 }

  dates: any[] = []

  selectDate = (date: Date) => {
    this.selected = new Date(date);
    this.selectedMonth = this.selected.getMonth() + 1;
    this.selectedYear = this.selected.getFullYear();
    this.dateChanged.emit(this.selected);
  } 
  selected: Date = new Date();
  selectedMonth = this.selected.getMonth() + 1;
  selectedYear = this.selected.getFullYear();

  currentDate: Date = new Date();
  currentMonth: number = this.currentDate.getMonth() + 1;
  currentYear: number = this.currentDate.getFullYear();

  /**
 * dateRange returns an array of year-month dates between the supplied start and end dates
 * @param startDate Date or natively parseable string (e.g. 2024-04-01)
 * @param endDate Date or natively parseable string (e.g. 2024-04-01)
 * @returns string[] of ISO 8601 strings for each month between start and end
 */
  dateRange = (startDate, endDate) => {
    // we use UTC methods so that timezone isn't considered
    let start : Date = new Date(startDate);
    let end : Date = new Date(endDate);
    end.setUTCHours(12);
    const dates : any = [];
    while (start <= end) {
      // compensate for zero-based months in display
      let monthYear = new Intl.DateTimeFormat('en-AU', { year: 'numeric', month: '2-digit' }).format(start);
      let monthString = new Intl.DateTimeFormat('en-AU', { month: 'long' }).format(start);
      dates.push({ date: new Date(start), year: start.getFullYear(), month: start.getUTCMonth() + 1, monthYearString: monthYear, monthString: monthString, monthCode: monthString.substring(0, 3)})
      
      const displayMonth = start.getUTCMonth() + 1;
      // dates.push([
      //   start.getUTCFullYear(),
      //   // months are zero based, ensure leading zero
      //   (displayMonth).toString().padStart(2, '0'),
      //   // always display the first of the month
      //   '01',
      // ].join('-'));

      // progress the start date by one month
      start = new Date(start.setUTCMonth(displayMonth));
    }

    return dates;
  }

  setMonthAndYear(normalizedMonthAndYear: Moment) {
    const ctrlValue = moment(this.selected);
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.selected = ctrlValue.toDate();
  }
}
