

<div class="orders-container">

  <mat-toolbar>
    <mat-toolbar-row style="justify-content: space-between;">
      <h2>Orders Report</h2>
      <p *ngIf="range.controls.start.value && range.controls.end.value ">{{range.controls.start.value | date}} - {{range.controls.end.value | date}}</p>
      <mat-form-field subscriptSizing="dynamic">
        <button (click)="resetRange()" matSuffix mat-icon-button><mat-icon>clear</mat-icon></button>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate #dateRangeStart formControlName="start" placeholder="Start date">
          <input matEndDate #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)" formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker style="width:316px" #picker></mat-date-range-picker>
      </mat-form-field>
    </mat-toolbar-row>
  </mat-toolbar>
  
  <div class="orders-split">
    <section class="search-results" style="background-color: #424242;">

      <div class="loading-shade" *ngIf="resultsLoading">
        <mat-spinner></mat-spinner>
      </div>
      <div #tableContainer class="table-container">

        <table #ordersTable mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        
          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> ID 

            </th>
            <td mat-cell *matCellDef="let element">{{element.order_id}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"><a [href]="'/orders/' + element.order_id">{{element.zedder && element.zedder.firstname}} {{element.zedder && element.zedder.lastname}}</a></td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="invoice">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Invoice </th>
            <td mat-cell *matCellDef="let element">{{element.invoice_no}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        
          <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element">{{element.zedder && element.zedder.email}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        
          <ng-container matColumnDef="phone">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let element">{{element.zedder && element.zedder.phone}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="itemName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Item </th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="itemType">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Item </th>
            <td mat-cell *matCellDef="let element">{{element.type}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="itemPrice">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Item Price</th>
            <td mat-cell *matCellDef="let element">{{element.itemPrice}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="items">
            <th mat-header-cell *matHeaderCellDef> Items </th>
            <td mat-cell *matCellDef="let element">{{element.items_json.length}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="merch">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Merch </th>
            <td mat-cell *matCellDef="let element">{{element.hasMerch}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        
          <ng-container matColumnDef="total">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Total </th>
            <td mat-cell *matCellDef="let element">{{element.total}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        
          <ng-container matColumnDef="transaction">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Method </th>
            <td mat-cell *matCellDef="let element">{{element.transaction.length && element.transaction[0].service}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="payer">
            <th mat-header-cell *matHeaderCellDef> Payer </th>
            <td mat-cell *matCellDef="let element">{{element.transaction.length && element.transaction[0].payer_id}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="transtatus">
            <th mat-header-cell *matHeaderCellDef> Transaction Status </th>
            <td mat-cell *matCellDef="let element">{{element.transaction.length && element.transaction[0].capture_status}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="processed">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Reception </th>
            <td mat-cell *matCellDef="let element">{{element.processed}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Created </th>
            <td mat-cell *matCellDef="let element">{{element.created_at | date:'dd/MM/yy hh:mm a'}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="updated">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Updated </th>
            <td mat-cell *matCellDef="let element">{{element.updated_at | date:'dd/MM/yy hh:mm a'}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="id-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
              <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
                <mat-label>ID</mat-label>
                <input #input matInput (keyup)="applyFilter($event, 'id')">
              </mat-form-field>
            </th>
          </ng-container>

          <ng-container matColumnDef="name-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
              <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
                <mat-label>Name</mat-label>
                <input #input matInput (keyup)="applyFilter($event, 'name')">
              </mat-form-field>
            </th>
          </ng-container>

          <ng-container matColumnDef="invoice-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>
              
          <ng-container matColumnDef="email-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
              <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
                <mat-label>Email</mat-label>
                <input #input matInput (keyup)="applyFilter($event, 'email')">
              </mat-form-field>
            </th>
          </ng-container>    

          <ng-container matColumnDef="phone-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
              <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
                <mat-label>Phone</mat-label>
                <input #input matInput (keyup)="applyFilter($event, 'phone')">
              </mat-form-field>
            </th>
          </ng-container>

          <ng-container matColumnDef="itemName-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>


          <ng-container matColumnDef="itemType-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="itemPrice-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="items-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="merch-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
              <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
                <mat-label>Merch</mat-label>
                <mat-select (selectionChange)="applyFilter($event, 'merch')" matNativeControl>
                  <mat-option value=""></mat-option>
                  <mat-option value="true">Merch</mat-option>
                  <mat-option value="false">No-merch</mat-option>
                </mat-select>
              </mat-form-field>
            </th>
          </ng-container>

          <ng-container matColumnDef="total-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="transaction-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="payer-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="transtatus-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="processed-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
              <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
                <mat-label>Reception</mat-label>
                <mat-select (selectionChange)="applyFilter($event, 'processed')" matNativeControl>
                  <mat-option value=""></mat-option>
                  <mat-option value="unprocessed">unprocessed</mat-option>
                  <mat-option value="fix">fix</mat-option>
                  <mat-option value="checked">checked</mat-option>
                  <mat-option value="completed">completed</mat-option>
                  <mat-option value="ready">ready</mat-option>
                </mat-select>
              </mat-form-field>
            </th>
          </ng-container>

          <ng-container matColumnDef="created-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <ng-container matColumnDef="updated-filter">
            <th mat-header-cell *matHeaderCellDef style="padding: 0;">
            </th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedFilters; sticky: false"></tr>
          <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>    
      </div>
      <div style="display: flex; align-items: center;">
        <button (click)="fetchOrders()" mat-icon-button matTooltip="Refresh" matTooltipPosition="above" style="margin-left: 8px; color: #a8a8a8;">
          <mat-icon>refresh</mat-icon>
        </button>
        <button (click)="exportCSV()" matTooltip="Export period as spreadsheet" matTooltipPosition="above" mat-icon-button style="margin-left: 8px; color: #a8a8a8;" aria-label="export period as spreadsheet">
          <mat-icon>table_chart</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Columns" matTooltipPosition="above" style="margin-left: 8px; color: #a8a8a8;">
          <mat-icon>view_column</mat-icon>
        </button>
        <mat-menu #menu="matMenu" > 
          <div style="max-height: 300px; min-width: 150px; overflow-y: scroll;">
            <div *ngFor="let col of allColumns; index as c">
              <mat-checkbox [checked]="col.visible" (change)="changeColumns(c, $event)">
                {{col.column}}
              </mat-checkbox>
            </div>
          </div>
        </mat-menu>
        <mat-paginator 
          #ordersPaginator
          style="flex: 1"
          [length]="resultsLength" 
          [pageSize]="40"
          showFirstLastButtons
          aria-label="Select page of orders">
        </mat-paginator>
      </div>


    </section>
    <section style="background: #212121; display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;">

      <!-- <canvas
      baseChart
      [data]="pieChartData"
      [type]="pieChartType"
      [options]="pieChartOptions"
        >
      </canvas> -->

      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Running Totals
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <canvas
            style="margin: 16px 8px;"
            baseChart
            type="line"
            [data]="incremental"
            [options]="incrementalOptions"
          >
          </canvas>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Daily Totals
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <canvas
            style="margin: 16px 8px;"
            baseChart
            type="bar"
            [data]="dailyTotals"
            [options]="dailyTotalsOptions"
          >
          </canvas>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Daily Breakdown Totals
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <canvas
            style="margin: 16px 8px;"
            baseChart
            type="bar"
            [data]="dailyBreakdown"
            [options]="dailyBreakdownOptions"
          >
          </canvas>
        </mat-card-content>
      </mat-card>
      
      <div style="display: flex; gap: 8px;">
        <mat-card style="width: 100%; height: auto;">
          <mat-card-header>
            <mat-card-title>
              # of Payments by Type
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <canvas
              style="margin: 16px 8px;"
              baseChart
              type="doughnut"
              [data]="paymentTypes"
              [options]="paymentTypesOptions"
            >
            </canvas>
          </mat-card-content>
        </mat-card>

        <mat-card style="width: 100%; height: auto;">
          <mat-card-header>
            <mat-card-title>
              Total $$$ by Type
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <canvas
              style="margin: 16px 8px;"
              baseChart
              type="pie"
              [data]="paymentTotalTypes"
              [options]="paymentTotalTypesOptions"
            >
            </canvas>
          </mat-card-content>
        </mat-card>

      </div>


      <mat-card>
        <mat-card-header>
          <mat-card-title>
            # of Prizes
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <canvas
            style="margin: 16px 8px;"
            baseChart
            type="pie"
            [data]="prizeTypes"
            [options]="prizeTypesOptions"
          >
          </canvas>
        </mat-card-content>
      </mat-card>

    </section>
  </div>

</div>