import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sponsorship-notes-editor-dialog',
  templateUrl: './sponsorship-notes-editor-dialog.component.html',
  styleUrls: ['./sponsorship-notes-editor-dialog.component.scss']
})
export class SponsorshipNotesEditorDialogComponent {
  constructor(
    private http: HttpClient,
  ){
    this.http.get(environment.dbUrl + '/sponsorship.html',  {responseType: 'text'}).subscribe(
     data =>  { this.sponsorshipMessage = data; }
    )
  }

  sponsorshipMessage;

  saveNotes() {
    this.http.post(environment.apiSrc + '/sponsorship/savecopy', { copy: this.sponsorshipMessage }).subscribe(
      data => { console.log( data )}
    );
  }
 }
