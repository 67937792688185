<div class="zed-digital-container mat-typography" cdkDropListGroup>

	<div class="show-column">
		<mat-form-field style="width: 100%" [hintLabel]="selectedProgram.name && selectedProgram.name + ' (' + selectedProgram.slug + ') ' + selectedProgram.start + ' (' + selectedProgram.day + ')'">
			<mat-label>Show</mat-label>
			<mat-select [formControl]="programSelector">
				<mat-option [value]="program" *ngFor="let program of zdPrograms">{{program.name}}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-calendar #showCalendar *ngIf="selectedProgram.day" [(selected)]="selectedDate" (selectedChange)="onDateSelected($event)" [dateFilter]="myFilter" />

		<div *ngIf="selectedProgram.day && programFolder.folder" style="flex: 1; overflow: scroll;">
			<app-fela-browser 
				[fileCallback]="openFile" 
				[options]="{ zedDigital: true, drag: true }" 
				[folder]="programFolder.folder" 
				(out)="updateFolder($event)" 
				[program]="selectedProgram" 
				[refresh]="test"
				[playlistDate]="bruceFile">
			</app-fela-browser>
		</div>

		<div style="padding: 16px 0" *ngIf="foldermessage">
			{{foldermessage}}
		</div>

		<div *ngFor="let file of files; let i = index">
			<div style="display: flex" >
				<div style="flex: 1">
					<h4 class="name">
						{{ file?.name }}
					</h4>
				</div>
				<div class="size">
					{{ file?.size | bytes }}
				</div>
			</div>
			<mat-progress-bar mode="determinate" [value]="file?.progress"></mat-progress-bar>
		</div>

		<div *ngIf="selectedProgram.day && programFolder.folder" class="media-drop" [accept]="'audio/*,.zip'" appFileDnd (fileDropped)="onMediaDropped($event)">
			<input type="file" #mediaDropRef [accept]="'audio/*,.zip'" id="mediaDropRef" multiple (change)="mediaBrowseHandler($event.target)" />
			<label for="mediaDropRef">Drop tracks here</label>
		</div>
	</div>

	<div style="flex: 1; display: flex;">
		<div *ngIf="tracks.length" style="flex: 1; display: flex; flex-flow: column;">
			<mat-toolbar><div style="flex: 1">Playlist </div>{{ selectedDate?.format('DD/MM/yy') }}  </mat-toolbar>
			<app-m3u-playlist style="flex: 1; display: flex; position: relative; overflow: hidden; " [tracks]="tracks"></app-m3u-playlist>
		</div>
		<div *ngIf="!tracks.length && selectedDate"  style="display: flex; justify-content: center; align-items: center; height: 100%;">
			No Playlist Created
		</div>
	</div>

</div>