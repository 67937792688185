<div class="availability-toggle" layout="row" style="margin-bottom: 38px;">
	<table>
		<tr>
			<th>...</th>
			<th *ngFor="let day of cols; index as col">
				{{day[0]}}
			</th>
		</tr>
		<tr *ngFor="let time of rows; index as row">
			<td>
				{{time}}
			</td>
			<td *ngFor="let day of cols; index as col" >
				<div class="availability-icon" [class]="{ 'never': data['avail-' + col + '-' + row] === 0, 'sometimes': data['avail-' + col + '-' + row] === 1, 'always': data['avail-' + col + '-' + row] === 2 }" (click)="update(col, row)">
					<!-- {{col}} {{row}} {{ availability.data[col + '-' + row] ? availability.states[ availability.data[col + '-' + row] ] : 'no' }} -->
				</div>
			</td>
		</tr>
	</table>

	<div style="margin-top: 20px; margin-left: 20px">
		<div style="line-height: 24px; display: flex;">
			<div class="availability-icon always"></div>
			<span>Always Available</span>
		</div>
		<div style="line-height: 24px; display: flex;">
			<div class="availability-icon sometimes"></div>
			<span>Sometimes Available</span>
		</div>
		<div style="line-height: 24px; display: flex;">
			<div class="availability-icon"></div>
			<span>Not Available</span>
		</div>
	</div>
</div>