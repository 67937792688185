import { Component, Input, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { secondsToHMS } from '../helpers';
import { Favourites, Info } from '../zeddb.types';
import { SessionService } from '../session.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-album-info',
  templateUrl: './album-info.component.html',
  styleUrls: ['./album-info.component.scss']
})
export class AlbumInfoComponent {
  @Input() favourite!: (arg0: any, arg1: any) => void;
  @Input() info!: Info;
  @Input() release!: (arg0: any) => void;
  @Input() queue!: (arg0: any, arg1: any, arg2: any, arg3: any) => void;
  @Input() more!: (arg0: any) => void;
  @Input() play!: (...args : any[]) => void;

  constructor(
    // @Inject('$rootScope') protected $rootScope: any,
    private SessionService: SessionService,
    private http: HttpClient,
    private location: Location,
    private router: Router
  ) {
  }
  
  ngOnChanges() {
    this.getTracks(this.info.library_no)
  }

  ngOnInit() {
    // this.getTracks(this.info.library_no)
  }

  musicDBapiSrc = environment.musicDBapiSrc;
  favourites: Favourites = { playlist: [] };
  folder: string = '';
  tracks: any[] = [];

  addToPlayList = () => {};

  click(arg) {};

  futureDate = (dt) => new Date() <= new Date(dt);

  getTracks = (libraryNo) => {
    return this.http.get<any>(environment.musicDBapiSrc + '/tracks-by-library/' + libraryNo).subscribe( data  => { 

      this.tracks = data;
      let favtemp = this.SessionService.get('favourites');
      if (favtemp) {
        this.favourites = JSON.parse(favtemp);
      }

      for (var i = 0; i < this.tracks.length; i++) {
        this.tracks[i].artist = this.tracks[i].artistName
        this.tracks[i].album = this.tracks[i].albumName
        if(this.tracks[i].part_of_a_set) {
          if(this.tracks[i].part_of_a_set.includes('/')){
            let parts = this.tracks[i].part_of_a_set.split('/');
            this.tracks[i].discnum = parts[0];
            this.tracks[i].disctotal = parts[1];
          }
          else {
            this.tracks[i].discnum = this.tracks[i].part_of_a_set;
            this.tracks[i].disctotal = null;
          }
        }
        this.tracks[i].tracknum = Number(this.tracks[i].tracknum)
        this.tracks[i].tracktotal = Number(this.tracks[i].tracktotal)

        
        if (i === 0) {
          var path = this.tracks[i].fullpath.replace('/mnt/fela', '')
          this.folder = path.substring(0, path.lastIndexOf("/"));

        }

        this.favourites && this.favourites.playlist && this.favourites.playlist.map( item => { if(this.tracks[i].title_id === item.title_id) { this.tracks[i].favourite = true }  } )
        this.tracks[i].duration = secondsToHMS(this.tracks[i].length);
        var text = this.tracks[i].fullpath;
        var link;
        if (navigator.platform.search('Win') !== -1) {
          link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
        }
        if (navigator.platform.search('Mac') !== -1) {
          link = text.replace('/mnt/fela', '/Volumes/audio')
        }
        if (navigator.platform.search('Linux') !== -1) {
          link = text;
        }
        this.tracks[i].ospath = link;
      }
    });
  }
}
