
<div class="artists-container mat-typography">
  <div style="display: flex; padding: 16px;">
    <div>
      <h1>{{artist.name}}</h1>
      <strong>ID:</strong> {{artist.id}}
    </div>
    <div style="flex: 1"></div>
    <button class="quota-icon" [disabled]="formDisabled" matTooltip="Australian" mat-icon-button aria-label="australian" (click)="changeQuota('a')">
			<mat-icon [svgIcon]="artist.cont_ausnz === 'A' ? 'australian': 'australian_outlined'"></mat-icon>
		</button>
    <button class="quota-icon" [disabled]="formDisabled" matTooltip="New Zealand" mat-icon-button aria-label="new zealand" (click)="changeQuota('nz')">
			<mat-icon [svgIcon]="artist.cont_ausnz === 'NZ' ? 'new_zealand': 'new_zealand_outlined'"></mat-icon>
		</button>
    <button class="quota-icon" [disabled]="formDisabled" matTooltip="Local" mat-icon-button aria-label="local" (click)="changeQuota('l')">
			<mat-icon [svgIcon]="artist.cont_local ? 'local': 'local_outlined'"></mat-icon>
		</button>
    <button class="quota-icon" [disabled]="formDisabled" matTooltip="Female" mat-icon-button aria-label="female" (click)="changeQuota('f')">
			<mat-icon [svgIcon]="artist.cont_female === 'F' ? 'female': 'female_outlined'"></mat-icon>
		</button>
    <button class="quota-icon" [disabled]="formDisabled" matTooltip="Indigenous" mat-icon-button aria-label="indigenous" (click)="changeQuota('i')">
			<mat-icon [svgIcon]="artist.first_nations ? 'indigenous' : 'indigenous_outlined'"></mat-icon>
		</button>
    <button class="quota-icon" [disabled]="formDisabled" matTooltip="Gender Non Conforming" mat-icon-button aria-label="Gender Non Conforming" (click)="changeQuota('gnc')">
			<mat-icon [svgIcon]="artist.genderdiverse ? 'gender_non_conforming' : 'gender_non_conforming_outlined'"></mat-icon>
		</button>
  </div>
  <div class="form-container" [formGroup]="artistForm">
    <div style="flex: 1">
      <mat-form-field class="full-width">
        <mat-label>Artist</mat-label>
        <input matInput placeholder="Artist" formControlName="name" [matAutocomplete]="artistComplete">
        <mat-autocomplete 
          autoActiveFirstOption 
          #artistComplete="matAutocomplete" 
          >
          <mat-option (click)="fetchArtist(artist)" *ngFor="let artist of artistOptions | async" [value]="artist.name">
            {{artist.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-card style="margin-bottom: 32px;">
        <div *ngIf="artistCover" class="cover editor" style="padding-bottom: 0">
          <img [src]="artistCover" style="width: 100%; position:relative" />
          
          <div class="bg" *ngIf="!formDisabled">
            <mat-icon  mat-icon-button [matMenuTriggerFor]="coverMenu" class="more" aria-label="more">more_horiz</mat-icon>
            <mat-menu #coverMenu="matMenu">
              <button mat-menu-item>
                <label for="coverDropRef">Replace Artist Image</label>
              </button>
            </mat-menu>
          </div>
        </div>

        <!-- <div ng-show="release.albumArt">
          <img style="width: 100%; height: auto" ng-click="playRelease()" ng-src="{{release.albumArt}}" >
        </div> -->
        <div *ngIf="!artistCover">
          <button mat-button style="width: 100%; padding: 32px;">
            <label for="coverDropRef">Add Artist Image</label>
          </button>
        </div>
        <input type="file" #coverDropRef [accept]="'image/*'" id="coverDropRef" (change)="fileBrowseHandler($event.target)" />

      </mat-card>


      <mat-form-field class="full-width">
        <mat-label>Artist Aliases</mat-label>
        <mat-chip-grid #aliasChip aria-label="Artists Aliases">
          <mat-chip-row *ngFor="let artist of artistForm.get('aliases')!.value"
            (removed)="removeAlias(artist)"
            [editable]="true"
            (click)="gotoArtist(artist.id)"
            (edited)="editAlias(artist, $event)"
            [aria-description]="'press enter to edit ' + artist.name">
            {{artist.name}}
            <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + artist.name">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Search Artists..."
          #aliasInput
          [formControl]="aliasControl"
          [matAutocomplete]="aliasComplete"
          [matChipInputFor]="aliasChip"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        <mat-autocomplete 
          autoActiveFirstOption 
          #aliasComplete="matAutocomplete" 
          (optionSelected)="selectedAlias($event)">
          <mat-option *ngFor="let artist of aliasOptions | async" [value]="artist">
            {{artist.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Related Artists</mat-label>
        <mat-chip-grid #relatedChip aria-label="Related Artists">
          <mat-chip-row *ngFor="let artist of artistForm.get('related')!.value"
            (removed)="removeRelated(artist)"
            [editable]="true"
            (click)="gotoArtist(artist.id)"
            (edited)="editRelated(artist, $event)"
            [aria-description]="'press enter to edit ' + artist.name">
            {{artist.name}}
            <button *ngIf="!formDisabled" matChipRemove [attr.aria-label]="'remove ' + artist.name">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Search Artists..."
          #relatedInput
          [formControl]="relatedControl"
          [matAutocomplete]="relatedComplete"
          [matChipInputFor]="relatedChip"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        <mat-autocomplete 
          autoActiveFirstOption 
          #relatedComplete="matAutocomplete" 
          (optionSelected)="selectedRelated($event)">
          <mat-option *ngFor="let artist of relatedOptions | async" [value]="artist">
            {{artist.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div style="flex: 1">
      <div style="display: flex; gap: 8px;">
        <mat-form-field class="full-width">
          <mat-label>Artist Hometown</mat-label>
          <input matInput oninput="this.value = this.value.toUpperCase()" placeholder="Artist Hometown" formControlName="hometown" [matAutocomplete]="hometownComplete">
          <mat-autocomplete 
          autoActiveFirstOption 
          #hometownComplete="matAutocomplete" 
          >
          <mat-option *ngFor="let hometown of hometownOptions | async" [value]="hometown.artist_hometown">
            {{hometown.artist_hometown}}
          </mat-option>
        </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Country</mat-label>
          <input matInput oninput="this.value = this.value.toUpperCase()" placeholder="Country" formControlName="country" [matAutocomplete]="countryComplete">
          <mat-autocomplete 
          autoActiveFirstOption 
          #countryComplete="matAutocomplete" 
          >
          <mat-option *ngFor="let country of countryOptions | async" [value]="country.album_origin">
            {{country.album_origin}}
          </mat-option>
        </mat-autocomplete>
        </mat-form-field>
      </div>

      <mat-form-field class="full-width">
        <mat-label>Artist Bio</mat-label>
        <textarea matInput cdkTextareaAutosize placeholder="Artist Bio" formControlName="bio" ></textarea>
      </mat-form-field>
      
      <mat-form-field class="full-width">
        <mat-label>Artist Pronouns</mat-label>
        <input matInput placeholder="Pronouns" formControlName="pronouns" value="">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" value="">
      </mat-form-field> 
      
      <mat-form-field class="full-width">
        <mat-label>Website</mat-label>
        <input matInput placeholder="Website" formControlName="website" value="">
      </mat-form-field>



    </div>
  </div>

  <app-cover-player [showYear]="true" [releases]="releases" [release]="click" [play]="play" [queue]="queueRelease" [favourite]="favourite"></app-cover-player>
</div>


<div class="floating-fab" [hidden]="!formEdited">
  <div  style="width: 64px; height: 64px; position: relative;">
    <mat-spinner *ngIf="locked" diameter="72" style="position: absolute; top: 0; left: 0" />
     <button [disabled]="locked" (click)="saveArtist()" mat-fab color="primary" aria-label="Example icon button with a delete icon" style="position: absolute; top: 8px; left: 8px">
        <mat-icon>edit</mat-icon>
     </button>
    </div>
</div>