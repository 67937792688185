import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-announcer-programs',
  templateUrl: './announcer-programs.component.html',
  styleUrls: ['./announcer-programs.component.scss']
})
export class AnnouncerProgramsComponent {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    // @Inject('$rootScope') private $rootScope: any,
    private AuthenticationService: AuthenticationService
  ) {


    this.user = this.AuthenticationService.getUser();

    console.log(this.user)
    this.pureHttp = new HttpClient(handler);
    
    this.pureHttp.get<any>('https://airnet.org.au/rest/stations/4ZZZ/guides/fm').subscribe(data => {
      this.programs = data.reduce((acc, cur) => acc.some(x=> (x.slug === cur.slug )) ? acc : acc.concat(cur), []).sort((a, b) => a.slug.localeCompare(b.slug));
    });

    this.programControl.valueChanges.subscribe( value => {
      this.fillInProgram = value;
    })

    // this.filteredPrograms = this.programControl.valueChanges.pipe(
    //   map(value => this._filter(value || '')),
    // );
  }


  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm alert before navigating away

    return false;
  }
  
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
        $event.returnValue = "This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)";
    }
  }
  
  user: any;

  pureHttp: HttpClient;
  programs: any[] = [];
  programControl = new UntypedFormControl('');
  filteredPrograms: any[] = [];

  fillInProgram: any|undefined = undefined;
  // filteredPrograms: Observable<any[]>;

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.programs.filter(program => program.name.toLowerCase().includes(filterValue));
  // }

  public filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();

    this.filteredPrograms =  this.programs.filter(program => program.name.toLowerCase().includes(filterValue));
  }

  displayName = (value: any) => value.name;
}
