import { Component, HostListener, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { playlistToM3U } from '../helpers';
import { AuthenticationService } from '../authentication.service';
import { environment } from 'src/environments/environment';
import { StudioService } from '../studio.service';
import { SocketService } from '../socket.service';
import { Output, EventEmitter } from '@angular/core';
import { SessionService } from '../session.service';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray, DragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import { PlaylistService } from '../playlist.service';

@Component({
  selector: 'app-track-list',
  templateUrl: './track-list.component.html',
  styleUrls: ['./track-list.component.scss']
})
export class TrackListComponent {

  @Input() track;
  // @Input() tracks;
  @Input() favourite!: (arg0: any, arg1: any) => void;
  @Input() play!: (arg0: any, arg1: any) => void;
  @Input() order!: (a: any, b: any) => number;
  @Output() onTrackSelected = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
      if (event.key === 'Escape') {

        this.dragRef && this.dragRef.reset();
        document.dispatchEvent(new Event('mouseup'));
          // this._canceledByEsc = true;
          // document.dispatchEvent(new Event('mouseup'));
      }
  }

  constructor(
    // @Inject('$rootScope') private $rootScope: any,
    private socket: SocketService,
    private http: HttpClient,
    private AuthenticationService: AuthenticationService,
	  private SessionService: SessionService,
		private PlaylistService: PlaylistService,
    private StudioService: StudioService
  ){
    this.onAir = this.StudioService.getOnAir()
    this.studio = this.StudioService.getStudio()
    this.StudioService.onMessage().subscribe( (data: any) => { 
      if(data.onAir)
        this.onAir = data.onAir; 
      if (data.studio)
        this.studio = data.studio;
    });
  }


  private _canceledByEsc = false;



  onAir = false;
  iconMairlist = '';
  studio = 0;
  tools = 0;
  dragRef: any;

  public draggingOutsideSourceList: boolean = false;
  revealPlaylist = false;


  drop(event: any ) {
    // moveItemInArray(this.favourites.playlist, event.previousIndex, event.currentIndex);
    // this.SessionService.set('favourites', JSON.stringify(this.favourites) );
    // this.FavouriteService.prepForBroadcast('update');
    this.draggingOutsideSourceList = false;
  }

  dragMoved(event) {
    // console.log(event);
    // console.log(this.dragDrop)
    if(!this.revealPlaylist && Math.abs(event.distance.x) > Math.abs(event.distance.y) && event.distance.x < 50) {
      this.revealPlaylist = true;
      this.PlaylistService.sendPlaylistMessage(true);
      setTimeout( () => { 
        event.source._changeDetectorRef.detectChanges();
        // event.source._dragRef._dropContainer._cacheParentPositions()
        // console.log('changes?')
      } , 1000 );
      // event.source._dragRef.dropContainer['_cachePositions']();
    }
    if(this.revealPlaylist && Math.abs(event.distance.x) > Math.abs(event.distance.y) && event.distance.x > 100) {
      this.revealPlaylist = false;
      this.PlaylistService.sendPlaylistMessage(false);
    }
    
  }

  onCdkDragStarted(event) {
    this.dragRef = event.source._dragRef;
  }

  onCdkDragDropped(event: CdkDragDrop<string>) {
    console.log(event.container !== event.item.dropContainer);
    this.draggingOutsideSourceList = false;
  }
  
  onCdkDragEntered(event: CdkDragEnter<string>) {
    console.log(event.container !== event.item.dropContainer);
    this.draggingOutsideSourceList = event.container !== event.item.dropContainer;
  }

  showTools(track: any) {
    if(track.showDetails) {
      track.showDetails = !track.showDetails;
      this.tools = 0;
    }
    else {

      this.onTrackSelected.emit(track)
      // this.tracks.map(i => i.showDetails = false);
      track.showDetails = !track.showDetails;
      this.tools = 1;
    }
  }

  languageWarning = (track: any) => {
    track.language_warning = !track.language_warning;
		this.http.get(environment.musicDBapiSrc + '/language-warning/' + track.title_id + '/' + track.language_warning).subscribe(data => {
      console.log(data);
    });
    console.log(track);
  }

  contentWarning = (track: any) => {
		track.content_warning = !track.content_warning;
		this.http.get(environment.musicDBapiSrc + '/content-warning/' + track.title_id + '/' + track.content_warning).subscribe(data => {
      console.log(data);
    });
    console.log(track);
  }


  copyToClipboard = (text: string) => {
    var link;
    if (navigator.platform.search('Win') !== -1) {
      console.log('win');
      link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
    }

    if (navigator.platform.search('Mac') !== -1) {
      console.log('mac');
      link = text.replace('/mnt/fela', '/Volumes/audio')
    }

    if (navigator.platform.search('Linux') !== -1) {
      console.log('lin');
      console.log(text);
      link = text;
    }

    console.log(navigator.platform);
    window.prompt("Copy to clipboard: Ctrl+C, Enter", link);
  }


  appendTrack = (track: any) => {
    if('start_time' in track) {
      track.file = ('//fela/temporary' + track.filename).replaceAll('/', '\\');
    }
    else {
      track.file = ((track.filepath + '/' + track.filename).replace('/mnt/fela', '//fela/audio')).replaceAll('/', '\\');
    }	
    let playlist = [ track ]

    let data = playlistToM3U(playlist);
    const headers = { 'Content-Type': 'application/json' };

    this.http.post(environment.musicDBapiSrc + '/save-playlist', { 
      file: 'studio'+ this.studio,
      destination: '/Tech', 
      playlist: data, share: 'temporary'
    }, {headers}).subscribe( (data: any) => {
      debugger;
      this.socket.send({ appendTrack: { 
        id: String(this.AuthenticationService.getUser().details.id).padStart(5, '0'), 
        studio: this.studio, 
        file: 'T:/Tech/Playlists/studio'+ this.studio + '.m3u' 
      } });
    }, error => {
      console.log(error);
    });
  }

}
