import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { VolunteerDepartmentsService } from '../volunteer-departments.service';

export interface VolunteerDepartments {
  id: number;
  department: string;
}
 
@Component({
  selector: 'voldepartment',
  templateUrl: './manage-volunteer-departments.component.html',
  styleUrls: ['./manage-volunteer-departments.component.scss']
})

export class ManageVolunteerDepartmentsComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    id: new UntypedFormControl({ value: 0, disabled: true }),
    department: new UntypedFormControl('')
  });
  lastSort: Sort = { active: "department", direction: "asc" };
  editVolunteerDepartments: VolunteerDepartments = {department: '', id: 0};
  subCategories: VolunteerDepartments[] = [];
  sortedData: VolunteerDepartments[];
  displayedColumns: string[] = ['id', 'department', 'edit'];

  constructor(public VolunteerDepartmentsService: VolunteerDepartmentsService) {
    this.sortedData = this.subCategories.slice();
    // this.VolunteerDepartmentsService = VolunteerDepartmentsService;
    VolunteerDepartmentsService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':           
          return compare(a.id, b.id, isAsc);
        case 'department':
          return compare(a.department, b.department, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      id: el.id,
      department: el.department
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      id: 0,
      department: ''
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().id === 0) { //new
      let subskill: any = {};
      subskill.department = this.interestForm.value.department;
      this.VolunteerDepartmentsService.save(subskill).subscribe( res => {
        this.VolunteerDepartmentsService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      this.VolunteerDepartmentsService.get(this.interestForm.getRawValue().id).subscribe( subskill => {
        subskill.department =  this.interestForm.value.department;
        this.VolunteerDepartmentsService.update(subskill).subscribe(response => { 
          let interests : VolunteerDepartments[] = [ {
            id: response.id,
            department: response.department
          }]
    
          const existing = this.subCategories.reduce((a,t)=> (a[t.id] = t, a), {}),
          changed = interests.reduce((a,t)=> (a[t.id] = t, a), {})
    
          this.subCategories = Object.values({...existing, ...changed})
          // this.sortedData = this.subCategories.slice(); 
          this.sortData(this.lastSort);
        });
       });

    }
   
  }
  
}
