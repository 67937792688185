import { Component, Inject, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-artist-info',
  templateUrl: './artist-info.component.html',
  styleUrls: ['./artist-info.component.scss']
})
export class ArtistInfoComponent {
  @Input() artists: any[] = [];
  @Input() clear = () => {};
  constructor() {}
  musicDBapiSrc = environment.musicDBapiSrc;
}
