<div #tableContainer class="table-container">

  <table #announcerTable mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)" >
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> ID 

      </th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Item </th>
      <td mat-cell *matCellDef="let element">{{element.name}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="itemType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Item Variant </th>
      <td mat-cell *matCellDef="let element">{{element.type}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="value">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Variant Value </th>
      <td mat-cell *matCellDef="let element">{{element.value}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="inventory">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Inventory </th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroup]="controls.controls[i]"> 
				<mat-form-field (click)="$event.stopPropagation()" *ngIf="element.selected" subscriptSizing="dynamic" style="width: 90px">
					<input matInput (change)="editInventory(element, $event, i)" type="number" formControlName="inventory" placeholder="Time"> 
				</mat-form-field>
				<span *ngIf="!element.selected">{{element.inventory}}</span>

				<!-- <mat-form-field subscriptSizing="dynamic" style="width: 60px">
					<input matInput type="text" formControlName="id" placeholder="Time"> 
				</mat-form-field> -->
			</td>
			<!-- <td mat-cell *matCellDef="let element">{{element.inventory}}</td> -->

 
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let element">
				<button mat-icon-button (click)="editItem(element, $event)">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="id-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>ID</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'id')">
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="name-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Name</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'name')">
        </mat-form-field>
      </th>
    </ng-container>


    <ng-container matColumnDef="itemType-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Item Variant</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'itemType')">
        </mat-form-field>
      </th>
    </ng-container>    

    <ng-container matColumnDef="value-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Variant Value</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'value')">
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="inventory-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="edit-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedFilters; sticky: false"></tr>
    <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>    
</div>