import { Component, Inject, ViewChild } from '@angular/core';
import { HttpClient, HttpEventType, HttpParams, HttpBackend } from '@angular/common/http';
import { map, startWith, filter, debounceTime, switchMap, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleMetadataEditorComponent } from '../simple-metadata-editor/simple-metadata-editor.component';
import { Observable, Observer, Subject, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-announcers-temporary',
  templateUrl: './announcers-temporary.component.html',
  styleUrls: ['./announcers-temporary.component.scss']
})
export class AnnouncersTemporaryComponent {
  @ViewChild('showCalendar', { static: false }) showCalendar!: MatCalendar<Date>;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    public dialog: MatDialog,
		// @Inject('$rootScope') private $rootScope: any,
  ) {
    this.pureHttp = new HttpClient(handler);
    this.fetchGrid()

    this.programSelector.valueChanges.subscribe({
      next: (result: any) => {
        console.log(result);
        this.selectedSlug = result.slug;
        this.selectedProgram = result;
        if(this.showCalendar) {
          this.showCalendar.updateTodaysDate()
        }
        this.getFolder();
      }
    })

    // this.bla = this.test.asObservable();
    // console.log(this.bla);
  }
  pureHttp: HttpClient;

  allPrograms: any[] = [];
  programSelector: any = new UntypedFormControl(null);
  selectedSlug: string | null = null;
  selectedProgram: any = {};
  selectedDate: Moment | null = null;
  uploadFolder = {folder: '', files: []};
  programFolder = {folder: '', files: []};
  foldermessage = '';
  files: any[] = [];

  cwd: string = '';
  bruceFile = '';

  tracks: any[] = [];

  test = new Subject();

  bla: any;

  myFilter = (d: Moment | null): boolean => {
    const day = (d || moment()).day();
    // Prevent Saturday and Sunday from being selected.
    if (this.selectedProgram.day ) {
      return day === this.selectedProgram.day % 7;
    }
    return day !== 0 && day !== 6;
  };

  onDateSelected(date) {
    let [hours, minutes, seconds] = this.selectedProgram.start.split(':');
    let test = moment(date).hour(0).minute(0).second(0).millisecond(0).add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds');
    let showPlaylist = test.format('MMMDD-HH');
    let archiveDate = test.format('YYYY-MM-DD-HH-mm');

    this.getPlaylist(showPlaylist);
    this.bruceFile = 'ZD-' + archiveDate + '.mp3';
  }

  fetchGrid() {
    let zdPrograms = this.pureHttp.get<any[]>(
      'https://airnet.org.au/rest/stations/4ZZZ/guides/digital', 
    )

    let fmPrograms = this.pureHttp.get<any[]>(
      'https://airnet.org.au/rest/stations/4ZZZ/guides/fm', 
    )

    forkJoin(zdPrograms, fmPrograms).subscribe( data => {
      const [ zdPrograms, fmPrograms ] = data;
      this.allPrograms = [...zdPrograms, ...fmPrograms].sort( (a, b) => a.name.localeCompare(b.name) );
    })
  }

  updateFolder = out => {
    console.log(out)
    this.uploadFolder = out;
  }


  getFolder = (folder = null) => {
    let path;
    if(!folder) {
      path = '/' + this.selectedProgram.name + '/';
    }
    else {
      path = folder;
    }
    this.cwd = path;
    this.http.post<any>(environment.musicDBapiSrc + '/list-fela', { folder: path, share: 'temporary' } ).subscribe (data => { 
      if(data) {
        this.programFolder = data 
        this.uploadFolder = data 
      }
      else {
        this.foldermessage = 'No folder found, create a folder in Digital Radio with the same name.';
      }
    });
  }

  tagEditor = ($event, file) => {
    this.dialog.open(SimpleMetadataEditorComponent, {
      data: {
        file: file,
      },
    });
  }

  getPlaylist =  (playlist) => {
    this.http.post<any>(environment.musicDBapiSrc + '/playlist', { playlist: playlist, share: 'temporary' }).subscribe( data => {
      // this.showPlaylist = data
      if (data.playlist === false) {
        alert('No playlist');
        this.tracks = [];
        return;
      }
      let m3u: any[] =  data.playlist.split(/\r\n|\n\r|\n|\r/);
      const m3uCopy = [...m3u];
      for(let n = m3uCopy.length - 1; n >= 0; n--) {
        let i = m3uCopy[n];
        if(i.search('Sponsorship Spots') > -1 || i.search('Station & Program Stings') > -1  ) { 
          m3u.splice(n-1, 2);
          console.log(i, n)
        } 
      }
      
      let files: any[] = []; 
      let extinf = m3u.filter((i,n) => { if (i.match(/^#EXTINF/)) { files.push( m3u[n+1] ); return true; } else { return false; } });
      let tracks = extinf.map((i, n) => {
        let spl = i.match(/#EXTINF:([\d.]*),\| *(.*)/)
        let inf = i.match(/#EXTINF:([\d.]*), *(.*)/);
        let splParts;
        if(spl) {
          splParts = spl[2].substr(1).slice(0, -1).split('|');
        }
        else {
          splParts = inf[2].split(' - ');
        }
       
        return { file: files[n], length: inf[1], artist: splParts[0], title: splParts[1], category: splParts[2], splParts: inf[2] } 
      })
      console.log(tracks)
      this.tracks = tracks;
    })
  }

  openFile = ($event, file) => {
    const extension = file.split('.').pop()
    const uncompressedAudioTypes = ['wav', 'aiff', 'aif']
    const compressedAudioTypes = ['mp3', 'm4a', 'ogg', 'flac']
    const playlistTypes = ['m3u']
    const uncompressedAudio = uncompressedAudioTypes.includes( extension )
    const compressedAudio = compressedAudioTypes.includes( extension )
    const playlist = playlistTypes.includes( extension )
    if(compressedAudio)
      return this.tagEditor($event, file)
    if(playlist)
      return this.getPlaylist(file)
  }

  onMediaDropped($event) {
    this.prepareMediaList($event);
  }

  prepareMediaList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadMedia();
    // this.uploadFilesSimulator(0);
  }

  mediaBrowseHandler(target ) {
    this.prepareMediaList(target.files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }
  
  getEventMessage = (event, file, chunkNumber, percentTotal) => {
    // console.log(event, file);
    // console.log(chunkNumber)
    if (event && event.type === HttpEventType.UploadProgress) {
      if(event.loaded &&
        event.total) {
        const percentDone = Math.round(100 * event.loaded / event.total);
        percentTotal[chunkNumber] = percentDone;
        let percentChunks = Math.round( percentTotal.reduce((sum, a) => sum + a, 0) / percentTotal.length );
        file.progress = percentChunks;
      }
    } 
  }

  uploadMedia() { 
    if (!this.files.length) {
      return;
    } else {
      this.files.forEach( async (file, n) => {

        const chunkSize = 10485760;
        let chunkNumber = 0;
        const chunkTotal = Math.ceil(file.size / chunkSize);
        let percentTotal = Array(chunkTotal).fill(0) 
         for( let offset = 0; offset < file.size; offset += chunkSize ){
          const filename = file.name;
          const chunk = file.slice( offset, offset + chunkSize );
          const formData = new FormData();
          formData.append('_chunkSize', String(chunkSize));
          formData.append('_currentChunkSize', String(chunk.size));
          formData.append('_chunkNumber', String(chunkNumber));
          formData.append('_totalSize', String(file.size));
          formData.append('file', chunk, filename);
          formData.append('share', 'temporary');
          formData.append('destination', this.uploadFolder.folder);
            const resp = await this.http.post<any>(environment.musicDBapiSrc + '/upload', formData, {
              observe: 'events',
              reportProgress: true,
            }).pipe(  tap(event => this.getEventMessage(event, file, chunkNumber, percentTotal)) ).toPromise() 

              if (resp && resp.type === HttpEventType.Response) {
              if(resp.body) {
                console.log('Upload complete');
                console.log(resp.body);
                if (resp.body.mime && resp.body.mime === 'application/zip') { 
                  let index = this.files.indexOf(file);
                  this.deleteFile(index);
                  this.getFolder()
                  this.test.next('refresh');
                }
                else {
                  let index = this.files.indexOf(file);
                  this.deleteFile(index);
                  this.getFolder()
                  this.test.next('refresh');
                }
                
              }
            }
          chunkNumber++;
        }
        
      })
    }
  }
}
