import { Inject, Injectable, SecurityContext } from '@angular/core';
import routingConfig from './ajs/controllers/acl'
import { SessionService } from './session.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, tap, interval, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private SessionService: SessionService,
    private http: HttpClient,
    private handler: HttpBackend,
    private location: Location,
    private sanitizer: DomSanitizer,
    private router: Router
    // @Inject('$http') private $http,
  ) {

    this.pureHttp = new HttpClient(handler);

    let cTemp = SessionService.get('user');
    this.currentUser = {
      username: '',
      role: this.userRoles.public,
    };
  
    if (cTemp) {
      this.currentUser = JSON.parse(cTemp);
      this.user = JSON.parse(cTemp);
      // this.$http.defaults.headers.common.Authorization = 'Bearer ' + this.SessionService.get('token');
  
    }
  }

  pureHttp: HttpClient;

  currentUser: any;
  user: any;
  accessLevels = routingConfig.accessLevels;
  userRoles = routingConfig.userRoles;
  beaconSubscription;
  lastBeacon;
  loginBeacon;
    //var currentUser = (SessionService.get('user')) ? JSON.parse(SessionService.get('user')) : { username: '', role: userRoles.public };
  
  public canActivate(acl) {
    return this.authorize(this.accessLevels[acl]) > 0;
  }

  public requireAccess(acl) {
    return this.authorize(this.accessLevels[acl]);
  }

  public getUser() {
    return this.user;
  }

  public getUsername() {
    return this.user.username;
  }

  cacheSession(response: any) {
    this.SessionService.set('user', JSON.stringify({ username: response.username,
      role: response.role, details: response.details }))
    this.SessionService.set('token', response.token);
    // this.$http.defaults.headers.common.Authorization = 'Bearer ' + response.token;
    this.SessionService.set('authenticated', true);
  }

  uncacheSession() {
    this.SessionService.unset('authenticated');
    this.SessionService.unset('user');
    this.SessionService.unset('token');
    // this.$http.defaults.headers.common.Authorization = '';

  }

  loginError(response) {
    // FlashService.show('Login Failed.');
    console.log('no login.')
  }

  setUser(response) {
    this.currentUser = { ...this.currentUser, ...response }
    // _.extend(currentUser, response);
    this.user = response;
  }

  sanitizeCredentials(credentials) {
    return {
      username: this.sanitizer.sanitize(SecurityContext.HTML, credentials.username),
      password: this.sanitizer.sanitize(SecurityContext.HTML, credentials.password)
    };
  }

  beacon() {
    // console.log('does auth beacon happen?');
    
    const prevBeacon = this.lastBeacon;
    const curBeacon = new Date();
    // console.log(curBeacon - prevBeacon)
    if ( prevBeacon && curBeacon.valueOf() - prevBeacon.valueOf() < (60000 * 5) - 10000 ) {
      return;
    } 

    this.lastBeacon = curBeacon;

    this.http.post<any>(environment.apiSrc + '/auth/beacon', {}).subscribe( data => {
      this.setUser(data);
      this.cacheSession(data);
      // FlashService.clear;
      // ScriptService.loader(['post-modules']).then(what => {console.log('what')})
      // import(/* webpackChunkName: "post-modules" */ './post-modules')
      // import(/* webpackChunkName: "orders" */ './controllers/orders')

    }, error => {
      if (error.status === 401) {
        this.beaconSubscription.unsubscribe();
        this.uncacheSession();
        this.setUser({
          username: '',
          role: this.userRoles.public
        });
        this.user = null;
        // $interval.cancel($rootScope.loginBeacon);
        this.loginBeacon = null;
        this.router.navigateByUrl('/login');
      }
    })
  }

  authorize(accessLevel, role?) {
    if (role === undefined) 
      role = this.currentUser.role;
    return accessLevel.bitMask & role.bitmask;
  }

  login(credentials) {
    return this.pureHttp.post<any>(environment.apiSrc + '/auth/login', this.sanitizeCredentials(credentials))
    .pipe( tap( data => {
      this.setUser(data);
      console.log('ok ok ok ok ok ok')
      this.cacheSession(data);
      this.startBeacon();
      // FlashService.clear;
    } ))
    .pipe( catchError( error => {
      console.log('hello');
      this.loginError(error);
      throw new Error ('Bad credentials');
    }))
  }

  logout() {
    //var logout = $http.get("/auth/logout");
    this.uncacheSession();
    this.setUser({
      username: '',
      role: this.userRoles.public
    });
    this.user = null;
    return true;
  }

  isLoggedIn() {
    // console.log(SessionService.get('authenticated'));
    // console.log(sessionStorage);
    return this.SessionService.get('authenticated');
  }

  startBeacon() {
    console.log('start beacon');
    // this.loginBeacon = setInterval( this.beacon, 60000 * 5);
    const beaconInterval  = interval(60000 * 5);
        
    this.beaconSubscription =  beaconInterval.subscribe( () => this.beacon() )
    this.beacon()
  }
  
  focusBeacon() {
    this.beacon()
  }
      //return roles etc so they can be monitored with directive to manip dom - angular style
}
