<mat-toolbar>
  <mat-toolbar-row style="justify-content: space-between;">
    <div>Announcers</div>
    <button (click)="exportCSV()" matTooltip="Export as spreadsheet" mat-icon-button style="margin-left: 8px; color: #a8a8a8;" aria-label="export as spreadsheet">
      <mat-icon>table_chart</mat-icon>
    </button>
  </mat-toolbar-row>
  
  </mat-toolbar>
<div #tableContainer class="table-container">

  <table #announcerTable mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> ID 

      </th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"><a [href]="'/zedders/' + element.id">{{element.firstname}} {{element.lastname}}</a></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pronouns">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Pronouns </th>
      <td mat-cell *matCellDef="let element">{{element.pronouns}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="program">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Program </th>
      <td mat-cell *matCellDef="let element">{{element.program && element.program.name}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element">{{element.email}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  
    <ng-container matColumnDef="phone">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Phone </th>
      <td mat-cell *matCellDef="let element">{{element.phone}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="expiry">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Expiry </th>
      <td mat-cell *matCellDef="let element">{{element.expiry}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="keywords">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Keywords </th>
      <td mat-cell *matCellDef="let element">{{element.keywords}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="rds">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> RDS </th>
      <td mat-cell *matCellDef="let element">{{element.program && element.program.rdscat}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="id-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>ID</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'id')">
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="name-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Name</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'name')">
        </mat-form-field>
      </th>
    </ng-container>


    <ng-container matColumnDef="email-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Email</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'email')">
        </mat-form-field>
      </th>
    </ng-container>    

    <ng-container matColumnDef="phone-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Phone</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'phone')">
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="expiry-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="program-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Program</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'program')">
        </mat-form-field>
      </th>
    </ng-container>


    <ng-container matColumnDef="keywords-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Keywords</mat-label>
          <input #input matInput (keyup)="applyFilter($event, 'keywords')">
        </mat-form-field>
      </th>
    </ng-container>


    <ng-container matColumnDef="rds-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="pronouns-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="items-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="merch-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Merch</mat-label>
          <mat-select (selectionChange)="applyFilter($event, 'merch')" matNativeControl>
            <mat-option value=""></mat-option>
            <mat-option value="true">Merch</mat-option>
            <mat-option value="false">No-merch</mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="total-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="transaction-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="payer-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="transtatus-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="processed-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
        <mat-form-field subscriptSizing="dynamic" style="width: 70px; min-width: 100%;">
          <mat-label>Reception</mat-label>
          <mat-select (selectionChange)="applyFilter($event, 'processed')" matNativeControl>
            <mat-option value=""></mat-option>
            <mat-option value="unprocessed">unprocessed</mat-option>
            <mat-option value="fix">fix</mat-option>
            <mat-option value="checked">checked</mat-option>
            <mat-option value="completed">completed</mat-option>
            <mat-option value="ready">ready</mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="created-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <ng-container matColumnDef="updated-filter">
      <th mat-header-cell *matHeaderCellDef style="padding: 0;">
      </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedFilters; sticky: false"></tr>
    <tr mat-row (click)="clickedRows(row)" [class.selected-row]="searchResultsSelection === row" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>    
</div>