  <mat-sidenav-container cdkDropListGroup>
    <mat-sidenav role="navigation" #sidenav mode="push" [fixedInViewport]="false" style="gap: 16px; background-color: black; 
    width: 256px;">
      <a routerLink="/" href="/">
      <mat-icon svgIcon="4zzz-icon" style="font-size: 150px;height: 150px;width: 150px; margin: 16px;"></mat-icon>
      </a>

      <div #sidenavItems class="sidenav-items" style="width: 240px;" [style.display]="showPlaylist ? 'block' : 'none'">
        
        <div cdkDropList [cdkDropListData]="playlist" (cdkDropListDropped)="playlistDrop($event)" class="playlist-cover" >
          <div style="display: flex; justify-content: start; align-items: center; gap: 8px; padding: 8px;">
            <div style="width: 60px; height: 60px; background-color: rgb(13, 13, 13); border: solid 1px black;"></div>
            <div>New Playlist</div>
          </div>
        <!-- <div *ngFor="let track of playlist" cdkDrag >
            {{track.title}}
          </div> -->
        </div>
        
        <div cdkDropList [cdkDropListData]="playlist" (cdkDropListDropped)="playlistDrop($event)" class="playlist-cover" *ngFor="let playlist of playlists">
          <div style="display: flex; justify-content: start; align-items: center; gap: 8px; padding: 8px;">
            <div style="width: 60px; height: 60px; background-size: cover; background-position: center; background-color: #1e1e1e; border: solid 1px black;" [style.backgroundImage]="'url('+ musicDBapiSrc + '/coverArt/150/' + playlist.id  + '/playlist)'" ></div>
            <div>{{playlist.name}}</div>
          </div>
        </div>



        <!-- <div cdkDropList [cdkDropListData]="playlist" (cdkDropListDropped)="playlistDrop($event)" class="playlist-cover" >
          <button mat-menu-item>Another fake playlist</button>
          <div *ngFor="let track of playlist" cdkDrag >
            {{track.title}}
          </div>
        </div> -->
      </div>

      <div #sidenavItems class="sidenav-items" style="width: 240px;" *ngIf="!showPlaylist">

        <a *ngIf="requireAccess('announcer') && !requireAccess('music') && !requireAccess('librarian')" routerLink="/announcers/programs" routerLinkActive="active" mat-menu-item>
          Show Playlists
        </a>

        <a *ngIf="requireAccess('public') && !requireAccess('music') && !requireAccess('librarian')" routerLink="/releases/browse" routerLinkActive="active" mat-menu-item>
          Digital Pigeonhole
        </a>

        <a *ngIf="requireAccess('public') && !requireAccess('music') && !requireAccess('librarian')"  routerLink="/releases/archive" routerLinkActive="active" mat-menu-item>
          Archive
        </a>

        <a *ngIf="requireAccess('announcer') && !requireAccess('music') && !requireAccess('librarian')" routerLink="/announcers/temporary" routerLinkActive="active" mat-menu-item>
          Temporary
        </a>

        <a *ngIf="requireAccess('announcer') && !requireAccess('music') && !requireAccess('librarian')" routerLink="/zed-digital" routerLinkActive="active" mat-menu-item>
          Zed Digital
        </a>

        <mat-accordion *ngIf="requireAccess('librarian') || requireAccess('music')">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Music
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a routerLink="/releases/browse" routerLinkActive="active" mat-menu-item>
              Digital Pigeonhole
            </a>
            <a routerLink="/releases/archive" routerLinkActive="active" mat-menu-item>
              Archive
            </a>
            <a *ngIf="requireAccess('music')" routerLink="/releases/search" routerLinkActive="active" mat-menu-item>
              Search
            </a>
            <a *ngIf="requireAccess('music')" routerLink="/releases/new" routerLinkActive="active" mat-menu-item>
              Add New Release
            </a>
            <a *ngIf="requireAccess('librarian')" routerLink="/merge/artists" routerLinkActive="active" mat-menu-item>
              Merge artists
            </a>
          </mat-expansion-panel>
        </mat-accordion>

        <a *ngIf="requireAccess('reception')" routerLink="/zedders/search" routerLinkActive="active" mat-menu-item >
          Zedders
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/orders" routerLinkActive="active" mat-menu-item>
          Orders
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/subscribers/search" routerLinkActive="active" mat-menu-item>
          Subscribers
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/volunteers/search" routerLinkActive="active" mat-menu-item>
          Volunteers
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/contacts/search" routerLinkActive="active" mat-menu-item>
          Contacts
        </a>

        <a *ngIf="requireAccess('public') && !requireAccess('admin')" routerLink="/sponsorship/spot-roster" routerLinkActive="active" mat-menu-item>
          Sponsorship
        </a>

        <mat-accordion  *ngIf="requireAccess('admin')">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Sponsorship
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a routerLink="/sponsorship/spot-roster" routerLinkActive="active" mat-menu-item>
              Spot Roster
            </a>
            <a routerLink="/sponsorship" routerLinkActive="active" mat-menu-item>
              Campaigns
            </a>
            <a routerLink="/sponsorship/schedule" routerLinkActive="active" mat-menu-item>
              Schedule
            </a>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion  *ngIf="requireAccess('admin')">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Reports
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a routerLink="reports/subscribers" routerLinkActive="active" mat-menu-item>
              Subscribers
            </a>
            <a routerLink="reports/orders" routerLinkActive="active" mat-menu-item>
              Orders
            </a>
            <a routerLink="reports/programs" routerLinkActive="active" mat-menu-item>
              Programs
            </a>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion  *ngIf="requireAccess('admin')">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Manage
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a routerLink="/manage/subscribers" routerLinkActive="active" mat-menu-item>
              Subscribers
            </a>
            <a routerLink="/manage/volunteers" routerLinkActive="active" mat-menu-item>
              Volunteers
            </a>
            <a routerLink="/manage/contacts" routerLinkActive="active" mat-menu-item>
              Contacts
            </a>
            <a routerLink="/manage/releases" routerLinkActive="active" mat-menu-item>
              Music Library
            </a>
            <a routerLink="/merge/contacts" routerLinkActive="active" mat-menu-item>
              Merge Contacts
            </a>
            <a routerLink="/merge/zedders" routerLinkActive="active" mat-menu-item>
              Merge Zedders
            </a>
            <a routerLink="/merge/artists" routerLinkActive="active" mat-menu-item>
              Merge Artists
            </a>
            <a routerLink="/manage/orders" routerLinkActive="active" mat-menu-item>
              Bulk Orders
            </a>
            <a routerLink="/donated-subscriptions" routerLinkActive="active" mat-menu-item>
              Donatated Subs
            </a>
            <a routerLink="/merch/inventory" routerLinkActive="active" mat-menu-item>
              Merch
            </a>
            <a routerLink="/schedule" routerLinkActive="active" mat-menu-item>
              Schedule
            </a>
            <a routerLink="/zed-digital" routerLinkActive="active" mat-menu-item>
              Zed Digital
            </a>
            <a routerLink="/announcers/temporary" routerLinkActive="active" mat-menu-item>
              Temporary
            </a>
            <a routerLink="/related-artists" routerLinkActive="active" mat-menu-item>
              Astrology
            </a>
            <a routerLink="/zedletter" mat-menu-item>
              Zedletter
            </a>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-divider></mat-divider>

      </div>
      <div style="flex: 1; display: flex; flex-direction: column; justify-content: end; align-items: start; width: 240px;">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true">
            <mat-expansion-panel-header style="padding: 0">
              <div style="font-weight: 200; display: flex; align-items: center; width: 100%; text-transform: none;">
                <button (click)="copyToken()" mat-icon-button><mat-icon>account_circle</mat-icon></button>
                <div style="flex: 1">{{getUser()?.username}}</div>
                <mat-icon [fontSet]="panelOpenState ? 'material-icons' : 'material-icons-outlined'"  style="font-size: 16px; line-height: 24px;">settings</mat-icon>
              </div>
            </mat-expansion-panel-header>
            <button (click)="togglePlayerVisibility()" mat-menu-item>Player Show/Hide</button>
          </mat-expansion-panel>
        </mat-accordion>

<!-- 
        <div style="font-weight: 200; display: flex; align-items: center; width: 100%;">
          <button (click)="copyToken()" mat-icon-button><mat-icon>account_circle</mat-icon></button>
          <div style="flex: 1">{{getUser()?.username}}</div>
          <button (click)="copyToken()" mat-icon-button><mat-icon style="font-size: 16px; line-height: 24px;">settings</mat-icon></button>
        </div> -->
        <a *ngIf="isLoggedIn()" routerLink="/logout" routerLinkActive="active" mat-menu-item>
          Logout
        </a>
      </div>
   
    </mat-sidenav>
	
    <mat-toolbar style="gap: 16px; background-color: black; justify-content: start;">
      <div class="logo-block">
        <a mat-icon-button routerLink="/" href="/">
          <mat-icon svgIcon="4zzz-icon"></mat-icon>
        </a>
      </div>
      
      <div appNavScroll #navItems class="nav-items" style="flex: 1">
<!-- 
        <div *ngIf="leftVisible" (click)="shiftRight()" style="display: inline-block; height: 36px; width: 16px; text-align: left; position: sticky; left: 0; background-color: black; z-index: 2; cursor: pointer;">⏴</div> -->

        <button *ngIf="requireAccess('librarian') || requireAccess('music')" [matMenuTriggerFor]="musicMenu" mat-button>Music</button>
        <mat-menu #musicMenu="matMenu" class="main-menu">
          <a routerLink="/releases/browse" routerLinkActive="active" mat-menu-item>
            Digital Pigeonhole
          </a>
          <a routerLink="/releases/archive" routerLinkActive="active" mat-menu-item>
            Archive
          </a>
          <a *ngIf="requireAccess('music')" routerLink="/releases/search" routerLinkActive="active" mat-menu-item>
            Search
          </a>
          <a *ngIf="requireAccess('music')" routerLink="/releases/new" routerLinkActive="active" mat-menu-item>
            Add New Release
          </a>
          <a *ngIf="requireAccess('librarian')" routerLink="/merge/artists" routerLinkActive="active" mat-menu-item>
            Merge artists
          </a>
        </mat-menu>

        <a *ngIf="requireAccess('public') && !requireAccess('music') && !requireAccess('librarian')" routerLink="/releases/browse" routerLinkActive="active" mat-button>
          Digital Pigeonhole
        </a>

        <a *ngIf="requireAccess('public') && !requireAccess('music') && !requireAccess('librarian')"  routerLink="/releases/archive" routerLinkActive="active" mat-button>
          Archive
        </a>

        <a *ngIf="requireAccess('announcer')" routerLink="/announcers/programs" routerLinkActive="active" mat-button>
          Show Playlists
        </a>

        <a *ngIf="requireAccess('announcer')" routerLink="/announcers/temporary" routerLinkActive="active" mat-button>
          Temporary
        </a>

        <a *ngIf="requireAccess('announcer')" routerLink="/zed-digital" routerLinkActive="active" mat-button>
          Zed Digital
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/zedders/search" routerLinkActive="active" mat-button>
          Zedders
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/orders" routerLinkActive="active" mat-button>
          Orders
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/subscribers/search" routerLinkActive="active" mat-button>
          Subscribers
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/volunteers/search" routerLinkActive="active" mat-button>
          Volunteers
        </a>

        <a *ngIf="requireAccess('reception')" routerLink="/contacts/search" routerLinkActive="active" mat-button>
          Contacts
        </a>

        <a *ngIf="requireAccess('public') && !requireAccess('admin')" routerLink="/sponsorship/spot-roster" routerLinkActive="active" mat-button>
          Sponsorship
        </a>

        <button *ngIf="requireAccess('admin')" [matMenuTriggerFor]="sponsorshipMenu" mat-button>Sponsorship</button>
        <mat-menu #sponsorshipMenu="matMenu" class="main-menu">
          <a routerLink="/sponsorship/spot-roster" routerLinkActive="active" mat-menu-item>
            Spot Roster
          </a>
          <a routerLink="/sponsorship" routerLinkActive="active" mat-menu-item>
            Campaigns
          </a>
          <a routerLink="/sponsorship/schedule" routerLinkActive="active" mat-menu-item>
            Schedule
          </a>
        </mat-menu>

        <button *ngIf="requireAccess('admin')" [matMenuTriggerFor]="reportsMenu" mat-button>Reports</button>
        <mat-menu #reportsMenu="matMenu" class="main-menu">
          <a routerLink="reports/subscribers" routerLinkActive="active" mat-menu-item>
            Subscribers
          </a>
          <a routerLink="reports/orders" routerLinkActive="active" mat-menu-item>
            Orders
          </a>
          <a routerLink="reports/programs" routerLinkActive="active" mat-menu-item>
            Programs
          </a>
        </mat-menu>

        <button *ngIf="requireAccess('admin')" [matMenuTriggerFor]="mangageMenu" mat-button>Manage</button>
        <mat-menu #mangageMenu="matMenu" class="main-menu">
          <button mat-menu-item [matMenuTriggerFor]="tags">Tags</button>
          <button mat-menu-item [matMenuTriggerFor]="merging">Merging</button>
          <button mat-menu-item [matMenuTriggerFor]="admin">Admin</button>
          <a routerLink="/zedletter" mat-menu-item>
            Zedletter
          </a>
          <button mat-menu-item [matMenuTriggerFor]="programming">Programming</button>
        </mat-menu>

        <mat-menu #tags="matMenu" class="main-menu">
          <a routerLink="/manage/subscribers" routerLinkActive="active" mat-menu-item>
            Subscribers
          </a>
          <a routerLink="/manage/volunteers" routerLinkActive="active" mat-menu-item>
            Volunteers
          </a>
          <a routerLink="/manage/contacts" routerLinkActive="active" mat-menu-item>
            Contacts
          </a>
          <a routerLink="/manage/releases" routerLinkActive="active" mat-menu-item>
            Music Library
          </a>
        </mat-menu>

        <mat-menu #merging="matMenu" class="main-menu">
          <a routerLink="/merge/contacts" routerLinkActive="active" mat-menu-item>
            Merge Contacts
          </a>
          <a routerLink="/merge/zedders" routerLinkActive="active" mat-menu-item>
            Merge Zedders
          </a>
          <a routerLink="/merge/artists" routerLinkActive="active" mat-menu-item>
            Merge Artists
          </a>
        </mat-menu>

        <mat-menu #admin="matMenu" class="main-menu">
          <a routerLink="/manage/orders" routerLinkActive="active" mat-menu-item>
            Bulk Orders
          </a>
          <a routerLink="/donated-subscriptions" routerLinkActive="active" mat-menu-item>
            Donatated Subs
          </a>
          <a routerLink="/merch/inventory" routerLinkActive="active" mat-menu-item>
            Merch
          </a>
        </mat-menu>
        
        <mat-menu #programming="matMenu" class="main-menu">
          <a routerLink="/schedule" routerLinkActive="active" mat-menu-item>
            Schedule
          </a>
          <a routerLink="/zed-digital" routerLinkActive="active" mat-menu-item>
            Zed Digital
          </a>
          <a routerLink="/announcers/temporary" routerLinkActive="active" mat-menu-item>
            Temporary
          </a>
          <a routerLink="/related-artists" routerLinkActive="active" mat-menu-item>
            Astrology
          </a>
        </mat-menu>


        <a *ngIf="isLoggedIn()" routerLink="/logout" routerLinkActive="active" mat-button>
          Logout
        </a>

        <!-- <div *ngIf="rightVisible" (click)="shiftLeft()" style="display: inline-block; height: 36px; width: 16px; text-align: right; position: sticky; right: 0; background-color: black;
  z-index: 2; cursor: pointer;">⏵</div> -->

      </div>

      <app-on-air-studio *ngIf="requireAccess('announcer')"></app-on-air-studio>

      <button mat-icon-button  (click)="sidenav.toggle()">
        <mat-icon>
          menu
        </mat-icon>
      </button>

    </mat-toolbar>

    <router-outlet></router-outlet>
    <!-- <ui-view></ui-view> -->
  </mat-sidenav-container>
   
  <app-player></app-player>
