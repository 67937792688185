import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavouriteService {

  constructor() { }

  private subject = new Subject<any>();

  prepForBroadcast(message: any){
    this.subject.next(message);
  }
  
  sendMessage(message: any) {
    this.subject.next(message);
  }

  clearMessages() {
      this.subject.next(null);
  }

  onMessage(): Observable<any> {
      return this.subject.asObservable();
  }
}
