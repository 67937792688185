import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: any, field: string): any[] {
    if (Array.isArray(array)) {
      array.sort((a: any, b: any) => {
        let val_a = get(a, field);
        let val_b = get(b, field);
        val_a = !isNaN(val_a) ? Number(val_a) : 0;
        val_b = !isNaN(val_b) ? Number(val_b) : 0;
        if (val_a < val_b) {
          return -1;
        } else if (val_a > val_b) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    }
    else {
      return [];
    }
  }

}
