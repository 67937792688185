import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(value: any[], element: string): string {
    return value.map(i => i[element]).join(', ');
  }

}
