<mat-toolbar style="position: sticky; top: 0;">
  <mat-toolbar-row>
    <h2 style="flex: 1">Zedletter</h2>
    <button *ngIf="editor" mat-button (click)="closeEditor()" matTooltip="Close Editor">Close Editor</button>
    <button mat-button matTooltip="Save" (click)="saveEmail()">Save</button>
    <button (click)="isOpen = !isOpen" mat-button matTooltip="Open" cdkOverlayOrigin #trigger="cdkOverlayOrigin">Open</button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      (overlayOutsideClick)="isOpen = false"
    >
      <ul class="template-menu">
        <li (click)="openTemplate(template)" style="display: flex;" *ngFor="let template of templates">
          <div style="flex: 1; text-align: left;">{{template.filename}}</div><div style="font-style: italic;">{{template.mtime * 1000 | date: 'dd/MM/yy'}}</div>
        </li>
      </ul>
    </ng-template>
    <button (click)="newZL = true;" mat-button matTooltip="New">New</button>
  </mat-toolbar-row>
</mat-toolbar>
<section [hidden]="editor" class="mat-typography" style="padding: 16px; background-color: #212121;">
  <div *ngIf="newZL">
    <h3>Select Template</h3>
    <button (click)="selectTemplate('zedletter')" mat-button>Basic Zedletter</button>
    <button (click)="selectTemplate('tnfka')" mat-button>TNFKA</button>
  </div>
  <mat-card [formGroup]="zedletterControl">
    <mat-card-content style="display: flex; flex-direction: column; gap: 16px;">
      <mat-form-field class="full-width">
        <mat-label>From</mat-label>
        <input matInput formControlName="from">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>To</mat-label>
        <textarea matInput formControlName="to"></textarea>
        <mat-hint>
          Multiple recipients need to be on individual lines, no commas!
        </mat-hint>
        <button matTooltip="Import Contacts" matSuffix mat-icon-button [matMenuTriggerFor]="menu" class="example-standalone-trigger"><mat-icon>import_contacts</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item disabled>Contact List</button>
          <button mat-menu-item (click)="dummy()">Dummy
          </button>
          <button mat-menu-item (click)="generateZedletterEmails()">Zedletter</button>
          <button mat-menu-item (click)="activeSubsList()">Active Subscribers</button>
        </mat-menu>
  
      </mat-form-field>


      <mat-form-field class="full-width">
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject">
      </mat-form-field>

      <div style="display: flex; align-items: center;">
        <label style="flex: 1; margin-left: 16px; font-size: 12px; color: #ffffffa0">Body</label>
        <button matTooltip="Open in Editor" mat-icon-button (click)="editor = !editor;">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <iframe style="display: block; background-color: white; color: black; border: 0; margin: 0; padding: 0; width: 100%; height: 400px" id="preview" [srcdoc]="newTemplate"></iframe>
      
      <!-- <div style="display: block; background-color: white; color: black; border: 0; margin: 0; padding: 0;" [innerHTML]="template"></div> -->
    </mat-card-content>

    <mat-card-actions style="flex-direction: column; align-items: start; gap: 8px;">
      <mat-progress-bar mode="indeterminate" *ngIf="sending"></mat-progress-bar>
      <button [disabled]="!zedletterControl.valid" mat-raised-button color="primary" (click)="confirmSend(zedletterControl.value.to)">
        Send
        <mat-icon>send</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
  

</section>
<div [hidden]="!editor" [innerHTML]="template" #gjs id="gjs"></div>

