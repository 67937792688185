import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sponsorship } from '../sponsorship';
import { Location } from "@angular/common";
import { superset } from 'd3';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { groupBy } from 'lodash';
import { CampaignService } from '../campaign.service';
import { environment } from 'src/environments/environment';
import { PlaylistService } from '../playlist.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent extends Sponsorship{

  constructor(
    private http: HttpClient,
    public location: Location,
    // @Inject('$rootScope') private $rootScope: any,
    private CampaignService: CampaignService,
    private PlaylistService: PlaylistService,
    private router: Router
  ) {
    super();
    this.fetchCampaigns()

    this.filterForm.valueChanges.subscribe( () => {
      console.log(this.filterForm.value)
      const spots = this.spots.filter(this.filterByCampaignType)
      const filteredSpots = spots.filter(this.filter)
      this.filteredGiveaways = this.giveaways.filter(this.filter)
      this.filteredLiveReads = this.liveReads.filter(this.filter)
      this.grouped = Object.values( groupBy(filteredSpots, 'campaign_cat') );
    });
  }

  filterForm = new UntypedFormGroup({
    searchTerm:  new UntypedFormControl(null),
    cat: new UntypedFormControl([]),
    sort: new UntypedFormControl(),
    archived: new UntypedFormControl(false),
  })
  filterAll = true;

  archived = false;
  spots: any[] = [];
  liveReads: any[] = [];
  filteredLiveReads: any[] = [];
  giveaways: any[] = [];
  filteredGiveaways: any[] = [];
  all: any[] = [];
  grouped: any[] = [];

  fetchCampaigns() {
    const spotsQuery = this.http.get<any>(environment.apiSrc + '/campaign/1');
    const liveReadsQuery = this.http.get<any>(environment.apiSrc + '/campaign/2');
    const giveawaysQuery = this.http.get<any>(environment.apiSrc + '/campaign/3');

    forkJoin(
      spotsQuery,
      liveReadsQuery,
      giveawaysQuery
    ).subscribe( data => {
      this.spots = data[0];
      const spots = data[0].filter(this.filterByCampaignType)
      const filteredSpots = spots.filter(this.filter)
      this.grouped = Object.values( groupBy(filteredSpots, 'campaign_cat') );

      this.liveReads = data[1];
      this.giveaways = data[2];
      this.all = [...data[0], ...data[1], ...data[2]];

      this.filteredGiveaways = this.giveaways.filter(this.filter)
      this.filteredLiveReads = this.liveReads.filter(this.filter)
    })
  }

  playTemp (filename) {
    let item = {filename: filename, share: 'fela'};
    this.PlaylistService.prepForBroadcast({action: 'queueTempAndPlay', playlist: [ item ]})
    // let file = item;
    // let url = environment.musicDBapiSrc + '/temporary/dl'
    // this.http.post<any>(url, { file: file, share: 'fela' }).subscribe( data => {
    //   var token = data.token
    //   var url = environment.dbUrl + '/fela/' + token
    //   this.$rootScope.player.src = url;
    //   this.$rootScope.player.play()
    // });
  }

  filterByCampaignType = (item, index) => {
    return this.filterForm.controls['cat'].value.length === 0  || this.filterForm.controls['cat'].value.includes(this.campaignCat[item.campaign_cat]);
  };

  filter = (item, index) => {
    let search, date;

    if (this.filterForm.controls['archived'].value === true) {
      date = true;
    } else {
      let today = new Date();
      today.setUTCHours(0,0,0,0);
  
      let campaignDate = new Date(item.complete_date)
      item.isCurrent = campaignDate >= today;
      date = campaignDate >= today;
    }
    
    if (this.filterForm.controls['searchTerm'].value && this.filterForm.controls['searchTerm'].value !== '' ) {
      search = (item.title && item.title.toLowerCase().includes(this.filterForm.controls['searchTerm'].value.toLowerCase())) || (item.promo && item.promo.contact && item.promo.contact.org_nm && item.promo.contact.org_nm.toLowerCase().includes(this.filterForm.controls['searchTerm'].value.toLowerCase()) )
    }
    else {
      search = true;
    }

    return date && search;
  }

  addCampaign() {
    this.router.navigateByUrl('/sponsorship/new/promo');
  }
}
