const template = `
<table width="100%" bgcolor="#000" style="box-sizing: border-box; padding: 0; margin: 0; width: 100%; background: #000 url(https://zedletter.4zzz.org.au/skinning.jpg) repeat scroll 0 0; background-color: #000; background-image: url(http://zedletter.4zzz.org.au/skinning.jpg); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto;" background="http://zedletter.4zzz.org.au/skinning.jpg">
   <tbody style="box-sizing: border-box;">
      <tr bgcolor="#000" style="box-sizing: border-box; background-image: url(none); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto; background-color: #000;" background="none">
         <td style="box-sizing: border-box; background-image: url(http://zedletter.4zzz.org.au/skinning.jpg); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto;" background="http://zedletter.4zzz.org.au/skinning.jpg">
            <table border="0" width="713" cellspacing="0" cellpadding="10" style="box-sizing: border-box; padding: 10px; margin: 0 auto; background: #fff url(https://zedletter.4zzz.org.au/none) repeat scroll 0 0; border: 0 solid #eee; width: 713px; min-height: 500px;">
               <tbody style="box-sizing: border-box;">
                  <tr style="box-sizing: border-box;">
                     <td style="box-sizing: border-box; paddinhg-bottom: 10px; text-align: center;" align="center">
                        <img src="http://zedletter.4zzz.org.au/nfkaheader.png" alt="" width="600px" style="box-sizing: border-box;">
                     </td>
                  </tr>
                  <tr style="box-sizing: border-box; min-height: 400px;">
                     <td style="box-sizing: border-box; border-top: 7px solid #000;">
                        <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;" width="100%" height="150">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box;">
                                 <td style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: top;" valign="top">
                                    <div style="box-sizing: border-box; padding: 10px;">
                                       <p style="box-sizing: border-box; text-align: center;">
                                          <em style="box-sizing: border-box;">
                                          <span style="box-sizing: border-box; font-size: 11px;">Welcome to 4ZZZ’s weekly&nbsp;newsletter&nbsp;for announcers and volunteers.
                                          <br style="box-sizing: border-box;">
                                          <br style="box-sizing: border-box;">It can be difficult to keep on top of all of the missives that 4ZZZ HQ disseminates week to week, so in the interest of keeping your inboxes under control and our messaging clear, we’ll be doing our darndest to compile all of our comms into one weekly mailout. ​​​​​​​
                                          <br style="box-sizing: border-box;">
                                          <br style="box-sizing: border-box;">​​​​​​​Obviously there will be exceptions from time to time, but consider The&nbsp;Newsletter&nbsp;Formerly&nbsp;Known As your Monday afternoon treat (named so because we expect you to be able to&nbsp;
                                          <a data-cke-saved-href="https://www.youtube.com/watch?v=jJ1l4UJW04Y" href="https://www.youtube.com/watch?v=jJ1l4UJW04Y" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.youtube.com/watch?v%3DjJ1l4UJW04Y&amp;source=gmail&amp;ust=1601345022175000&amp;usg=AFQjCNGux9lWsvAiG4wG7oGnVMLee-WAkQ" style="box-sizing: border-box;">regurgitate</a>&nbsp;the information herein).
                                          </span>
                                          </em>
                                       </p>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;" width="100%" height="150">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box;">
                                 <td style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: top;" valign="top">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="http://zedletter.4zzz.org.au/covid19header.png" alt="placeholder" width="600" height="200" style="box-sizing: border-box; width: 600px; height: 200px; margin: 0px 0px 0px 30px;">
                                    </a>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;" width="100%" height="150">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box;">
                                 <td style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: top;" valign="top">
                                    <div style="box-sizing: border-box; padding: 10px;">
                                       <p style="box-sizing: border-box;">Insert your text here</p>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="http://placehold.it/280x280/78c5d6/fff/" alt="placeholder" width="280" height="280" style="box-sizing: border-box;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 160px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px;" valign="top">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                                 <td style="box-sizing: border-box; padding: 8px 8px 8px 0;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="http://placehold.it/140x140/78c5d6/fff/" alt="placeholder" width="300" height="300" style="box-sizing: border-box; width: 300px; height: 300px;">
                                    </a>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="http://placehold.it/280x280/78c5d6/fff/" alt="placeholder" width="280" height="280" style="box-sizing: border-box;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="http://placehold.it/280x280/78c5d6/fff/" alt="placeholder" width="280" height="280" style="box-sizing: border-box;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="http://placehold.it/280x280/78c5d6/fff/" alt="placeholder" width="280" height="280" style="box-sizing: border-box;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <h2 style="box-sizing: border-box; font-family: Verdana, Geneva, sans-serif;">A major heading</h2>
                        <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;" width="100%" height="150">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box;">
                                 <td style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: top;" valign="top">
                                    <div style="box-sizing: border-box; padding: 10px;">
                                       <p style="box-sizing: border-box; text-align: center;">
                                          <i style="box-sizing: border-box;">Thanks for playing along! If you have any big interviews, noteworthy subs shoutouts or particularly heroic volunteering work that deserves a shout out, let me know by emailing manager@4zzz.org.au by 2pm Mondays. Got any burning questions about the studio, station policy or other FAQs? Check the
                                          <a data-cke-saved-href="https://wiki.4zzz.org.au/index.php/Main_Page" href="https://wiki.4zzz.org.au/index.php/Main_Page" style="box-sizing: border-box;">4ZZZ Wiki</a> out, if accessing from outside of the station use the following loging credentials: zeduser / 4zzzrules. Have a great week connecting and amplifying the voices of our local communities!
                                          </i>
                                       </p>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr style="box-sizing: border-box;">
                     <td colspan="3" align="center" height="30" style="box-sizing: border-box; text-align: center; border-top: 7px solid #000; background: #fff;">
                        <a href="http://twitter.com/4ZZZ" target="_blank" style="box-sizing: border-box;"></a>
                        <a href="https://www.facebook.com/4ZZZfm" target="_blank" style="box-sizing: border-box;"></a>
                        <a title="Instagram" href="http://instagram.com/4zzzradio" target="_blank" style="box-sizing: border-box;"></a>
                        <br style="box-sizing: border-box;">
                     </td>
                  </tr>
                  <tr style="box-sizing: border-box;"></tr>
               </tbody>
            </table>
            <p style="box-sizing: border-box;"></p>
         </td>
      </tr>
   </tbody>
</table>
<p style="box-sizing: border-box; display: none;"></p>
<p style="box-sizing: border-box;"></p>
`;

export default template;