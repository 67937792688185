import { Pipe, PipeTransform } from '@angular/core';
import { secondsToHMS } from './helpers';
@Pipe({
  name: 'hms'
})
export class HmsPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    return secondsToHMS(value);
  }

}
