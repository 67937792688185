<mat-sidenav-container class="description-container">

  <mat-sidenav #editDrawer [fixedInViewport]="true" fixedTopGap="64" mode="over" position="end">
    <h2 class="mat-h2">Tags</h2>
    <form [formGroup]="interestForm" class="intersest-form" (ngSubmit)="onSubmit()">
      <mat-form-field class="form-full-width" appearance="fill">
        <mat-label>ID</mat-label>
        <input [readonly]="true" matInput placeholder="ID" formControlName="theme_id">
      </mat-form-field>
  
      <mat-form-field class="form-full-width" appearance="fill">
        <mat-label>Tag</mat-label>
        <input matInput placeholder="Tag" formControlName="theme_desc">
      </mat-form-field>
      <button type="submit" mat-raised-button color="primary">Submit</button>
    </form>
  </mat-sidenav>

  <mat-sidenav-content>

    <table mat-table [dataSource]="sortedData" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
      <!-- Position Column -->
      <ng-container matColumnDef="theme_id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.theme_id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="theme_desc">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.theme_desc}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let element">
          <button class="edit-button" mat-icon-button (click)="editRow(element)" aria-label="Edit">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <button (click)="addRow()" mat-mini-fab color="warn" aria-label="Add Training">
            <mat-icon>add</mat-icon>
          </button>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns">Test</tr>
      
    </table>    

  </mat-sidenav-content>


</mat-sidenav-container>
