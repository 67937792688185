<mat-toolbar style="position: sticky; top: 0; z-index: 150; min-height: 84px; height: auto;">
  <div style="display: flex; width: 100%;">
    <div style="flex: 1; ">
      <h2><a [routerLink]="'/zedders/' + order.zedder_id"> {{order.zedder.firstname}} {{order.zedder.lastname}}</a></h2>
      <ul class="toolbar-details">
        <li>
          <strong>Order ID #:</strong> {{order.order_id}}
        </li>
        <li>
          <strong>Zedder #:</strong><a [routerLink]="'/zedders/' + order.zedder_id"> {{order.zedder_id}}</a>
        </li>
        <li>
          <strong>Invoice #:</strong> {{order.invoice_no}}
        </li>
        <li>
          <strong>Created:</strong> {{order.created_at  | date:'medium'}}
        </li>
        <li>
          <strong>Last Updated:</strong> {{order.updated_at | date:'medium'}}
        </li>
      </ul>
    </div>
    <div style="display: flex; align-items: center; gap: 16px;">
      <button mat-raised-button (click)="process(order.order_id)" [color]="order.processed === 'completed' ? 'primary' : order.processed === 'checked' ? 'accent' :  order.processed === 'ready' ? 'primary lighter' :   order.processed === 'processing' ? 'accent' : order.processed === 'fix' ? 'warn': 'warn'">{{(order.processed === 'completed') ? 'COMPLETED' : (order.processed === 'processing') ? 'PROCESSING' : (order.processed === 'fix') ? 'FIX' : (order.processed === 'ready') ? 'TO PICKUP' : (order.processed === 'checked') ? 'CHECKED' : 'NEW' }}</button>
      <button mat-fab matTooltip="Delete Order"  color="warn" (click)="deleteOrder(order.order_id)"><mat-icon>delete_forever</mat-icon></button>
      <button mat-fab matTooltip="Print Subcard" color="accent" (click)="fetchPrintableSubcards(order.order_id)"><mat-icon svgIcon="subcard"></mat-icon></button>
      <button mat-fab matTooltip="Print Invoice" color="primary" (click)="fetchInvoice(order.order_id)"><mat-icon>receipt_log</mat-icon></button>
      <button mat-fab matTooltip="Print Packing Slip" color="accent" (click)="fetchPackingSlip(order.order_id)"><mat-icon>print</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<div class="mat-typography details-container">
  <div style="flex: 1">
    <div style="display: flex; flex-direction: column; gap: 8px;">
      <mat-card>
        <mat-card-header>
          <mat-card-title><a [routerLink]="'/orders/' + order.order_id">Order #{{order.order_id}}</a><span *ngIf="order.items_json" style="float: right">{{order.items_json.length}} {{order.items_json.length > 1 ? 'items' : 'item'}} {{order.hasMerch ? 'with merch' : ''}}</span></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div style="margin: 16px">
            <button mat-raised-button *ngIf="order.transaction[order.transaction.length - 1].service === 'Pledge'" color="primary" style="float: right" (click)="showPrompt($event, order.order_id)">
              PAY PLEDGE
            </button>
            <div><b>Email:</b> {{order.zedder.email}}</div>
            <div><b>Phone:</b> {{order.zedder.phone}}</div>
            <div *ngIf="order.zedder.delivery_address">
              <b>Address:</b> {{ order.zedder.delivery_address.address}}, {{ order.zedder.delivery_address.locality}} {{ order.zedder.delivery_address.region}} {{ order.zedder.delivery_address.postcode}}
            </div>
           
            <div style="text-decoration: underline;" *ngIf="order.station_pickup"><b>Station Pickup</b></div>
          </div>

          <mat-form-field style="width: 100%; margin-top: 0px; margin-bottom: 16px;">
            <mat-label>Reception Notes</mat-label>
            <textarea cdkTextareaAutosize cdkAutosizeMinRows="2"  matInput [formControl]="notes"></textarea>
            <mat-hint>Notes appear in the packing slip</mat-hint>
          </mat-form-field>

        </mat-card-content>
      </mat-card>

      <mat-card *ngFor="let sub of order.subscriptions" [style.backgroundColor]="sub.subscription.subtypeid === 8 ? 'rgb(140, 41, 41)' : sub.subscription.subtypeid === 17 ? '#ff9130' : 'rgb(20, 94, 130)'">
        <mat-card-header>
          <mat-card-title><a [routerLink]="'/subscribers/' + sub.subid">{{sub.subscription.subtypecode}}<span style="float: right">#{{sub.subnumber}}</span></a></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div><b>Name:</b> {{sub.subfirstname}} {{sub.sublastname}}</div>
          <div><b>Nameoncard:</b> {{sub.nameoncard}}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="items-container">
    <mat-card *ngFor="let item of order.items_json">
      <mat-card-header>
        <mat-card-title>{{item.name}}<span style="float: right">{{item.itemPrice}}</span></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let param of item.param | keyvalue; index as i;">
          <span *ngIf="param.value && (param.key !== 'members')"><b>{{param.key}}:</b> {{param.value}}</span>
          <ng-container *ngIf="param.key === 'members'">
            <div *ngFor="let member of $any(param).value; index as m;">
              <div *ngIf="member && m === 0">
                <b>members:</b>
              </div>
              
              <div style="padding-left: 8px;" *ngIf="member">
                {{member}}
              </div>
            </div>
            <!-- <div *ngFor="let member of param.value" ></div> -->
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
<!-- 
    <mat-card style="margin: 16px;">
      <mat-card-header>
        <mat-card-subtitle>Subtotal<span style="float: right">{{ order.subtotal }}</span></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
    </mat-card> -->

    <mat-card *ngIf="order.shipping_price > 0">
      <mat-card-header>
        <mat-card-title>Postage<span style="float: right">{{ order.shipping_price }}</span></mat-card-title>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title style="font-weight: 900;">TOTAL<span style="float: right">{{ order.total }}</span></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div><b>Payment Method:</b> {{order.transaction[order.transaction.length - 1].service}}</div>
        <div><b>Transaction Status:</b> {{order.transaction[order.transaction.length - 1].capture_status}}</div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="floating-fab" [hidden]="notes.pristine">
  <button [disabled]="submitLocked" (click)="sendNotes()" mat-fab color="primary" aria-label="Save Subscriber">
    <mat-icon>edit</mat-icon>
  </button>
</div>