<h2 mat-dialog-title>Save</h2>
<mat-dialog-content>
	<mat-form-field class="example-full-width">
		<mat-label>Save as</mat-label>
		<input matInput [(ngModel)]="data.filename">
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="abort">Cancel</button>
  <button mat-button [mat-dialog-close]="data.filename" cdkFocusInitial>Save</button>
</mat-dialog-actions>