<h2 mat-dialog-title>{{data.playlist ? 'Edit Playlist' : 'New Playlist' }}</h2>
<mat-dialog-content class="mat-typography" [formGroup]="playlistForm">
	<mat-form-field class="full-width">
		<mat-label>Playlist Name</mat-label>
		<input matInput formControlName="name">
	</mat-form-field>

  <div style="position: relative; width: 272px;  margin-bottom: 24px;">
    <label for="coverDropRef" style="color: black; margin-top: 20px; width: 272px; height: 272px; background-position: 50% 50%;" class="square" appFileDnd [accept]="'image/*'" (fileDropped)="onCoverDropped($event)">
      <div style="width: 272px; height: 272px; background-color: #e6e6e6; background-size: cover; background-position: center; display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline;" [style.backgroundImage]="'url( ' + coverImage  + ')'" class="square-content">
        <span *ngIf="!coverImage">Upload Cover</span>
      </div>
    </label>
  </div>
  <input style="display: none;" type="file" #coverDropRef [accept]="'image/*'" id="coverDropRef" (change)="coverBrowseHandler($event.target)" />


  <mat-form-field class="full-width">
		<mat-label>Contributors</mat-label>
		<mat-chip-grid #contributorChip aria-label="Announcer">
			<mat-chip-row *ngFor="let contributor of playlistForm.get('contributors')!.value"
										(removed)="removeContributor(contributor)"
				>
				{{contributor.firstname}} {{contributor.lastname}} {{contributor.id}}
				<button matChipRemove [attr.aria-label]="'remove ' + contributor.name">
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
		</mat-chip-grid>
		<input placeholder="Search Contributors..."
			#contributorInput
			[formControl]="contributorControl"
			[matAutocomplete]="contributorComplete"
			[matChipInputFor]="contributorChip"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			/>
		<mat-autocomplete 
			autoActiveFirstOption 
			#contributorComplete="matAutocomplete" 
			(optionSelected)="selectedContributor($event)">
			<mat-option *ngFor="let contributor of contributorOptions | async" [value]="contributor">
				{{contributor.firstname}} {{contributor.lastname}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

  <mat-checkbox formControlName="pin">Pin</mat-checkbox>

  <mat-checkbox formControlName="archive">Archive</mat-checkbox>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="abort">Cancel</button>
    <button *ngIf="!saved" mat-button (click)="savePlaylist()" cdkFocusInitial>{{  data.playlist ? 'Edit' : 'Save' }}</button>
    <button *ngIf="saved" mat-button [mat-dialog-close]="playlistForm.value" cdkFocusInitial>Close</button>
  </mat-dialog-actions>