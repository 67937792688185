import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { ProgramsService } from '../programs.service';

export interface Interests {
  programid: number;
  programname: string;
  programdate: string;
  programtime: string;
  active: boolean;
}
 
@Component({
  selector: 'programs',
  templateUrl: './manage-programs.component.html',
  styleUrls: ['./manage-programs.component.scss']
})

export class ManageProgramsComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    programid: new UntypedFormControl({ value: 0, disabled: true }),
    programname: new UntypedFormControl(''),
    programdate: new UntypedFormControl(''),
    programtime: new UntypedFormControl(''),
    active: new UntypedFormControl(false)
  });
  lastSort: Sort = { active: "programname", direction: "asc" };
  editInterests: Interests = {programname: '', programdate: '', programid: 0,  programtime: '', active: false};
  subCategories: Interests[] = [];
  sortedData: Interests[];
  displayedColumns: string[] = ['programid', 'programname', 'programdate', 'programtime', 'active', 'edit'];

  constructor(public ProgramsService: ProgramsService) {
    this.sortedData = this.subCategories.slice();
    // this.InterestsService = InterestsService;
    ProgramsService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'programid':
          return compare(a.programid, b.programid, isAsc);
        case 'programname':
          return compare(a.programname, b.programname, isAsc);
        case 'programdate':
          return compare(a.programdate, b.programdate, isAsc);
        case 'programtime':
          return compare(a.programtime, b.programtime, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      programid: el.programid,
      programname: el.programname,
      programdate: el.programdate,
      programtime: el.programtime,
      active: el.active
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      programid: 0,
      programname: '',
      programdate: '',
      programtime: '',
      active: false
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().programid === 0) { //new
      let subprogramname: any = {};
      subprogramname.programname =  this.interestForm.value.programname;
      subprogramname.programdate =  this.interestForm.value.programdate;
      subprogramname.programtime =  this.interestForm.value.programtime;
      subprogramname.active = this.interestForm.value.active;
      this.ProgramsService.save(subprogramname).subscribe(res => {
        this.ProgramsService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      this.ProgramsService.get(this.interestForm.getRawValue().programid).subscribe(subprogramname => {
        subprogramname.programname =  this.interestForm.value.programname;
        subprogramname.programdate =  this.interestForm.value.programdate;
        subprogramname.programtime =  this.interestForm.value.programtime;
        subprogramname.active = this.interestForm.value.active;
        this.ProgramsService.update(subprogramname).subscribe(response => { 
          let interests : Interests[] = [ {
            programid: response.programid,
            programname: response.programname,
            programdate: response.programdate,
            programtime: response.programtime,
            active: response.active
          }]
    
          const existing = this.subCategories.reduce((a,t)=> (a[t.programid] = t, a), {}),
          changed = interests.reduce((a,t)=> (a[t.programid] = t, a), {})
    
          this.subCategories = Object.values({...existing, ...changed})
          // this.sortedData = this.subCategories.slice(); 
          this.sortData(this.lastSort);
        });
       });
      
    }
   
  }
  
}
