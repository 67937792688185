import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { ThemesService } from '../themes.service';

export interface Themes {
  theme_id: number;
  theme_desc: string;
}
@Component({
  selector: 'app-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    theme_id: new UntypedFormControl({ value: 0, disabled: true }),
    theme_desc: new UntypedFormControl('')
  });
  lastSort: Sort = { active: "theme_desc", direction: "asc" };
  editQualifications: Themes = {theme_desc: '', theme_id: 0};
  subCategories: Themes[] = [];
  sortedData: Themes[];
  displayedColumns: string[] = ['theme_id', 'theme_desc', 'edit'];

  constructor(public ThemesService: ThemesService) {
    this.sortedData = this.subCategories.slice();
    // this.QualificationsService = QualificationsService;
    ThemesService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'theme_id':           
          return compare(a.theme_id, b.theme_id, isAsc);
        case 'theme_desc':
          return compare(a.theme_desc, b.theme_desc, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      theme_id: el.theme_id,
      theme_desc: el.theme_desc
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      theme_id: 0,
      theme_desc: ''
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().theme_id === 0) { //new
      let subskill: any = {};
      subskill.theme_desc = this.interestForm.getRawValue().theme_desc;
      this.ThemesService.save(subskill).subscribe(res => {
        this.ThemesService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      let subskill = this.ThemesService.get(this.interestForm.getRawValue().theme_id).subscribe( subskill => {
        subskill.theme_desc = this.interestForm.getRawValue().theme_desc;
        this.ThemesService.update(subskill).subscribe(response => { 
          let interests : Themes[] = [ {
            theme_id: response.theme_id,
            theme_desc: response.theme_desc
          }]
    
          const existing = this.subCategories.reduce((a,t)=> (a[t.theme_id] = t, a), {}),
          changed = interests.reduce((a,t)=> (a[t.theme_id] = t, a), {})
    
          this.subCategories = Object.values({...existing, ...changed})
          // this.sortedData = this.subCategories.slice(); 
          this.sortData(this.lastSort);
        });

       });
      
    }
   
  }
  
}
