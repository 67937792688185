import { Component, Input, Inject } from '@angular/core';
import { any } from '@uirouter/core';
import { playlistToM3U } from '../helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';
import { StudioService } from '../studio.service';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-album-track-list',
  templateUrl: './album-track-list.component.html',
  styleUrls: ['./album-track-list.component.scss']
})
export class AlbumTrackListComponent {
  @Input() tracks!: any[];
  @Input() favourite!: (arg0: any, arg1: any) => void;
  @Input() play!: (arg0: any, arg1: any) => void;
  @Input() order!: (a: any, b: any) => number;

  constructor(
    // @Inject('$rootScope') private $rootScope: any,
    private socket: SocketService,
    private http: HttpClient,
    private AuthenticationService: AuthenticationService,
    private StudioService: StudioService
  ){
    this.onAir = this.StudioService.getOnAir()
    this.studio = this.StudioService.getStudio()
    this.StudioService.onMessage().subscribe( (data: any) => { 
      if(data.onAir)
        this.onAir = data.onAir; 
      if (data.studio)
        this.studio = data.studio;
    });
  }

  onAir = false;
  iconMairlist = '';
  studio = 0;
  tools = 0;
  announcer = false;

  ngOnInit() {
    const defaultOrder = (a,b) => a.discnum - b.discnum || a.tracknum - b.tracknum || a.title_id - b.title_id;
    this.order = this.order ? this.order : defaultOrder;
  }

  ngOnChanges(changes) {
    // console.log(changes)
    console.log(this.tracks)
    this.tracks.forEach(a => {a.showDetails = false})
    this.tracks = this.tracks.sort(this.order);
    this.tools = 0;
    let userId = this.AuthenticationService.getUser()?.details?.id;
    this.announcer = userId ? true : false;
  }

  trackSelected(track) {
    console.log(track)
    this.tracks.forEach(a => {a.showDetails = false})

  }

  showTools(track: any) {
    if(track.showDetails) {
      track.showDetails = !track.showDetails;
      this.tools = 0;
    }
    else {
      this.tracks.map(i => i.showDetails = false);
      track.showDetails = !track.showDetails;
      this.tools = 1;
    }
  }

  languageWarning = (track: any) => {
    track.language_warning = !track.language_warning;
		this.http.get(environment.musicDBapiSrc + '/language-warning/' + track.title_id + '/' + track.language_warning).subscribe(data => {
      console.log(data);
    });;
    console.log(track);
  }

  contentWarning = (track: any) => {
		track.content_warning = !track.content_warning;
		this.http.get(environment.musicDBapiSrc + '/content-warning/' + track.title_id + '/' + track.content_warning).subscribe(data => {
      console.log(data);
    });;
    console.log(track);
  }

  copyToClipboard = (text: string) => {
    var link;
    if (navigator.platform.search('Win') !== -1) {
      console.log('win');
      link = text.replace(/\//g, '\\').replace('\\mnt\\fela', 'S:')
    }

    if (navigator.platform.search('Mac') !== -1) {
      console.log('mac');
      link = text.replace('/mnt/fela', '/Volumes/audio')
    }

    if (navigator.platform.search('Linux') !== -1) {
      console.log('lin');
      console.log(text);
      link = text;
    }

    console.log(navigator.platform);
    window.prompt("Copy to clipboard: Ctrl+C, Enter", link);
  }

  appendTrack = (track: any) => {
    if('start_time' in track) {
      track.file = ('//fela/temporary' + track.filename).replaceAll('/', '\\');
    }
    else {
      track.file = ((track.filepath + '/' + track.filename).replace('/mnt/fela', '//fela/audio')).replaceAll('/', '\\');
    }	
    let playlist = [ track ]

    let data = playlistToM3U(playlist);
    const headers = { 'Content-Type': 'application/json' };

    this.http.post(environment.musicDBapiSrc + '/save-playlist', { 
      file: 'studio'+ this.studio,
      destination: '/Tech', 
      playlist: data, share: 'temporary'
    }, {headers}).subscribe( (data: any) => {
      debugger;
      this.socket.send({ appendTrack: { 
        id: String(this.AuthenticationService.getUser().details.id).padStart(5, '0'), 
        studio: this.studio, 
        file: 'T:/Tech/Playlists/studio'+ this.studio + '.m3u' 
      } });
    }, error => {
      console.log(error);
    });
  }

}
