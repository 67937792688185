import { Component, Input, Output, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

export interface DialogData {
  file: string;
}

@Component({
  selector: 'app-simple-metadata-editor',
  templateUrl: './simple-metadata-editor.component.html',
  styleUrls: ['./simple-metadata-editor.component.scss']
})
export class SimpleMetadataEditorComponent {
	@Input() file?;
  
  constructor(
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ){

    this.http.post<any>(environment.musicDBapiSrc + '/info-metadata', { file: this.data.file }).subscribe(data => { 
      this.metadata.patchValue({
        file: data.file,
        path: data.path,
        title: data.title,
        album: data.album,
        artist: data.artist,
        albumartist: data.albumartist,
        date: data.date,
        year: data.year,
        tracknum: data.tracknum,}
      )
      this.fileMeta = data; 
      console.log(this.fileMeta); 
    });
  }


  saving = false;
  fileMeta: any;

  metadata = new UntypedFormGroup({
    file: new UntypedFormControl(null),
    path: new UntypedFormControl(null),
    title: new UntypedFormControl(null),
    album: new UntypedFormControl(null),
    artist: new UntypedFormControl(null),
    albumartist: new UntypedFormControl(null),
    date: new UntypedFormControl(null),
    year: new UntypedFormControl(null),
    tracknum: new UntypedFormControl(null),
  })

  saveTags = () => {
    this.saving = true;
    // console.log(this.metadata.value)
    this.http.post(environment.musicDBapiSrc + '/editMetaData', this.metadata.value ).subscribe(
      data => { 
      this.saving = false; 
      console.log(data);  
    });
  }

}
