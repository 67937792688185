import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDrawer } from '@angular/material/sidenav'
import { compare } from '../helpers'
import { ContactCategoriesService } from '../contact-categories.service';

export interface Interests {
  id: number;
  category: string;
}
 
@Component({
  selector: 'categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.scss']
})

export class ManageCategoriesComponent {

  @ViewChild('editDrawer') editDrawer!: MatDrawer;
  interestForm = new UntypedFormGroup({
    id: new UntypedFormControl({value: 0, disabled: true}),
    category: new UntypedFormControl('')
  });
  lastSort: Sort = { active: "category", direction: "asc" };
  editInterests: Interests = {category: '', id: 0};
  subCategories: Interests[] = [];
  sortedData: Interests[];
  displayedColumns: string[] = ['id', 'category', 'edit'];

  constructor(public ContactCategoriesService: ContactCategoriesService) {
    this.sortedData = this.subCategories.slice();
    // this.InterestsService = InterestsService;
    ContactCategoriesService.query().subscribe(response => { 
      this.subCategories = response; 
      this.sortData(this.lastSort);
    });
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.subCategories.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'category':
          return compare(a.category, b.category, isAsc);
        default:
          return 0;
      }
    });
  }

  editRow(el) {
    console.log(el);
    this.interestForm.patchValue({
      id: el.id,
      category: el.category
    });
    this.editDrawer.toggle();
  }

  addRow() {
    this.interestForm.patchValue({
      id: 0,
      category: ''
    });
    this.editDrawer.toggle();
  }

  onSubmit() {
    if(this.interestForm.getRawValue().id === 0) { //new
      let subcategory: any = {};
      subcategory.category = this.interestForm.value.category;
      this.ContactCategoriesService.save(subcategory).subscribe(res => {
        this.ContactCategoriesService.query().subscribe(response => { 
          this.subCategories = response; 
          this.sortedData = this.subCategories.slice(); 
        });
      });
    } else { //edit
      this.ContactCategoriesService.get(this.interestForm.getRawValue().id).subscribe(subcategory => {
        subcategory.category =  this.interestForm.value.category;
        this.ContactCategoriesService.update(subcategory).subscribe(response => { 
          let interests : Interests[] = [ {
            id: response.id,
            category: response.category
          }]
    
          const existing = this.subCategories.reduce((a,t)=> (a[t.id] = t, a), {}),
          changed = interests.reduce((a,t)=> (a[t.id] = t, a), {})
    
          this.subCategories = Object.values({...existing, ...changed})
          // this.sortedData = this.subCategories.slice(); 
          this.sortData(this.lastSort);
        });
       });
      
    }
   
  }
  
}
