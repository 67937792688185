import { Component, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { compare } from '../helpers'
import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription, interval, pairwise } from 'rxjs';
import { T, V } from '@angular/cdk/keycodes';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { FormControl, FormGroup } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';
import { groupBy } from 'lodash';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

function round(date, duration, method) {
  return moment(Math[method]((+date) / (+duration)) * (+duration));
}

function genColours(array: any[]) {
  return array.map(i => ('hsl('+ Math.floor(Math.random() * 360) +', 75%, 75%)') );
}

@Component({
  selector: 'app-orders-report',
  templateUrl: './orders-report.component.html',
  styleUrls: ['./orders-report.component.scss']
})
export class OrdersReportComponent implements AfterViewInit {
  @ViewChild( 'ordersTable' ) ordersTable!: MatTable<any>;
  @ViewChild( 'ordersPaginator', {static: false} ) paginator: MatPaginator = <MatPaginator>{};
  @ViewChild( 'tableContainer') tableContainer!: ElementRef;
  constructor(
    private http: HttpClient,
    public location: Location,
    private router: Router
    // @Inject('$rootScope') private $rootScope: any,
  ){
    this.updateColumns()
  }

  ngOnInit() {
    this.range.valueChanges.subscribe( next => {
      // if ( next.start && next.end ) {
      //   console.log(next);
      //   this.fetchOrders();
      // }
    })
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    console.log(this.paginator)
    this.fetchOrders();
    this.paginator.page.subscribe( data =>{ 
      // this.fetchOrders() 
      this.sortData(this.lastSort);
    } )
  }

  private subscription!: Subscription;

  isOpen = false;

  closeOverlay(event) { 
    console.log(event)
    event.stopPropagation();

    this.isOpen = false;
  }

  // range = new FormGroup({
  //   start: new FormControl<Date | null>(moment.utc('2023-08-11').toDate()),
  //   end: new FormControl<Date | null>(moment.utc('2023-08-21').toDate()),
  // });

  range = new FormGroup({
    start: new FormControl<Date | null>(moment().subtract(2, 'weeks').toDate()),
    end: new FormControl<Date | null>(moment().toDate()),
  });
  dateString: string = '';
  

  dateRangeChange(dateRangeStart, dateRangeEnd) {
    console.log(dateRangeStart, dateRangeEnd);
    let {start, end} = this.range.value;
    console.log(start,end);
    if(start && end) {
      this.fetchOrders();
    }
  }

  resetRange() {
    this.range.patchValue({
      start: null,
      end: null,
    });
  }

  exportCSV = () => {
		console.log(this.exportCols);
    let {start, end} = this.range.value;

		const flatList = this.orders.map( order => 
      this.exportCols.map(i => { 
        let keys = i.field.replace(/]/g,"").replace(/\[/g,".").split(".");
        let value = keys.reduce((obj,i) => obj && obj[i] , order)
        return {key: i.displayName ? i.displayName : i.field,  value: value === null ? '' : value };
      }) 
    );
    

		console.log(flatList);
		            
    let headerNames = flatList[0].map( i => i .key );
		const csv = [
			headerNames.join(','), // header row first
			...flatList.map( order => order.map( i => i.value && JSON.stringify(i.value).replace(/\\"/g, '""')).join(',') )
		].join('\r\n')

		console.log(csv)

   
    let startText = moment(start).format('YYMMDD');
    let endText = moment(end).format('YYMMDD');

		let title = 'Orders-report'; 
		const blob = new File([csv], title + '-' + startText + '-' + endText + '.csv', { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);
	};

  orders: any = [];
  sortedData: any[] = [];
  displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'itemName', 'itemType', 'itemPrice', 'total', 'items', 'merch', 'transaction', 'processed', 'updated' ];
  displayedFilters: string[] = ['id-filter', 'name-filter', 'email-filter', 'phone-filter', 'itemName-filter', 'itemType-filter', 'itemPrice-filter', 'total-filter', 'items-filter', 'merch-filter', 'transaction-filter', 'processed-filter', 'updated-filter'];

  allColumns: any[] = [
    { 'column': 'id', 'visible': true},
    { 'column': 'name', 'visible': true},
    { 'column': 'invoice', 'visible': false},
    { 'column': 'email', 'visible': true},
    { 'column': 'phone', 'visible': false}, 
    { 'column': 'itemName', 'visible': true},
    { 'column': 'itemType', 'visible': true},
    { 'column': 'itemPrice', 'visible': true},
    { 'column': 'total', 'visible': true},  
    { 'column': 'items', 'visible': true},
    { 'column': 'merch', 'visible': true}, 
    { 'column': 'transaction', 'visible': true}, 
    { 'column': 'payer', 'visible': false},
    { 'column': 'transtatus', 'visible': false}, 
    { 'column': 'processed', 'visible': false}, 
    { 'column': 'created', 'visible': false },
    { 'column': 'updated', 'visible': true }
  ];
  
  public pieChartType: ChartType = 'pie';

  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'],
    datasets: [
      {
        data: [300, 500, 100],
      },
    ],
  };

  public pieChartOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  incremental: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: '# Items',
        yAxisID: 'y1',
        pointRadius: 2,
        borderWidth: 1,
        borderColor: 'hsla(128, 75%, 75%, 1)',
        backgroundColor: 'rgba(128, 116, 168, 0.4)',
        pointBackgroundColor: 'rgba(128, 116, 168, 1)',
        pointBorderColor: 'rgba(128, 116, 168, 1)',
        fill: 'origin'
      },
      {
        data: [],
        label: 'Accumulative Total',
        yAxisID: 'y2',
        pointStyle: false, 
        pointRadius: 0,
        borderWidth: 1,
        borderColor: 'rgba(196, 100, 135, 1)',
        backgroundColor: 'hsla(128, 75%, 75%, 0.4)',
        fill: 'origin',
      }
    ]
  }

  incrementalOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y1: {
        type: 'linear',
        position: 'left',
        ticks: { font: { size: 8 }}
      },
      y2: {
        type: 'linear',
        position: 'right',
        ticks: { font: { size: 8 }}
      },
      x: {
        ticks: { font: { size: 8 }}
      }
    },
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 14
              }
          }
      }
  }
  };


  public dailyTotals?: ChartData<'bar'>;
  
  public dailyTotalsOptions: ChartConfiguration<'bar'>['options'] = {
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };


  public dailyBreakdown?: ChartData<'bar'>;
  
  public dailyBreakdownOptions: ChartConfiguration<'bar'>['options'] = {
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        stacked: true
      },
      y: {
        min: 10,
        stacked: true
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  public paymentTypes?: any;
  // public paymentTypes?: ChartData<'doughnut', number[], string | string[]>;

  public paymentTypesOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function(context : any) {
            let label = context.dataset.labels && context.dataset.labels[context.dataIndex] + ": " + context.dataset.data[context.dataIndex];
							return label;
          }
        }
      }
    },

  };


  public paymentTotalTypes?: ChartData<'pie', number[], string | string[]>;

  public paymentTotalTypesOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  public prizeTypes?: ChartData<'pie', number[], string | string[]>;

  public prizeTypesOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };


  lastSort: Sort = { active: 'id', direction: '' };
  page = 1;
  searchResultsSelection: any = {}
  filters: any = {};
  resultsLength = 40000;
  resultsLoading: boolean = false;

  changeColumns = (column, $event) => {
    console.log(column, $event);
    this.allColumns[column].visible = $event.checked;
    this.updateColumns();
  }

  updateColumns = () => {
    this.displayedColumns = this.allColumns.filter(i => i.visible).map(i => i.column)
    this.displayedFilters = this.allColumns.filter(i => i.visible).map(i => i.column + '-filter')
  }

  clickedRows = (row) => {
    console.log(row);
    this.searchResultsSelection = row;
    this.router.navigateByUrl('/orders/' + row.order_id)
  };


	fetchOrders = () => {
    this.resultsLoading = true;
    // return this.http.post<any>(environment.apiSrc + '/new-filter-orders', {reception: true, page: (this.paginator ? this.paginator.pageIndex + 1 : 1), ...this.filters }).subscribe(
    //   data => { 
    //     data.orders.forEach((i,n ) => {i.hasMerch = i.items_json && i.items_json.some(i => i.type === 'merch')})
    //     this.orders = data.orders;
    //     this.resultsLength = data.total;
    //     this.sortData(this.lastSort);
    //     this.ordersTable && this.ordersTable.renderRows();
    //     this.resultsLoading = false;
    //     this.tableContainer.nativeElement.scrollTo({ top: 0, left: 0, behaviour: 'smooth'})
    //   }
    // );

    let start = this.range.controls.start.value?.toISOString().split('T')[0];
    let end = this.range.controls.end.value?.toISOString().split('T')[0];

    console.log(start, end);
    return this.http.get<any>(environment.apiSrc + '/orders-by-date/' + start + '/' + end ).subscribe(
      data => { 

        let items: any[] = []
        data.filter(i => (i.invoice_no !== null) ).forEach(order => { 
          order.hasMerch = order.items_json && order.items_json.some(item => item.type === 'merch')
          order.completed = order.transaction.filter(
            f => f.capture_status === "COMPLETED"
          ); 
          items = items.concat(
            order.items_json.map( item => ({...item, ...order}) )
          )
        })


        items.sort((a:any,b:any) => ( a.order_id - b.order_id ))

			  let breakdown = {}
        items.forEach( i => { 
          i.breakdown = round( moment(i.created_at), moment.duration(15, "minutes"), "round" ).format('MM DD HH:mm') 
        } )
        let itemsPerBlock = groupBy(items, i => i.breakdown)

        let blocks = {}
        for (let m = moment(start); m.isBefore( moment(end).add(1, 'day') ); m.add(15, 'minutes')) {
            // console.log(m.format('YYYY-MM-DD h:mmA'));
            let interval = m.format('MM DD HH:mm')
            blocks[interval] = itemsPerBlock[interval] ? itemsPerBlock[interval] : []
        }

        let incremental: any[] = [];
        let incrementalItems: any[] = [];
        let incrementalTotal: any[] = [];

        Object.keys(blocks).forEach((i, x) => { incrementalItems.push( blocks[i].length ) });

			  let last = 0;
      
        Object.keys(blocks).forEach((i, x) => { 
          let prices = blocks[i].map( i => i.itemPrice );
          let totals = prices.reduce((accumulator, currentValue) => accumulator + currentValue, last ); 
          last = totals; incrementalTotal.push( totals ) 
        });

        this.incremental = {
          datasets: [
            {
              data: incrementalItems,
              label: '# Items',
              yAxisID: 'y1',
              pointRadius: 2,
              borderWidth: 1,
              borderColor: 'hsla(220, 75%, 75%, 1)',
              backgroundColor: 'hsla(220, 75%, 75%, 0.4)',
              pointBackgroundColor: 'hsla(220, 75%, 75%, 1)',
              pointBorderColor: 'hsla(220, 75%, 75%, 1)',
              fill: 'origin'
            },
            {
              data: incrementalTotal,
              label: 'Accumulative Total',
              yAxisID: 'y2',
              pointStyle: false,
              pointRadius: 0,
              borderWidth: 1,
              borderColor: 'hsla(350, 75%, 75%, 1)',
              backgroundColor: 'hsla(350, 75%, 75%, 0.5)',
              fill: 'origin',
            }
          ],
          labels: Object.keys(blocks)
        }


        let daily = { filter: {}, totals: {} }

        for (let day = moment(start); day.isBefore( moment(end).add(1, 'day') ); day.add(1, 'day')) {
          let datestart = day.format('YYYY-MM-DD');
          let dateend = moment(day).add(1, 'day').format('YYYY-MM-DD');
          // console.log(datestart, dateend);
          daily.filter[datestart] = items.filter(i => (i.invoice_no !== null && moment( i.created_at ).isBetween(datestart, dateend) ) );
          daily.totals[datestart] = daily.filter[datestart].map(i => i.itemPrice).reduce((a, c) => Number(a) + Number(c), 0)
        }

        this.dailyTotals = {
          labels: Object.keys(daily.totals),
          datasets: [
            { data: Object.values(daily.totals), label: 'Totals' },
          ],
        };

        let dailyBreakdown = { filter: {}, totals: {} }

        for (var day = moment(start); day.isBefore( moment(end).add(1, 'day') ); day.add(1, 'day')) {
          let datestart = day.format('YYYY-MM-DD');
          let dateend = moment(day).add(1, 'day').format('YYYY-MM-DD');
          // console.log(datestart, dateend);
          dailyBreakdown.filter[datestart] = groupBy(items.filter(i => (i.invoice_no !== null && moment( i.created_at ).isBetween(datestart, dateend) ) ), i => i.type );
        }

        let subscription = Object.keys(dailyBreakdown.filter).map(i => { let a = dailyBreakdown.filter[i].subscription; if (a) {  return a.map(i => i.itemPrice ).reduce( (c, d) => c + d, 0 ) } else { return 0 } } );

        let donation = Object.keys(dailyBreakdown.filter).map(i => { let a = dailyBreakdown.filter[i].donation; if (a) {  return a.map(i => i.itemPrice ).reduce( (c, d) => c + d, 0 ) } else { return 0 } } );
    
        let merch = Object.keys(dailyBreakdown.filter).map(i => { let a = dailyBreakdown.filter[i].merch; if (a) {  return a.map(i => i.itemPrice ).reduce( (c, d) => c + d, 0 ) } else { return 0 } } );
  
        this.dailyBreakdown  = { 
          labels: Object.keys(daily.totals),
          datasets: [ 
            {
              label: 'Donations',
              data: donation,
            },
            {
              label: 'Merch',
              data: merch,
            },
            {
              label: 'Subscription',
              data: subscription,
            },
          ]
        }

        let types = groupBy(items.filter(i => (i.invoice_no !== null) ), i => i.type )
        let ids = groupBy(items.filter(i => (i.invoice_no !== null) ), i => i.type === 'subscription' ? i.id : i.type )
        let newIds = { ...ids, 'merch': ids.merch, 'donation': ids.donation }
        let newTypes = { ...types, 'merch': types.merch, 'donation': types.donation }

        let labels: string[] = []
        let values: number[] = [];
        let colours: string[] = [];
        let typeColours: string[] = [];

        
        Object.entries(newTypes).forEach((i:any, index) => { 
          if(i[0] === 'subscription') { 
            let group = groupBy(i[1], ({ id }) => id);
            let colour;
            Object.entries(group).sort((a: any, b: any) => b[1].length - a[1].length).forEach( (i:any) => { 
              labels = [...labels, i[0]];
              colour = 'hsl('+ Math.floor((Math.random() * 60) + (index * 90) - 44 ) +  ', 75%, 75%)';
              colours.push(colour);
              values = [...values, i[1].length ]; 
            }) 
            typeColours.push(colour);
          }
          else { 
            labels = [...labels, i[0]]; 
            let colour = 'hsl(' + ((index * 90) - 44)  + ', 75%, 75%)';
            colours.push(colour);
            typeColours.push(colour);
            values = [...values, i[1].length ]; 
          } 
        } )

        this.paymentTypes = {
          
          datasets: [
            {
              labels: labels,
              data: values,
              backgroundColor: colours, 
            },
            {
              labels: Object.keys(newTypes),
              data: Object.values(newTypes).map((i:any) => i.length),
              backgroundColor: typeColours,
            },
          ],
        };
          
        console.log(this.paymentTypes)

        this.paymentTotalTypes = {
          labels: Object.keys(newTypes),
          datasets: [
            {
              data: Object.values(newTypes).map((i:any) => ( i.map(a => a.itemPrice).reduce( (b, c) => b + c, 0 ) ))
            },
          ],
        };
        
        let subsAll = { ...types };
        let prizeType = 'goldprize';
        let aaP = groupBy(subsAll.subscription, i => (i['goldprize'] || i['aprilAtonementPrize'] || i['monthlyPrize']) );

        this.prizeTypes = {
          labels: Object.keys(aaP),
          datasets: [
            {
              data: Object.values(aaP).map((i: any) => i.length)
            },
          ],
        };

        this.orders = items;
        this.resultsLength = items.length;
        this.sortData(this.lastSort);
        this.ordersTable && this.ordersTable.renderRows();
        this.resultsLoading = false;
        this.tableContainer.nativeElement.scrollTo({ top: 0, left: 0, behaviour: 'smooth'})
      }
    );
	}

  applyFilter(event: any, filter: string) {

    this.paginator.pageIndex = 0;
    let filterFunction;
    let filterValue = 'value' in event ? event.value : (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase();

    switch(filter) {
      case 'id':
        if(!filterValue)
          delete this.filters.order_id;
        else 
          this.filters = { ...this.filters, order_id: filterValue};
        filterFunction = i => i.order_id.toString().includes(filterValue);
        break;
      case 'email':
        if(!filterValue)
          this.filters.zedder && delete this.filters.zedder.email;
        else 
          this.filters = { ...this.filters, zedder: { ...this.filters.zedder, email: filterValue } };
        filterFunction = i => i.zedder.email.includes(filterValue);
        break;
      case 'phone':
        if(!filterValue)
          this.filters.zedder && delete this.filters.zedder.phone;
        else 
          this.filters = { ...this.filters, zedder: { ...this.filters.zedder, phone: filterValue } };
        filterFunction = i => i.zedder.phone.includes(filterValue);
        break;
      case 'name':
        if(!filterValue)
          this.filters.zedder && delete this.filters.zedder.name;
        else 
          this.filters = { ...this.filters, zedder: { ...this.filters.zedder, name: filterValue } };
        filterFunction = i => (i.zedder.firstname  + ' ' + i.zedder.lastname).trim().toLowerCase().includes(filterValue);
        break;
      case 'merch':
        if(!filterValue)
          delete this.filters.hasMerch;
        else 
          this.filters = { ...this.filters, hasMerch: filterValue};
        filterFunction = i => i.hasMerch === (filterValue === 'true');
        break;
      case 'processed':
        if(!filterValue)
          delete this.filters.processed;
        else 
          this.filters = { ...this.filters, processed: filterValue};
        filterFunction = i => i.processed === filterValue;
        break;
      default:
        filterFunction = i => i;
    }

    // console.log(this.filters)
    // if(filterValue)
    //  this.sortedData = this.orders.slice().filter( filterFunction )
    // else
    //   this.sortedData = this.orders.slice()

    this.fetchOrders();
    // return this.http.post<any>(environment.apiSrc + '/new-filter-orders', {reception: true, page: (this.paginator ? this.paginator.pageIndex + 1 : 1), ...this.filters }).subscribe(
    //   data => { 
    //     data.orders.forEach((i,n ) => {i.hasMerch = i.items_json && i.items_json.some(i => i.type === 'merch')})
    //     this.resultsLength = data.total;
    //     this.orders = data.orders;
    //     this.sortData(this.lastSort);
    //     this.ordersTable && this.ordersTable.renderRows();

    //   }
    // );
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = this.orders.slice( (this.paginator.pageIndex * 40), (this.paginator.pageIndex * 40) + 40  );

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction   === 'asc';
      switch (sort.active) {
        case 'id':
          return compare(a.order_id, b.order_id, isAsc);
        case 'name':
          return compare(a.zedder.firstname + a.zedder.lastname, b.zedder.firstname + b.zedder.lastname, isAsc);
        case 'invoice':
          return compare(a.invoice_no, b.invoice_no, isAsc);
        case 'email':
          return compare(a.zedder.email, b.zedder.email, isAsc);
        case 'phone':
          return compare(a.zedder.phone, b.zedder.phone, isAsc);
        case 'merch':
          return compare(a.hasMerch, b.hasMerch, isAsc);
        case 'total':
          return compare(a.total, b.total, isAsc);
        case 'transaction':
          return compare(a.transaction[0].service, b.transaction[0].service, isAsc);
        case 'processed':
          return compare(a.processed, b.processed, isAsc);
        case 'updated':
          return compare(a.updated_at, b.updated_at, isAsc);
        default:
          return 0;
      }
    });
  }

  exportCols = [
    {
        field: 'order_id',
        displayName: 'Order ID',
    }, {
        field: 'invoice_no',
        displayName: 'Invoice #'
    }, {
        field: 'zedder.firstname',
        displayName: 'Firstname'
    },{
        field: 'zedder.lastname',
        displayName: 'Lastname'
    }, {
        field: 'zedder.email',
        displayName: 'Email',
    }, {
        field: 'zedder.phone',
        displayName: 'Phone',
    }, {
        field: 'name',
        displayName: 'Item'
    }, {
        field: 'type',
        displayName: 'Item Type'
    }, {
        field: 'itemPrice',
        displayName: 'Item Price'
    }, {
        field: 'total',
        displayName: 'Order Total'
    }, {
        field: 'shipping_price',
        displayName: 'Order Shipping'
    }, {
        field: 'transaction[0].service',
        displayName: 'Transaction Type'
    },{
        field: 'completed[0].transaction_id',
        displayName: 'Transaction ID',
        visible: false
    },{
        field: 'completed[0].payer_id',
        displayName: 'Payer ID',
        visible: false
    },{
        field: 'completed[0].payment_id',
        displayName: 'Payment ID',
        visible: true
    },{
        field: 'completed[0].gross',
        displayName: 'Gross ammount',
        visible: false
    },{
        field: 'completed[0].fee',
        displayName: 'PayPal Fee',
        visible: false
    },{
        field: 'completed[0].net',
        displayName: 'Net Ammount',
        visible: false
    },{
        field: 'completed[0].capture_status',
        displayName: 'Transaction Status'
    },{
        field: 'completed[0].pledge_paid',
        displayName: 'Pledge Payment'
    },{
        field: 'items_json.length',
        displayName: 'Total Items'
    },{
        field: 'created_at',
        displayName: 'Created'
    },{
        field: 'updated_at',
        displayName: 'Updated',
        sort: { priority: 0, direction: 'desc' },
    },{
        field: 'zedder.delivery_address.address',
        displayName: 'Address',
    },{
        field: 'zedder.delivery_address.locality',
        displayName: 'Suburb',
    },{
        field: 'zedder.delivery_address.region',
        displayName: 'State',
    },{
        field: 'zedder.delivery_address.postcode',
        displayName: 'Postcode',
    }
  ];
}
