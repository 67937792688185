<mat-toolbar [formGroup]="filterForm" style="gap: 8px; align-items: center;">
	<div>Current Campaigns</div>

	<div style="flex: 1"></div>

	<mat-form-field subscriptSizing="dynamic">
    <mat-label>Search</mat-label>
    <input formControlName="searchTerm" matInput placeholder="Search">
  </mat-form-field>

	<mat-form-field subscriptSizing="dynamic">
		<mat-label>Campaign Types</mat-label>
		<mat-select formControlName="cat" multiple>
			<mat-option *ngFor="let cat of campaignCat" [value]="cat">{{cat}}</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field subscriptSizing="dynamic">
		<mat-label>Sort by</mat-label>
		<mat-select formControlName="sort">
			<mat-option value="created_at">Created</mat-option>
			<mat-option value="commence_date">Commencing</mat-option>
			<mat-option value="complete_date">Completed</mat-option>
			<mat-option value="title">Name</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-slide-toggle formControlName="archived">Archived</mat-slide-toggle>

</mat-toolbar>

<section>

	<h2 style="padding-left: 16px; padding-top: 16px;">Spots</h2>
	<div style="display: flex;">
		<div *ngFor="let spots of grouped" style="min-width: 25vw">
			<mat-list>
				<a [routerLink]="'/sponsorship/' + spot.promo_id" *ngFor="let spot of spots">
					<mat-list-item [style.backgroundColor]="campaignColours[spot.campaign_cat]">
						<mat-icon (click)="playTemp(spot.media_location)" [matTooltip]="spot.media_location" matListItemIcon>play_circle</mat-icon>
						<span matListItemTitle>{{spot.title}}</span>
						<span matListItemLine>{{spot.promo.contact && spot.promo.contact.org_nm}}</span>
						<span matListItemLine>{{spot.commence_date}} - {{spot.complete_date}}</span>
					</mat-list-item>
				</a>
			</mat-list>
		</div>
	</div>
	

	<h2 style="padding-left: 16px; padding-top: 16px;">Live Reads</h2>
	<div style="display: flex;">
		<mat-list>
			<a [routerLink]="'/sponsorship/' + item.promo_id" *ngFor="let item of filteredLiveReads">
				<mat-list-item>
					<span matListItemTitle>{{item.title}}</span>
					<span matListItemLine>{{item.promo && item.promo.contact && item.promo.contact.org_nm}}</span>
					<span matListItemLine>{{item.commence_date}} - {{item.complete_date}}</span>
				</mat-list-item>
			</a>
		</mat-list>
	</div>


	<h2 style="padding-left: 16px; padding-top: 16px;">Giveaway</h2>
	<div style="display: flex;">
		<mat-list>
			<a [routerLink]="'/sponsorship/' + item.promo_id" *ngFor="let item of filteredGiveaways">
				<mat-list-item>
					<span matListItemTitle>{{item.title}}</span>
					<span matListItemLine>{{item.promo && item.promo.contact && item.promo.contact.org_nm}}</span>
					<span matListItemLine>{{item.commence_date}} - {{item.complete_date}}</span>
				</mat-list-item>
			</a>
		</mat-list>
	</div>

</section>

<div class="floating-fab">
  <button (click)="addCampaign()" mat-fab color="primary" matTooltip="Add Campaign" aria-label="Add Campaign">
    <mat-icon>add</mat-icon>
  </button>
</div>