import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  constructor() { }
  private subject = new Subject<any>();

  private playlist = new Subject<any>();


  prepForBroadcast = (input: any) => {
		if(input.action) {
      this.sendMessage({action: input.action, value: input});
    }
    else {
			this.sendMessage({action: 'addAndPlay', value: input });
		}
	};

  sendMessage(message: any) {
    this.subject.next({ action: message.action, value: message.value });
  }

  clearMessages() {
    this.subject.next(null);
  }

  onMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  onPlaylistMessage(): Observable<any> {
    return this.playlist.asObservable();
  }

  sendPlaylistMessage(message: any) {
    this.playlist.next(message);
  }
}
