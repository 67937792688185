import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Sponsorship } from '../sponsorship';
import { Moment } from 'moment';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { xor } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { LiveReadsDialogComponent } from '../live-reads-dialog/live-reads-dialog.component';
import { SponsorshipNotesEditorDialogComponent } from '../sponsorship-notes-editor-dialog/sponsorship-notes-editor-dialog.component';
import { CampaignScheduleService } from '../campaign-schedule.service';
import { environment } from 'src/environments/environment';
import { PlaylistService } from '../playlist.service';
 
@Component({
  selector: 'app-sponsorship-scheduler',
  templateUrl: './sponsorship-scheduler.component.html',
  styleUrls: ['./sponsorship-scheduler.component.scss']
})
export class SponsorshipSchedulerComponent extends Sponsorship {
  @ViewChild('spotList') public spotList?: ElementRef<HTMLElement>;
  constructor(
    private http: HttpClient,
		// @Inject('$rootScope') private $rootScope: any,
		private CampaignScheduleService: CampaignScheduleService,
    public dialog: MatDialog,
    private PlaylistService: PlaylistService
  ) {
    super();

    this.http.get<any>(environment.musicDBapiSrc + '/grid').subscribe( data => {
      const grid = data.gridByBlockWeek;

      this.grid = { 
        'Monday': grid.Monday, 
        'Tuesday': grid.Tuesday, 
        'Wednesday': grid.Wednesday, 
        'Thursday': grid.Thursday, 
        'Friday': grid.Friday, 
        'Saturday': grid.Saturday, 
        'Sunday': grid.Sunday 
      };

      this.thehours.forEach( hour => {
        this.gridFlip[hour] = {} 
      });


      this.recalc()
    });

    this.thedatepicker = this.thedate.toDate();

    

  }

  thedate: Moment = moment().startOf('isoWeek');

  thedays: string[] = Array(7).fill(0).map((_, i) => moment().day(i + 1).format('dddd')); 
  thehours: string[] =  Array(24).fill(0).map((_, i) => moment().hour(i).minute(0).second(0).format('HH:mm')); 
  theWeeklySpots: any[] = [];
  liveReads: any[] = [];
  
  time: string[] =  Array(24).fill(0).map((_, i) => moment().hour(i).minute(0).second(0).format('HH:mm')); 

  campaigns: any = [];

  
  grid = {
    'Monday': {}, 
    'Tuesday': {}, 
    'Wednesday': {}, 
    'Thursday': {}, 
    'Friday': {}, 
    'Saturday': {}, 
    'Sunday': {}
  }

  gridFlip: any = {
    
  }


  gridWeek: any = {

  }


  spotListHidden: boolean = false;

  thedatepicker: Date;


  ngOnInit() {
    // const cdkScrollable: CdkScrollable = <CdkScrollable>containers.keys().next().value;

    // if (cdkScrollable)
    // {
    //   console.log(cdkScrollable.getElementRef().nativeElement);
    // }
  }

  playTemp (filename) {
    let item = {filename: filename, share: 'fela'};
    this.PlaylistService.prepForBroadcast({action: 'queueTempAndPlay', playlist: [ item ]})
    // let url = environment.musicDBapiSrc + '/temporary/dl'
    // this.http.post<any>(url, { file: file, share: 'fela' }).subscribe( data => {
    //   var token = data.token
    //   var url = environment.dbUrl + '/fela/' + token
    //   this.$rootScope.player.src = url;
    //   this.$rootScope.player.play()
    // });
  }

  prevDate() {
    this.thedate = this.thedate.subtract(7, 'days')
    this.thedatepicker = this.thedate.toDate();
    this.recalc();
  }
  nextDate() {
    this.thedate = this.thedate.add(7, 'days')
    this.thedatepicker = this.thedate.toDate();
    this.recalc();
  }

  toggleCampaigns = colour => {
    this.campaigns = xor(this.campaigns, [ colour ]);
    console.log(this.campaigns)
  }

  spotsStyling = (spot) => {
    let style = { textDecoration: 'none', fontWeight: 'bold', opacity: 0.75, color:'#000', cursor: 'pointer', backgroundColor: spot.campaign_type === 3 ? 'hsl(7, 42%, 62.7%)' : this.campaignColours[spot.campaign_cat] }
    if (spot.spotsRemaining || spot.spotsRemaining === 0) {
      if (spot.spotsRemaining < 1) {
        style = {...style, cursor: 'not-allowed', textDecoration: 'line-through', fontWeight: 'normal'  } 
      }
      else { 
        style = { ...style, cursor: 'pointer' };
      }
    }
    else {
      if ( spot.runs_campaign || spot.runs_campaign === 0 ) { 
        if ((spot.runs_campaign - spot.runs_total) < 1) {
          style = { ...style, cursor: 'not-allowed', textDecoration: 'line-through', fontWeight: 'normal' }
        } 
        else {
          style = { ...style, cursor: 'pointer' }
        } 
      } else {
        style = { ...style, cursor: 'pointer' };
      } 
    }
    return style;
  }

  campaignCatStyle = (colour) => ({
     backgroundColor: colour, 
     border: this.campaigns.includes(colour) ? '2px solid #fff' : '2px solid '+ colour
  })

 
  
  itemClass(promo) {
    let classes : string[] = [];
    if (promo.calDate && promo.calDate !== promo.date) 
      classes.push('recur');
    if (promo.campaign_type === 3)
       classes.push('giveaway');
    else
      classes.push(this.campaignIds[promo.campaign_cat]);
    return classes.join(' ');
  }

  dateCompare (promo) {
    return moment(promo.calDate).isSameOrAfter(promo.date)
  }

  filterByCampaignType = (items, $index) => {
    // debugger;
    // $scope.campaignColours[items.campaign_cat]
    return this.campaigns.length === 0  || this.campaigns.includes(this.campaignColours[items.campaign_cat]) ;
  };

  recurring (promo, action) {
    console.log(promo, action)
    if(action === 'add'){
      promo.recur_end = promo.calDate;
      this.http.post(environment.apiSrc + '/scheduled-campaign/recurring-end/' + promo.schedule_id, { schedule_id: promo.schedule_id, date: promo.calDate, action: action } ).subscribe( data => console.log(data));
    }
    if(action === 'remove'){
      promo.recur_end = null;
      this.http.post(environment.apiSrc + '/scheduled-campaign/recurring-end/' + promo.schedule_id, { schedule_id: promo.schedule_id, date: promo.calDate, action: action } ).subscribe( data => console.log(data));
    }
  }


  totalLength = (array) => { 
    let total = array.map((i) => i.media_length).reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue));
    return Math.ceil(total);
  }

  weeklySpots(start_date, end_date) {
    const currentSpots = this.http.get<any[]>(environment.apiSrc + '/campaign-by-date/1/' + start_date + '/' + end_date);
    const currentGiveaways = this.http.get<any[]>(environment.apiSrc + '/campaign-by-date/3/' + start_date + '/' + end_date);
    const currentLiveReads = this.http.get<any[]>(environment.apiSrc + '/campaign-by-date/2/' + start_date + '/' + end_date);

    forkJoin(
      currentSpots,
      currentGiveaways,
      currentLiveReads,
    ).subscribe( data => { 
      this.liveReads = data[2];

      let spots = data[0].concat(data[1]);
      spots.forEach( (bla, key) => {
        if(bla.runs_campaign) {
          // response.data[key].runs_campaign  = bla.runs_campaign - bla.runs_total;
        }
      })

      this.theWeeklySpots = [ ...data[0], ...data[1]] ; 
      // $scope.the_weekly_spots = response.data.sort((a,b) => a.title.localeCompare(b.title) ) ;
      this.schedule(start_date);

    } );

  }

  filterWeeklySpots = () => this.theWeeklySpots.filter(this.filterByCampaignType).sort( (a,b) => a.title.localeCompare(b.title) )
  
  datePredicate(drag: any, drop: any){
    let time = moment(drop.data.thedate).add(drop.data.dayIndex, 'days').add(drop.hourIndex, 'hours');
    let result =  time.isSameOrAfter(drag.data.commenceDate ) && time.isSameOrBefore(drag.data.completeDate +'T23:59:59');
    return result;
  }

  drop = ($event, show) => {
    // console.log($event, show)
    const { item, previousContainer, container, previousIndex } = $event;
    console.log(item.data, container.data, previousContainer.data)
    console.log(show.promo)
    if(previousContainer.data && previousContainer.data.show) {
      previousContainer.data.show.promo.splice(previousIndex, 1);
    }
    const spot = item.data.spot;
    const newSpot = { ...item.data.spot }
    show.promo.push(newSpot);

    if (spot.spotsThisWeek || spot.spotsThisWeek === 0){
      spot.spotsThisWeek++;
    }
    if (spot.spotsRemaining || spot.spotsRemaining === 0){
      spot.spotsRemaining--;
    }
    if (spot.runs_total || spot.runs_total === 0){
      spot.runs_total++;
    } 
    if(spot.schedule_id) {
      let schedule: { campaign_id?: number, date?: string, week?: number, day?: any, hour?: any, slug?: string, recurring?: string } = {};
      schedule.campaign_id = spot.id;
      schedule.date = moment(container.data.thedate).add(container.data.dayIndex, 'days').format('YYYY-MM-DD');
      schedule.week = container.data.thedate.week();
      schedule.day = show.day;
      schedule.hour = show.hour;
      schedule.slug = show.program.slug;
      schedule.recurring = spot.recurring;
      this.CampaignScheduleService.update(schedule);
    } else {
      let schedule: any = {};
      schedule.campaign_id = spot.id;
      schedule.date = moment(container.data.thedate).add(container.data.dayIndex, 'days').format('YYYY-MM-DD');
      schedule.week = container.data.thedate.week();
      schedule.day = show.day;
      schedule.hour = show.hour;
      schedule.slug = show.program.slug;
      schedule.recurring = spot.recurring;
      this.CampaignScheduleService.save(schedule).subscribe( schedule => {
        console.log('added');
        console.log(schedule)
        newSpot.schedule_id = schedule.id;
      });
      this.http.get(environment.apiSrc + '/campaign-total/plus/' + spot.id).subscribe( data => { console.log(data) } );
    }
  }

  returnDrop = ($event) => {
    const data = $event.item.data;

    if(data.hour && data.dayIndex !== null) {
      let show = this.gridWeek[this.thedate.format('YYYY-MM-DD')][data.hour][data.dayIndex];

      console.log(data);
      const spot = this.theWeeklySpots.find(i => i.id === data.spot.id) ;
      
      if (spot.spotsThisWeek || spot.spotsThisWeek === 0){
        spot.spotsThisWeek--;
      }
      if (spot.spotsRemaining || spot.spotsRemaining === 0){
        spot.spotsRemaining++;
      }
      if (spot.runs_total || spot.runs_total === 0){
        spot.runs_total--;
      } 
      this.http.get(environment.apiSrc + '/campaign-total/minus/' + data.spot.id).subscribe( data => { console.log(data) } );
      this.http.delete(environment.apiSrc + '/campaign-schedule/' + data.spot.schedule_id).subscribe( data => { console.log(data) } );

      let showIndex = show.promo.findIndex( i => i === data.spot );
      console.log(showIndex);

      show.promo.splice(showIndex, 1);
    }
  }

  dragStarted (event) {
    // console.log(event, this.spotList)
    if(this.spotList)
      this.spotList.nativeElement.style.overflow = 'hidden';

  }

  dragEnded (event) {
    // console.log(event, this.spotList)
    if(this.spotList)
        this.spotList.nativeElement.style.overflow = 'scroll';

  }

  schedule = (date) => {
    this.http.get<any[]>(environment.apiSrc + '/schedule/week/' + date).subscribe( data => {
      data.forEach( schedule => {
        const scheduletime = moment(schedule.date).hour(schedule.hour).minute(0).second(0);
        const hour = scheduletime.format('HH:mm');
        const day = scheduletime.isoWeekday() - 1;
        // console.log($scope.gridflip[date][hour][day])
        // console.log(schedule.campaign)
        if(!schedule.campaign) {
          debugger;
          return;
        }

        schedule.campaign.schedule_id = schedule.id;
        if (schedule.campaign.runs_campaign) {
          schedule.campaign.runs_campaign = 1;
        }
        if (schedule.campaign.runs_week) {
          schedule.campaign.runs_week = 1;
        }
        if(moment(date).add(7, 'days').isSameOrAfter(schedule.date)) { 
          this.gridWeek[date][hour][day].promo.push({...schedule.campaign, date: schedule.date, calDate: moment(date).add(day, 'days').format('YYYY-MM-DD'), recur_end: schedule.recur_end })
        }
      })
      let campaignLog = data.filter(i => moment(i.date)
        .isBefore(moment(date).add(7, 'days')))
        .map(i => i.campaign)
        .reduce( (r, a) => {
          r[a.id] = r[a.id] || [];
          r[a.id].push(a);
          return r;
        }, Object.create(null));
      
      Object.keys(campaignLog).forEach(i => { 
        let campaign = this.theWeeklySpots.find( s => s.id === Number(i));
        if (campaign) {
          campaign.spotsThisWeek = campaignLog[i].length;
          campaign.spotsRemaining = campaign.runs_campaign ? campaign.runs_campaign - campaign.runs_total : campaign.runs_week - campaign.spotsThisWeek;
        }
      })
      console.log(campaignLog);

      return data;
    });
  };


  recalc() {
    var theweek = this.thedate.format('YYYY-MM-DD')
    var endweek = moment(this.thedate).add(6, 'days').format('YYYY-MM-DD');
    this.weeklySpots(theweek, endweek);
    // if (! $scope.gridflip[theweek] ) {
      // $scope.schedule(theweek)

    var dayindex = 0;
    for( let day in this.grid) {
      const days = this.grid[day];

      for( let key in days ) {
        const hour = days[key];
        var hrmin = key.split(':')

        this.gridFlip[key][this.thedays.indexOf(day)] = structuredClone(hour);
        this.gridFlip[key][this.thedays.indexOf(day)].thetime = moment(this.thedate).add(dayindex, 'days').add(hrmin[0], 'hours').toDate();
        this.gridFlip[key][this.thedays.indexOf(day)].durationHours = hour.duration / 3600;
        this.gridFlip[key][this.thedays.indexOf(day)].promo = [];
      }
      dayindex++;
    }

    this.gridWeek[theweek] = structuredClone(this.gridFlip);

    // }
  }

  showCalendar() {

  }

  editNotes() {
    this.dialog.open(SponsorshipNotesEditorDialogComponent, {
      // height: '80vh',
      // width: 'calc(80vh * 3/4)',
      data: {
  
      },
    });
  }

  showLiveReads() {
    this.dialog.open(LiveReadsDialogComponent, {
      height: '80vh',
      width: 'calc(80vh * 3/4)',
      data: {
        liveReads: this.liveReads
      },
    });
  }

  checkPlayed() {
    const theweek = this.thedate.format('YYYY-MM-DD')
    const endweek = moment(this.thedate).add(6, 'days').format('YYYY-MM-DD');

    this.http.get<any>(environment.musicDBapiSrc + '/mairlist/promo/week/'+ theweek + '/' + endweek).subscribe(data => {
      console.log(data);

      Object.values(this.gridWeek[this.thedate.format('YYYY-MM-DD')]).forEach( (i:any) => {
        Object.values(i).forEach(
        (a:any) => { 
          let start = this.thedate.toDate(); 
          start.setDate(start.getDate() + ((a.day + 6) % 7) ); 
          start.setHours(start.getHours() + a.hour);
          let end = new Date(start);
          end.setSeconds(end.getSeconds() + a.duration)
          let played = data.filter(item => {
            let date = new Date(item.time);
            return date >= start && date <= end;
          })
          let files = played.map(i => i.filename.replace(/^(.*[\\\/])?(.*?\.[^.]*?|)$/, '$2'))
          a.promo.forEach(a => { a.played = files.includes(a.media_location) })
          // console.log(a.program.slug, start, end, files, played, a.promo.map(i => ({ media: i.media_location, played: i.played }) )) 
        }) 
      })
    })
  }



}
